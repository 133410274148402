import React, { useEffect, useState } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import times from 'lodash/times';
import { useIntl } from 'react-intl';
import CollapsibleInput from './CollapsibleInput';
import DropdownInput from '../components/DropdownInput';
import booleanOptions from '../data/booleanOptions';
import organizationTypes from '../data/organizationTypes';
import { Box, Checkbox, InputAdornment, Typography } from '@mui/material';
import { useCalculations } from '../utils/useCalculations';
import TextFieldInput from "../components/TextFieldInput";


export default function AssumptionInputs() {
  const { formatMessage } = useIntl();
  const {
    lifespanYears,
    organizationType,
    isPublicEntity,
    isTaxExempt,
    setInput,
    chargerInstallationCost,
    vehicleSets,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
  } = useInputs();

  const lifespanOptions = times(15, (i) => ({
    value: i + 1,
    children: formatMessage({ id: 'timePeriods.years' }, { years: i + 1 }),
  }));
  
  const Calculations = useCalculations();
  const [customInstallation, setCustomInstallation] = useState(false);

  const tmpVehicleSets=vehicleSets.map((vehicleSet) => ({
    ...vehicleSet,
    chargingWindows: vehicleSet.chargingWindows.map((cw) => ({
      ...cw,
      charger: {
        ...cw.charger,
        chargerType: cw.charger.chargerType === "level_2" ? "ac" : "dc",
      }
      
    })),
  }));

  const defaultInstallationCost = Calculations.getInstallationCosts({
    input: tmpVehicleSets,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
  });

  useEffect(() => {
    if(customInstallation === false){
      setInput({ chargerInstallationCost: parseInt(defaultInstallationCost) })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customInstallation])

  return (
    <CollapsibleInput title={formatMessage({ id: 'inputs.assumptions.title' })} customTitleSx={{textTransform: 'none', fontWeight: '400'}}>
      <DropdownInput
        id="vehicle-lifespan-input"
        label={formatMessage({ id: 'inputs.assumptions.vehicleLifespan.label' })}
        options={lifespanOptions}
        value={lifespanYears}
        onChange={(e) => setInput({ lifespanYears: parseInt(e.target.value) })}
        tooltip={formatMessage({ id: 'inputs.assumptions.vehicleLifespan.tooltip' })}
        mb={1.5}
      />

      <DropdownInput
        id="organization-type-input"
        label={formatMessage({ id: 'inputs.assumptions.organizationType.label' })}
        options={organizationTypes.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={organizationType}
        onChange={(e) => setInput({ organizationType: e.target.value })}
        tooltip={formatMessage({ id: 'inputs.assumptions.organizationType.tooltip' })}
        mb={1.5}
      />

      <DropdownInput
        id="public-entity-input"
        label={formatMessage({ id: 'inputs.assumptions.publicEntity.label' })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isPublicEntity ? 'yes' : 'no'}
        onChange={(e) => setInput({ isPublicEntity: e.target.value === 'yes' })}
        tooltip={formatMessage({ id: 'inputs.assumptions.publicEntity.tooltip' })}
        mb={1.5}
      />

      <DropdownInput
        id="tax-exempt-input"
        label={formatMessage({ id: 'inputs.assumptions.taxExempt.label' })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isTaxExempt ? 'yes' : 'no'}
        onChange={(e) => setInput({ isTaxExempt: e.target.value === 'yes' })}
        tooltip={formatMessage({ id: 'inputs.assumptions.taxExempt.tooltip' })}
        mb={0.5}
      />
      <TextFieldInput
        value={chargerInstallationCost}
        label={formatMessage({
          id: "inputs.location.chargerInstallationCost.label",
        })}
        tooltip={formatMessage({
          id: "inputs.location.chargerInstallationCost.tooltip",
        })}
        onBlur={(v) => setInput({ chargerInstallationCost: parseInt(v) })}
        inputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
        mb={1.5}
        disabled={!customInstallation}
      />
      <Box display="flex" mb={2}>
        <Checkbox
          checked={customInstallation}
          onChange={() => setCustomInstallation(e => !e)}
          inputProps={{ "aria-label": "Purchasing for multiple sites" }}
          disableRipple
          sx={{ marginRight: ".1rem", alignSelf: "flex-start" }}
        />
        <Box>
          <Typography fontSize="0.875rem" mb={0.5} mt={1.4}>
            {formatMessage({id: "inputs.location.chargerInstallationCost.checkbox"})}
          </Typography>
        </Box>
      </Box>
    </CollapsibleInput>
  );
}
