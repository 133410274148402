import { get } from '../utils/api';
import {
  transformApiElectricVehicle,
  transformApiFossilVehicle,
  transformApiCharger,
} from '../utils/conversions';
import sampleApiElectricVehicles from '../data/sampleData/sampleApiElectricVehicles';
import sampleApiFossilVehicles from '../data/sampleData/sampleApiFossilVehicles';
import mockApiElectricVehicles from '../data/mock/mockApiElectricVehicles';
import mockApiFossilVehicles from '../data/mock/mockApiFossilVehicles';
import { v4 as uuidv4 } from 'uuid';
import sampleApincentives from '../data/sampleData/sampleApiIncentives';
import sampleApiEvaluatedIncentives from '../data/sampleData/sampleApiEvaluatedIncentives';

export const DEFAULT_CHARGING_WINDOW = {
  start: 18,
  finish: 6,
  chargingApproach: 'Max',
  chargerId: 'AC_11.52_1',
  chargerAccess:'private',
  is_open_to_public: false,
  isGeneric: true,
  id: uuidv4(),
};

// used after DEFAULT_CHARGING_WINDOW has already been added
export const SECONDARY_DEFAULT_CHARGING_WINDOW = {
  start: 6,
  finish: 12,
  chargingApproach: 'Max',
  chargerId: 'AC_7_2_1',
  is_open_to_public: false,
  isGeneric: true,
  id: uuidv4(),
};


export const DEFAULT_VEHICLE_SET = {
  id: uuidv4(),
  name: 'Vehicle Set #1',
  existingVehicleId: 'on_road_step_van_light_duty',
  replacementVehicleId: 'BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024',
  vehicleCount: 20,
  milesPerWorkday: 90,
  personalMilesPerWorkday: 90,
  personalMilesPaidFor: false,
  hoursPerWorkdayHigh: 1,
  hoursPerWorkdayMedium: 1,
  hoursPerWorkdayLow: 1,
  averageMilesWorkday: '',
  workdays: [1, 2, 3, 4, 5],
  workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  chargingWindows: [DEFAULT_CHARGING_WINDOW],
  cargoWeight: 1000,
  model: '',
};
const DEFAULT_ZIP_CODE = '90802';
const DEFAULT_STATE = 'California';
const DEFAULT_POWER_SUPPLIER = 'PG&E';

const defaults = {
  vehicleSets: [DEFAULT_VEHICLE_SET],
  resaleOneYearEv: false,
  resaleOneYearFossil: false,
  resaleLifetimeEv: false,
  resaleLifetimeFossil: false,
  selectedTab: 0,
  startYear: new Date().getFullYear() + 1,
  lifespanYears: 10,
  organizationType: 'logistics_company',
  isPublicEntity: false,
  isTaxExempt: false,
  isMultipleSites: false,
  includeChargerCost: true,
  includeChargerInstallationCost: true,
  zipCode: '90802',
  state: DEFAULT_STATE,
  powerSupplier: DEFAULT_POWER_SUPPLIER,
  isInADac: false,
  buildingType: 'warehouse',
  dieselPrice: 6.2290,
  agriculturalDieselPrice: 6.229,
  gasolinePrice: 5.3170,
  electricityPrice: 0.2702,
  electricityEmissions: 10146.853,
  iceGallonCost: 6.2290,
  isOnboarded: false,
  isCustomChargerChosen: false,
  onboardingStep: 'gettingStarted',
  isResetting: false,
  isSetVehicleModal: false,
  carbonCreditsPrice: 175,
  level2ChargersInstallationCost: 6266,
  dcfcChargersInstallationCost: 79534,
  computeData: true
};

// ONLY USED FOR TESTS
export const getTestingDefaults = () => ({
  ...defaults,
  apiElectricVehicles: sampleApiElectricVehicles,
  apiFossilVehicles: sampleApiFossilVehicles,
  apiIncentives: sampleApincentives,
  apiEvaluatedIncentives: sampleApiEvaluatedIncentives,
  apisLoading: {
    incentives: false,
    evaluatedIncentives: false,
    location: false,
  },
});

const getElectricVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}`);
  if(response.data){
    return response.data.vehicles.map(transformApiElectricVehicle);
  } else{
    return mockApiElectricVehicles.map(transformApiElectricVehicle);
  }
};

const getFossilVehicles = async () => {
  const response = await get(`/commercial/vehicles?postcode=${DEFAULT_ZIP_CODE}&fuel_type=gas`);
  if(response.data){
    return response.data.vehicles.map(transformApiFossilVehicle);
  } else{
    return mockApiFossilVehicles.map(transformApiFossilVehicle);
  }
};

const getChargers = async (zip) => {
  const response = await get(`/commercial/chargers?postcode=${zip || DEFAULT_ZIP_CODE}`);
  return response.data.chargers.map(transformApiCharger);
};

const getIncentives = async (zip) => {
  const response = await get(
    `/commercial/incentives?postcode=${zip || DEFAULT_ZIP_CODE}&project_type=fleets`,
  );
  return response.data.incentives;
};

const getDefaultsWithApi = async (zip) => {
  const [apiElectricVehicles, apiFossilVehicles, apiChargers, apiIncentives] = await Promise.all([
    getElectricVehicles(),
    getFossilVehicles(),
    getChargers(zip),
    getIncentives(zip),
  ]);

  const apiLocationData = (await get(`/location?postcode=${zip || DEFAULT_ZIP_CODE}`)).data
    .location;
  const dieselPrice = apiLocationData.regional_fuel_cost[0].diesel;
 //  apiLocationData.regional_fuel_cost[0].diesel -
 //  (apiLocationData.regional_fuel_cost[0].diesel_tax / 100 +
 //    apiLocationData.national_fuel_cost[0].diesel_tax / 100);
 
  const gasolinePrice = apiLocationData.regional_fuel_cost[0].gasoline;
  const electricityPrice = apiLocationData.regional_fuel_cost[0].commercial_electricity / 100;
  const carbonCreditsPrice = apiLocationData?.carbon_credits?.[0]?.price || 0;
  const electricityEmissions = apiLocationData.regional_electricity[0].emissions.emissions_co2;
  const vehicleSets = [{ ...DEFAULT_VEHICLE_SET }];

  return {
    ...defaults,
    vehicleSets,
    dieselPrice,
    gasolinePrice,
    electricityPrice,
    carbonCreditsPrice,
    electricityEmissions,
    apiChargers,
    apiElectricVehicles: apiElectricVehicles,
    apiFossilVehicles: apiFossilVehicles,
    apiIncentives,
    apiEvaluatedIncentives: [],
    apisLoading: {
      incentives: false,
      evaluatedIncentives: false,
      location: false,
    },
  };
};

export default getDefaultsWithApi;
