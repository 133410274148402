import { useState, useCallback, useMemo } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import uniq from 'lodash.uniq';

const sortConfig = {
  rangeDesc: {
    field: 'range',
    method: 'desc',
  },
  rangeAsc: {
    field: 'range',
    method: 'asc',
  },
};

const useVehiclesByType = () => {
  const { apiElectricVehicles } = useInputs();

  return apiElectricVehicles
    .sort((a, b) => b.range - a.range)
    .reduce(
      (acc, vehicle) => {
        return {
          ...acc,
          all: {
            ...acc.all,
            all: [...(acc.all.all || []), { ...vehicle }],
            [vehicle.subtype]: [...(acc[vehicle.type]?.[vehicle.subtype] || []), { ...vehicle }],
          },
          [vehicle.type]: {
            ...(acc[vehicle.type] || []),
            all: [...(acc[vehicle.type]?.all || []), { ...vehicle }],
            [vehicle.subtype]: [...(acc[vehicle.type]?.[vehicle.subtype] || []), { ...vehicle }],
          },
        };
      },
      { all: apiElectricVehicles },
    );
};

const SHOW_COUNT = 24;

const useFilteredVehicles = (type = 'all') => {
  const vehiclesByType = useVehiclesByType();
  const [showCount, setShowCount] = useState(SHOW_COUNT);

  const showMore = () => setShowCount(showCount + SHOW_COUNT);
  const resetShowCount = () => setShowCount(SHOW_COUNT);

  const [sortOrder, _setSortOrder] = useState('rangeDesc');
  const setSortOrder = (value) => {
    resetShowCount();
    _setSortOrder(value);
  };

  const [filters, setFilters] = useState({
    type: type,
    subtype: 'all',
    make: 'all',
    rangeMin: 0,
    rangeMax: 0,
  });

  const resetFilters = () => {
    setFilters({
      type: type,
      subtype: 'all',
      make: 'all',
      rangeMin: 0,
      rangeMax: 0,
    });
  };

  const updateFilter = useCallback(
    (name, value) => {
      resetShowCount();
      if (name === 'type') {
        setFilters({
          ...filters,
          type: value,
          subtype: 'all',
          make: 'all',
        });
      } else if (name === 'range') {
        const { rangeMin, rangeMax } = value;
        setFilters({
          ...filters,
          rangeMin,
          rangeMax,
        });
      } else {
        setFilters({ ...filters, [name]: value });
      }
    },
    [filters],
  );

  const filteredVehicles = useMemo(
    () =>
      vehiclesByType?.[filters.type][filters.subtype].filter(
        ({ make, range }) =>
          (filters.make === 'all' || filters.make === make) &&
          (filters.rangeMin === 0 || filters.rangeMin <= range) &&
          (filters.rangeMax === 0 || filters.rangeMax >= range),
      ),
    [
      filters.make,
      filters.rangeMax,
      filters.rangeMin,
      filters.subtype,
      filters.type,
      vehiclesByType,
    ],
  );

  // Vehicles are sorted descending by range at the start of useVehiclesByType
  // We can skip sorting again as long as 'range' is the only way to sort
  /** @type Array<Vehicle> */
  const vehicles =
    sortConfig[sortOrder].method === 'asc' ? filteredVehicles.reverse() : filteredVehicles;

  const types = useMemo(() => Object.keys(vehiclesByType), [vehiclesByType]);
  const subtypes = useMemo(
    () => Object.keys(vehiclesByType[filters.type]),
    [filters.type, vehiclesByType],
  );
  const makes = useMemo(
    () =>
      uniq(vehiclesByType[filters.type].all.map(({ make }) => make)).sort((a, b) =>
        a.localeCompare(b),
      ),
    [filters.type, vehiclesByType],
  );

  return {
    vehicles,
    sortOrder,
    sortConfig,
    setSortOrder,
    filters,
    updateFilter,
    resetFilters,
    types,
    subtypes,
    makes,
    showCount,
    showMore,
  };
};

export default useFilteredVehicles;
