import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import TextFieldInput from "../../../components/TextFieldInput";
import StatsCard from "./StatsCard";
import { ELECTRIC_VEHICLES } from "../../../data/VEHICLES";
import TabSelector from "./TabSelector";

export default function SelectVehicleStep({
  vehicleSet,
  onChangeVehicleSet,
  onChangeMake,
}) {
  const options = ["Fossil Fuel Make/Model", "EV Replacement Model"];
  const { formatMessage } = useIntl();
  const {
    name,
    vehicleCount,
    replacementVehicle,
    existingVehicle,
    replacementVehicleId,
  } = vehicleSet;

  const [touched, setTouched] = useState({});
  const [tab, setTab] = useState(options[0]);

  const fossilIsSelected = tab === options[0];
  const touchField = (fieldName) =>
    setTouched({ ...touched, [fieldName]: true });

  const apiVehicleOptions = ELECTRIC_VEHICLES.map(({ id, name }) => ({
    value: id,
    children: name,
  }));

  useEffect(() => {
    if (apiVehicleOptions.length === 1 && !replacementVehicleId) {
      onChangeVehicleSet({ replacementVehicleId: apiVehicleOptions[0].value });
    }
  }, [apiVehicleOptions, onChangeVehicleSet, replacementVehicleId]);

  return (
    <Grid container display="flex" flexDirection={"column"}>
      <Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Typography mb="26px" fontSize="12px">
          <span style={{ color: "#E35205" }}>*</span> indicates a required field
        </Typography>
      </Box>
      <Box
        width="100%"
        alignItems="center"
        justifyContent={"space-between"}
        display="flex"
        mb="24px"
        sx={{
              '@media (max-width: 768px)': {
                     flexWrap: 'wrap',
                     width: '100%',
              },
       }}
      >
        <Box width="45%" sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
          <TextFieldInput
            value={name}
            label={formatMessage({ id: "onboarding.selectVehicle.name" })}
            onBlur={(v) => onChangeVehicleSet({ name: v })}
            required
          />
        </Box>
        <Box width="45%" sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
          }}>
          <TextFieldInput
            required
            mask="999999"
            value={vehicleCount}
            label={formatMessage({
              id: "onboarding.selectVehicle.numberVehicles",
            })}
            onBlur={(v) => {
              touchField("vehicleCount");
              onChangeVehicleSet({ vehicleCount: parseInt(v) });
            }}
            error={
              touched.vehicleCount &&
              (!vehicleCount || vehicleCount < 1) &&
              formatMessage({
                id: "onboarding.selectVehicle.numberVehiclesError",
              })
            }
          />
        </Box>
      </Box>
      <Box width="100%" alignItems={"center"} justifyContent="center">
        {replacementVehicleId && (
          <StatsCard
            replacementVehicle={replacementVehicle}
            existingVehicle={existingVehicle}
            fossilIsSelected={fossilIsSelected}
            onChangeVehicleSet={onChangeVehicleSet}
            vehicleSet={vehicleSet}
            tab={tab}
            onChangeMake={onChangeMake}
          />
        )}
      </Box>
      <Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        justifyContent="space-around"
        sx={{
              '@media (max-width: 768px)': {
                     flexWrap: 'wrap',
              },
          }}
      >
        <Box
          display="flex"
          width="48%"
          height="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
          }}
        >
          <Typography mt="16px" fontSize="12px">
            Generic vehicle range and cargo capacity are based on an average of
            selected best selling fossil fuel vehicles of this type. Drivetrain
            and fuel are typical of the best selling fossil fuel vehicles of
            this type. All information on competitive models have been pulled
            from OEM websites and is managed by JDPA.
          </Typography>
        </Box>
        <Box
          display="flex"
          width="48%"
          alignItems="flex-start"
          justifyContent="flex-end"
          sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
          }}
        >
          <Typography mt="16px" fontSize="12px">
            GM estimate based on a full charge and subject to change prior to
            production. GM Estimated range based on current capability of
            analytical projection consistent with SAE J1634 revision 2017 - MCT.
            Actual range may vary based on several factors, including
            temperature, terrain, battery age, loading, and how you use and
            maintain your vehicle
          </Typography>
        </Box>
      </Box>
      <TabSelector
        onChangeVehicleSet={onChangeVehicleSet}
        onChangeMake={onChangeMake}
        vehicleSet={vehicleSet}
        tab={tab}
        setTab={setTab}
        options={options}
      />
    </Grid>
  );
}
