import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import DropdownInput from '../../components/DropdownInput';
import { useInputs } from '@bellawatt/use-inputs';
import uniq from 'lodash.uniq';
import CollapsibleInput from '../../inputs/CollapsibleInput';
import TypeFilters from './TypeFilters';

export default function Filters({ onChange }) {
  const { formatMessage } = useIntl();
  const { apiChargers } = useInputs();
  const [chargerMSRP, setChargerMSRP] = useState(0);
  const [chargerMake, setChargerMake] = useState('All');
  const [chargerPorts, setChargerPorts] = useState('All');
  const [chargerOutput, setChargerOutput] = useState(0);
  const [types, setTypes] = useState(['All']);

  const handleSetType = (value) => {
    let newTypes = types;

    if (newTypes.includes('All')) {
      newTypes = [value];
    } else if (newTypes.includes(value)) {
      newTypes = types.filter((val) => val !== value);
      if (newTypes.length === 0) {
        newTypes.push('All');
      }
    } else {
      newTypes.push(value);
    }
    setTypes(newTypes);
    onChange('types', newTypes);
  };

  const chargerBrands = uniq(apiChargers.map(({ make }) => make))
    .map((make) => ({
      value: make,
      children: make,
    }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const portOptions = uniq(
    apiChargers
      .filter((c) => (chargerMake === 'All' || c.make === chargerMake) && c.ports !== 'Unknown')
      .map(({ ports }) => ports),
  )
    .map((ports) => ({ value: ports.toString(), children: ports.toString() }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const maxPrices = [
    {
      value: 0,
      children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
    },
    {
      value: 5000,
      children: `${formatMessage({ id: 'inputs.chargerCatalog.upTo' })} $5,000`,
    },
    {
      value: 10000,
      children: `${formatMessage({ id: 'inputs.chargerCatalog.upTo' })} $10,000`,
    },
    {
      value: 20000,
      children: `${formatMessage({ id: 'inputs.chargerCatalog.upTo' })} $20,000`,
    },
    {
      value: 50000,
      children: `${formatMessage({ id: 'inputs.chargerCatalog.upTo' })} $50,000`,
    },
    {
      value: 100000,
      children: `${formatMessage({ id: 'inputs.chargerCatalog.upTo' })} $100,000`,
    },
  ];

  const outputOptions = [
    {
      value: 0,
      children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
    },
    {
      value: 1,
      children: '≥ 1 kW',
    },
    {
      value: 10,
      children: '≥ 10 kW',
    },
    {
      value: 20,
      children: '≥ 20 kW',
    },
    {
      value: 50,
      children: '≥ 50 kW',
    },
    {
      value: 100,
      children: '≥ 100 kW',
    },
    {
      value: 150,
      children: '≥ 150 kW',
    },
    {
      value: 200,
      children: '≥ 200 kW',
    },
  ];

  chargerBrands.unshift({
    value: 'Generic',
    children: formatMessage({ id: 'inputs.chargerCatalog.generic' }),
  });
  chargerBrands.unshift({
    value: 'All',
    children: formatMessage({ id: 'inputs.chargerCatalog.allBranded' }),
  });
  portOptions.unshift({
    value: 'All',
    children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
  });

  return (
    <Box my={4}>
      <CollapsibleInput
        title={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.filters' })}
        defaultOpen
      >
        <DropdownInput
          id="charger-brands-filter-input"
          label={formatMessage({ id: 'inputs.chargerCatalog.brand' })}
          options={chargerBrands}
          value={chargerMake}
          onChange={(e) => {
            setChargerMake(e.target.value);
            onChange('brand', e.target.value);
          }}
          mb={1}
        />
        <DropdownInput
          id="charger-output-filter-input"
          label={formatMessage({ id: 'inputs.chargerCatalog.minOutput' })}
          options={outputOptions}
          value={chargerOutput}
          onChange={(e) => {
            setChargerOutput(e.target.value);
            onChange('output', e.target.value);
          }}
          mb={1}
        />
        <DropdownInput
          id="charger-price-filter-input"
          label={formatMessage({ id: 'inputs.chargerCatalog.price' })}
          options={maxPrices}
          value={chargerMSRP}
          onChange={(e) => {
            setChargerMSRP(e.target.value);
            onChange('maxPrice', e.target.value);
          }}
          mb={1}
        />
        <DropdownInput
          id="charger-ports-filter-input"
          label={formatMessage({ id: 'inputs.chargerCatalog.ports' })}
          options={portOptions}
          value={chargerPorts}
          onChange={(e) => {
            setChargerPorts(e.target.value);
            onChange('ports', e.target.value);
          }}
          mb={1}
        />
      </CollapsibleInput>
      <CollapsibleInput
        title={formatMessage({ id: 'inputs.chargerCatalog.type' })}
        defaultOpen
        hasBottomBorder
      >
        <TypeFilters types={types} onChange={handleSetType} />
      </CollapsibleInput>
    </Box>
  );
}
