import times from 'lodash/times';
import { isHourInUse } from '../utils/time';

const HOUR_OF_DAY = times(24, (i) => i);

const alarmMultipleChargersInUseAtOnce = (chargingWindows) => {
  const rawPlugSchedule = HOUR_OF_DAY.map((hour) =>
    chargingWindows.filter((chargingWindow) =>
      isHourInUse({ start: chargingWindow.start, finish: chargingWindow.finish }, hour),
    ),
  );

  return rawPlugSchedule.map((hour) => hour.length).some((count) => count >= 2);
};

export default alarmMultipleChargersInUseAtOnce;
