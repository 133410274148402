/* eslint-disable import/prefer-default-export */
import axios from 'axios';

// const BASE_URL= "https://api.beta.zappyride.com";
// const BASE_POST_URL= "https://commercial.beta.zappyride.com";

const BASE_URL= process.env.REACT_APP_BASE_API_URL || 'http://localhost:4000';
const BASE_POST_URL = process.env.REACT_APP_EVALUATOR_API_URL || 'http://localhost:4000';

export const get = (url) =>
  axios.get(`${BASE_URL}${url}`, {
    headers: {
      Authorization: 'Bearer f77ae0a79dcc49e18f1a54db71c1d576',
    },
  });

export const post = (url, data) => axios.post(`${BASE_POST_URL}${url}`, data);
