import { Box, InputAdornment } from "@mui/material";
import { useIntl } from "react-intl";
import TextFieldInput from "../../../components/TextFieldInput";
import { useState, useEffect } from "react";
import { useCalculations } from "../../../utils/useCalculations";
import { useInputs } from '@bellawatt/use-inputs';

const ResaleInputs = ({ isEquivalent, yearResale, setYearResale, lifetimeResale, setLifetimeResale, resaleOneYearEv, resaleOneYearFossil, resaleLifetimeEv, resaleLifetimeFossil}) => {

    const [error, setError] = useState(false)

    const { formatMessage } = useIntl();
    const { vehicleSets, lifespanYears } = useInputs();
    const Calculations = useCalculations();

    let msrpValue
    let initialValue;
    let yearValue;
    let lifetimeResaleValue;

    if(isEquivalent){
        msrpValue = vehicleSets[0].replacementVehicle.msrp
        initialValue = Calculations.calcResaleValue({input:vehicleSets[0].replacementVehicle, yearsOfOperation: 1}) 
        yearValue = initialValue / msrpValue ;
        lifetimeResaleValue = Calculations.calcResaleValue({input:vehicleSets[0].replacementVehicle, yearsOfOperation:lifespanYears })  / msrpValue

    } else {
        msrpValue = vehicleSets[0].existingVehicle.msrp
        initialValue = Calculations.calcResaleValue({input:vehicleSets[0].existingVehicle, yearsOfOperation: 1})
        yearValue = initialValue / vehicleSets[0].existingVehicle.msrp;
        lifetimeResaleValue = Calculations.calcResaleValue({input:vehicleSets[0].existingVehicle, yearsOfOperation: lifespanYears }) / vehicleSets[0].existingVehicle.msrp
    }

    useEffect(() => {
        if(isEquivalent && !resaleOneYearEv) {
            setYearResale((prev) => ({...prev, ev: (yearValue * 100).toFixed(2)}))
        }

        if(!isEquivalent && !resaleOneYearFossil) {
            setYearResale((prev) => ({...prev, fossil: (yearValue * 100).toFixed(2)}))
        }

        if(isEquivalent && !resaleLifetimeEv) {
            setLifetimeResale((prev) => ({...prev, ev: (lifetimeResaleValue * 100).toFixed(2)}))    
        }

        if(!isEquivalent && !resaleLifetimeFossil) {
            setLifetimeResale((prev) => ({...prev, fossil: (lifetimeResaleValue * 100).toFixed(2)}))  
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const handleYearResaleChange = (event) => {
        if(!event || event === '' || event < 0 || isNaN(event) ) return setYearResale(1); 

        if(event > 100){
            setError('Max value is 100%')
            return;
        } else {
            setError(false)
        }
        
        setYearResale((prev) => (isEquivalent ? {...prev, ev: event} : {...prev, fossil: event}));
    };
    
    const handleLifetimeResaleChange = (event) => {
        if(!event || event === '' || event < 0 || isNaN(event)) return setLifetimeResale(1);;

        if(event > 100){
            setError('Max value is 100%')
            return;
        } else {
            setError(false)
        }

        setLifetimeResale((prev) => (isEquivalent ? {...prev, ev: event} : {...prev, fossil: event}));
    };

    const tooltip = formatMessage({id: "onboarding.selectVehicle.tooltip.resale"})

    const yearResaleValue = isEquivalent ? yearResale.ev : yearResale.fossil;
    const litimeResaleValue = isEquivalent ? lifetimeResale.ev : lifetimeResale.fossil;

    return (
      <Box sx={{width: '100%', alignItems: 'center', justifyContent: 'space-between', display: 'flex', marginTop: '35px'}}>
        <Box width={'46%'} display={'flex'}>
            <Box width={'54%'} marginRight={'16px'} >
                <TextFieldInput
                    value={yearResaleValue + '%'}
                    label={formatMessage({
                        id: "onboarding.selectVehicle.labels.resaleYear",
                    })}
                    type="percent"
                    onBlur={(e) => handleYearResaleChange(e)}
                    error={error}
                />
            </Box>
            <Box width={'42%'} >
                <TextFieldInput
                    value={(msrpValue * (yearResaleValue / 100)).toFixed(2)}
                    type="number"
                    onBlur={(e) => console.log(e, "gvwr")}
                    disabled={true}
                    tooltip={tooltip}
                    isThousand
                    inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
        <Box width={'46%'} display={'flex'}>
            <Box width={'54%'} marginRight={'16px'} >
                <TextFieldInput
                    value={litimeResaleValue + '%'}
                    label={formatMessage({
                        id: "onboarding.selectVehicle.labels.resaleLife",
                    }, {value: lifespanYears})}
                    type="percent"
                    onBlur={(e) => handleLifetimeResaleChange(e)}
                    error={error}
                />
            </Box>
            <Box width={'42%'} >
                <TextFieldInput
                    value={((msrpValue) * (litimeResaleValue / 100)).toFixed(2)}
                    type="number"
                    onBlur={(e) => console.log(e, "gvwr")}
                    disabled={true}
                    tooltip={tooltip}
                    isThousand
                    inputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Box>
      </Box>
    );
  };

  export default ResaleInputs