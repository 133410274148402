import React from "react";
import { Grid, Typography } from "@mui/material";
import { YearlyFuelCostChart } from "./YearlyFuelCostChart";
import { useIntl } from "react-intl";
import AnnualFuelCostTable from "./AnnualFuelCostTable";
import FossilFleetTable from "./FossilFleetTable";
import ElectricFleetTable from "./ElectricFleetTable";
import { useInputs } from "@bellawatt/use-inputs";
import getDefaultRate from "../../calculations/rates/defaultRate";
import sum from "lodash/sum";
import { useDisplay } from "../../components/DisplayProvider";
import Link from "../../components/Link";
import { useCalculations } from "../../utils/useCalculations";

export default function Fuel() {
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();
  const Calculations = useCalculations();

  const { vehicleSets, electricityPrice, dieselPrice, gasolinePrice } =
    useInputs();

  const rate = getDefaultRate(electricityPrice);

  const electricCosts = vehicleSets.reduce(
    (acc, vehicleSet) => ({
      ...acc,
      [vehicleSet.id]: Calculations.annualElectricityCosts({
        input: vehicleSet,
        rate,
      }),
    }),
    {}
  );

  const fossilFuelCosts = vehicleSets.reduce(
    (acc, vehicleSet) => ({
      ...acc,
      [vehicleSet.id]: Calculations.annualFossilFuelCosts({
        input: vehicleSet,
        dieselPrice: dieselPrice,
        gasolinePrice: gasolinePrice,
      }) * vehicleSet.vehicleCount,
    }),
    {}
  );
 
  const totalElectricCosts = sum(Object.values(electricCosts));
  const totalFossilFuelCosts = sum(Object.values(fossilFuelCosts));
  const savings = Math.max(totalFossilFuelCosts - totalElectricCosts, 0);

  return (
    <Grid container paddingLeft="40px" sx={{
              '@media (max-width: 768px)': {
                     paddingLeft: '0px',
              },
       }}>
      <Grid item xs={12}>
        <YearlyFuelCostChart
          electricCosts={totalElectricCosts}
          fossilFuelCosts={totalFossilFuelCosts}
          savings={savings}
        />
      </Grid>
      <Grid item xs={12} mt="50px">
        <FossilFleetTable fossilFuelCosts={fossilFuelCosts} />
        <Typography fontSize="11px" fontWeight='300' mt={'6px'}>
          {formatMessage({ id: 'panels.fuel.gasPriceSource' })}
          <Link to="https://gasprices.aaa.com/" external variant="tooltip">
            AAA
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12} mt="53px">
        <ElectricFleetTable electricCosts={electricCosts} fossilFuelCosts={totalFossilFuelCosts}/>
        <Typography fontSize="11px" mt={"6px"}>
          {formatMessage({ id: "panels.fuel.electricPriceSource" })}
          <Link
            to="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a"
            external
            variant="tooltip"
            textTransform="none"
          >
            {formatMessage({ id: "panels.fuel.usEnergy" })}
          </Link>
        </Typography>
        <Typography fontSize="11px" mt={"6px"}>
          {formatMessage({ id: "panels.fuel.priceSourceNote" })}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={mode === "print" ? "200px" : "53px"}>
        <AnnualFuelCostTable
          totalElectricCosts={totalElectricCosts}
          totalFossilFuelCosts={totalFossilFuelCosts}
        />
      </Grid>
    </Grid>
  );
}
