import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { SimpleVehicleCard } from './VehicleCard';
import ContentHeader from './ContentHeader';
import Filters from './Filters';
import VehicleList from './VehicleList';
import useFilteredVehicles from './useFilteredVehicles';
import { titleCase } from '../../utils/formatters';
import ButtonInput from '../../components/ButtonInput';

export default function VehicleCatalog({
  vehicleSet,
  initialType = 'all',
  selectedVehicle,
  onSelect,
}) {
  const { formatMessage } = useIntl();
  const {
    vehicles,
    sortOrder,
    sortConfig,
    setSortOrder,
    filters,
    updateFilter,
    types,
    subtypes,
    makes,
    showCount,
    showMore,
  } = useFilteredVehicles(initialType);

  const sortOptions = useMemo(() => {
    return Object.keys(sortConfig).map((key) => ({
      value: key,
      children: formatMessage({ id: `inputs.vehicleSet.vehicleCatalog.sortOptions.${key}` }),
    }));
  }, [formatMessage, sortConfig]);

  const typeOptions = useMemo(() => {
    return types.map((type) => ({ value: type, children: titleCase(type) }));
  }, [types]);

  const subtypeOptions = useMemo(() => {
    return subtypes.map((subtype) => ({ value: subtype, children: titleCase(subtype) }));
  }, [subtypes]);

  const makeOptions = [
    { value: 'all', children: 'All' },
    ...makes.map((make) => ({ value: make, children: make })),
  ];

  return (
    <Box display="flex" gap="16px" flexWrap={{ xs: 'wrap', md: 'nowrap' }}>
      <Box width={{ xs: '100%', md: '250px' }} position="relative">
        {vehicleSet && <SimpleVehicleCard vehicleSet={vehicleSet} />}
        <Box position="sticky" top={0}>
          <Filters
            onChange={updateFilter}
            filters={filters}
            typeOptions={typeOptions}
            subtypeOptions={subtypeOptions}
            makeOptions={makeOptions}
          />
        </Box>
      </Box>
      <Box flex="1 1">
        <ContentHeader
          subset={showCount}
          total={vehicles.length}
          sortOrder={sortOrder}
          sortOptions={sortOptions}
          changeSort={setSortOrder}
        />
        <VehicleList
          vehicles={vehicles.slice(0, showCount)}
          selectedVehicle={selectedVehicle}
          vehicleCount={vehicleSet?.vehicleCount}
          milesPerWorkday={vehicleSet?.milesPerWorkday}
          onSelect={onSelect}
        />
        {vehicles.length > showCount && (
          <Box width="100%" p="16px" textAlign="center">
            <ButtonInput onClick={showMore}>
              {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.showMore' })}
            </ButtonInput>
          </Box>
        )}
      </Box>
    </Box>
  );
}
