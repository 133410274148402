import React, { useState, useEffect } from "react";
import { Box, Input, Typography } from "@mui/material";
import InputMask from "react-input-mask";
import Tooltip from "./ToolTip";
import { formatAsThousands } from "../utils/formatters";
//import { LockerIcon } from '../assets/icons/icons';

export default function TextInput(props) {
  const {
    value: defaultValue,
    onBlur,
    label,
    tooltip,
    error,
    required,
    mask,
    maskChar,
    type,
    width,
    disabled,
    isThousand,
    ...rest
  } = props;
  const [value, setValue] = useState(defaultValue);

  // value can sometimes change because of external state
  // this ensures the working value matches the real value in the UI
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handlers = {
    onChange: setValue,
    onBlur: onBlur,
  };

  return (
    <Box
      component="label"
      mb={1}
      sx={{
        width: "100%",
        color: (theme) => (error ? "#E35205" : theme.palette.grey[900]),
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          height: "18px",
          fontSize: "14px",
          fontWeight: "300",
          display: "flex",
          gap: "4px",
          color: error ? "#E35205" : "#1A1A1A",
          whiteSpace: "nowrap"
        }}
      >
        {label}
        {required && (
          <Typography color="#E35205" fontSize="14px">
            *
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        {mask ? (
          <MaskedInput
            {...rest}
            value={value}
            {...handlers}
            mask={mask}
            maskChar={maskChar}
            width={width}
            disabled={disabled}
            error={error}
          />
        ) : (
          <PlainInput
            {...rest}
            value={value}
            type={type}
            {...handlers}
            disabled={disabled}
            isThousand={isThousand}
          />
        )}
        {tooltip && <Tooltip content={tooltip}></Tooltip>}
      </Box>
      {error && (
        <Box sx={{ minHeight: "24px", lineHeight: "1.1" }}>{error}</Box>
      )}
    </Box>
  );
}

const inputStyle = (props) => {
  return {
    border: "1px solid #D9D9D6",
    borderRadius: "5px",
    width: props[0]?.width || "100%",
    height: "60px",
    padding: "12px 12px 11px",
    color: "#3D3935",
    "&.Mui-focused": {
      borderColor: "common.DTEPrimary",
    },
    "&.Mui-error": {
      borderColor: "#E35205",
    },
    "&.Mui-disabled": {
      borderColor: "common.grey[300]",
      backgroundColor: props[0]?.theme.palette.grey[100],
    },
  };
};

function PlainInput({
  placeHolder,
  onChange,
  onFocus,
  onBlur,
  error,
  disabled,
  step,
  value,
  type,
  inputProps,
  pattern,
}) {
  const [hasFocus, setHasFocus] = useState(false);

  if (type === "number" && value && !hasFocus) {
    let originalValue = value
    value = formatAsThousands(
      Number(value.toString().replaceAll(",", "")),
      false
    );

    if(originalValue < 1 ) value = value.toString().replaceAll(",", "")

  }
  

  return (
    <Input
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
      onBlur={(event) => {
        setHasFocus(false);
        if (onBlur) {
          onBlur(type === "number" ? +event.target.value : event.target.value);
        }
      }}
      onFocus={(e) => {
        setHasFocus(true);
        if (onFocus) {
          onFocus(e);
        }
      }}
      placeholder={placeHolder}
      disabled={disabled}
      error={error}
      className="input"
      type={type !== "number" ? type : hasFocus ? "number" : "tel"}
      disableUnderline
      sx={(theme) => inputStyle([{ theme }])}
      {...inputProps}
      inputProps={{
        step,
      }}
    />
  );
}

function MaskedInput({
  placeHolder,
  onChange,
  onBlur,
  onFocus,
  error,
  disabled,
  step,
  mask,
  maskChar,
  value,
  type,
  width,
}) {
  return (
    <InputMask
      mask={mask}
      maskChar={maskChar || " "}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      onBlur={(event) => onBlur(event.target.value)}
      onFocus={onFocus}
      placeholder={placeHolder}
      disabled={disabled}
      error={error}
      className="input"
    >
      {(inputProps) => (
        <Input
          width={width}
          {...inputProps}
          type={type}
          disableUnderline
          sx={(theme) => inputStyle([{ width: width, theme }])}
          disabled={disabled}
          inputProps={{
            step,
          }}
        />
      )}
    </InputMask>
  );
}
