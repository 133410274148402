import React from "react";
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useIntl } from "react-intl";
import {
  formatGenericChargerName,
  formatTimeRange,
} from "../../utils/formatters";
import { useDisplay } from "../../components/DisplayProvider";
import { useCalculations } from "../../utils/useCalculations";

const Labels = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Grid container py={2}>
      <Grid item xs={6}>
        <Typography fontSize="13px" color={"#333333"} fontWeight="600">
          {formatMessage({ id: "panels.charging.chargerType" })}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography
          fontSize="14px"
          color={theme.palette.grey[900]}
          fontWeight="700"
        >
          {formatMessage({ id: "panels.charging.vehicle" })}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          fontSize="14px"
          color={theme.palette.grey[900]}
          fontWeight="700"
        >
          {formatMessage({ id: "panels.charging.time" })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default function ChargingSets({ vehicleSets }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();
  const Calculations = useCalculations();

  const chargingSets = Calculations.optimizedChargers({ input: vehicleSets });

  //Print Preview registers as a small screen, but we want to print as large
  const isLargeScreen =
    useMediaQuery((theme) => theme.breakpoints.up("sm")) || mode === "print";

  return (
    <Grid container>
      {isLargeScreen && <Labels />}
      {chargingSets.map(
        (
          {
            charger: { chargerType, name, portKw },
            ports,
            minimumRequired,
            vehicles,
            isGeneric,
          },
          index
        ) => (
          <Grid
            container
            key={index}
            alignItems="center"
            py={2}
            px={"35px"}
            borderTop={`1px solid ${theme.palette.grey[300]}`}
            borderBottom={`1px solid ${theme.palette.grey[300]}`}
            mb={3}
            width="890px"
          >
            {!isLargeScreen && (
              <Grid item xs={6}>
                <Typography fontSize="12px" color={theme.palette.grey[900]}>
                  {formatMessage({
                    id: "panels.charging.chargerType",
                  }).toUpperCase()}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Box display="flex" sx={{
                     '@media (max-width: 768px)': {
                            flexDirection: 'column',
                     },
              }}>
                <Typography
                  fontSize="13px"
                  color={"#333333"}
                  sx={(theme) => ({
                    [theme.breakpoints.up("sm")]: { maxWidth: "150px" },
                     '@media (max-width: 768px)': {
                            maxWidth: '100%',
                     },
                  })}
                >
                  {isGeneric
                    ? formatGenericChargerName({
                        type: formatMessage({
                          id: `chargerTypes.${chargerType}`,
                        }),
                        portKw,
                      })
                    : name}{" "}
                  | &nbsp;
                </Typography>
                <Typography fontSize="13px" color={"#333333"}>
                  {formatMessage({
                    id:
                      ports === 1
                        ? "panels.charging.singlePort"
                        : "panels.charging.dualPort",
                  })}{" "}
                  | &nbsp;
                </Typography>
                <Typography fontSize="13px" color={"#333333"}>
                  {formatMessage(
                    { id: "panels.charging.minimumReq" },
                    { value: minimumRequired }
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={mode === "print" ? 6 : 12} md={6}>
              {vehicles.map(
                (
                  {
                    replacementVehicle,
                    vehicleCount,
                    id,
                    chargingWindow: { start, finish },
                  },
                  index
                ) => (
                  <Grid container alignItems="center" key={`${id}-${index}`}>
                    {!isLargeScreen && (
                      <Grid item xs={6}>
                        <Typography
                          fontSize="12px"
                          color={theme.palette.grey[900]}
                        >
                          {formatMessage({
                            id: "panels.charging.vehicle",
                          }).toUpperCase()}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={mode === "print" ? 8 : 6} md={8}>
                      <Box display="flex" alignItems="center">
                        {isLargeScreen && (
                          <Box width="66px">
                            <img
                              src={replacementVehicle.imageUrl}
                              alt={replacementVehicle.name}
                              style={{ width: "100%", height: "auto" }}
                            />
                          </Box>
                        )}
                        <Box pl={"10px"}>
                          <Typography
                            fontSize="14px"
                            color={theme.palette.grey[900]}
                          >
                            x{vehicleCount} {replacementVehicle.name}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {!isLargeScreen && (
                      <Grid item xs={6}>
                        <Typography
                          fontSize="12px"
                          color={theme.palette.grey[900]}
                        >
                          {formatMessage({
                            id: "panels.charging.time",
                          }).toUpperCase()}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={mode === "print" ? 4 : 6} md={4}>
                      <Box>
                        <Typography
                          fontSize="14px"
                          color={theme.palette.grey[900]}
                        >
                          {formatTimeRange(start, finish)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        )
      )}
    </Grid>
  );
}
