import { createTheme } from '@mui/material/styles';
import SFProTextRegular from '../assets/fonts/SFProTextRegular.ttf';
import SFProTextSemibold from '../assets/fonts/SFProTextSemibold.ttf';
import ShellHeavy from '../assets/fonts/ShellHeavy.ttf';

const BLUE = '#0072CE'; // BLUE
const YELLOW = '#F3CA24';
const RED_100 = '#FAE7E7'; // RED
const RED_200 = '#FCE8E9';
const RED_400 = '#DD1D21';
const RED_600 = '#CC292C';
const RED_800 = '#692B30';
const WHITE = '#ffffff'; // WHITE
const AMBER_200 = '#FFEED1';
const AMBER_800 = '#925E04';
const BLUE_200 = '#89CFDC'; // LIGHT_BLUE
const BLUE_400 = '#009EB4'; // MID_BLUE
const BLUE_500 = '#316AB4'; //subtle blue
const BLUE_600 = '#003C88'; // DARK_BLUE
const PURPLE_200 = '#BA95BE'; // LILAC
const PURPLE_400 = '#641964'; // PURPLE
const BROWN_200 = '#FFEAC2'; // SAND
const BROWN_600 = '#743410'; // BROWN
const ORANGE_200 = '#FFEED1'; // ORANGE
const ORANGE_400 = '#EB8705'; // ORANGE
const ORANGE_900 = '#924E04';
const GREEN_100 = '#E4F0EA';
const GREEN_200 = '#BED50F'; // LIGHT_GREEN
const GREEN_600 = '#008443'; // DARK_GREEN
const GREEN_800 = '#1D4A3A';
const TEAL_100 = '#E5EBF5';
const TEAL_200 = '#99D5DD';
const TEAL_300 = '#89ccdd';
const TEAL_400 = '#0097A9';
const TEAL_800 = '#0097BB';

const GREY_100 = '#F7F7F7';
const GREY_200 = '#F0F0F0';
const GREY_300 = '#D9D9D9';
const GREY_500 = '#A6A6A6';
const GREY_600 = '#7F7F7F';
const GREY_700 = '#777777';
const GREY_800 = '#595959';
const GREY_900 = '#404040';

const BORDER = '#0000000f';

const theme = createTheme({
  settings: {
    maxWidth: {
      header: '1440px',
      body: '1440px',
      cardContent: '1118px',
      footer: '1392px',
    },
  },
  palette: {
    background: GREY_300,
    primary: {
      main: BLUE,
    },
    grey: {
      100: GREY_100,
      200: GREY_200,
      300: GREY_300,
      500: GREY_500,
      600: GREY_600,
      700: GREY_700,
      800: GREY_800,
      900: GREY_900,
    },
    common: {
      border: BORDER,
      white: WHITE,
      yellow: YELLOW,
      amber: {
        200: AMBER_200,
        800: AMBER_800,
      },
      red: {
        100: RED_100,
        200: RED_200,
        400: RED_400,
        600: RED_600,
        800: RED_800,
      },
      blue: {
        200: BLUE_200,
        400: BLUE_400,
        500: BLUE_500,
        600: BLUE_600,
      },
      purple: {
        200: PURPLE_200,
        400: PURPLE_400,
      },
      brown: {
        200: BROWN_200,
        600: BROWN_600,
      },
      orange: {
        200: ORANGE_200,
        400: ORANGE_400,
        900: ORANGE_900,
      },
      teal: {
        100: TEAL_100,
        200: TEAL_200,
        300: TEAL_300,
        400: TEAL_400,
        800: TEAL_800,
      },
      green: {
        100: GREEN_100,
        200: GREEN_200,
        600: GREEN_600,
        800: GREEN_800,
      },
    },
  },
  typography: {
    fontFamily:
      "'Overpass', sans-serif",
    primary: {
      fontFamily: 'Overpass',
      fontWeight: '600',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Shell'), url(${ShellHeavy}) format('truetype');
        }
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          // //src: local('SF Pro'), url(${SFProTextRegular}) format('truetype');
        }
        @font-face {
          font-family: 'Overpass', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          // src: local('SF Pro'), url(${SFProTextSemibold}) format('truetype');
        }
        
        body {
          color: ${GREY_900}
        }
      `,
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: GREY_300,
          [`&.Mui-checked`]: {
            color: TEAL_800,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: `1px solid ${GREY_300}`,
          borderRadius: '2px',
          textTransform: 'none',
          color: GREY_900,
          padding: '10px',
          height: '35px',
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          ':not(:first-of-type)': {
            marginLeft: '20px',
            borderLeft: `1px solid ${GREY_300}`,
            borderRadius: '2px',
          },
          ':not(:last-of-type)': {
            borderRadius: '2px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: WHITE,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

export default theme;
