import React from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ChargingWindows from '../../dialogs/editVehicleSetDialog/ChargingWindows';
import vehicle from '../../assets/images/zevoImg.png';
import { useInputs } from '@bellawatt/use-inputs';

export default function ChargingBehaviorStep({ vehicleSet, onChangeVehicleSet }) {
  const { replacementVehicle } = vehicleSet;
  const { formatMessage } = useIntl();
  const { includeChargerCost, includeChargerInstallationCost, setInput } = useInputs();
 
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        mb={4}
        style={{ border: '1px solid #BBBBBB', padding: '16px', borderRadius: '16px' }}
      >
        <Box mr={'5%'} ml='2%'>
          <img src={vehicle} alt={replacementVehicle?.name} height="80px" />
        </Box>

        <Box>
          <Typography fontWeight={300} fontSize="14px" mb='8px'>
            {formatMessage({ id: 'onboarding.chargingBehavior.selectChargersFor' })}
          </Typography>
          <Typography fontWeight={300} fontSize="16px">
            {replacementVehicle?.name}
          </Typography>
        </Box>
      </Box>
      <ChargingWindows
        vehicleSet={vehicleSet}
        onChangeVehicleSet={onChangeVehicleSet}
        isOnboarding
      />
      <Box display="flex" mt="24px">
        <Checkbox
          id="chargerCost" 
          checked={includeChargerCost}
          onChange={(e) => {
            setInput({ includeChargerCost: e.target.checked });
          }}
          inputProps={{ 'aria-label': 'Purchasing for multiple sites' }}
          disableRipple
          sx={{ marginRight: '8px', alignSelf: 'flex-start', padding: '0' }}
          style={{color: includeChargerCost ? '#0072CE' : '#333333'}}
        />
          <Box>
            <label htmlFor="multipleSites">
              <Typography fontSize="14px" fontWeight="300" color="#3D3935">
                {formatMessage({
                  id: 'onboarding.chargingBehavior.includeChargerCosts',
                })}
              </Typography>
            </label>
          </Box>
      </Box>
      <Box display="flex" mt="16px">
        <Checkbox
          id="chargerInstalationCost" 
          checked={includeChargerInstallationCost}
          onChange={(e) => {
            setInput({ includeChargerInstallationCost: e.target.checked });
          }}
          inputProps={{ 'aria-label': 'Purchasing for multiple sites' }}
          disableRipple
          sx={{ marginRight: '8px', alignSelf: 'flex-start', padding: '0' }}
          style={{color: includeChargerInstallationCost ? '#0072CE' : '#333333'}}
        />
        <Box>
          <label htmlFor="multipleSites">
            <Typography fontSize="14px" fontWeight="300" color="#3D3935">
              {formatMessage({
                id: 'onboarding.chargingBehavior.includeChargerInstallationCosts',
              })}
            </Typography>
          </label>
        </Box>
      </Box>
    </>
  );
}
