import React, { useState } from 'react';

const MobileMenuContext = React.createContext({ isOpen: false });

export default function MobileMenuProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <MobileMenuContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </MobileMenuContext.Provider>
  );
}

export const useMobileMenu = () => React.useContext(MobileMenuContext);
