import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

export default function TypeFilters({ onChange, types }) {
  const { formatMessage } = useIntl();

  const checkboxes = {
    wallMounted: 'Wall mounted',
    pedestal: 'Pedestal',
    overhead: 'Overhead',
  };

  return (
    <Box>
      {Object.keys(checkboxes).map((key) => (
        <Box key={key} display="flex" alignItems="center">
          <Checkbox
            id={key}
            checked={types.includes(checkboxes[key])}
            onChange={() => onChange(checkboxes[key])}
            inputProps={{ 'aria-label': `${checkboxes[key]} chargers` }}
            disableRipple
            sx={{ alignSelf: 'flex-start' }}
          />
          <Box>
            <label htmlFor={key}>
              <Typography fontSize="0.875rem">
                {formatMessage({
                  id: `inputs.chargerCatalog.${key}`,
                })}
              </Typography>
            </label>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
