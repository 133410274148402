import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { InfoIcon } from "../../assets/icons/icons";
import ButtonInput from "../../components/ButtonInput";
import Connectivity from "../../tabs/charging/Connectivity";
import ChargerDetails from "../../tabs/charging/ChargerDetails";
import {
  formatGenericChargerName,
  formatTimeRange,
} from "../../utils/formatters";
import { cloneDeep } from "lodash";
import CHARGERS from "../../data/CHARGERS";
import { useInputs } from "@bellawatt/use-inputs";
import OutputMessage from "./OutputMessage";
import { useCalculations } from "../../utils/useCalculations";

const Spec = ({ spec }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" justifyContent="space-between" py="3px">
      <Typography
        fontSize="11px"
        lineHeight="16px"
        color={theme.palette.grey[900]}
        fontWeight="700"
        pb={1}
      >
        {formatMessage({ id: spec.name })}
      </Typography>
      <Typography
        fontSize="11px"
        lineHeight="16px"
        color={theme.palette.grey[900]}
        textAlign="left"
        width="50%"
      >
        {spec.value}
      </Typography>
    </Box>
  );
};

const Title = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p="10px"
      m="6px"
      borderBottom={`1px solid ${theme.palette.grey[200]}`}
    >
      <Typography
        fontSize="16px"
        fontWeight="700"
        color={theme.palette.grey[900]}
      >
        {formatMessage({ id: "inputs.chargerCatalog.requirements" })}
      </Typography>
      <InfoIcon />
    </Box>
  );
};

export function SimpleChargerCard({
  milesPerDay,
  vehicleName,
  vehicleCount,
  batteryCapacity,
  efficiency,
  start,
  finish,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  //specs that do not currently have mock values are commented out
  const specList = [
    {
      name: "inputs.chargerCatalog.vehicle",
      value: vehicleName,
    },
    {
      name: "inputs.chargerCatalog.vehicleCount",
      value: vehicleCount,
    },
    {
      name: "inputs.chargerCatalog.capacity",
      value: batteryCapacity,
    },
    {
      name: "inputs.chargerCatalog.milesPerDay",
      value: `${milesPerDay} ${formatMessage({
        id: "inputs.chargerCatalog.milesAb",
      })}`,
    },
    {
      name: "inputs.chargerCatalog.efficiency",
      value: `${efficiency?.toFixed(2)} ${formatMessage({
        id: "inputs.chargerCatalog.efficiencyMiles",
      })}`,
    },
    {
      name: "inputs.chargerCatalog.window",
      value: `${formatTimeRange(start, finish)}`,
    },
    {
      name: "inputs.chargerCatalog.duration",
      value: `${
        start < finish ? finish - start : 23 - start + finish
      } ${formatMessage({
        id: "inputs.chargerCatalog.hours",
      })}`,
    },
  ];

  return (
    <Box border={`1px solid ${theme.palette.grey[300]}`} borderRadius="2px">
      <Title name={vehicleName} />
      <Box px="10px">
        {specList.map((spec) => (
          <Spec spec={spec} key={spec.name} />
        ))}
      </Box>
    </Box>
  );
}

export function ComplexChargerCard({
  setName,
  vehicleCount,
  replacementOption,
  selectedCharger,
  onSelect,
  onlyCharger,
  vehicleSet,
  showingGeneric,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { ports, chargerType, imageUrl, name, portKw } = replacementOption;
  const { apiChargers } = useInputs();
  const Calculations = useCalculations();

  //TODO: missing info
  const image =
    imageUrl ||
    "https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png";

  const isDualPort = ports === 2;

  const cardBorder =
    selectedCharger.id === replacementOption.id
      ? `2px solid ${theme.palette.primary.main}`
      : `1px solid ${theme.palette.grey[300]}`;

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const meetsMinimum = () => {
    if (onlyCharger) {
      let newChargingWindow = cloneDeep(vehicleSet.chargingWindows[0]);
      newChargingWindow = {
        ...newChargingWindow,
        chargerId: replacementOption.id,
        charger: showingGeneric
          ? CHARGERS.find(({ id }) => id === replacementOption.id)
          : apiChargers.find(({ id }) => id === replacementOption.id),
      };

      const newSet = {
        ...vehicleSet,
        chargingWindows: [newChargingWindow],
      };
      const minimumStateOfCharge = Calculations.minimumStateOfCharge({
        input: newSet,
      });
      return minimumStateOfCharge > 0;
    }
    //if the vehicle set has more than one charging window, then we don't actually care to calculate
    return true;
  };
  const chargerMeetsMinimum = meetsMinimum();

  return (
    <Box
      minWidth="240px"
      maxWidth="320px"
      flex="1 1"
      border={cardBorder}
      borderRadius="4px"
      boxShadow="0px 0px 20px rgba(0, 0, 0, 0.1)"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        p="10px"
        m="6px"
        backgroundColor={theme.palette.grey[100]}
      >
        <Typography fontSize="14px" color={theme.palette.grey[900]}>
          {vehicleCount}x
        </Typography>
        <Typography fontSize="14px" color={theme.palette.grey[900]}>
          {setName}
        </Typography>
      </Box>
      {!chargerMeetsMinimum && <OutputMessage />}
      <Box px={2} display="flex" flexDirection="column" flexGrow={1}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: { height: "150px" },
          })}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="16px">{name}</Typography>
          </Box>
          <Typography>
            {formatGenericChargerName({
              type: formatMessage({ id: `chargerTypes.${chargerType}` }),
              portKw,
            })}
          </Typography>
          <Typography
            fontSize="12px"
            color={theme.palette.grey[900]}
            borderRadius="4px"
            px="10px"
            py="2px"
            mt="10px"
            height="22px"
            backgroundColor={
              isDualPort ? theme.palette.grey[200] : "transparent"
            }
            width="fit-content"
          >
            {isDualPort
              ? formatMessage({ id: "panels.charging.infoCard.dualPort" })
              : ""}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" alignItems="center" mb={1}>
          <img src={image} alt={name} width="auto" height="124px" />
        </Box>

        <Connectivity charger={replacementOption} />

        {isLargeScreen && (
          <Box flexGrow={1}>
            <ChargerDetails charger={replacementOption} />
          </Box>
        )}

        <Box textAlign="center" py={2}>
          <ButtonInput
            variant={
              selectedCharger.id === replacementOption.id
                ? "contained"
                : "outlined"
            }
            onClick={onSelect}
            textTransform="none"
          >
            {formatMessage({
              id: `inputs.chargerCatalog.${
                selectedCharger.id === replacementOption.id
                  ? "selected"
                  : "selectReplacement"
              }`,
            })}
          </ButtonInput>
        </Box>
      </Box>
    </Box>
  );
}
