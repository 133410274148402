import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Dialog from '../dialog/Dialog';
import ButtonInput from '../../components/ButtonInput';

export default function ExternalSiteDialog({ isOpen, onCancel, to }) {
  const { formatMessage } = useIntl();

  return (
    <Dialog
      width="550px"
      isOpen={isOpen}
      onClose={onCancel}
      title={formatMessage({ id: 'externalSiteDialog.title' })}
      footer={
        <Box
          sx={{
            textDecoration: 'none',
            color: (theme) => theme.palette.primary.main,
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
          }}
        >
          <ButtonInput link onClick={onCancel}>
            {formatMessage({ id: 'externalSiteDialog.cancel' })}
          </ButtonInput>
          <a
            href={to}
            onClick={onCancel}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'none',
            }}
          >
            <ButtonInput variant="filled">
              {formatMessage({ id: 'externalSiteDialog.continue' })}
            </ButtonInput>
          </a>
        </Box>
      }
    >
      {formatMessage({ id: 'externalSiteDialog.message' })}
    </Dialog>
  );
}
