import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import ToolTip from '../../components/ToolTip';
import { formatAsDollars } from '../../utils/formatters';
import { useInputs } from '@bellawatt/use-inputs';
import { useCalculations } from "../../utils/useCalculations";
import sum from "lodash/sum";
import GlobalData from '../../calculations/GlobalData'

const StyledCell = styled(TableCell)(({ theme, flex }) => ({
  border: 'none',
  fontSize: '14px',
  fontWeight: 400,
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} 0`,
  'th&': {
    fontWeight: 300,
  },
  display: flex,
  verticalAlign: 'top',
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.border}`,
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.common.border}`,
  },
}));

export default function FinancialsTable({
  totalRetiringFleetCosts,
  totalElectrifiedFleetCosts,
  state,
  totalFleetMaintenance,
  setTotalValues
}) {
  const { formatMessage } = useIntl();
  const { includeChargerCost, includeChargerInstallationCost, vehicleSets, lifespanYears, resaleOneYearEv, resaleOneYearFossil, resaleLifetimeEv, resaleLifetimeFossil } = useInputs();
  const Calculations = useCalculations();
  const { data } = GlobalData();

  const totalVehicles = sum(
    vehicleSets.map(({ vehicleCount }) => vehicleCount)
  );

  const electricAggregated = totalElectrifiedFleetCosts.byCategory;
  const fossilAggregated = totalRetiringFleetCosts.byCategory;
 
  let fossilResaleValues = Calculations.calcResaleValue({input:vehicleSets[0].existingVehicle, yearsOfOperation: lifespanYears})
  let eVResaleValues = Calculations.calcResaleValue({input:vehicleSets[0].replacementVehicle, yearsOfOperation: lifespanYears})

  if((resaleOneYearEv && resaleLifetimeEv) || (resaleOneYearFossil && resaleLifetimeFossil)) {
    const resaleData =  {resaleFirstYearEv: resaleOneYearEv, resaleFirstYearFossil: resaleOneYearFossil, resaleLifeTimeEv: resaleLifetimeEv, resaleLifeTimeFossil: resaleLifetimeFossil }
    Calculations?.setData({resaleInputs: resaleData })

    fossilResaleValues = Calculations.calcResaleValue({
      input:vehicleSets[0].existingVehicle, 
      yearsOfOperation: lifespanYears, 
      resaleInputs: resaleData
    });

    eVResaleValues = Calculations.calcResaleValue({
      input:vehicleSets[0].replacementVehicle, 
      yearsOfOperation: lifespanYears, 
      resaleInputs: resaleData
    })
  }

  fossilResaleValues *= -totalVehicles;
  eVResaleValues *= -totalVehicles;

 
  const evSavings = Object.keys(electricAggregated).reduce((acc, key) => {
   
    if (!acc[key]) {
      acc[key] = (fossilAggregated[key] || 0) - electricAggregated[key];
    }

    return acc;
  }, {});

  const fossilInsurenceCost = vehicleSets.reduce((totalCost, set) => {
    for (let i = 1; i <= lifespanYears; i++) {
      totalCost += Calculations.annualInsuranceCosts({
        input: set.existingVehicle,
        count: set.vehicleCount,
        state: state,
        yearsOfOperation: i
      });
    }
    return totalCost;
  }, 0);
  
  const evInsurenceCost = vehicleSets.reduce((totalCost, set) => {
    for (let i = 1; i <= lifespanYears; i++) {
      totalCost += Calculations.annualInsuranceCosts({
        input: set.replacementVehicle,
        count: set.vehicleCount,
        state: state,
        yearsOfOperation: i
      });
    }
    return totalCost;
  }, 0);
  
  const insuranceSavings = (fossilInsurenceCost - evInsurenceCost)

  const totalEvCost = electricAggregated.fuel + electricAggregated.chargers + electricAggregated.installationCosts + totalFleetMaintenance.electric + electricAggregated.purchase + eVResaleValues + electricAggregated.incentives + evInsurenceCost

  const totalFossilCost = (fossilAggregated.fuel * lifespanYears) + totalFleetMaintenance.fossil + fossilAggregated.purchase + fossilResaleValues + fossilInsurenceCost

  const totalCost = {
    
    fuel: (fossilAggregated.fuel * lifespanYears) - electricAggregated.fuel,
    chargers: evSavings.chargers,
    installationCosts: evSavings.installationCosts,
    maintenance: totalFleetMaintenance.fossil - totalFleetMaintenance.electric,
    insurance: insuranceSavings,
    purchase: evSavings.purchase,
    resale: (fossilResaleValues - eVResaleValues ),
    incentives: evSavings.incentives,
    total: totalFossilCost - totalEvCost
  };

  const electricCost = {
    fuel: electricAggregated.fuel,
    chargers: electricAggregated.chargers,
    installationCosts: electricAggregated.installationCosts,
    maintenance: totalFleetMaintenance.electric,
    insurance: evInsurenceCost,
    purchase: electricAggregated.purchase,
    resale: eVResaleValues,
    incentives: electricAggregated.incentives,
    total: totalEvCost
  }

  const fossilCost = {
    fuel: fossilAggregated.fuel * lifespanYears,
    chargers: 0,
    installationCosts: 0,
    maintenance: totalFleetMaintenance.fossil,
    insurance: fossilInsurenceCost,
    purchase: fossilAggregated.purchase,
    resale: fossilResaleValues,
    incentives: 0,
    total: totalFossilCost
  }

  
  let table = [
    {
      item: { title: formatMessage({ id: 'panels.financials.table.fuel' }) },
      tips: {},
      ev: { val1: data.electricFleetCost.fuel },
      fv: { val1: data.fossilFleetCost.fuel},
      evSave: { val1: data.totalFleetSavings.fuel},
    },
    {
      item: { title: formatMessage({ id: 'panels.financials.table.chargers' }) },
      tips: {
        tip1: { text: formatMessage({ id: 'panels.financials.table.chargerPurchase' }) },
        tip2: { text: formatMessage({ id: 'panels.financials.table.installations' }) },
      },
      ev: { val1: data.electricFleetCost.chargers, val2: data.electricFleetCost.installationCosts },
      fv: {
        val1: formatMessage({ id: 'panels.financials.table.na' }),
        val2: formatMessage({ id: 'panels.financials.table.na' }),
      },
      evSave: {
        val1: data.totalFleetSavings.chargers,
        val2: data.totalFleetSavings.installationCosts,
      },
    },
    {
      item: { title: formatMessage({ id: 'panels.financials.table.operations' }) },
      tips: {
        tip1: { text: formatMessage({ id: 'panels.financials.table.maintenance' }) },
        tip2: { text: formatMessage({ id: 'panels.financials.table.insurance' }) },
      },
      ev: { val1: (data.electricFleetCost.maintenance),  val2: data.electricFleetCost.insurance},
      fv: { val1:  (data.fossilFleetCost.maintenance), val2: data.fossilFleetCost.insurance },
      evSave: { val1: (data.totalFleetSavings.maintenance), val2: data.totalFleetSavings.insurance },
    },
    {
      item: { title: formatMessage({ id: 'panels.financials.table.vehicles' }) },
      tips: {
        tip1: { text: formatMessage({ id: 'panels.financials.table.purchase' }) },
        tip2: { text: formatMessage({ id: 'panels.financials.table.resale' }) },
      },
      ev: { val1: data.electricFleetCost.purchase, val2: data.electricFleetCost.resale},
      fv: { val1: data.fossilFleetCost.purchase, val2: data.fossilFleetCost.resale},
      evSave: { val1: data.totalFleetSavings.purchase, val2: data.totalFleetSavings.resale },
    },
    {
      item: { title: formatMessage({ id: 'panels.financials.table.incentives' }) },
      tips: {},
      ev: { val1: data.electricFleetCost.incentives },
      fv: { val1: formatMessage({ id: 'panels.financials.table.na' }) },
      evSave: { val1: data.totalFleetSavings.incentives },
    },
    {
      item: { title: formatMessage({ id: 'panels.financials.table.total' }) },
      tips: {},
      ev: { val1: data.electricFleetCost.total, isTotal: true },
      fv: { val1: data.fossilFleetCost.total, isTotal: true },
      evSave: { val1: data.totalFleetSavings.total, isTotal: true },
    },
  ];

  function removeProperties(obj, props) {
    props.forEach(prop => delete obj[prop]);
  }

  if (!includeChargerCost) {
    // Remove chargerPurchase related data
    removeProperties(table[1].tips, ['tip1']);
    removeProperties(table[1].ev, ['val1']);
    removeProperties(table[1].fv, ['val1']);
    removeProperties(table[1].evSave, ['val1']);
  }  
  
  if (!includeChargerInstallationCost) {
    // Remove installations related data
    removeProperties(table[1].tips, ['tip2']);
    removeProperties(table[1].ev, ['val2']);
    removeProperties(table[1].fv, ['val2']);
    removeProperties(table[1].evSave, ['val2']);
  }
  
  if (!includeChargerCost && !includeChargerInstallationCost) {
    // Remove the entire charger object
    table.splice(1, 1);
  }

  //need to remove and replace for globaldata
  useEffect(() => {
    setTotalValues({totalCost, electricCost, fossilCost})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[vehicleSets])

  return (
    <Table>
      <TableHead>
        <StyledRow>
          <StyledCell />
          <StyledCell />
          <StyledCell>{formatMessage({ id: 'panels.financials.table.eletricTitle' })}</StyledCell>
          <StyledCell>{formatMessage({ id: 'panels.financials.table.fossilTitle' })}</StyledCell>
          <StyledCell>{formatMessage({ id: 'panels.financials.table.evTitle' })}</StyledCell>
        </StyledRow>
      </TableHead>
      <TableBody>
        {table.map((row) => {
          return (
            <StyledRow>
              <StyledCell component="th" scope="row" flex="flex">
                {row.item.title}
                {row.item.tooltip && <ToolTip content={row.item.tooltip} />}
              </StyledCell>

              {Object.keys(row.tips).length ? (
                <StyledCell>
                  {row.tips && row.tips.tip1 && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        paddingBottom: '10px',
                      }}
                    >
                      {row.tips.tip1.text}
                      {row.tips.tip1.tooltip && <ToolTip content={row.tips.tip1.tooltip} />}
                    </Box>
                  )}
                    
                  {row.tips && row.tips.tip2 && (
                    <Box
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {row.tips.tip2.text}
                      {row.tips.tip2.tooltip && <ToolTip content={row.tips.tip2.tooltip} />}
                    </Box>
                  )}
                </StyledCell>
              ) : (
                <StyledCell />
              )}

              {row.ev.val2 ? (
                <StyledCell>
                  <Typography fontSize="13px" paddingBottom="10px" lineHeight="1.43">
                    {formatAsDollars(row.ev.val1)}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="1.43">
                    {formatAsDollars(row.ev.val2)}
                  </Typography>
                </StyledCell>
              ) : (
                <StyledCell>{formatAsDollars(row.ev.val1)}</StyledCell>
              )}

              {row.fv.val2 ? (
                <StyledCell>
                  <Typography fontSize="13px" paddingBottom="10px" lineHeight="1.43">
                    {formatAsDollars(row.fv.val1)}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="1.43">
                    {formatAsDollars(row.fv.val2)}
                  </Typography>
                </StyledCell>
              ) : (
                <StyledCell>{formatAsDollars(row.fv.val1)}</StyledCell>
              )}

              {row.evSave.val2 ? (
                <StyledCell>
                  <Typography fontSize="13px" paddingBottom="10px" lineHeight="1.43">
                    {formatAsDollars(row.evSave.val1)}
                  </Typography>
                  <Typography fontSize="13px" lineHeight="1.43">
                    {formatAsDollars(row.evSave.val2)}
                  </Typography>
                </StyledCell>
              ) : (
                <StyledCell>{formatAsDollars(row.evSave.val1)}</StyledCell>
              )}
            </StyledRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
