import React from 'react';
import { Button, useTheme } from '@mui/material';

export default function ButtonInput({
  variant = 'outlined',
  onClick,
  alert,
  children,
  textTransform,
  fullWidth,
  small,
  disabled,
  style: customStyles,
}) {
  const theme = useTheme();

  const styles = {
    base: {
      borderRadius: '0',
      fontSize: '18px',
      fontWeight: '700',
      padding: '16px',
      width: fullWidth ? '100%' : 'auto',
      cursor: 'pointer',
      textTransform: 'none',
      '@media (max-width: 768px)': {
              width: '100%',
       },
    },
    outlined: {
      minHeight: '40px',
      lineHeight: '17px',
      fontSize: '18px ',
      fontWeight: '300',
      padding: '16px 40px',
      color: '#0072CE',
      border: `1px solid #0072CE`,
      backgroundColor: 'transparent',
      textTransform: textTransform ?? 'uppercase',
      borderRadius: '50px',
      '&:hover': {
        opacity: '0.8'
      },
    },
    outlinedIcon: {
      p: 0,
      minHeight: '60px',
      minWidth: '60px',
      lineHeight: 1.1,
      fontSize: '12px',
      color: '#0072CE',
      border: `1px solid #0072CE`,
      borderRadius: '5px',
      backgroundColor: 'transparent',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.grey[100],
      },
    },
    outlinedNoTransform: {
      color: '#0072CE',
      padding: '12px 40px',
      border: `1px solid #0072CE`,
      borderRadius: '50px',
      backgroundColor: 'transparent',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    filled: {
      backgroundColor: '#0072CE',
      color: '#FFF',
      border: `1px solid #0072CE`,
      textTransform: textTransform ?? 'uppercase',
      lineHeight: 1.1,
      borderRadius: '16px',
      '&:hover': {
        backgroundColor: '#0072CE',
        opcaity: 0.7
      },
    },
    rounded: {
      backgroundColor: '#0072CE',
      color: theme.palette.common.white,
      padding: '16px 40px',
      fontSize: '18px',
      fontWeight: '300',
      border: `1px solid ${theme.palette.primary.main}`,
      textTransform: textTransform ?? 'uppercase',
      lineHeight: 1.1,
      borderRadius: '50px',
      '&:hover': {
        color: '#0072CE',
        border: `1px solid #0072CE`,
        backgroundColor: 'transparent',
      },
    },
    warning: {
      color: 'white',
      backgroundColor: theme.palette.common.red[400],
      border: '1px solid transparent',
    },
    contained: {
      textTransform: textTransform ?? 'uppercase',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '5px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      lineHeight: 2.5,
    },
    small: {
      fontSize: '11px',
      minHeight: '32px',
      lineHeight: 1.1,
    },
    link: {
      fontSize: '16px',
      fontWeight: '600',
      color: '#404040',
      fill: alert ? theme.palette.common.red[400] : theme.palette.grey[900],
      textDecoration: 'none',
      padding: '12px',
    },
    disabled: {
      backgroundColor: '#D9D9D6 !important',
      padding: '16px 40px',
      color: 'white !important',
      fontSize: '18px',
      fontWeight: '300',
      textTransform: textTransform ?? 'uppercase',
      lineHeight: 1.1,
      borderRadius: '50px',
    },
  };

  return (
    <Button
      variant={disabled ? 'disabled' : variant}
      onClick={onClick}
      disabled={disabled}
      sx={{
        ...styles.base,
        ...styles[disabled ? 'disabled' : variant],
        ...(small ? styles.small : {}),
        ...customStyles,
      }}
    >
      {children}
    </Button>
  );
}
