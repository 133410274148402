import React from 'react';
import { Box, useTheme } from '@mui/material';
import {
  WarningIcon,
  InfoIcon,
  NoChargeIcon,
  BoltIcon,
  AlertErrorIcon,
} from '../assets/icons/icons';

export default function WarningMessage({ message, variant = 'warning', fullWidth, customIcon }) {
  const theme = useTheme();

  const variants = {
    noCharge: {
      backgroundColor: '#FFEFEF',
      fillColor: theme.palette.common.red[600],
      textColor: theme.palette.common.red[800],
      icon: <NoChargeIcon />,
    },
    warning: {
      backgroundColor: '#FFF4DA',
      fillColor: theme.palette.common.orange[900],
      textColor: theme.palette.common.orange[900],
      icon: <WarningIcon />,
    },
    info: {
      backgroundColor: theme.palette.common.teal[100],
      fillColor: theme.palette.common.teal[800],
      textColor: theme.palette.grey[900],
      icon: <InfoIcon />,
    },
    electricInfo: {
      backgroundColor: theme.palette.common.teal[100],
      fillColor: theme.palette.common.teal[800],
      textColor: '#363D44',
      icon: <BoltIcon />,
    },
    danger: {
      backgroundColor: theme.palette.common.red[200],
      fillColor: theme.palette.common.red[900],
      textColor: theme.palette.common.red[900],
      icon: <AlertErrorIcon />,
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      maxWidth={fullWidth ? '100%' : '750px'}
      padding={'16px 24px'}
      backgroundColor={variants[variant].backgroundColor}
      borderRadius='16px'
    >
      <Box
        lineHeight={1}
        maxWidth="48px"
        sx={{
          '& svg': {
            fill: variants[variant].fillColor,
          },
        }}
      >
        {customIcon ? customIcon : variants[variant].icon}
      </Box>
      <Box pl={2} fontSize="14px" textAlign="left" color={variants[variant].textColor}>
        {message}
      </Box>
    </Box>
  );
}
