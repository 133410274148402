// source: https://docs.google.com/spreadsheets/d/1gh6FE8xkA4OZ8WvDUtpuW6WJnwHePU-X2kPa95WwWkE/edit#gid=1674745959
// import genericImg from '../assets/images/brightdrop_generic.png';
import brightdropImg from "../assets/images/zevoImg.png";
import fordE350 from "../assets/images/vehicles/Ford E-350.png";
import fordTransitExtended from "../assets/images/vehicles/Ford Transit High Roof extended.png";
import fordTransitLwb from "../assets/images/vehicles/Ford Transit High Roof LWB.png";
import mercedesBenz from "../assets/images/vehicles/Mercedes-Benz Sprinter High Roof LWB.png";
import morganOlson from "../assets/images/vehicles/Morgan Olson Route Star (E-350 Chassis).png";
import ramPromaster from "../assets/images/vehicles/RAM Promaster High Roof LWB.png";
import utilimasterAeromaster from "../assets/images/vehicles/Utilimaster Aeromaster.png";
import utilimasterVelocity from "../assets/images/vehicles/Utilimaster Velocity F3 16 foot body.png";
//THIS SHOULD BE REFACORED WHEN HAVING THE FINAL API ARCHETYPE
const ELECTRIC_VEHICLES = [
  {
    id: "BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024",
    name: "BrightDrop Zevo 600 - 250 mi - Class 2b",
    make: "BrightDrop",
    model: "Zevo 600",
    trim: "20 Mod AWD Class 2b",
    category: "On-Road",
    type: "Light Duty Step Van",
    subtype: "light_duty",
    imageUrl: brightdropImg,
    rangeInMiles: 250,
    formFactor: "step_van",
    liftCapacity: 0,
    weightClass: 2,
    batteryCapacityInKwh: 173,
    efficiencyInKwhPerHundredMiles: 32,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0.13,
    efficiencyInMilesPerKwh: 0.692,
    runtime: "3-6",
    horsePower: "25",
    fuelConsuption: 0,
    gvwr: 9990,
    msrp: 101950,
    milesPerGallon: 24, //MPG
    cargoVolume: "615 ft³",
    drivetrain: "AWD",
    seating: 2,
    payload: 1460,
    interiorHeight: '41.9 "',
    fuel: "BEV",
    duty: "light_duty",
  },
  {
    id: "BrightDrop_Zevo_600_20_Mod_AWD_Class_3_BEV_2024",
    name: "BrightDrop Zevo 600 - 250 mi - Class 3",
    make: "BrightDrop",
    model: "Zevo 600",
    trim: "20 Mod AWD Class 3",
    category: "On-Road",
    type: "Medium Duty Step Van",
    subtype: "medium_duty",
    imageUrl: brightdropImg,
    rangeInMiles: 250,
    formFactor: "step_van",
    liftCapacity: 0,
    gvwr: 11000,
    weightClass: 3,
    batteryCapacityInKwh: 173,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 102950,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0.13,
    efficiencyInMilesPerKwh: 0.692,
    runtime: "3-6",
    horsePower: "25",
    fuelConsuption: 0,
    cargoVolume: "615 ft³",
    drivetrain: "AWD",
    seating: 2,
    payload: 2450,
    interiorHeight: '41.9 "',
    fuel: "BEV",
    duty: "medium_duty",
  },
  {
    id: "BrightDrop_Zevo_400_20_Mod_AWD_Class_2b_BEV_2024",
    name: "BrightDrop Zevo 400 - 250 mi - Class 2b",
    make: "BrightDrop",
    model: "Zevo 400",
    trim: "20 Mod AWD Class 2b",
    category: "On-Road",
    type: "Light Duty Step Van",
    subtype: "light_duty",
    imageUrl: brightdropImg,
    rangeInMiles: 250,
    formFactor: "step_van",
    liftCapacity: 0,
    weightClass: 2,
    batteryCapacityInKwh: 173,
    efficiencyInKwhPerHundredMiles: 32,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0.13,
    efficiencyInMilesPerKwh: 0.692,
    runtime: "3-6",
    horsePower: "25",
    fuelConsuption: 0,
    gvwr: 9990,
    msrp: 101950,
    milesPerGallon: 24, //MPG
    cargoVolume: "412 ft³",
    drivetrain: "AWD",
    seating: 2,
    payload: 1800,
    interiorHeight: '41.9 "',
    fuel: "BEV",
    duty: "light_duty",
  },
  {
    id: "BrightDrop_Zevo_400_20_Mod_AWD_Class_3_BEV_2024",
    name: "BrightDrop Zevo 400 - 250 mi - Class 3",
    make: "BrightDrop",
    model: "Zevo 400",
    trim: "20 Mod AWD Class 3",
    category: "On-Road",
    type: "Medium Duty Step Van",
    subtype: "medium_duty",
    imageUrl: brightdropImg,
    rangeInMiles: 250,
    formFactor: "step_van",
    liftCapacity: 0,
    gvwr: 11000,
    weightClass: 3,
    batteryCapacityInKwh: 173,
    efficiencyInKwhPerHundredMiles: 32,
    msrp: 102950,
    maintenanceCostPerMile: 0.13,
    maintenanceCostPerHour: 0.13,
    efficiencyInMilesPerKwh: 0.692,
    runtime: "3-6",
    horsePower: "25",
    fuelConsuption: 0,
    cargoVolume: "412 ft³",
    drivetrain: "AWD",
    seating: 2,
    payload: 2800,
    interiorHeight: '41.9 "',
    fuel: "BEV",
    duty: "medium_duty",
  },
];
const ARCHETYPES_VEHICLE = [
  {
    id: "on_road_cargo_van_light_duty",
    name: "Generic Light Duty Cargo Van",
    category: "On-Road",
    type: "Cargo Van",
    subtype: "Light Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cargo_Van.png",
    rangeInMiles: 115,
    formFactor: "cargo_van",
    gvwr: 5233,
    weightClass: 1,
    fuel: "Diesel",
    msrp: 34100,
    payload: 3867.55555555556,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0.15,
    milesPerGallon: 20,
    eligibleForCaRebate: false,
    cargoVolume: "524 ft³",
    drivetrain: "RWD",
    make: "Generic 4",
    model: "Generic 4",
    horsepower: 249.888888888889,
    ice_range: 316,
  },
  {
    id: "on_road_step_van_light_duty",
    name: "Generic Light Duty Step Van",
    category: "On-Road",
    type: "Step Van",
    subtype: "Light Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Step_Van.png",
    formFactor: "step_van",
    gvwr: 9370,
    weightClass: 2,
    msrp: 55000,
    payload: 3867.55555555556,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    rangeInMiles: 316.2,
    fuel: "Diesel",
    eligibleForCaRebate: false,
    cargoVolume: "525 ft³",
    drivetrain: "RWD",
    make: "Generic 1",
    model: "Generic 1",
    horsePower: 250,
    liftCapacity: 0,
    seating: 2,
    interiorHeight: '41.9 "',
    ice_range: 316,
  },
  {
    id: "on_road_cargo_van_medium_duty",
    name: "Generic Medium Duty Cargo Van",
    category: "On-Road",
    type: "Cargo Van",
    subtype: "Medium Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cargo_Van.png",
    rangeInMiles: 177,
    formFactor: "cargo_van",
    gvwr: 13293,
    weightClass: 3,
    fuel: "Diesel",
    msrp: 48800,
    payload: 5214.2,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    eligibleForCaRebate: false,
    cargoVolume: "635 ft³",
    drivetrain: "RWD",
    make: "Generic 3",
    model: "Generic 3",
    horsepower: 288.4,
    ice_range: 317,
  },
  {
    id: "on_road_step_van_medium_duty",
    name: "Generic Medium Duty Step Van",
    category: "On-Road",
    type: "Step Van",
    subtype: "Medium Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Step_Van.png",
    rangeInMiles: 200,
    formFactor: "step_van",
    gvwr: 14000,
    weightClass: 3,
    fuel: "Diesel",
    msrp: 60000,
    payload: 5214.2,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    eligibleForCaRebate: false,
    cargoVolume: "636 ft³",
    drivetrain: "RWD",
    make: "Generic 2",
    model: "Generic 2",
    horsePower: 288,
    ice_range: 317,
  },
];
const ICE_VEHICLES = [
  {
    id: "on_road_cargo_van_light_duty",
    name: "Generic Light Duty Cargo Van",
    category: "On-Road",
    type: "Cargo Van",
    subtype: "Light Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Cargo_Van.png",
    rangeInMiles: 115,
    formFactor: "cargo_van",
    gvwr: 5233,
    weightClass: 1,
    fuel: "Diesel",
    msrp: 79000,
    payload: 3867.55555555556,
    maintenanceCostPerMile: 0.15,
    maintenanceCostPerHour: 0.15,
    milesPerGallon: 15,
    eligibleForCaRebate: false,
    cargoVolume: "524 ft³",
    drivetrain: "RWD",
    make: "Generic 4",
    model: "Generic 4",
    horsepower: 249.888888888889,
    ice_range: 316,
  },
  {
    id: "on_road_step_van_light_duty",
    name: "Generic Light Duty Step Van",
    category: "On-Road",
    type: "Step Van",
    subtype: "Light Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Light_Duty_Step_Van.png",
    formFactor: "step_van",
    gvwr: 9370,
    weightClass: 2,
    msrp: 55000,
    payload: 3867.55555555556,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    rangeInMiles: 316.2,
    fuel: "Diesel",
    eligibleForCaRebate: false,
    cargoVolume: "525 ft³",
    drivetrain: "RWD",
    make: "Generic 1",
    model: "Generic 1",
    horsePower: 250,
    liftCapacity: 0,
    seating: 2,
    interiorHeight: '41.9 "',
    ice_range: 316,
  },
  {
    id: "on_road_cargo_van_medium_duty",
    name: "Generic Medium Duty Cargo Van",
    category: "On-Road",
    type: "Cargo Van",
    subtype: "Medium Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Cargo_Van.png",
    rangeInMiles: 177,
    formFactor: "cargo_van",
    gvwr: 13293,
    weightClass: 3,
    fuel: "Diesel",
    msrp: 48800,
    payload: 5214.2,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    eligibleForCaRebate: false,
    cargoVolume: "635 ft³",
    drivetrain: "RWD",
    make: "Generic 3",
    model: "Generic 3",
    horsepower: 288.4,
    ice_range: 317,
  },
  {
    id: "on_road_step_van_medium_duty",
    name: "Generic Medium Duty Step Van",
    category: "On-Road",
    type: "Step Van",
    subtype: "Medium Duty",
    imageUrl:
      "https://assets.zappyride.com/img/vehicles/commercial-vehicle-archtypes/large-images-1280-960/Medium_Duty_Step_Van.png",
    rangeInMiles: 200,
    formFactor: "step_van",
    gvwr: 14000,
    weightClass: 3,
    fuel: "Diesel",
    msrp: 162000,
    payload: 5214.2,
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    milesPerGallon: 11.6,
    eligibleForCaRebate: false,
    cargoVolume: "636 ft³",
    drivetrain: "RWD",
    make: "Generic 2",
    model: "Generic 2",
    horsePower: 288,
    ice_range: 317,
  },
  {
    id: "morgan_olson_route_star_(e-350_chassis)_e-series_89w_81h", //input_handle - ice_handle
    name: 'Morgan Olson Route Star (E-350 Chassis) E-Series 89" width 81" interior height',
    make: "Morgan Olson",
    model: "Route Star (E-350 Chassis)",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 57500,
    milesPerGallon: 9, //MPG
    cargoVolume: "455 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: morganOlson,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '81 "',
    payload: 5100,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "morgan_olson_route_star_(e-350_chassis)_e-series_89w_85h", //input_handle
    name: 'Morgan Olson Route Star (E-350 Chassis) E-Series 89" width 85" interior height',
    make: "Morgan Olson",
    model: "Route Star (E-350 Chassis)",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 61500,
    milesPerGallon: 9, //MPG
    cargoVolume: "640 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: morganOlson,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '85 "',
    payload: 5000,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "morgan_olson_route_star_(e-350_chassis)_e-series_95w_81h", //input_handle
    name: 'Morgan Olson Route Star (E-350 Chassis) E-Series 95" width 81" interior height',
    make: "Morgan Olson",
    model: "Route Star (E-350 Chassis)",
    type: "Medium Duty Step Van",
    gvwr: 12500,
    msrp: 65500,
    milesPerGallon: 9, //MPG
    cargoVolume: "654 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: morganOlson,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '81 "',
    payload: 5800,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "morgan_olson_route_star_(e-350_chassis)_e-series_95w_85h", //input_handle
    name: 'Morgan Olson Route Star (E-350 Chassis) E-Series 95" width 85" interior height',
    make: "Morgan Olson",
    model: "Route Star (E-350 Chassis)",
    type: "Medium Duty Step Van",
    gvwr: 12500,
    msrp: 69500,
    milesPerGallon: 9, //MPG
    cargoVolume: "783 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: morganOlson,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '85 "',
    payload: 5650,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_aeromaster_86.5w_81h_12'_body", //input_handle
    name: 'Utilimaster Aeromaster 86.5" width 81" interior height 12" body',
    make: "Utilimaster",
    model: "Aeromaster",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 56500,
    milesPerGallon: 9, //MPG
    cargoVolume: "500 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: utilimasterAeromaster,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '81 "',
    payload: 5100,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_aeromaster_86.5w_85h_14'_body", //input_handle
    name: 'Utilimaster Aeromaster 86.5" width 85" interior height 14" body',
    make: "Utilimaster",
    model: "Aeromaster",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 60500,
    milesPerGallon: 9, //MPG
    cargoVolume: "550 ft³",
    drivetrain: "RWD",
    horsePower: "332",
    imageUrl: utilimasterAeromaster,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '85 "',
    payload: 4950,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_aeromaster_93.5w_81h_16'_body", //input_handle
    name: 'Utilimaster Aeromaster 93.5" width 81" interior height 16" body',
    make: "Utilimaster",
    model: "Aeromaster",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 64500,
    milesPerGallon: 9, //MPG
    cargoVolume: "650 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: utilimasterAeromaster,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '81 "',
    payload: 4800,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_aeromaster_93.5w_85h_18'_body", //input_handle
    name: 'Utilimaster Aeromaster 93.5" width 85" interior height 18" body',
    make: "Utilimaster",
    model: "Aeromaster",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 68500,
    milesPerGallon: 9, //MPG
    cargoVolume: "700 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: utilimasterAeromaster,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '85 "',
    payload: 4650,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_m3_12_foot_body", //input_handle
    name: "Utilimaster M3 12 foot body",
    make: "Utilimaster",
    model: "M3",
    type: "Medium Duty Step Van",
    gvwr: 12135,
    msrp: 63000,
    milesPerGallon: 18, //MPG
    cargoVolume: "600 ft³",
    drivetrain: "RWD",
    horsePower: "161",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 441,
    seating: 2,
    interiorHeight: '85 "',
    payload: 5992,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_m3_14_foot_body", //input_handle
    name: "Utilimaster M3 14 foot body",
    make: "Utilimaster",
    model: "M3",
    type: "Medium Duty Step Van",
    gvwr: 12135,
    msrp: 67000,
    milesPerGallon: 18, //MPG
    cargoVolume: "650 ft³",
    drivetrain: "RWD",
    horsePower: "161",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 441,
    seating: 2,
    interiorHeight: '85 "',
    payload: 5871,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f2_12_foot_body", //input_handle
    name: "Utilimaster Velocity F2 12 foot body",
    make: "Utilimaster",
    model: "Velocity F2 12 foot body",
    type: "Light Duty Step Van",
    gvwr: 9950,
    msrp: 53500,
    milesPerGallon: 15, //MPG
    cargoVolume: "600 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 278,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 3700,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f2_14_foot_body", //input_handle
    name: "Utilimaster Velocity F2 14 foot body",
    make: "Utilimaster",
    model: "Velocity F2 14 foot body",
    type: "Light Duty Step Van",
    gvwr: 9950,
    msrp: 57500,
    milesPerGallon: 15, //MPG
    cargoVolume: "650 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 278,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 3300,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f2_16_foot_body", //input_handle
    name: "Utilimaster Velocity F2 16 foot body",
    make: "Utilimaster",
    model: "Velocity F2 16 foot body",
    type: "Light Duty Step Van",
    gvwr: 9950,
    msrp: 61500,
    milesPerGallon: 15, //MPG
    cargoVolume: "700 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 275,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 2750,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f3_12_foot_body", //input_handle
    name: "Utilimaster Velocity F3 12 foot body",
    make: "Utilimaster",
    model: "Velocity F3 12 foot body",
    type: "Medium Duty Step Van",
    gvwr: 10360,
    msrp: 57500,
    milesPerGallon: 15, //MPG
    cargoVolume: "600 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 4150,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f3_14_foot_body", //input_handle
    name: "Utilimaster Velocity F3 14 foot body",
    make: "Utilimaster",
    model: "Velocity F3 14 foot body",
    type: "Medium Duty Step Van",
    gvwr: 10360,
    msrp: 61500,
    milesPerGallon: 15, //MPG
    cargoVolume: "650 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 3700,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "utilimaster_velocity_f3_16_foot_body", //input_handle
    name: "Utilimaster Velocity F3 16 foot body",
    make: "Utilimaster",
    model: "Velocity F3 16 foot body",
    type: "Medium Duty Step Van",
    gvwr: 10360,
    msrp: 65500,
    milesPerGallon: 15, //MPG
    cargoVolume: "700 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: utilimasterVelocity,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '85 "',
    payload: 3550,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "ford_e-350", //input_handle
    name: "Ford E-350",
    make: "Ford",
    model: "E-350",
    type: "Medium Duty Step Van",
    gvwr: 11500,
    msrp: 57500,
    milesPerGallon: 9, //MPG
    cargoVolume: "455 ft³",
    drivetrain: "RWD",
    horsePower: "331",
    imageUrl: fordE350,
    rangeInMiles: 297,
    fuel: "Diesel",
    weightClass: 3,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Medium Duty",
    ice_range: 297,
    seating: 2,
    interiorHeight: '85 "',
    payload: 5100,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "ford_transit_high_roof_lwb", //input_handle
    name: "Ford Transit High Roof LWB",
    make: "Ford",
    model: "Transit",
    type: "Light Duty Step Van",
    gvwr: 9500,
    msrp: 48115,
    milesPerGallon: 15, //MPG
    cargoVolume: "453 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: fordTransitLwb,
    rangeInMiles: 278,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '81.5 "',
    payload: 3962,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "ford_transit_high_roof_extended", //input_handle
    name: "Ford Transit High Roof Extended",
    make: "Ford",
    model: "Transit",
    type: "Light Duty Step Van",
    gvwr: 9500,
    msrp: 49335,
    milesPerGallon: 15, //MPG
    cargoVolume: "536 ft³",
    drivetrain: "RWD",
    horsePower: "275",
    imageUrl: fordTransitExtended,
    rangeInMiles: 278,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 278,
    seating: 2,
    interiorHeight: '81.5 "',
    payload: 3966,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "ram_promaster_high_roof_lwb", //input_handle
    name: "RAM Promaster High Roof LWB",
    make: "RAM",
    model: "Promaster",
    type: "Light Duty Step Van",
    gvwr: 9350,
    msrp: 48535,
    milesPerGallon: 15, //MPG
    cargoVolume: "420 ft³",
    drivetrain: "RWD",
    horsePower: "276",
    imageUrl: ramPromaster,
    rangeInMiles: 360,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 360,
    seating: 2,
    interiorHeight: '77 "',
    payload: 4427,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "ram_promaster_high_roof_extended", //input_handle
    name: "RAM Promaster High Roof Extended",
    make: "RAM",
    model: "Promaster",
    type: "Light Duty Step Van",
    gvwr: 9350,
    msrp: 51395,
    milesPerGallon: 15, //MPG
    cargoVolume: "463 ft³",
    drivetrain: "RWD",
    horsePower: "276",
    imageUrl: ramPromaster,
    rangeInMiles: 360,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 360,
    seating: 2,
    interiorHeight: '77 "',
    payload: 4330,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "mercedes-benz_sprinter_high_roof_lwb", //input_handle
    name: "Mercedes-Benz Sprinter High Roof LWB",
    make: "Mercedes-Benz",
    model: "Sprinter",
    type: "Light Duty Step Van",
    gvwr: 9990,
    msrp: 51170,
    milesPerGallon: 15, //MPG
    cargoVolume: "430 ft³",
    drivetrain: "RWD",
    horsePower: "161",
    imageUrl: mercedesBenz,
    rangeInMiles: 368,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 368,
    seating: 3,
    interiorHeight: '79 "',
    payload: 4247,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
  {
    id: "mercedes-benz_sprinter_high_roof_extended", //input_handle
    name: "Mercedes-Benz Sprinter High Roof Extended",
    make: "Mercedes-Benz",
    model: "Sprinter",
    type: "Light Duty Step Van",
    gvwr: 9990,
    msrp: 53190,
    milesPerGallon: 15, //MPG
    cargoVolume: "469 ft³",
    drivetrain: "RWD",
    horsePower: "161",
    imageUrl: mercedesBenz,
    rangeInMiles: 368,
    fuel: "Diesel",
    weightClass: 2,
    formFactor: "step_van",
    maintenanceCostPerMile: 0.2,
    maintenanceCostPerHour: 0.2,
    category: "On-Road",
    subtype: "Light Duty",
    ice_range: 368,
    seating: 3,
    interiorHeight: '79 "',
    payload: 4126,
    eligibleForCaRebate: false,
    liftCapacity: 0,
  },
];
export { ELECTRIC_VEHICLES, ICE_VEHICLES, ARCHETYPES_VEHICLE };
export default ELECTRIC_VEHICLES;
