import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { customWaterfallPlugin } from './Waterfall.plugin';

export default function StyledBarGraph(props) {
  const theme = useTheme();
  const {
    waterfall,
    yTitle,
    data,
    indexAxis,
    stacked,
    xTicks,
    yTicks,
    layout,
    tooltip,
    tooltipLabelFormatter,
    tooltipTitleFormatter,
    tooltipEnabled = true,
    legendPosition = 'top',
    legendAlign = 'end',
    showLegend = !waterfall,
    dataLabels = { display: false },
    suggestedXMax,
    height,
  } = props;

  const options = {
    responsive: true,
    indexAxis,
    maintainAspectRatio: false,
    layout: layout,
    plugins: {
      title: {
        display: true,
        text: yTitle,
        color: theme.palette.grey[900],
        align: 'start',
        font: {
          size: 12,
          family: 'Overpass',
        },
      },
      legend: {
        onClick: null,
        display: showLegend,
        align: legendAlign,
        position: legendPosition,
        labels: {
          textAlign: 'right',
          boxWidth: 40,
          usePointStyle: true,
          color: theme.palette.grey[900],
          font: {
            size: 11,
          },
        },
      },
      waterfall: waterfall,
      datalabels: dataLabels,
      tooltip: tooltip ? tooltip : {
        enabled: tooltipEnabled,
        callbacks: {
          label:
            tooltipLabelFormatter ||
            function (data) {
              return `${data.dataset.label}: ${data.formattedValue}`;
            },
          title:
            tooltipTitleFormatter ||
            function (data) {
              return null;
            },
        },
      }

    },
    scales: {
      x: {
        suggestedMax: suggestedXMax,
        grid: {
          display: false,
          drawBorder: true,
          drawTicks: true,
          color: theme.palette.common.border,
        },
        ticks: xTicks
          ? xTicks
          : {
              padding: 20,
              color: theme.palette.grey[800],
              font: {
                size: 16,
              },
            },
        stacked,
      },
      y: {
        grid: {
          color: theme.palette.common.border,
          display: true,
          drawBorder: false,
          drawTicks: false,
          max: 3000000,
        },
        title: {
          align: 'start',
          display: false,
          text: yTitle,
          color: '#333333',
          font: {
            size: 11,
            weight: 'bold',
          },
        },
        ticks: yTicks
          ? yTicks
          : {
              padding: 20,
              color: theme.palette.grey[900],
              font: {
                size: 16,
              },
            },
        stacked,
      },
    },
  };

  return (
    <Box sx={{ height: height || '100%', width: '100%' }}>
      <Bar options={options} data={data} plugins={waterfall ? [{ ...customWaterfallPlugin }] : null} />
    </Box>
  );
}
