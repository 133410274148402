import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useInputs } from "@bellawatt/use-inputs";
import CollapsibleInput from "../../inputs/CollapsibleInput";
import ChargerList from "./ChargerList";
import { useIntl } from "react-intl";
import { AlertErrorIcon, SuccessVariantIcon } from "../../assets/icons/icons";
import { useCalculations } from "../../utils/useCalculations";

const OutputMessage = ({ setId, vehicleSets }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const vehicleSet = vehicleSets.find((set) => set.id === setId);
  const minimumStateOfCharge = Calculations.minimumStateOfCharge({
    input: vehicleSet,
  });

  const meetsOutput = minimumStateOfCharge > 0;

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      p={1}
      borderRadius="5px"
      mx="22px"
      mb={2}
      backgroundColor={meetsOutput ? "#E5F5E9" : "#F5E5E5"}
    >
      <Box height="24px">
        {meetsOutput ? <SuccessVariantIcon /> : <AlertErrorIcon />}
      </Box>
      <Typography
        pl="18px"
        fontSize="14px"
        fontWeight="400"
        color={
          meetsOutput
            ? theme.palette.common.green[800]
            : theme.palette.common.red[400]
        }
      >
        {formatMessage({
          id: meetsOutput
            ? "panels.charging.infoCard.success"
            : "panels.charging.infoCard.warning",
        })}
      </Typography>
    </Box>
  );
};

const TitleRow = ({ name, count }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box
      mb={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pt={1}
      pl="10px"
      textTransform="none"
    >
      <Typography
        fontSize="22px"
        fontWeight="400"
        color={theme.palette.grey[900]}
        width="100%"
      >
        {name}
      </Typography>
      <Typography
        fontSize="22px"
        fontWeight="400"
        color={theme.palette.grey[900]}
        width="100%"
        textAlign="end"
        pr={3}
      >
        {formatMessage({ id: "panels.charging.vehicles" }, { value: count })}
      </Typography>
    </Box>
  );
};
export default function VehicleSetList() {
  const { vehicleSets } = useInputs();

  return (
    <Box rowGap={3} display="flex" flexDirection="column">
      {vehicleSets.map(
        (
          {
            chargingWindows,
            name,
            vehicleCount,
            id,
            milesPerWorkday,
            personalMilesPerWorkday,
            replacementVehicle,
          },
          index
        ) => (
          <CollapsibleInput
            key={name}
            title={<TitleRow name={name} count={vehicleCount} />}
            hasAllBorder
            defaultOpen={index === 0}
          >
            <OutputMessage setId={id} vehicleSets={vehicleSets} />
            <ChargerList
              chargers={chargingWindows}
              vehicleData={{
                vehicleCount,
                vehicleSetName: name,
                vehicleSetId: id,
                milesPerWorkday,
                personalMilesPerWorkday,
                replacementVehicle,
                vehicleName: replacementVehicle.name,
                efficiencyInMilesPerKwh:
                  replacementVehicle.efficiencyInMilesPerKwh,
                batteryCapacity: replacementVehicle.batteryCapacityInKwh,
              }}
            />
          </CollapsibleInput>
        )
      )}
    </Box>
  );
}
