import React from 'react';
import { Grid, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { steps } from '../data/onboardingSteps';
import ButtonInput from '../components/ButtonInput';
import { useInputs } from '@bellawatt/use-inputs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ToolTip from '../components/ToolTip';

const GettingStartedButtons = ({ onStart, onSkip }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Box sx={{ textAlign: 'center', marginBottom: '20px' }} maxWidth="1068px">
        <ButtonInput variant="rounded" onClick={onStart}>
          {formatMessage({ id: 'onboarding.gettingStarted.createFirstVehicleSet' }).toUpperCase()}
        </ButtonInput>
      </Box>
      <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: "center", alignItems: "center" }}>
        <ButtonInput variant="link" onClick={onSkip}>
          {formatMessage({ id: 'onboarding.gettingStarted.doLater' })}
        </ButtonInput>
        <ToolTip content={formatMessage({ id: 'onboarding.gettingStarted.doLaterToolTip' })}></ToolTip>
      </Box>
    </>
  );
};

export default function OnboardingButtonRow({ onSave, forwardDisabled }) {
  const history = useHistory();
  const { setInput, onboardingStep } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const stepIndex = steps.findIndex((step) => step === onboardingStep);

  const handleSkipOnboarding = () => {
    setInput({ isOnboarded: true, organizationType: 'logistics_company' });
  };

  const handleGoForward = () => {
    if (stepIndex !== steps.length - 1) {
      setInput({ onboardingStep: steps[stepIndex + 1]});
    } else {
      onSave();
    }
    //scroll back to the top between each step
    document.documentElement.scrollTop = 0;
  };

  const handleGoBack = () => {
    if (stepIndex !== 0) {
      setInput({ onboardingStep: steps[stepIndex - 1]});
    } else {
      history.replace('/');
    }
    document.documentElement.scrollTop = 0;
  };

  const styles = {
    buttons: {
      justifyContent: 'space-evenly',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'space-between',
      },
      '@media (max-width: 768px)': {
              flexDirection: 'column-reverse'
       },
    },
  };

  return (
    <Box width="100%" mb="50px" py={2}>
      {stepIndex === 0 ? (
        <GettingStartedButtons onStart={handleGoForward} onSkip={handleSkipOnboarding} />
      ) : (
        <Grid container sx={{ ...styles.buttons }}>
          <Grid item sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                            textAlign: 'center',
                     },
              }}>
            <ButtonInput variant="outlined" onClick={handleGoBack}>
              {formatMessage({ id: 'onboarding.back' })}
            </ButtonInput>
          </Grid>
          <Grid item sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                            textAlign: 'center',
                            marginBottom: '20px',
                     },
              }}>
            <ButtonInput variant="rounded" onClick={handleGoForward} disabled={forwardDisabled}>
              {formatMessage({ id: `onboarding.${onboardingStep}.next` })}
            </ButtonInput>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
