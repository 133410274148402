const sampleApiElectricVehicles = [
  {
    fromApi: true,
    id: 'BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024',
    category: 'On-Road',
    name: 'BrightDrop Zevo 600 - 250 mi - Class 2',
    type: 'Light Duty Step Van',
    subtype: 'light_duty',
    formFactor: 'step_van',
    make: 'Brightdrop',
    year: 0,
    model: '',
    duty: 'Y',
    trim: 'e25G',
    imageUrl: 'zevoImg.png',
    rangeInMiles: 22,
    rangeInHours: 22,
    range: 22,
    fuel: 'BEV',
    liftCapacity: 0,
    batteryCapacityInKwh: 22,
    weightClass: null,
    msrp: 32299,
    msrpIsEstimated: false,
    efficiencyInHoursPerKwhHeavy: 6.79,
    efficiencyInHoursPerKwhMedium: 3.75,
    efficiencyInHoursPerKwhLight: 2.19,
    efficiencyHeavyLoad: 0.14727540500736377,
    efficiencyMediumLoad: 0.26666666666666666,
    efficiencyLightLoad: 0.4566210045662101,
    maintenanceCostPerMile: 0,
    maintenanceCostPerHour: 0.095,
    cargoVolume: null,
    seats: 1,
    gvwr: null,
    horsepower: 25,
    availability: 'released',
    fuelConsuption: {
      heavy: 6.79,
      meadium: 3.75,
      light: 2.19,
      type: 'kWh/hr',
    },
    runtime: '3-6',
  },
];

export default sampleApiElectricVehicles;
