import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import WarningMessage from "../../components/WarningMessage";
import { formatAsDollars } from "../../utils/formatters";
import EstimatedRevenuesChart from "./EstimatedRevenuesChart";
import { useInputs } from "@bellawatt/use-inputs";
import { times } from "lodash";
import { useCalculations } from "../../utils/useCalculations";

export default function LCFS() {
  const { formatMessage } = useIntl();
  const { vehicleSets, carbonCreditsPrice, lifespanYears, startYear, state } =
    useInputs();
  const Calculations = useCalculations();

  const revenuesByYear = times(lifespanYears, (i) => startYear + i)
    .map((year) => {
      return {
        year,
        value: Calculations.lcfsCost({
          input: vehicleSets,
          year,
          carbonCreditsPrice,
        }),
      };
    })
    .filter((i) => i.value > 0);

  const lcsfSavings = revenuesByYear[0]?.value ? revenuesByYear[0]?.value : 0;
  const isCalifornia = state === "California";
  const message = isCalifornia
    ? formatMessage(
        { id: "panels.lcfs.infoMessage" },
        {
          perYear: (
            <b>
              {formatMessage(
                { id: "panels.lcfs.perYear" },
                { value: formatAsDollars(lcsfSavings) }
              )}
            </b>
          ),
        }
      )
    : formatMessage({ id: "panels.lcfs.infoMessageNonCali" });

  return (
    <Box pb={16}>
      <WarningMessage variant="electricInfo" fullWidth message={message} />
      {isCalifornia && (
        <EstimatedRevenuesChart revenuesByYear={revenuesByYear} />
      )}
    </Box>
  );
}
