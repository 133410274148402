import React from 'react';
import { InfoIcon } from '../assets/icons/icons';
import { Box, Tooltip as MuiTooltip } from '@mui/material';

export default function ToolTip({ content, children, justify = 'start', align = 'start' }) {
  return (
    <MuiTooltip
      title={content}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            color: '#ffff',
            backgroundColor: '#3D3935',
            borderRadius: '16px',
            fontSize: '12px',
            padding: '8px 16px',
            '& a': {
              color: '#fff'
            }
          },
        },
      }}
    >
      <Box display="flex" justifyContent={justify} alignItems={align}>
        {children}
        <Box flexShrink={0} ml={1} mt={'2px'}>
          <InfoIcon />
        </Box>
      </Box>
    </MuiTooltip>
  );
}
