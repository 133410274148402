import { Dialog, DialogContent, DialogTitle, DialogActions, Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import { useInputs } from '@bellawatt/use-inputs';
import { ComplexChargerCard, SimpleChargerCard } from './ChargerCard';
import { ActionButtons } from './ActionButtons';
import { cloneDeep } from 'lodash';
import ContentHeader from './ContentHeader';
import Filters from './Filters';
import CatalogHeader from './CatalogHeader';
import CHARGERS from '../../data/CHARGERS';
import ButtonInput from '../../components/ButtonInput';
import { useIntl } from 'react-intl';

const sortConfig = {
  msrpDesc: {
    field: 'price',
    method: 'desc',
  },
  msrpAsc: {
    field: 'price',
    method: 'asc',
  },
};

const useChargers = (filters, sort) => {
  const { apiChargers } = useInputs();

  const filteredChargers = (filters.brand === 'Generic' ? CHARGERS : apiChargers)
    .filter(
      ({ make, ports, formFactor }) =>
        (filters.brand === 'All' || filters.brand === 'Generic' || filters.brand === make) &&
        (filters.ports === 'All' || filters.ports === ports.toString()) &&
        (filters.types.includes('All') || filters.types.includes(formFactor)),
    )
    .filter(({ price }) => {
      if (filters.maxPrice === 5000) {
        return price <= 5000;
      } else if (filters.maxPrice === 10000) {
        return price <= 10000;
      } else if (filters.maxPrice === 20000) {
        return price <= 20000;
      } else if (filters.maxPrice === 40000) {
        return price <= 40000;
      } else if (filters.maxPrice === 80000) {
        return price <= 80000;
      }
      return true;
    })
    .filter(({ portKw }) => {
      if (filters.output === 1) {
        return portKw > 1;
      } else if (filters.output === 10) {
        return portKw > 10;
      } else if (filters.output === 20) {
        return portKw > 20;
      } else if (filters.output === 50) {
        return portKw > 50;
      } else if (filters.output === 100) {
        return portKw > 100;
      } else if (filters.output === 150) {
        return portKw > 150;
      } else if (filters.output === 200) {
        return portKw > 200;
      }
      return true;
    });

  const { field, method } = sort;

  switch (method) {
    case 'asc':
      filteredChargers.sort((a, b) => a[field] - b[field]);
      break;
    case 'desc':
      filteredChargers.sort((a, b) => b[field] - a[field]);
      break;
    default:
  }

  return filteredChargers;
};

const SHOW_COUNT = 24;

export default function ChargerCatalog({
  isOpen,
  onClose,
  charger,
  vehicleData,
  start,
  finish,
  windowId,
}) {
  const { formatMessage } = useIntl();
  const { setInput, apiChargers, vehicleSets } = useInputs();
  const [showCount, setShowCount] = useState(SHOW_COUNT);
  const showMore = () => setShowCount(showCount + SHOW_COUNT);
  const resetShowCount = () => setShowCount(SHOW_COUNT);
  const [selectedCharger, setSelectedCharger] = useState(charger);
  const [sortOrder, setSortOrder] = useState('msrpAsc');
  const [activeFilters, setActiveFilters] = useState({
    brand: 'All',
    output: 0,
    maxPrice: 'All',
    ports: 'All',
    cableLength: 'All',
    types: ['All'],
    range: 0,
  });
  const {
    vehicleCount,
    vehicleSetName,
    vehicleName,
    vehicleSetId,
    milesPerWorkday,
    personalMilesPerWorkday,
    efficiencyInMilesPerKwh,
    batteryCapacity,
  } = vehicleData;

  const kWhPerWorkday = milesPerWorkday / efficiencyInMilesPerKwh;

  const vehicleSet = vehicleSets.find((set) => set.id === vehicleSetId);
  const onlyCharger = vehicleSet.chargingWindows.length === 1;

  const handleSave = () => {
    const setIndex = vehicleSets.findIndex((set) => set.id === vehicleSetId);
    const vehicleSet = vehicleSets[setIndex];
    const newSets = cloneDeep(vehicleSets);
    const chargingWindowInd = vehicleSet.chargingWindows.findIndex(
      (window) => window.id === windowId,
    );

    let newChargingWindow = cloneDeep(vehicleSet.chargingWindows[chargingWindowInd]);
    if (newChargingWindow.chargerId !== selectedCharger.id) {
      newChargingWindow = {
        ...newChargingWindow,
        chargerId: selectedCharger.id,
        isGeneric: activeFilters.brand === 'Generic',
        charger: selectedCharger,
      };
    }
    newSets[setIndex].chargingWindows[chargingWindowInd] = newChargingWindow;
  
    setInput({ vehicleSets: newSets, computeData: false });
    resetShowCount();
    setActiveFilters({
      brand: 'All',
      output: 'All',
      maxPrice: 'All',
      ports: 'All',
      cableLength: 'All',
      types: ['All'],
      range: 0,
    });
    onClose();
  };

  const handleClose = () => {
    onClose();
    resetShowCount();
    setSelectedCharger(charger);
    setActiveFilters({
      brand: 'All',
      output: 'All',
      maxPrice: 'All',
      ports: 'All',
      cableLength: 'All',
      types: ['All'],
      range: 0,
    });
  };

  const updateFilter = (name, value) => {
    resetShowCount();
    setActiveFilters({ ...activeFilters, [name]: value });
  };

  const chargers = useChargers(activeFilters, sortConfig[sortOrder]);

  return (
    <Dialog open={isOpen} maxWidth="lg" fullWidth onBackdropClick={handleClose}>
      <DialogTitle>
        <CatalogHeader
          onClose={onClose}
          vehicleName={vehicleName}
          milesPerDay={milesPerWorkday + personalMilesPerWorkday}
          kWhPerWorkday={kWhPerWorkday}
        />
      </DialogTitle>
      <DialogContent px="48px">
        <Grid container justifyContent="space-around">
          <Grid item xs={12} md={3}>
            <SimpleChargerCard
              vehicleCount={vehicleCount}
              vehicleName={vehicleName}
              milesPerDay={milesPerWorkday + personalMilesPerWorkday}
              start={start}
              finish={finish}
              efficiency={efficiencyInMilesPerKwh}
              batteryCapacity={batteryCapacity}
            />
            <Filters charger={charger} onChange={updateFilter} />
          </Grid>
          <Grid item xs={12} md={8}>
            <ContentHeader
              subset={chargers.length}
              total={activeFilters.brand === 'Generic' ? CHARGERS.length : apiChargers.length}
              sortOrder={sortOrder}
              changeSort={setSortOrder}
            />
            <Box
              display="flex"
              flexWrap="wrap"
              columnGap={2}
              rowGap={3}
              minHeight="680px"
              sx={(theme) => ({
                justifyContent: 'center',
                [theme.breakpoints.up('sm')]: { justifyContent: 'space-around' },
              })}
            >
              {chargers.slice(0, showCount).map((charger) => (
                <ComplexChargerCard
                  key={charger.id || charger.name}
                  replacementOption={charger}
                  selectedCharger={selectedCharger}
                  vehicleCount={vehicleCount}
                  onSelect={() => setSelectedCharger(charger)}
                  setName={vehicleSetName}
                  onlyCharger={onlyCharger}
                  vehicleSet={vehicleSet}
                  showingGeneric={activeFilters.brand === 'Generic'}
                />
              ))}
            </Box>
            {chargers.length > showCount && (
              <Box p="16px" textAlign="center">
                <ButtonInput onClick={showMore}>
                  {formatMessage({ id: 'inputs.chargerCatalog.showMore' })}
                </ButtonInput>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ActionButtons
          onClose={handleClose}
          onSave={handleSave}
          disableSave={!selectedCharger?.id || selectedCharger?.id === charger?.id}
        />
      </DialogActions>
    </Dialog>
  );
}
