import { useInputs } from "@bellawatt/use-inputs";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useIntl } from "react-intl";
import getDefaultRate from "../../calculations/rates/defaultRate";
import { useDisplay } from "../../components/DisplayProvider";
import DropdownInput from "../../components/DropdownInput";
import { useCalculations } from "../../utils/useCalculations";
import {
  formatAsCents,
  formatAsDollars,
  formatAsThousands
} from "../../utils/formatters";
import VehicleInfoCard from "./VehicleInfoCard";
import VehicleOverview from "./VehicleOverview";

export default function Comparison() {
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();
  const theme = useTheme();
  const { vehicleSets } = useInputs();

  const [selectedVehicleSetIndex, setSelectedVehicleSetIndex] = useState(0);
  const selectedVehicleSet =
    vehicleSets[selectedVehicleSetIndex] || vehicleSets[0];

  const handleVehicleChange = (e) => {
    const vehicleSetIndex =
      vehicleSets.findIndex(({ id }) => id === e.target.value) || 0;
    setSelectedVehicleSetIndex(vehicleSetIndex);
  };

  return (
    <Grid container paddingLeft="40px" sx={{
              '@media (max-width: 768px)': {
                     paddingLeft: '0px',
                     paddingBottom: '40px'
              },
       }}>
      <Grid item xs={12}>
        <Box mb={4}>
          <Typography
            fontSize="1.25rem"
            color={theme.palette.grey[900]}
            textTransform="uppercase"
          >
            {formatMessage({ id: "panels.comparison.header" })}
          </Typography>
          <Typography fontSize="1rem" color={theme.palette.grey[900]} mt={2}>
            {formatMessage({ id: "panels.comparison.subheader" })}
          </Typography>
        </Box>

        {mode === "print" ? (
          vehicleSets.map((set) => (
            <>
              <h3>{set.replacementVehicle.name}</h3>
              <ComparisonSet vehicleSet={set} />
            </>
          ))
        ) : (
          <>
            <DropdownInput
              id="vehicle-comparison-select"
              label={formatMessage({ id: "panels.comparison.dropdownLabel" })}
              value={selectedVehicleSet.id}
              options={vehicleSets.map(
                ({ id, replacementVehicle: { name } }) => ({
                  value: id,
                  children: name,
                })
              )}
              onChange={handleVehicleChange}
              maxWidth={300}
              mb={4}
            />
            <ComparisonSet vehicleSet={selectedVehicleSet} />
          </>
        )}
      </Grid>
    </Grid>
  );
}

function ComparisonSet({ vehicleSet }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { mode } = useDisplay();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const {
    dieselPrice,
    gasolinePrice,
    electricityPrice,
    state,
    isMultipleSites,
    userUpdatedElectricity,
    userUpdatedDiesel
  } = useInputs();
  const Calculations = useCalculations();

  const annual_business_miles = Calculations.annualMiles({ input: vehicleSet, fossilFuelType: vehicleSet.existingVehicle.fuel })

  const annual_business_miles_EV = Calculations.annualMiles({ input: vehicleSet });

  const annualFossilFuelCost = Calculations.annualFossilFuelCosts({
    input: vehicleSet,
    dieselPrice,
    gasolinePrice,
  });

  const fuelCostPerMile = annualFossilFuelCost / annual_business_miles;

  const annualElectricityCost = Calculations.annualElectricityCosts({
    input: vehicleSet,
    rate: getDefaultRate(electricityPrice),
  });

  const evCostPerMile =
    annualElectricityCost / vehicleSet.vehicleCount / annual_business_miles_EV;

  const specsfossilFuelInfo = [
    {
      label: formatMessage({ id: "panels.comparison.specs.cargo" }),
      value: vehicleSet.existingVehicle.cargoVolume
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.seating" }),
      value: vehicleSet.existingVehicle.seating
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.interiorHeight" }),
      value: vehicleSet.existingVehicle.interiorHeight
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.payload" }),
      value: vehicleSet.existingVehicle.payload.toFixed(2)
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.drivetrain" }),
      value: vehicleSet.existingVehicle.drivetrain
    }
  ];

  const fossilFuelInfo = [
    {
      label: formatMessage({ id: "panels.comparison.fuel.annualFossilCost" }),
      value: formatAsDollars(annualFossilFuelCost * vehicleSet.vehicleCount)
    },
    {
      label: formatMessage({ id: "panels.comparison.fuel.efficiency" }),
      value: formatMessage(
        { id: 'panels.comparison.fuel.fuelEfficiency' },
        { value: vehicleSet.existingVehicle.milesPerGallon, fuel: vehicleSet.existingVehicle.fuel}
      )
    },
    {
      label: formatMessage({ id: "panels.comparison.fuel.gasolineCostPerMile" }),
      value: formatMessage({ id: 'panels.comparison.fuel.gasolineCostPerMileValue' },
        { value: fuelCostPerMile.toFixed(2) })
    },
    {
      label: formatMessage({ id: "panels.comparison.fuel.totalMilesPerYear" }),
      value: formatAsThousands(annual_business_miles * vehicleSet.vehicleCount)
    },
    {
      label: formatMessage({ id: "panels.comparison.fuel.fossilAssumptions" }),
      value: formatMessage(
        {
          id: `panels.comparison.fuel.${isMultipleSites
             ? vehicleSet.existingVehicle.fuel.toLowerCase() === "diesel"
                ? "avgDieselCostNational"
                : "avgGasolineCostNational"
              : vehicleSet.existingVehicle.fuel.toLowerCase() === "diesel"
              ? "avgDieselCost"
              : "avgGasolineCost"
          }`,
        },
        {
          state,
          price: formatAsCents(dieselPrice),
          source: userUpdatedDiesel ? (
            "User specified"
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://gasprices.aaa.com/"
            >
              AAA
            </a>
          ),
        }
      )
    }
  ];

  const electricFuelInfo = [
    {
      label: formatMessage({ id: "panels.comparison.fuel.annualElectricCost" }),
      value: formatAsDollars(annualElectricityCost),
    },
    {
      label: formatMessage({
        id: "panels.comparison.electricEV.electricityCostPerMile",
      }),
      value: formatMessage({ id: 'panels.comparison.electricEV.electricityCostPerMileValue' }, { value: evCostPerMile.toFixed(2) }),
    },
    {
      label: formatMessage({ id: "panels.comparison.fuel.totalMilesPerYear" }),
      value: formatAsThousands(annual_business_miles * vehicleSet.vehicleCount)
    },
    {
      label: formatMessage({
        id: "panels.comparison.fuel.electricAssumptions",
      }),
      value: formatMessage(
        {
          id: `panels.comparison.fuel.${isMultipleSites
              ? "avgElectricityCostNational"
              : "avgElectricityCost"
          }`,
        },
        {
          state,
          price: formatAsCents(electricityPrice),
          source: userUpdatedElectricity ? (
            "User specified"
          ) : (
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a"
            >
              EIA
            </a>
          ),
        }
      ),
    },
  ];

  const specsElectricInfo = [
    {
      label: formatMessage({ id: "panels.comparison.specs.cargo" }),
      value: vehicleSet.replacementVehicle.cargoVolume
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.seating" }),
      value: vehicleSet.replacementVehicle.seating
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.interiorHeight" }),
      value: vehicleSet.replacementVehicle.interiorHeight
    },
    {
      label: formatMessage({ id: "panels.comparison.specs.payload" }),
      value: vehicleSet.replacementVehicle.payload.toFixed(2)
    }
  ];

  // TODO: hardcoded values until API gives us better data

  const electricTableInfo = [
    {
      title: formatMessage({ id: "panels.comparison.fuel.header" }),
      items: electricFuelInfo,
    },
    {
      title: formatMessage({ id: "panels.comparison.specs.header" }),
      items: specsElectricInfo,
    },
  ];

  const fuelTableInfo = [
    {
      title: formatMessage({ id: "panels.comparison.fuel.header" }),
      items: fossilFuelInfo,
    },
    {
      title: formatMessage({ id: "panels.comparison.specs.header" }),
      items: specsfossilFuelInfo,
    }
  ];

  return (
    <Grid container gap={4} justifyContent="space-around" flex={1}>
      <Grid
        item
        flex={1}
        width={mode === "print" || isLargeScreen ? null : "100%"}
        borderRadius={5}
        border={`1px solid ${theme.palette.grey[300]}`}
      >
        <VehicleOverview
          name={vehicleSet.existingVehicle.name}
          imgUrl={vehicleSet.existingVehicle.imageUrl}
          isElectric={false}
          fuel={vehicleSet.existingVehicle.fuel}
        />
        <VehicleInfoCard info={fuelTableInfo} />

      </Grid>
      <Grid
        item
        flex={1}
        width={mode === "print" || isLargeScreen ? null : "100%"}
        borderRadius={5}
        border={`1px solid ${theme.palette.grey[300]}`}
      >
        <VehicleOverview
          name={vehicleSet.replacementVehicle.name}
          imgUrl={vehicleSet.replacementVehicle.imageUrl}
          isElectric={true}
        />
        <VehicleInfoCard info={electricTableInfo} />
      </Grid>
    </Grid>
  );
}
