import {
    Dialog,
    DialogContent,
    DialogTitle,
    Box,
    Typography,
    DialogActions,
  } from "@mui/material";
  import React, { useState } from "react";
  import { useIntl } from "react-intl";

import ButtonInput from "../../../components/ButtonInput";
import DropdownInput from "../../../components/DropdownInput";

import DefaultInputs from "./DefaultInputs";
import { ELECTRIC_VEHICLES, ICE_VEHICLES } from "../../../data/VEHICLES";

import { CloseIcon } from "../../../assets/icons/icons";
import ResaleInputs from './ResaleInputs'
import { useInputs } from '@bellawatt/use-inputs';


export default function EditVehicleModal({
    isOpen,
    onClose,
    onDelete,
    canRemove,
    onSave,
    vehicleSet,
    onChangeVehicleSet,
    onChangeMake, 
    tab,
    isEquivalent
  }) {
    const { formatMessage } = useIntl();
    const { setInput, resaleOneYearEv, resaleOneYearFossil, resaleLifetimeEv, resaleLifetimeFossil } = useInputs();
    const [yearResale, setYearResale] = useState({ev: resaleOneYearEv || 100, fossil: resaleOneYearFossil || 100})
    const [lifetimeResale, setLifetimeResale] = useState({ev: resaleLifetimeEv || 100, fossil: resaleLifetimeFossil || 100})

    const apiVehicleOptions = ICE_VEHICLES.map(({ id, name }) => ({
        value: id,
        children: name,
    }));

    const apiVehicleOptionsElectric = ELECTRIC_VEHICLES.map(({ id, name }) => ({
        value: id,
        children: name,
    }));

    const handleVehicleMakeChange = (make) => {
        if(isEquivalent){
            handleVehicleMakeChangeReplace(make)
        } else {
            handleVehicleMakeChangeFossil(make)
        } 
    }

    const handleVehicleMakeChangeFossil = (make) => {
        onChangeMake({
          existingVehicleId: make,
        });
    };

    const handleVehicleMakeChangeReplace = (make) => {
        onChangeMake({
            replacementVehicleId: make,
          });
    };
    
    
    let requireFossilArr = ["gvwr", "msrp", "mpg"];
    let requireReplaceArr = ["gvwr", "msrp", "mpg"];
    let disableReplaceArr = ["gvwr", "cargo"];
    let removedArr = []


    if ((vehicleSet.replacementVehicle.id === "BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024" ||
    vehicleSet.replacementVehicle.id === "BrightDrop_Zevo_600_20_Mod_AWD_Class_3_BEV") && isEquivalent) {
      disableReplaceArr.push("msrp")
      removedArr.push("mpg")
    }

    const handleSave = () => {
      if(isEquivalent) {
        setInput({resaleOneYearEv: Number(yearResale.ev), resaleLifetimeEv: Number(lifetimeResale.ev)})
      } else {
        setInput({resaleOneYearFossil: Number(yearResale.fossil), resaleLifetimeFossil: Number(lifetimeResale.fossil)})
      }
      
      onClose()
    }

    return (
      <Dialog open={isOpen} onClose={onClose} maxWidth={"100%"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DialogTitle>
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                padding: '20px 0px 35px',
                [theme.breakpoints.up("sm")]: { width: "902px" },
              })}
            >
              <Typography fontSize="22px" fontWeight="200">
                {formatMessage({
                  id: `inputs.vehicleSet.editSetDialog.title${isEquivalent ? 'Equivalent' : 'Current'}`,
                })}
              </Typography>
              <div onClick={onClose} style={{ cursor: "pointer" }}>
                <CloseIcon width={'14px'} />
              </div>
            </Box>

            <Box sx={{ width: "100%", height: "1px", background: "#D9D9D6" }} />
          </DialogTitle>
          <DialogContent
            sx={{ width: "902px", justifyContent: "center", padding: 0 }}
            className="hiddenScroll"
          >
            <Box
              display="flex"
              width="100%"
              justifyContent="flex-start"
              mb="24px"
            >
              <Box width="46%">
                <DropdownInput
                  disabled={
                    isEquivalent
                      ? apiVehicleOptionsElectric.length === 1
                      : apiVehicleOptions.length === 1
                  }
                  id="vehicle-select-input-ice"
                  label={formatMessage({
                    id: "onboarding.selectVehicle.model",
                  })}
                  options={
                    isEquivalent ? apiVehicleOptionsElectric : apiVehicleOptions
                  }
                  value={
                    isEquivalent
                      ? vehicleSet.replacementVehicleId
                      : vehicleSet.existingVehicleId
                  }
                  required
                  onChange={(e) => handleVehicleMakeChange(e.target.value)}
                />
              </Box>
              <img
                src={
                  isEquivalent
                    ? vehicleSet.replacementVehicle.imageUrl
                    : vehicleSet.existingVehicle.imageUrl
                }
                alt={
                  isEquivalent
                    ? vehicleSet.replacementVehicle.name
                    : vehicleSet.existingVehicle.name
                }
                style={{ width: "130px", height: "auto", marginLeft: "85px" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "1px",
                background: "#D9D9D6",
                marginBottom: "35px",
              }}
            />
            <Box>
              <DefaultInputs
                tab={tab}
                vehicleSet={vehicleSet}
                onChangeVehicleSet={onChangeVehicleSet}
                isModal
                removedArr={removedArr}
                requiredArr={
                  isEquivalent ? requireReplaceArr : requireFossilArr
                }
                disabledArr={isEquivalent && disableReplaceArr}
              />
            </Box>
            <Box>
                <ResaleInputs 
                  yearResale={yearResale} 
                  setYearResale={setYearResale} 
                  isEquivalent={isEquivalent}
                  lifetimeResale={lifetimeResale}
                  setLifetimeResale={setLifetimeResale}
                  resaleOneYearEv={resaleOneYearEv}
                  resaleOneYearFossil={resaleOneYearFossil}
                  resaleLifetimeEv={resaleLifetimeEv}
                  resaleLifetimeFossil={resaleLifetimeFossil}
                />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              width: "910px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "35px",
            }}
          >
            <ButtonInput variant="rounded" onClick={handleSave}>
              Save
            </ButtonInput>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
  