// Source: https://docs.google.com/spreadsheets/d/1o8ydfXcQ6B6Mtk_EagQECyZ2LXQICQFbT2WAtkXboXI/edit#gid=1078164887

const CHARGERS = [
  {
    id: 'AC_7_2_1',
    price: 350,
    estimatedRebate: 0,
    ports: 1,
    portKw: 7.2,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.2KW.png',
  },
  {
    id: 'AC_7.7_1',
    price: 400,
    estimatedRebate: 0,
    ports: 1,
    portKw: 7.7,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png',
  },
  {
    id: 'AC_9.6_1_wire',
    price: 100,
    estimatedRebate: 0,
    ports: 1,
    portKw: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-02.png',
  },
  {
    id: 'AC_9.6_1',
    price: 450,
    estimatedRebate: 0,
    ports: 1,
    portKw: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-01.png',
  },
  {
    id: 'AC_9.6_2',
    price: 450,
    estimatedRebate: 0,
    ports: 2,
    portKw: 9.6,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 9.6KW-01.png',
  },
  {
    id: 'AC_11.52_1',
    price: 600,
    estimatedRebate: 0,
    ports: 1,
    portKw: 11.52,
    chargerType: 'level_2',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 11.5KW-01.png',
  },
  {
    id: 'DC_24_1',
    price: 12000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 24,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 24 KW Singal port charger-01.png',
  },
  {
    id: 'DC_24_3',
    price: 13000,
    estimatedRebate: 0,
    ports: 3,
    portKw: 24,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 24 KW Three port charger-01-01.png',
  },
  {
    id: 'DC_25_1',
    price: 12600,
    estimatedRebate: 0,
    ports: 1,
    portKw: 25,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC%2025kW%20Single%20Port-01.png',
  },
  {
    id: 'DC_40_1',
    price: 21700,
    estimatedRebate: 0,
    ports: 1,
    portKw: 40,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 40kW Single Port-01.png',
  },
  {
    id: 'DC_50_1',
    price: 27800,
    estimatedRebate: 0,
    ports: 1,
    portKw: 50,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    id: 'DC_50_2',
    price: 28300,
    estimatedRebate: 0,
    ports: 2,
    portKw: 50,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 50 KW Single port charger-01.png',
  },
  {
    id: 'DC_60_1',
    price: 34000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 60,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 60kW, Single Port-01.png',
  },
  {
    id: 'DC_60_2',
    price: 34000,
    estimatedRebate: 0,
    ports: 2,
    portKw: 60,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 60kW, Dual Port-01.png',
  },
  {
    id: 'DC_60_3',
    price: 35000,
    estimatedRebate: 0,
    ports: 3,
    portKw: 60,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DCFC 60kW, Three Port-01.png',
  },
  {
    id: 'DC_62.5_1',
    price: 35000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 62.5,
    chargerType: 'dc_fast_charging',
    imageUrl:
      '',
  },
  {
    id: 'DC_75_1',
    price: 43000,
    estimatedRebate: 0,
    ports: 1,
    portKw: 75,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/DC%2075kW%20Single%20Port-01.png',
  },
  {
    id: 'DC_75_2',
    price: 44000,
    estimatedRebate: 0,
    ports: 2,
    portKw: 75,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 75 KW dual port charger-01.png',
  },
  {
    id: 'DC_100_2',
    price: 59000,
    estimatedRebate: 0,
    ports: 2,
    portKw: 100,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 100 KW dual port charger-01.png',
  },
  {
    id: 'DC_120_2',
    price: 71000,
    estimatedRebate: 0,
    ports: 2,
    portKw: 120,
    chargerType: 'dc_fast_charging',
    imageUrl:
      'https://assets.zappyride.com/img/commercial_chargers/archetypes/Fast charger 120 KW dual port charger-01.png',
  },
];

export default CHARGERS;
