import React from 'react';
import { useIntl } from 'react-intl';
import OverviewVehicles from './overview/OverviewVehicles';
import Fuel from './fuel/Fuel';
import Incentives from './incentives/Incentives';
import Comparison from './comparison/Comparison';
import Charging from './charging/Charging';
import Emissions from './emissions/Emissions';
import Electricity from './electricity/Electricity';
import Operations from './operations/Operations';
import Financials from './financials/Financials';
import { InsufficientChargeBlocker } from '../components/InsufficientChargeBlocker';
import { useDepletedBatterySets } from '../utils/useDepletedBatterySets';
import { useInputs } from '@bellawatt/use-inputs';

export default function useTabs() {
  const { formatMessage } = useIntl();
  const { depletedBatterySets } = useDepletedBatterySets();
  const showBatteryWarning = depletedBatterySets.length;
  const { state } = useInputs();

  const isCalifornia = state === 'California';

  let tabArr = [
    {
      label: formatMessage({ id: 'tabs.overview' }),
      content: <OverviewVehicles />,
    },
    {
      label: formatMessage({ id: 'tabs.financials' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Financials />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.fuel' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Fuel />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.electricity' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Electricity />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.incentives' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Incentives />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.comparison' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Comparison />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.charging' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Charging />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.operations' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Operations />
      ),
    },
    {
      label: formatMessage({ id: 'tabs.emissions' }),
      content: showBatteryWarning ? (
        <InsufficientChargeBlocker vehicleSets={depletedBatterySets} />
      ) : (
        <Emissions />
      ),
    },
  ];

  if (!isCalifornia)
    tabArr = tabArr.filter((e) => {
      return e.label !== 'LCFS';
    });

  return tabArr;
}
