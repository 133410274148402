import React from 'react';
import { Grid, Box,Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { makeStyles } from '@mui/styles';
import GaTracker from '../utils/GaTracker';

const useStyles = makeStyles((theme) => ({
  footerBox: {
    padding: `${theme.spacing(0)}px 0`, 
    maxWidth: '1440px' 
  },
  footerText: {
    color: '#00000',
  },

  
  linksBox: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2px',
    },
  },
  textLink: {
    fontSize: '12px',
    fontWeight: '300',
    marginRight: '24px',
    textDecoration: 'none'
  },

}));
export default function Footer() {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const handleClickPrivacy = () => {
    GaTracker.trackEvent({
      category: "Lead Generation",
      action: "Privacy Police footer",
      label: "Click Privacy Police"
    });
  }

  const handleClickGuideline = () => {
    GaTracker.trackEvent({
      category: "Lead Generation",
      action: "User Guidelines footer",
      label: "Click User Guidelines"
    });
  }

  const handleClickCopyright = () => {
    GaTracker.trackEvent({
      category: "Lead Generation",
      action: "Copyright & Trademarks footer",
      label: "Click Copyright & Trademarks"
    });
  }

  return (
    <Box>
      <Box>
        <Grid
          container
          sx={(theme) => ({
            color: theme.palette.grey[800],
            fill: theme.palette.grey[800],
            p: '16px',
            [theme.breakpoints.up('sm')]: {
              p: '24px 24px 8px',
            },
          })}
        >
          <Box
            display="flex"
            columnGap="120px"
            rowGap="20px"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            m="0 auto"
            sx={{
              maxWidth: (theme) => theme.settings.maxWidth.footer,
            }}
            fontSize="12px"
            lineHeight={1.5}
            minWidth="200px"
            flex="1 1"
          >
            {formatMessage({ id: 'footer.disclaimer' })}
          </Box>
        </Grid>
      </Box>
      <Box>
        <Grid           
          container
          sx={(theme) => ({
            color: theme.palette.grey[800],
            fill: theme.palette.grey[800],
            p: '16px',
            [theme.breakpoints.up('sm')]: {
              p: '8px 24px 32px',
            },
          })}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            m="0 auto"
            sx={{
              maxWidth: (theme) => theme.settings.maxWidth.footer,
            }}
            fontSize="12px"
            lineHeight={1.5}
            minWidth="200px"
            flex="1 1"
          >
              <Link
                href={'https://www.gm.com/privacy-statement'}
                target='_blank'
                className={classes.textLink}
                onClick={handleClickPrivacy}
              >
                {formatMessage({ id: 'footer.privacyPolicy.text' })}
              </Link>
              <Link 
                href={'https://www.gobrightdrop.com/user-guidelines.pdf'} 
                className={classes.textLink}
                target='_blank'
                onClick={handleClickGuideline}
              >
                {formatMessage({ id: 'footer.userGuidelines.text' })}
              </Link>
              <Link
                href={'https://www.gm.com/copyright-trademark'}
                className={classes.textLink}
                target='_blank'
                onClick={handleClickCopyright}
              >
                {formatMessage({ id: 'footer.copyrightAndTrademark.text' })}
              </Link>
            

            </Box> 
        </Grid>
      </Box>
    </Box>
  );
}
