import React from 'react';
import { Button, ClickAwayListener, Tooltip, Typography } from '@mui/material';
import { formatAsDollars } from '../../utils/formatters';
import { useState } from 'react';
import sum from 'lodash/sum';
import { useIntl } from 'react-intl';

export default function EligibilityRationale({
  incentive: { eligibility_rationale, amount_rationale },
}) {
  const { formatMessage } = useIntl();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const formattedAmounts = amount_rationale.map(
    ({ rationale, amount }) =>
      (rationale.trim().endsWith('.') ? rationale : rationale.trim() + '. ') +
      ` (${formatAsDollars(amount)})`,
  );

  // logic to dynamically put some amount rationales behind a tooltip
  const amountsLimitIdx = formattedAmounts.findIndex(
    (msg, idx, arr) =>
      eligibility_rationale.length + sum(arr.slice(0, idx).map((m) => m.length)) > 250,
  );

  const showTooltip = formattedAmounts.length > 1 && amountsLimitIdx !== -1;

  const tooltipContent = (
    <>
      <Typography fontSize="0.875rem">{eligibility_rationale}</Typography>

      {formattedAmounts.map((msg) => (
        <Typography key={msg} fontSize="0.875rem" mb={1}>
          {msg}
        </Typography>
      ))}
    </>
  );

  return (
    <>
      <Typography fontSize="0.875rem" mb={1}>
        {eligibility_rationale}
      </Typography>

      {formattedAmounts.slice(0, showTooltip ? amountsLimitIdx : undefined).map((msg, idx) => (
        <Typography key={msg} fontSize="0.875rem" mb={1}>
          {msg}
          {showTooltip && idx === amountsLimitIdx - 1 && (
            <>
              ...
              <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setTooltipOpen(true)}
                  open={tooltipOpen}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={tooltipContent}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: 'black',
                        backgroundColor: 'white',
                        border: (theme) => `1px solid ${theme.palette.grey[300]}`,
                        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                        borderRadius: '2px',
                        fontSize: '14px',
                        px: 2,
                        py: 1,
                        maxWidth: 400,
                      },
                    },
                  }}
                >
                  <Button
                    onClick={() => setTooltipOpen(true)}
                    sx={(theme) => ({
                      textTransform: 'none',
                      p: 0,
                      verticalAlign: 'text-top',
                      lineHeight: '1rem',
                      fontSize: '0.875rem',
                      color: theme.palette.common.blue[600],
                      fontWeight: '700',
                    })}
                  >
                    {formatMessage({ id: 'panels.incentives.more' })}
                  </Button>
                </Tooltip>
              </ClickAwayListener>
            </>
          )}
        </Typography>
      ))}
    </>
  );
}
