import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

import Table from '../overview/Table';

export default function VehicleInfoCard({ header, info, showBorder }) {

  const { formatMessage } = useIntl();

  return (
    <Box height="100%" px={4} py={1}>
      <Grid container width="100%" rowSpacing={4}>
        {info.map((item) => {
          return (
            <Grid item width="100%">
              <Table title={item.title} items={item.items} />
            </Grid>
          );
        })}
        <Typography fontSize={14}  mt={2} fontStyle={'italic'}>
          {formatMessage({ id: "panels.comparison.standardVehicleSpecs" })}
        </Typography>
      </Grid>
    </Box>
  );
}
