import { useInputs } from "@bellawatt/use-inputs";
import { useCalculations } from "../utils/useCalculations";

export const useDepletedBatterySets = () => {
  const { vehicleSets } = useInputs();
  const Calculations = useCalculations();

  const depletedBatterySets = vehicleSets.filter(
    (set) => Calculations.minimumStateOfCharge({ input: set }) <= 0
  );
  return {
    depletedBatterySets,
  };
};
