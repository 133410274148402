import { useInputs } from "@bellawatt/use-inputs";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import CollapsibleInput from "./CollapsibleInput";
import EditVehicleSetCard from "./EditVehicleSetCard";
import cloneDeep from "lodash/cloneDeep";
import { DEFAULT_VEHICLE_SET } from "../use-inputs/getDefaults";
import { v4 as uuidv4 } from "uuid";
import { addComputedData} from "../utils/computedData";
import { PlusIcon } from "../assets/icons/icons";
import VehicleCatalogDialog from "../dialogs/vehicleCatalog/VehicleCatalogDialog";
import EditVehicleSetDialog from "../dialogs/editVehicleSetDialog/EditVehicleSetDialog";
import CHARGERS from "../data/CHARGERS";

const DIALOG = {
  VEHICLE_SET: "vehicleSet",
  REPLACEMENT_VEHICLE: "replacementVehicle",
};

export default function VehicleSetInputs() {
  const { formatMessage } = useIntl();
  const {
    vehicleSets,
    setInput,
    apiFossilVehicles,
    apiChargers,
    apiElectricVehicles,
  } = useInputs();
  const [openDialog, setOpenDialog] = useState();

  const closeDialog = () => {
    setInput({ isSetVehicleModal: false });
    setOpenDialog(null);
    setWorkingVehicleSet(null);
  };
  const [workingVehicleSet, setWorkingVehicleSet] = useState(null);

  const handleChangeVehicleSet = (newValues) => {
    setWorkingVehicleSet({
      ...workingVehicleSet,
      ...newValues,
    });
  };

  const handleChangeMake = (newData) => {
    let replacementVehicleId = workingVehicleSet.replacementVehicleId;
    let existingVehicleId = workingVehicleSet.existingVehicleId;

    if (newData["existingVehicleId"] !== undefined) {
      existingVehicleId = newData.existingVehicleId;
    }
    if (newData["replacementVehicleId"] !== undefined) {
      replacementVehicleId = newData.replacementVehicleId;
    }
    // when working vehicle set state is changed, pass it through the use-inputs computed flow
    // to make sure all pieces of state are available
    const newVehicleSet = addComputedData({
      vehicleSets: [
        {
          ...workingVehicleSet,
          ...newData,
          replacementVehicleId,
          existingVehicleId,
        },
      ],
      apiFossilVehicles,
      apiElectricVehicles,
      apiChargers,
      computeData: true,
    }).vehicleSets[0];

    setWorkingVehicleSet(newVehicleSet);
  };

  const handleOpenVehicleSet = (vehicleSet) => {
    setWorkingVehicleSet(vehicleSet);
    setInput({ isSetVehicleModal: true });
  };

  const handleAddNewSet = () => {
    const newSet = addComputedData({
      vehicleSets: [
        {
          ...DEFAULT_VEHICLE_SET,
          id: uuidv4(),
          name: `Vehicle Set #${vehicleSets.length + 1}`,
          isNewSet: true,
        },
      ],
      apiFossilVehicles,
      apiElectricVehicles,
      apiChargers,
      computeData: true,
    }).vehicleSets[0];

    const setsArr = cloneDeep(vehicleSets);

    setWorkingVehicleSet(cloneDeep(newSet));
    //We need the vehicle data for the working set, but it should not get saved to state
        setInput({ vehicleSets: setsArr, computeData: false });
       setInput({ isSetVehicleModal: true });
  };

  const handleChangeReplacementVehicle = (newReplacementVehicle) => {
    setWorkingVehicleSet({
      ...workingVehicleSet,
      replacementVehicle: newReplacementVehicle,
      replacementVehicleId: newReplacementVehicle.id,
    });
    setInput({ isSetVehicleModal: true });
  };

  const handleDelete = (setId) => {
    const index = vehicleSets.findIndex((set) => set.id === setId);
    const newSets = cloneDeep(vehicleSets);

    newSets.splice(index, 1);
    setInput({ vehicleSets: newSets, computeData: false });
    closeDialog();
  };

  const handleSaveAndClose = (vehicleData) => {
    const index = vehicleSets.findIndex((set) => set.id === vehicleData.id);
    const newSets = cloneDeep(vehicleSets);

    vehicleData.chargingWindows.forEach(e => {
      if(!e.charger){
        e.charger = e.isGeneric 
        ? CHARGERS.find(({ id }) => id === e.chargerId)
        : apiChargers.find(({ id }) => id === e.chargerId)
      }
    })

    if (index < 0) {
      newSets.push(vehicleData);
    }
    else {
      newSets[index] = vehicleData;
    }
    setInput({ vehicleSets: newSets, computeData: false });
    closeDialog();
    setWorkingVehicleSet(null);
  };

  return (
    <CollapsibleInput
      title={formatMessage({ id: "inputs.vehicleSet.title" })}
      defaultOpen
      customTitleSx={{ textTransform: "none", fontWeight: "400" }}
    >
      {vehicleSets.map((vehicleSet, index) => (
        <EditVehicleSetCard
          key={`${vehicleSet.name}-${index}`}
          vehicleSet={vehicleSet}
          onClick={() => handleOpenVehicleSet(vehicleSet)}
        />
      ))}
      <Box
        p={0.5}
        border={`1px solid #0072CE`}
        borderRadius="50px"
        width="100%"
        sx={{ "&:hover": { border: `1px solid #0072CE` } }}
      >
        <Button
          onClick={handleAddNewSet}
          style={{
            fontWeight: "700",
            width: "100%",
            color: "#0072CE",
            textTransform: "none",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <Box textTransform="uppercase" display="flex">
            <Box mr={1}>
              <PlusIcon />
            </Box>
            {formatMessage({ id: "inputs.vehicleSet.addNewButton" })}
          </Box>
        </Button>
      </Box>
      {workingVehicleSet && (
        <>
          <VehicleCatalogDialog
            isOpen={openDialog === DIALOG.REPLACEMENT_VEHICLE}
            onClose={closeDialog}
            onChangeVehicle={handleChangeReplacementVehicle}
            vehicleSet={workingVehicleSet}
          />
          <EditVehicleSetDialog
            onOpenChangeReplacementVehicle={() =>
              setOpenDialog(DIALOG.REPLACEMENT_VEHICLE)
            }
            onClose={closeDialog}
            onSave={() => handleSaveAndClose(workingVehicleSet)}
            onDelete={() => handleDelete(workingVehicleSet.id)}
            canRemove={vehicleSets.length > 1}
            onChangeMake={handleChangeMake}
            onChangeVehicleSet={handleChangeVehicleSet}
            vehicleSet={workingVehicleSet}
          />
        </>
      )}
    </CollapsibleInput>
  );
}
