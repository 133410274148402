import { useInputs } from "@bellawatt/use-inputs";
import Loading from "./components/Loading";
import ContentPane from "./tabs/ContentPane";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route } from "react-router-dom";
import useApiLocationData from "./utils/useApiLocationData";
import useApiIncentiveData from "./utils/useApiIncentiveData";
import OnboardingLayout from "./onboarding/OnboardingLayout";
import useApiEvaluatedIncentiveData from "./utils/useApiEvaluatedIncentiveData";
import ContentPrint from "./components/ContentPrint";
import DisplayProvider from "./components/DisplayProvider";
import { Box } from "@mui/material";
import VehiclesPage from "./catalogs/VehiclesPage";
import MobileMenuProvider from "./components/MobileMenuProvider";
import { useEffect } from "react";
import getDefaultsWithApi from "./use-inputs/getDefaults";
import { CalculationsProvider } from "@zappy-ride/library.calculations";
import GaTracker from "./utils/GaTracker";
import { useLocation } from "react-router-dom";
import { useCalculations } from "./utils/useCalculations";
import CHARGERS from "./data/CHARGERS";
import comprehensiveRates from "./data/insurance/comprehensive.json";
import liabilityRates from "./data/insurance/liability.json";

function AppWrapper({ children }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100vh"
      width="100%"
      overflow="hidden"
    >
      <Header />
      <Box flex="1 1" overflow="hidden auto" backgroundColor="#F0F2EF">
        {children}
        <Footer />
      </Box>
    </Box>
  );
}

const AppContent = ({loading, isResetting, isOnboarded}) => {
  const Calculations = useCalculations();
  // dynamically fetch fuel prices, incentives and set state when certain inputs change
  useApiLocationData();
  useApiIncentiveData();
  useApiEvaluatedIncentiveData();

  useEffect(()=>{
    if(Calculations && !Calculations.initialized) {
      Calculations?.setData({
        chargers: CHARGERS,
        comprehensiveRates: comprehensiveRates,
        liabilityRates: liabilityRates
      })
    }
  }, [Calculations])

  return <MobileMenuProvider>
  {loading || isResetting ? (
    <Loading />
  ) : (
    <>
      <Route path="/print" component={ContentPrint} />
      <Route path="/vehicle-catalog">
        <AppWrapper>
          <VehiclesPage />
        </AppWrapper>
      </Route>
      <Route exact path="/">
        <AppWrapper>
          {isOnboarded ? <ContentPane /> : <OnboardingLayout />}
        </AppWrapper>
      </Route>
    </>
  )}
</MobileMenuProvider>
}

function App() {
  GaTracker.initialize();
  const { loading, isOnboarded, isResetting, setInput, zipCode } = useInputs();
  const location = useLocation();

  useEffect(() => {
    GaTracker.trackPage(location);
  }, [location]);

  useEffect(() => {
    fetchElectricData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);

  const fetchElectricData = async () => {
    if (!zipCode) return;

    const { electricityPrice, gasolinePrice, dieselPrice, carbonCreditsPrice } =
      await getDefaultsWithApi(zipCode);

    setInput({
      electricityPrice,
      gasolinePrice,
      dieselPrice,
      userUpdatedElectricity: false,
      userUpdatedDiesel: false,
      carbonCreditsPrice,
    });
  };



  

  return (
    <div className="App">
      <DisplayProvider>
        <CalculationsProvider
          dynamicModulePath={process.env.REACT_APP_DYNAMIC_CALCULATION_MODULE}
        >
         <AppContent isOnboarded={isOnboarded} loading={loading} isResetting={isResetting} />
        </CalculationsProvider>
      </DisplayProvider>
    </div>
  );
}

export default App;
