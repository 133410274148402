import React, { useState } from "react";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { PencilIcon } from "../../../assets/icons/icons";
import EditVehicleModal from "./EditVehicleModal";

const VehicleSpec = ({ field, value, border }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      width="100%"
      pb={1}
      borderBottom={border ? `1px solid ${theme.palette.grey[300]}` : "none"}
      sx={{
              '@media (max-width: 768px)': {
                     flexDirection: 'column',
              },
       }}
    >
      <Box width="150px" display="flex">
        <Typography fontSize="14px" fontWeight="300">
          {field}
        </Typography>
      </Box>
      <Typography fontSize="14px" fontWeight="200">
        {value}
      </Typography>
    </Box>
  );
};

const VehicleBox = ({
  title,
  name,
  imageUrl,
  children,
  isBorder,
  isEquivalent,
  fossilIsSelected,
  props,
}) => {
  const [open, setOpen] = useState(false);

  const showPencil = () => {
    if (isEquivalent) {
      return fossilIsSelected ? true : false;
    } else {
      return fossilIsSelected ? false : true;
    }
  };

  return (
    <Box
      sx={{
        ...vehicleCardStyle.container,
        borderRight: isBorder && "1px solid #D9D9D6",
        borderRadius: "0px",
        padding: !isBorder ? "16px 0px 16px 16px" : "16px",
      }}
    >
      <Grid xs={3} item sx={vehicleCardStyle.columnLeft}>
        <img
          src={imageUrl}
          alt={name}
          width="100%"
          style={{ marginBottom: "16px" }}
        />
        <Typography fontSize="14px" fontWeight="300">
          {title}
        </Typography>
      </Grid>
      <Grid xs={8} item sx={vehicleCardStyle.columnRight}>
        {children}
      </Grid>
      <Grid xs={1.8} item sx={vehicleCardStyle.editColumn}>
        {showPencil() && (
          <div
            style={vehicleCardStyle.editContainer}
            onClick={() => setOpen(true)}
          >
            <PencilIcon />
          </div>
        )}
      </Grid>
      <EditVehicleModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onChangeVehicleSet={props.onChangeVehicleSet}
        vehicleSet={props.vehicleSet}
        tab={props.tab}
        onChangeMake={props.onChangeMake}
        isEquivalent={isEquivalent}
      />
    </Box>
  );
};

const vehicleCardStyle = {
  container: {
    display: "flex",
    borderRadius: "16px",
    width: "50%",
    height: "100%",
    padding: "16px",
    position: "relative",
    marginBottom: "16px",
    alignItems: "center",
    '@media (max-width: 768px)': {
       width: '100%',
       borderRight: 'none',
       },
  },
  columnLeft: {
    display: "flex",
    flexDirection: "column",
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
  },
  editColumn: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  editContainer: {
    width: "40px",
    height: "40px",
    border: "1px solid #0072CE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

// const renderConsuption = (formatMessage, h, m, l, type) => {
//   const isKwh = type === 'kWh/hr';

//   return (
//     <Box width="105%">
//       <Box display="flex" justifyContent="space-between">
//         <Typography fontSize="14px" fontWeight="400" lineHeight="22px">
//           {formatMessage(
//             { id: `onboarding.selectVehicle.fuelConsuption.${isKwh ? 'heavyKwh' : 'heavy'}` },
//             { value: h },
//           )}
//         </Typography>
//         <ToolTip
//           content={formatMessage({ id: 'onboarding.selectVehicle.fuelConsuption.heavyToolTip' })}
//         />
//       </Box>

//       <Box display="flex" justifyContent="space-between">
//         <Typography fontSize="14px" fontWeight="400" lineHeight="22px">
//           {formatMessage(
//             { id: `onboarding.selectVehicle.fuelConsuption.${isKwh ? 'mediumKwh' : 'medium'}` },
//             { value: m },
//           )}
//         </Typography>
//         <ToolTip
//           content={formatMessage({ id: 'onboarding.selectVehicle.fuelConsuption.mediumToolTip' })}
//         />
//       </Box>

//       <Box display="flex" justifyContent="space-between">
//         <Typography fontSize="14px" fontWeight="400" lineHeight="22px">
//           {formatMessage(
//             { id: `onboarding.selectVehicle.fuelConsuption.${isKwh ? 'lightKwh' : 'light'}` },
//             { value: l },
//           )}
//         </Typography>
//         <ToolTip
//           content={formatMessage({ id: 'onboarding.selectVehicle.fuelConsuption.lightToolTip' })}
//         />
//       </Box>
//     </Box>
//   );
// };

const VehicleBoxWrapper = ({
  vehicle,
  isBorder,
  isEquivalent,
  fossilIsSelected,
  props,
}) => {
  const { formatMessage } = useIntl();
  return (
    <VehicleBox
      isBorder={isBorder}
      title={vehicle.name}
      name={vehicle.name}
      imageUrl={vehicle.imageUrl}
      isEquivalent={isEquivalent}
      fossilIsSelected={fossilIsSelected}
      props={props}
    >
      <Box display="flex" flexDirection={"column"} ml="40px">
        <Typography mb="8px" fontSize="14px" fontWeight="300" lineHeight="24px">
          {isEquivalent
            ? "Equivalent Electric"
            : formatMessage({ id: "onboarding.selectVehicle.currentPlanned" })}
        </Typography>
        <VehicleSpec
          field={
            isEquivalent
              ? "EPA Estimated Range on a Full Charge"
              : "EPA Estimated Range"
          }
          value={vehicle.rangeInMiles}
        />
        <VehicleSpec field={"Fuel"} value={vehicle.fuel} />
        <VehicleSpec field={"Drivetrain"} value={vehicle.drivetrain} />
        <VehicleSpec field={"Cargo capacity"} value={vehicle.cargoVolume} />

        <Box
          width="100%"
          height="1px"
          sx={{ background: "#D9D9D6" }}
          mb="8px"
        />

        <VehicleSpec
          field={"Weight Class"}
          value={
            vehicle.gvwr <= 8500
              ? "2a"
              : vehicle.gvwr > 8500 && vehicle.gvwr <= 10000
              ? "2b"
              : vehicle.weightClass
          }
        />
      </Box>
    </VehicleBox>
  );
};

export default function StatsCard(props) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      height="auto"
      width="100%"
    >
      <Box
        sx={{
          border: "1px solid #D9D9D6",
          borderRadius: "16px",
          display: "flex",
          height: "214px",
          '@media (max-width: 768px)': {
              flexWrap: 'wrap',
              height: 'auto',
          },
        }}
        width="100%"
      >
        <VehicleBoxWrapper
          vehicle={props.existingVehicle}
          isBorder
          fossilIsSelected={props.fossilIsSelected}
          props={props}
        />
        <VehicleBoxWrapper
          vehicle={props.replacementVehicle}
          isEquivalent
          fossilIsSelected={props.fossilIsSelected}
          props={props}
        />
      </Box>
    </Box>
  );
}
