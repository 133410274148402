import React, { useState } from 'react';
import { Box, useTheme, useMediaQuery, Typography, ButtonBase } from '@mui/material';
import { useIntl } from 'react-intl';
import Card from '../components/Card';
import TabContent from './TabContent';
import FleetSummary from '../components/FleetSummary';
import LeftInputs from '../inputs/LeftInputs';
import ButtonInput from '../components/ButtonInput';
import useTabs from './useTabs';
import { BackArrowIcon } from '../assets/icons/icons';
import { useDisplay } from '../components/DisplayProvider';
import FindYourRep from '../components/FindYourRep';

export default function ContentPane() {
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();
  const theme = useTheme();
  const [showInputs, setShowInputs] = useState(false);
  const tabs = useTabs();

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const handleShowInputs = () => {
    setShowInputs((value) => !value);
    document.documentElement.scrollTop = 0;
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        p: '16px',
        maxWidth: (theme) => theme.settings.maxWidth.body,
        marginX: 'auto',
        [theme.breakpoints.up('sm')]: {
          padding: '24px',
        },
      }}
    >
      <Box
        sx={{
          display: 'none',
          [theme.breakpoints.up('sm')]: {
            display: 'block',
          },
        }}
      >
        <Card>
          <FleetSummary />
        </Card>
      </Box>
      <Card>
        <Box
          mx="auto"
          display="flex"
          flexWrap="wrap-reverse"
          gap="32px"
          width="100%"
          sx={{
            maxWidth: (theme) => theme.settings.maxWidth.cardContent,
          }}
        >
          {(showInputs || isLargeScreen) && (
            <Box flex="1 1 260px">
              {showInputs && (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                  pb={2}
                  mb={4}
                  borderBottom={`1px solid ${theme.palette.grey[300]}`}
                >
                  <ButtonBase onClick={() => setShowInputs(!showInputs)}>
                    <BackArrowIcon />
                  </ButtonBase>
                  <Typography fontSize={16} fontWeight={700} ml={2}>
                    {formatMessage({ id: `inputs.inputsButton` })}
                  </Typography>
                </Box>
              )}
              <LeftInputs />
            </Box>
          )}
          {(isLargeScreen || !showInputs) && (
            <Box flex="100 1 65%" overflow="hidden">
              <TabContent tabList={tabs} />
            </Box>
          )}
        </Box>
        {mode !== 'print' && <FindYourRep />}
        <Box
          textAlign="center"
          width="100%"
          borderTop={`1px solid ${theme.palette.grey[300]}`}
          mt={showInputs ? 4 : 0}
          pt={4}
          sx={{ display: 'block', [theme.breakpoints.up('sm')]: { display: 'none' } }}
        >
          <ButtonInput
            variant="contained"
            textTransform="uppercase"
            onClick={handleShowInputs}
            fullWidth
          >
            {formatMessage({ id: `inputs.${showInputs ? 'updateButton' : 'inputsButton'}` })}
          </ButtonInput>
        </Box>
      </Card>
    </Box>
  );
}
