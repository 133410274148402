import { Box } from '@mui/material';
import StyledColumn from '../../components/charts/StyledColumn';

export default function Table({ title, items }) {
  return (
    <Box>
      <StyledColumn title={title} rows={items} />
    </Box>
  );
}
