import React from 'react';
import { Typography, Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { useIntl } from 'react-intl';

export default function OnboardingHeader() {
  const { onboardingStep } = useInputs();
  const { formatMessage } = useIntl();

  return (
    <Box px={2}>
      <Box width='100%' alignItems='center' justifyContent='center' display='flex'>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '48px',
            lineHeight: '54px',
            maxWidth: '660px',
            margin: '0 auto',
            marginBottom: '24px',
            fontWeight: 200,
            color: '#3D3935',
          }}
        >
          {formatMessage({ id: `onboarding.${onboardingStep}.title` })}
        </Typography>
      </Box>
      <Box width='100%' alignItems='center' justifyContent='center' display='flex'>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: '20px',
            maxWidth: '800px',
            color: '#3D3935',
            margin: '0 0 48px 0px',
            fontWeight: 300
          }}
        >
        
          {formatMessage({ id: `onboarding.${onboardingStep}.leadCopy` })}
          <br></br>
        </Typography>
      </Box>
    </Box>
  );
}
