// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    handle: 'generic_25_hp_diesel_tractor',
    make: 'Generic',
    model: '',
    trim: '25 HP Diesel Tractor',
    fuel: 'diesel',
    type: 'tractor',
    msrp: 34650,
    doors: 'N/A',
    horsepower: 25,
    acceleration: 'N/A',
    availability: 'released',
    images: [
      {
        alt: 'Generic 25 HP Diesel Tractor Icon',
        url_full: 'Generic_25_hp_Diesel_Tractor.png',
        url_thumbnail: 'Generic_25_hp_Diesel_Tractor.png',
      },
    ],
    duty: 'Y',
    gvwr: null,
    payload: null,
    subtype: 'Light Duty',
    electric_vehicle_id: 0,
    available_for_purchase_as_new: null,
    epa_id: 44178,
    fuel_id: 29896,
    model_year: 2022,
    form_factor: 'step_van',
    custom_price: null,
    battery_capacity: 0,
    fuel_capacity: 6.1,
    ac_charging_power: 'N/A',
    dc_charging_power: 'N/A',
    seats_min: 1,
    seats_max: 1,
    cargo_volume_min: null,
    cargo_volume_max: null,
    official_website_url: 'N/A',
    eligible_for_ca_rebate: 'No',
    eligible_for_ca_hov: false,
    san_joaquin_valley_ev_rebate: 0,
    electric_range: 'N/A',
    total_range: 370,
    electric_efficiency: 0,
    curb_weight: null,
    display_name: 'Generic 25 HP Disele Tractor',
    drive_train: '4WD',
    additional_fields: [],
    annual_fuel_consumption: '11.771790000',
    fossil_fuel_efficiency: 28,
    video_reviews: [],
    equivalent_gas_vehicle: {
      make: 'N/A',
      model: 'N/A',
      msrp: 0,
      picture: 0,
      epa_id: 0,
      model_year: 0,
      fossil_fuel_efficiency: 0,
    },
    electric_efficiency_unit: null,
    fossil_efficiency_unit: 'gallons/hr',
    is_induction_charging_capable: false,
    induction_charging_power: null,
    is_archtype: false,
    is_estimated_battery_capacity: false,
    is_estimated_gvwr: false,
    is_estimated_maintenance: false,
    is_estimated_payload: false,
    is_estimated_plug_types: false,
    is_estimated_price: false,
    is_offroad: false,
    is_overhead_charging_capable: false,
    lift_capacity: null,
    maintenance_cost: 0.2,
    maintenance_cost_unit: null,
    battery_type: null,
    fuel_types: ['diesel'],
    is_commercial_only: false,
    overhead_charging_power: null,
    plug_types: [],
    weight_class: null,
    fossil_fuel_efficiency_heavy: 1.564,
    fossil_fuel_efficiency_medium: 0.912,
    fossil_fuel_efficiency_light: 0.576,
    runtime: '3.9 - 10.6',
    fossil_miles_per_gallon: 7.2
  },
];
