import { Box, useTheme, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import DropdownInput from '../../components/DropdownInput';

const sortConfig = {
  msrpDesc: {
    field: 'msrp',
    method: 'desc',
  },
  msrpAsc: {
    field: 'msrp',
    method: 'asc',
  },
};

export default function ContentHeader({ subset, total, sortOrder, changeSort }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const sortOptions = Object.keys(sortConfig).map((key) => ({
    value: key,
    children: formatMessage({ id: `inputs.chargerCatalog.sortOptions.${key}` }),
  }));

  return (
    <Box
      display="flex"
      justifyContent="end"
      alignItems="center"
      mb={4}
      sx={(theme) => ({ [theme.breakpoints.up('sm')]: { mx: 2 } })}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          flexDirection: 'column-reverse',
          [theme.breakpoints.up('sm')]: { flexDirection: 'row' },
        })}
      >
        <Typography
          fontSize="12px"
          color={theme.palette.grey[900]}
          mr={1}
          sx={(theme) => ({
            [theme.breakpoints.up('sm')]: { mt: 2 },
          })}
        >
          {formatMessage({ id: 'inputs.chargerCatalog.showing' }, { subset: subset, total: total })}
        </Typography>
        <DropdownInput
          id="vehicle-sort-input"
          label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.sortBy' })}
          options={sortOptions}
          value={sortOrder}
          onChange={(e) => changeSort(e.target.value)}
        />
      </Box>
    </Box>
  );
}
