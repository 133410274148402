
export const customWaterfallPlugin = {
  id: 'waterfall',
  beforeInit: (chart, _, options) => {
    const { datasets, labels } = chart.data;

    const { datasetReferenceIndex = 0, summaryLabel, summaryBackgroundColor } = options;

    const compareReferenceIndex = datasetReferenceIndex === 0 ? 1 : 0;
    const referenceDataset = datasets[datasetReferenceIndex];
    const compareDataset = datasets[compareReferenceIndex];

    const resultDatasetObj = {
      data: [...referenceDataset.data],
      backgroundColor: Array.from({ length: referenceDataset.data.length }).fill(
        compareDataset.backgroundColor 
      ),
      label: summaryLabel 
    };

    let previousValue = 0;
    resultDatasetObj.data = resultDatasetObj.data.map((value, index) => {
      const referenceDataVal =
        value === undefined ? 0 : Number.parseFloat(value?.toString());
      const currDatasetVal =
        value === undefined
          ? 0
          : Number.parseFloat(compareDataset.data[index]?.toString());
      const differenceVal = referenceDataVal - currDatasetVal;
      const maximumValue = previousValue + differenceVal;

      const newValue = [previousValue, maximumValue];
      if (currDatasetVal > referenceDataVal)
        (resultDatasetObj.backgroundColor)[index] =
          referenceDataset.backgroundColor;

      previousValue = maximumValue;
      return newValue;
    });

    if (summaryLabel && summaryBackgroundColor) {
      (resultDatasetObj.backgroundColor).push(summaryBackgroundColor);
      resultDatasetObj.data.push([previousValue, 0]);
      if (!labels?.some((el) => el === summaryLabel)) labels?.push(summaryLabel);
    }

    // Push the resultant dataset
    datasets.push(resultDatasetObj);

    // Hide other datasets
    datasets[0].hidden = true;
    datasets[1].hidden = true;
  },

  beforeDestroy: (chart, _, options) => {
    const { labels } = chart.data;
    const { summaryLabel } = options;

    // Summary clean up
    const summaryLabelIndex = labels?.findIndex((el) => el === summaryLabel);
    if (summaryLabelIndex && summaryLabelIndex !== -1) labels?.splice(summaryLabelIndex);
  },
};
