import { Box, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../utils/formatters';
import times from 'lodash/times';

export default function AnnualMaintenanceInsuranceChart({ fossilFleetCosts, evFleetCosts }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const numYears = fossilFleetCosts && Math.max(fossilFleetCosts.length, evFleetCosts.length);
  const labels = times(numYears, (i) => `Year ${i + 1}`);

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.operations.electric' }),
        data: evFleetCosts,
        backgroundColor: '#0072CE',
        barPercentage: 0.5,
      },
      {
        label: formatMessage({ id: 'panels.operations.fossil' }),
        data: fossilFleetCosts,
        backgroundColor: '#05C3DD',
        barPercentage: 0.5,
      },
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[800],
    font: {
      size: 11,
      weight: 'bold',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: theme.palette.grey[800],
    font: {
      size: 11,
    },
  };

  return (
    <Box height="400px" maxWidth="890px">
      <StyledBarGraph
        data={chargeData}
        graphTitle={formatMessage({ id: 'panels.operations.annualMaintenanceCosts' })}
        yTicks={yTicks}
        xTicks={xTicks}
        showVerticalGrid
        tooltipLabelFormatter={(data) => `${data.dataset.label}: ${formatAsDollars(data.raw)}`}
      />
    </Box>
  );
}
