import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box } from "@mui/material";
import DropdownInput from "../../../components/DropdownInput";
import { ELECTRIC_VEHICLES, ICE_VEHICLES } from "../../../data/VEHICLES";
import { useIntl } from "react-intl";
import { getVehicleMapping } from "../../../data/vehicleMappings";
import { DEFAULT_VEHICLE_SET } from "../../../use-inputs/getDefaults";
import DefaultInputs from "./DefaultInputs";

const FossilFuel = ({
  tab,
  onChangeMake,
  DefaultInputs,
  vehicleSet,
  formatMessage,
  apiMappingVehicles,
  onChangeVehicleSet,
}) => {

/**
 * TODO
 * uncomment fuelType when vehicle list has gas options
 */

const [fuelType] = useState('Diesel');


  const vehicleOptions = useMemo(() => {
    return (fuelType === 'All'
      ? ICE_VEHICLES
      : ICE_VEHICLES.filter(({fuel}) => fuel === fuelType))
      .map(({ id, name }) => ({ value: id, children: name }));
  }, [fuelType]);

  const handleVehicleMakeChange = (make) => {
    let EquivalentVehicle = apiMappingVehicles.filter(
      (e) => e.input_handle === make && e.default === true
    );

    onChangeMake({
      existingVehicleId: make,
      replacementVehicleId:
        EquivalentVehicle[0]?.output_handle ||
        DEFAULT_VEHICLE_SET.replacementVehicleId,
    });
  };

  let arr = ["gvwr", "msrp", "mpg"];

  /*
  const fuelTypeOptions = [
    {
      value: 'All',
      children: formatMessage({ id: "onboarding.selectVehicle.all"})
    },
    {
      value: 'Diesel',
      children: formatMessage({ id: "onboarding.selectVehicle.diesel"})
    },
    {
      value: 'Gas',
      children: formatMessage({ id: "onboarding.selectVehicle.gas"})
    }];*/

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {/*<Box display="flex" width="100%" justifyContent="space-between" mb="24px">
        <Box width="46%">
          <DropdownInput
            id="vehicle-select-input-ice-fuelTypes"
            label={formatMessage({ id: "onboarding.selectVehicle.fuelType" })}
            options={fuelTypeOptions}
            value={fuelType}
            required
            onChange={(e) => setFuelType(e.target.value)}
          />
        </Box>
  </Box>*/}
      <Box display="flex" width="100%" justifyContent="space-between" mb="24px"
       sx={{
              '@media (max-width: 768px)': {
                     flexWrap: 'wrap',
                     marginBottom: '0px'
              },
       }}
      >
        <Box width="46%" sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
          <DropdownInput
            disabled={vehicleOptions.length === 1}
            id="vehicle-select-input-ice"
            label={formatMessage({ id: "onboarding.selectVehicle.model" })}
            options={vehicleOptions}
            value={vehicleSet.existingVehicleId}
            required
            onChange={(e) => handleVehicleMakeChange(e.target.value)}
          />
        </Box>
        <Box width="46%" sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                            paddingBottom: '25px'
                     },
              }}>
          <Typography mt="32px" fontSize="12px">
            All information on competitive models has been pulled from OEM
            websites and managed by JDPA
          </Typography>
        </Box>
      </Box>
      <Box width="100%" height="1px" mb="24px" sx={{ background: "#D9D9D6" }} />
      <DefaultInputs
        tab={tab}
        requiredArr={arr}
        vehicleSet={vehicleSet}
        onChangeVehicleSet={onChangeVehicleSet}
      />
    </Box>
  );
};

const ReplacementModel = ({
  tab,
  onChangeMake,
  DefaultInputs,
  vehicleSet,
  formatMessage,
  onChangeVehicleSet,
}) => {
  const apiVehicleOptionsElectric = ELECTRIC_VEHICLES.map(({ id, name }) => ({
    value: id,
    children: name,
  }));

  const handleVehicleMakeChange = (make) => {
    if (make === "BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024") {
      onChangeMake({
        existingVehicleId: "on_road_step_van_light_duty",
        replacementVehicleId: make,
      });
    } else {
      onChangeMake({
        existingVehicleId: "on_road_step_van_medium_duty",
        replacementVehicleId: make,
      });
    }
  };

  let arr = ["gvwr", "msrp", "mpg"];
  let disabledArr = ["gvwr", "cargo"];
  let removedArr = [];

  if (
    vehicleSet.replacementVehicle.id === "BrightDrop_Zevo_600_20_Mod_AWD_Class_2b_BEV_2024" ||
    vehicleSet.replacementVehicle.id ===
      "BrightDrop_Zevo_600_20_Mod_AWD_Class_3_BEV"
  ) {
    disabledArr.push("msrp");
    removedArr.push("mpg");
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" width="100%" justifyContent="space-between" mb="24px">
        <Box width="46%" sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
          }}>
          <DropdownInput
            disabled={apiVehicleOptionsElectric.length === 1}
            id="vehicle-select-input-electric"
            label={formatMessage({ id: "onboarding.selectVehicle.model" })}
            options={apiVehicleOptionsElectric}
            value={vehicleSet.replacementVehicleId}
            required
            onChange={(e) => handleVehicleMakeChange(e.target.value)}
          />
        </Box>
      </Box>
      <Box width="100%" height="1px" mb="24px" sx={{ background: "#D9D9D6" }} />
      <DefaultInputs
        removedArr={removedArr}
        requiredArr={arr}
        disabledArr={disabledArr}
        tab={tab}
        onChangeVehicleSet={onChangeVehicleSet}
        vehicleSet={vehicleSet}
      />
    </Box>
  );
};

const TabSelector = ({
  onChangeMake,
  vehicleSet,
  onChangeVehicleSet,
  tab,
  setTab,
  options,
}) => {
  const [apiMappingVehicles, setApiMappingVehicles] = useState();
  const { formatMessage } = useIntl();

  const getMapping = async () => {
    try {
      let fetch = await getVehicleMapping();
      setApiMappingVehicles(fetch);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMapping();
  }, []);

  const renderTab = () => {
    switch (tab) {
      case options[0]:
        return (
          <FossilFuel
            tab={tab}
            formatMessage={formatMessage}
            onChangeMake={onChangeMake}
            onChangeVehicleSet={onChangeVehicleSet}
            vehicleSet={vehicleSet}
            DefaultInputs={DefaultInputs}
            apiMappingVehicles={apiMappingVehicles}
          />
        );

      case options[1]:
        return (
          <ReplacementModel
            tab={tab}
            formatMessage={formatMessage}
            onChangeMake={onChangeMake}
            onChangeVehicleSet={onChangeVehicleSet}
            DefaultInputs={DefaultInputs}
            vehicleSet={vehicleSet}
          />
        );

      default:
        break;
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        margin="24px 0px"
        width="100%"
      >
        <Typography mb="8px">Select replaced vehicle by:</Typography>
        <Box
          display="flex"
          padding="8px"
          border="1px solid #D9D9D6"
          borderRadius="16px"
          width="100%"
        >
          {options.map((opt, index) => {
            const currentTab = tab === opt;

            return (
              <Box
                key={index}
                onClick={() => setTab(opt)}
                width={`${100 / options.length}%`}
                height="auto"
                padding="24px 12px"
                mr={index !== options.length - 1 ? "16px" : "0"}
                sx={{
                  background: currentTab ? "#0072CE" : "transparent",
                  borderRadius: "16px",
                  cursor: "pointer",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  transition: "all ease 0.3s",
                }}
              >
                <Typography
                  fontSize="16px"
                  fontWeight="300"
                  color={currentTab ? "#fff" : "#3D3935"}
                >
                  {opt}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box width={"100%"}>{renderTab()}</Box>
    </Box>
  );
};

export default TabSelector;
