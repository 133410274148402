import React, { useState } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useInputs } from '@bellawatt/use-inputs';
import TableTitle from '../../components/TableTitle';
import CostsWaterfallChart from "../../tabs/overview/CostsWaterfallChart";
import GlobalData from '../../calculations/GlobalData';

export default function TotalCostChart({ totalRetiringFleetCosts, totalElectrifiedFleetCosts, totalValues }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { vehicleSets } = useInputs();
  const { data } = GlobalData();
  const [isBar, setIsBar] = useState(true);

  const [selectedVehicleSetId] = useState(vehicleSets[0].id);

  const selectedVehicleSet =
    vehicleSets.find(({ id }) => id === selectedVehicleSetId) || vehicleSets[0];

  const { replacementVehicle, vehicleCount, existingVehicle } = selectedVehicleSet;

  const removeMultipleObjectKeys = (obj, keysToRemove) => {
    let newObj = { ...obj };
    keysToRemove.forEach(key => {
      delete newObj[key];
    });
    return newObj;
  }

  let modifiedTotalCostElectric = removeMultipleObjectKeys(data.electricFleetCost, ["total", "lcfs"])
  let modifiedTotalCostGas = removeMultipleObjectKeys(data.fossilFleetCost, ["total", "lcfs"])

  const totalCostLabels = Object.keys(modifiedTotalCostElectric).map(key => `${formatMessage({ id: `panels.financials.labels.${key}` })}`);

  
  const chargeData =  {
      labels: totalCostLabels,
        datasets: [
          {
            label: `${vehicleCount}x ${replacementVehicle.name}`,
            data: Object.values(modifiedTotalCostElectric).map((value) => value),
            backgroundColor: '#0072CE',
            borderColor: '#0072CE',
            barPercentage: 0.8,
          },
          {
            label: `${vehicleCount}x ${existingVehicle.name}`,
            data: Object.values(modifiedTotalCostGas).map((value) => value),
            backgroundColor: ' #05C3DD',
            borderColor: ' #05C3DD',
            barPercentage: 0.8,
          },
        ],
      }

  const xTicks = {
    max: 100,
    min: 0,
    padding: 20,
    color: '#3D3935',
    font: {
      size: 12,
      weight: '300',
    },
  };

  const yTicks = {
    min: 0,
    callback: function (value, index, values) {
      value = value.toString();
      return formatAsDollars(value);
    },
    color: theme.palette.grey[900],
    font: {
      size: 12,
    },
  };

  return (
    <Box width="100%" mt={'24px'}>
      <TableTitle title={formatMessage({ id: 'panels.financials.TotalCostTitle' })} isLowCase />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexDirection: 'column', [theme.breakpoints.up('sm')]: { flexDirection: 'row' } }}
      >
        <Box />
        <Box
          width="320px"
          height="35px"
          display="flex"
          style={{ borderRadius: '50px', border: '1px solid #0072CE' }}
        >
          <Button
            onClick={() => setIsBar(true)}
            style={{
              width: '50%',
              height: '100%',
              backgroundColor: isBar ? '#0072CE' : 'transparent',
              borderRadius: '50px 0 0 50px',
            }}
          >
            <Typography fontWeight="300" fontSize="14" color={!isBar ? '#0072CE' : 'white'}>
              {formatMessage({ id: 'panels.financials.barChart' })}
            </Typography>
          </Button>
          <Button
            onClick={() => setIsBar(false)}
            style={{
              width: '50%',
              height: '100%',
              backgroundColor: !isBar ? '#0072CE' : 'transparent',
              borderRadius: '0px 50px 50px 0px',
            }}
          >
            <Typography fontWeight="300" fontSize="14" color={isBar ? '#0072CE' : 'white'}>
              {formatMessage({ id: 'panels.financials.waterfall' })}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box maxWidth="890px" height="400px">
        {isBar ? (
          <StyledBarGraph
            height="400px"
            data={chargeData}
            yTicks={yTicks}
            xTicks={xTicks}
            tooltipLabelFormatter={(data) =>
              `${data.dataset.label}: ` + formatAsDollars(data.raw.toFixed(0))
            }
            suggestedYMax={100}
          />
        ) : (
          <CostsWaterfallChart
            fossilFleetCosts={totalRetiringFleetCosts}
            evFleetCosts={totalElectrifiedFleetCosts}
            modifiedTotalCostElectric={modifiedTotalCostElectric}
            modifiedTotalCostGas={modifiedTotalCostGas}
            customLabel={totalCostLabels}
            waterfall= {{ summaryLabel: 'Summary', summaryBackgroundColor: '#3D3935'}}
          />
        )}
      </Box>
    </Box>
  );
}
