import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Grid, Box, useTheme, Typography } from '@mui/material';
import DropdownInput from '../../components/DropdownInput';
import { chargingTimes, chargingAccess } from '../../data/chargingWindows';
import cloneDeep from 'lodash/cloneDeep';
import {
  DEFAULT_CHARGING_WINDOW,
  SECONDARY_DEFAULT_CHARGING_WINDOW,
} from '../../use-inputs/getDefaults';
import { TrashIconVariant, PlusIcon, SpeedIcon5percent, SpeedEmpty  } from '../../assets/icons/icons';
import CHARGERS from '../../data/CHARGERS';
import WarningMessage from '../../components/WarningMessage';
import ButtonInput from '../../components/ButtonInput';
import { useInputs } from '@bellawatt/use-inputs';
import ChargerRecommendation from '../../onboarding/ChargerRecommendation';
import { formatGenericChargerName } from '../../utils/formatters';
import TextFieldInput from '../../components/TextFieldInput';
import alarmMultipleChargersInUseAtOnce from '../../calculations/alarmMultipleChargersInUseAtOnce';
import { useCalculations } from '../../utils/useCalculations';

const MAX_CHARGING_WINDOWS = 4;
const DEFAULT_START = 20;
const DEFAULT_FINISH = 5;

const ChargingWindow = ({
  start,
  finish,
  onChange,
  onRemove,
  canDelete,
  chargerId,
  chargingApproach,
  lastWindow,
  isGeneric,
  id,
  charger,
  small,
  is_open_to_public,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const handleChange = (e, key) => {
    onChange({
      start,
      finish,
      chargingApproach,
      chargerId,
      isGeneric,
      id,
      is_open_to_public,
      [key]: e.target.value,
    });
  };

  const outlinedIcon = {
    p: 0,
    minHeight: '60px',
    minWidth: '60px',
    lineHeight: 1.1,
    fontSize: '12px',
    color: '#0072CE',
    border: `1px solid #D9D9D6`,
    borderRadius: '5px',
    backgroundColor: '#F3F3F3',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.grey[100],
    },
  }

  const getChargerOpts = () => {
    let arr = [];
    let kwhArr = [];
    CHARGERS.forEach(({ id, chargerType, portKw, ports }) => {
      if (kwhArr.includes(portKw + ports)) return null;

      kwhArr.push(portKw + ports);

      return arr.push({
        value: id,
        children: formatGenericChargerName({
          type: formatMessage({ id: `chargerTypes.${chargerType}` }),
          portKw: portKw,
          ports: ports,
        }),
      });
    });

    return arr;
  };

  return (
    <Box
      display='flex'
      alignItems='flex-start'
      justifyContent={small ? 'space-around' : 'space-between'}
      borderBottom={!lastWindow && `1px solid ${theme.palette.grey[300]}`}
      mt='24px'
      pb='20px'
      sx={{
              '@media (max-width: 768px)': {
                     flexWrap: 'wrap',
              },
       }}
    >
      <Box sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
       }}>
        <Box display='flex' sx={{
              '@media (max-width: 768px)': {
                     flexWrap: 'wrap',
              },
       }}>
          <Box width={small ? '133px' : '165px'} mr='16px' sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                            marginRight: '0',
                     },
              }}>
            <DropdownInput
              label={formatMessage({ id: 'chargingWindows.times.start' })}
              onChange={(e) => handleChange(e, 'start')}
              options={chargingTimes.map(({ id, translationKey }) => ({
                value: id,
                children: formatMessage({ id: translationKey }),
              }))}
              value={start}
              inputStyle={{height: '60px'}}
            />
          </Box>
          <Box width={small ? '133px' : '165px'} sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
            <DropdownInput
              label={formatMessage({ id: 'chargingWindows.times.end' })}
              onChange={(e) => handleChange(e, 'finish')}
              options={chargingTimes.map(({ id, translationKey }) => ({
                value: id,
                children: formatMessage({ id: translationKey }),
              }))}
              value={finish}
              inputStyle={{height: '60px'}}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{
                     '@media (max-width: 768px)': {
                           width: '100%',
                     },
              }}>
        <Box display="flex" gap="36px" sx={{
                     '@media (max-width: 768px)': {
                            flexWrap: 'wrap',
                     },
              }}>
          <Box display='flex' sx={{
                     '@media (max-width: 768px)': {
                            flexWrap: 'wrap',
                     },
              }}>
            <Box flex="1 1" display="flex" flexDirection="column" width='202px' mr='16px' sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                            marginRight: '0',
                     },
              }}>
              {isGeneric ? (
                <DropdownInput
                  disabled={!isGeneric}
                  label={formatMessage({ id: 'chargingWindows.times.type' })}
                  onChange={(e) => handleChange(e, 'chargerId')}
                  options={getChargerOpts()}
                  value={chargerId}
                  inputStyle={{height: '60px'}}
                />
              ) : (
                <TextFieldInput
                  value={charger.name}
                  label={formatMessage({ id: 'chargingWindows.times.type' }).toUpperCase()}
                  disabled={true}
                />
              )}
              <Typography fontSize="11px" mt={'8px'} color={'#333333'}>
                {isGeneric
                  ? formatMessage({ id: 'chargingWindows.times.output' })
                  : formatMessage({ id: 'inputs.vehicleSet.editSetDialog.chargerSelectedMessage' })}
              </Typography>
            </Box>

            <Box width='202px' sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
              <DropdownInput
                label={formatMessage({ id: 'chargingWindows.times.access' })}
                onChange={(e) => handleChange(e, 'is_open_to_public')}
                options={chargingAccess.map(({ id, translationKey }) => ({
                  value: id,
                  children: formatMessage({ id: translationKey }),
                }))}
                value={is_open_to_public}
                tooltip={formatMessage({id: 'chargingWindows.access.tooltip'})}
                inputStyle={{height: '60px'}}
              />
            </Box>
          </Box>
          <Box mt="30px" sx={{
                     '@media (max-width: 768px)': {
                            marginTop: '0',
                     },
              }}>
            <ButtonInput disabled={!canDelete} variant="outlinedIcon" alert onClick={onRemove} style={!canDelete && outlinedIcon}>
              <TrashIconVariant disabled={!canDelete} />
            </ButtonInput>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default function ChargingWindows({ vehicleSet, onChangeVehicleSet, isOnboarding, small }) {
  const { formatMessage } = useIntl();
  const { chargingWindows } = vehicleSet;
  const { isCustomChargerChosen, setInput, apiChargers } = useInputs();
  const Calculations = useCalculations();

  const getNextAvailableChargingWindow = () => {
    const lastChargingWindow = chargingWindows[chargingWindows.length - 1];
    let nextAvailableStart = lastChargingWindow.finish + 1;
    if (nextAvailableStart > 23) {
      nextAvailableStart = nextAvailableStart - 24;
    }
    const nextAvailableFinish = nextAvailableStart + 3;

    return {
      ...SECONDARY_DEFAULT_CHARGING_WINDOW,
      start: nextAvailableStart,
      finish: nextAvailableFinish,
    };
  };
  
  const handleChange = (idx, data) => {
    const newChargingWindows = cloneDeep(chargingWindows);
    newChargingWindows[idx] = data;

    newChargingWindows.forEach(e => {
      if(!e.charger){
        e.charger = e.isGeneric 
        ? CHARGERS.find(({ id }) => id === e.chargerId)
        : apiChargers.find(({ id }) => id === e.chargerId)
      }
    })

    onChangeVehicleSet({ chargingWindows: newChargingWindows });

    // remove recommendation banner when charging window is adjusted in any way
    if (!isCustomChargerChosen) {
      setInput({ isCustomChargerChosen: true, computeData: true, });
    }
  };

  const handleAddChargingWindow = () => {
    const newChargingWindows = chargingWindows.length === 0
    ? DEFAULT_CHARGING_WINDOW
    : chargingWindows.length >= 2
    ? getNextAvailableChargingWindow()
    : SECONDARY_DEFAULT_CHARGING_WINDOW;

    newChargingWindows.charger = newChargingWindows.isGeneric
          ? CHARGERS.find(({ id }) => id === newChargingWindows.chargerId)
          : apiChargers.find(({ id }) => id === newChargingWindows.chargerId)

    onChangeVehicleSet({
      chargingWindows: [
        ...vehicleSet.chargingWindows,
        newChargingWindows
      ],
    });

    // remove recommendation banner when charging window is adjusted in any way
    if (!isCustomChargerChosen) {
      setInput({ isCustomChargerChosen: true });
    }
  };

  const handleRemoveChargingWindow = (idx) => {
    onChangeVehicleSet({
      chargingWindows: vehicleSet.chargingWindows.filter((_, i) => i !== idx),
    });
  };
  
  const minimumStateOfCharge = Calculations.minimumStateOfCharge({input: vehicleSet});
  const stateOfChargeIsLow = minimumStateOfCharge < 20;
  const stateOfChargeIsDepleted = minimumStateOfCharge <= 0;

  const multipleChargesOverlap = alarmMultipleChargersInUseAtOnce(vehicleSet.chargingWindows);

  const showRecommendation = isOnboarding && !isCustomChargerChosen;

  useEffect(() => {
    if (isOnboarding && !isCustomChargerChosen) {
      // before user has adjusted charging window, recommend a charger and timeframe
      const recommendedCharger = Calculations.recommendedCharger({
        input: vehicleSet,
        start: DEFAULT_START,
        finish: DEFAULT_FINISH,
      });

      // if no charger meets criteria, don't show recommendation
      if (recommendedCharger) {
        const newChargingWindow = {
          ...vehicleSet.chargingWindows[0],
          charger: recommendedCharger,
          chargerId: recommendedCharger.id,
          type: recommendedCharger.type,
          start: DEFAULT_START,
          finish: DEFAULT_FINISH,
        };
        onChangeVehicleSet({ chargingWindows: [newChargingWindow] });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomChargerChosen, isOnboarding, JSON.stringify(vehicleSet)]);

  return (
    <Grid container spacing={2} pl={2}>
      <Grid item xs={12}>
        {chargingWindows &&
          chargingWindows.map((chargingWindow, idx) => (
            <ChargingWindow
              small={small}
              minimumStateOfCharge={minimumStateOfCharge}
              key={chargingWindow.id}
              onChange={(data) => handleChange(idx, data)}
              onRemove={() => handleRemoveChargingWindow(idx)}
              canDelete={chargingWindows.length > 1}
              lastWindow={chargingWindows.length - 1 === idx}
              {...chargingWindow}
            />
          ))}
      </Grid>
      <Grid item xs={12}>
        {chargingWindows && chargingWindows.length < MAX_CHARGING_WINDOWS && (
          <ButtonInput
            style={{ padding: '12px 42px' }}
            variant="outlined"
            small
            onClick={handleAddChargingWindow}
          >
            <PlusIcon />
            <p style={{ margin: '0  0  0   8px', fontSize: '14px', fontWeight: '300' }}>
              {formatMessage({ id: 'chargingWindows.times.add' })}
            </p>
          </ButtonInput>
        )}
      </Grid>

      {stateOfChargeIsLow && (
        <Grid item xs={12}>
          <WarningMessage
            variant={stateOfChargeIsDepleted ? 'noCharge' : 'warning'}
            customIcon={stateOfChargeIsDepleted ? <SpeedEmpty /> : <SpeedIcon5percent  />}
            fullWidth
            message={formatMessage(
              {
                id: `inputs.vehicleSet.editSetDialog.${
                  stateOfChargeIsDepleted ? 'depletedBatteryWarning' : 'lowBatteryWarning'
                }`,
              },
              {
                percent: <b>{minimumStateOfCharge}%</b>,
              },
            )}
          />
        </Grid>
      )}

      {multipleChargesOverlap && (
        <Grid item xs={12}>
          <WarningMessage
            variant="danger"
            fullWidth
            message={formatMessage(
              {
                id: `inputs.vehicleSet.editSetDialog.chargerOverlap`,
              },
              {
                percent: <b>{minimumStateOfCharge}%</b>,
              },
            )}
          />
        </Grid>
      )}

      {showRecommendation && !stateOfChargeIsLow && (
        <Grid item xs={12}>
          <ChargerRecommendation vehicleSet={vehicleSet} />
        </Grid>
      )}
    </Grid>
  );
}
