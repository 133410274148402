import React, { useState } from 'react';
import { Grid, Typography, Box, Checkbox } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import organizationTypes from '../../data/organizationTypes';
import DropdownInput from '../../components/DropdownInput';
import TextFieldInput from '../../components/TextFieldInput';
import validZipcode  from '../../data/sampleData/validZipcode';

export default function GeneralInformationStep() {
  const { formatMessage } = useIntl();
  const [touched, setTouched] = useState({});
  const touchField = (fieldName) => setTouched({ ...touched, [fieldName]: true });
  const { organizationType, zipCode, isMultipleSites, setInput } = useInputs();


  return (
    <Grid container>
      <Grid item xs={1} />
      <Box sx={{ padding: '15px',
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
              <Box width='387px'
              sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}>
          <DropdownInput
            id="organization-type-input"
            required
            label={formatMessage({ id: 'onboarding.generalInformation.organizationType.label' })}
            options={organizationTypes.sort((a, b) => a.translationKey > b.translationKey ? 1 : -1,).map(({ id, translationKey }) => ({
              value: id,
              children: formatMessage({ id: translationKey }),
            }))}
            value={organizationType}
            onChange={(e) => setInput({ organizationType: e.target.value })}
            tooltip={formatMessage({ id: 'onboarding.generalInformation.organizationType.tooltip' })}
          />
          <Typography fontSize="11px" color="#595959">
            { !organizationType && formatMessage({ id: 'onboarding.generalInformation.seeDefinition' })}
          </Typography>
          {organizationType && (
            <Box mt="24px">
              <Typography fontSize="16px" fontWeight="300" color="#3D3935">
                {formatMessage({
                  id: organizationTypes.find((type) => type.id === organizationType)?.translationKey,
                })}
              </Typography>
              <Typography fontSize="14px" fontWeight="300" color="#3D3935" mt='8px'>
                {formatMessage({
                  id: `onboarding.generalInformation.organizationDescriptions.${organizationType}`,
                })}
              </Typography>
            </Box>
          )}
          </Box>
      </Box>
      <Grid item md={5} xs={12} sx={{ padding: '15px' }}>
        <Box width='387px'
              sx={{
                     '@media (max-width: 768px)': {
                            width: '100%',
                     },
              }}
        >
          <TextFieldInput
            required
            mask="99999"
            labelTooltip={formatMessage({ id: 'onboarding.generalInformation.zipCode.tooltip' })}
            disabled={isMultipleSites}
            value={zipCode}
            label={formatMessage({ id: 'onboarding.generalInformation.zipCode.label' })}
            tooltip={formatMessage({ id: 'onboarding.generalInformation.zipCode.tooltip' })}
            onBlur={(v) => {
              touchField('zipCode');
              setInput({ zipCode: v });
            }}
            inputProps={{ maxLength: 5 }}
            maxLength={5}
            type="number"
            mb={4}
            error={
              touched.zipCode  && 
              !validZipcode.includes(zipCode)  && !isMultipleSites &&
              formatMessage({ id: 'inputs.location.zipCode.formatError' })
            }

          />
        </Box>
        <Typography fontSize="11px">
          {formatMessage({ id: 'onboarding.generalInformation.vehiclesLocated' })}
        </Typography>

        <Box display="flex" mt="16px">
          <Checkbox
            id="multipleSites" 
            checked={isMultipleSites}
            onChange={(e) => {
              setInput({ zipCode: '' });
              setInput({ isMultipleSites: e.target.checked });
            }}
            inputProps={{ 'aria-label': 'Purchasing for multiple sites' }}
            disableRipple
            sx={{ marginRight: '8px', alignSelf: 'flex-start', padding: '0', color: '#333333' }}
          />
          <Box>
            <label htmlFor="multipleSites">
              <Typography fontSize="14px" fontWeight="300" color="#3D3935">
                {formatMessage({
                  id: 'onboarding.generalInformation.purchasingMultipleSites.header',
                })}
              </Typography>
              <Typography fontSize="14px" fontWeight="300" color="#3D3935" mt='12px'>
                {formatMessage({
                  id: 'onboarding.generalInformation.purchasingMultipleSites.posHeader',
                })}
              </Typography>
            </label>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
}
