import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  ThreeGIcon,
  FourGIcon,
  FiveGIcon,
  LTEIcon,
  BlueToothIcon,
  WifiIcon,
  CableIcon,
} from '../../assets/icons/icons';

export default function Connectivity({ charger }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const { bluetooth, wifi, ethernet, cellularSupport } = charger;

  const connectionList = [
    {
      icon: <ThreeGIcon />,
      available: cellularSupport && cellularSupport.includes('3G'),
    },
    {
      icon: <FourGIcon />,
      available: cellularSupport && cellularSupport.includes('4G'),
    },
    {
      icon: <FiveGIcon />,
      available: cellularSupport && cellularSupport.includes('5G'),
    },
    {
      icon: <LTEIcon />,
      available: cellularSupport && cellularSupport.includes('LTE'),
    },
    {
      icon: <BlueToothIcon />,
      available: bluetooth,
    },
    {
      icon: <WifiIcon />,
      available: wifi,
    },
    {
      icon: <CableIcon />,
      available: ethernet,
    },
  ];

  const shouldShow = connectionList.some(({ available }) => available);

  if (!shouldShow) {
    return null;
  }

  return (
    <Box
      borderTop={`1px solid ${theme.palette.grey[300]}`}
      borderBottom={`1px solid ${theme.palette.grey[300]}`}
      width="100%"
      px="2px"
      py={3}
      mb="30px"
    >
      <Typography fontSize="11px" fontWeight="700">
        {formatMessage({ id: 'panels.charging.infoCard.connectivity' })}
      </Typography>
      <Box display="flex" justifyContent="start" alignItems="center">
        {connectionList.map(({ icon, available }, index) => (
          <Box key={index}>{available && <Box ml="12px">{icon}</Box>}</Box>
        ))}
      </Box>
    </Box>
  );
}
