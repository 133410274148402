import React from 'react';
import { Box, useTheme } from '@mui/material';
import { steps } from '../data/onboardingSteps';
import { CheckIcon } from '../assets/icons/icons';

export default function ProgressBar({ stepIndex }) {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center" width={'40%'}
       sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
       }}
    >
      {steps.map((_step, i) => (
        <div
          key={i}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: !(i + 1 === steps.length) ? '100%' : '',
          }}
        >
          <Box
            height="32px"
            width="32px"
            sx={{
              outline: stepIndex === i ? 'unset' : '1px transparent',
              borderWidth: stepIndex === i ? '2px' : '2px',
              borderRadius: '100%',
              borderStyle: 'solid',
              borderColor: stepIndex >= i ? '#0072CE' : '#D9D9D6',
              color: theme.palette.grey[700],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px',
              background: stepIndex > i ? '#0072CE' : '2px',
              minWidth: '32px',
            }}
          >
            {stepIndex > i ? (
              <CheckIcon />
            ) : stepIndex === i ? (
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  background: '#0072CE',
                  borderRadius: '100%',
                }}
              />
            ) : (
              ''
            )}
          </Box>
          {!(i + 1 === steps.length) && (
            <div
              style={{
                height: '2px',
                background: stepIndex > i ? '#0072CE' : '#D9D9D6',
                width: '74%',
              }}
            />
          )}
        </div>
      ))}
    </Box>
  );
}
