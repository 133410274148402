import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material';

export default function StyledColumn({ title, rows }) {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ height: '48px' }}>
            <TableCell
              sx={{
                padding: '12px',
                fontSize: '1rem',
                fontWeight: '600',
              }}
            >
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, iRow) => (
            <TableRow key={iRow} sx={{ height: '48px' }}>
              <TableCell
                key={`column-label-${iRow}`}
                align="left"
                sx={{
                  padding: '10px',
                  borderTop: `1px solid ${theme.palette.common.border}`,
                  borderBottom: iRow === rows.length - 1 ? 'none' : null,
                  fontWeight: '600',
                  verticalAlign: 'top',
                  width: '50%',
                }}
              >
                {row.label}
              </TableCell>
              <TableCell
                key={`column-value-${iRow}`}
                align="left"
                sx={{
                  padding: '10px',
                  borderTop: `1px solid ${theme.palette.common.border}`,
                  borderBottom: iRow === rows.length - 1 ? 'none' : null,
                  fontWeight: 400,
                  verticalAlign: 'top',
                  width: '50%',
                }}
              >
                {row.value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
