import { Box } from '@mui/material';
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/lotties/brightdrop_loader_dark';

export const Loading = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        zIndex: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Lottie
        options={defaultOptions}
        height={200}
        width={200}
      />
    </Box>
  );

};
export default Loading;
