import { Box, Typography } from "@mui/material";
import StyledTable from "../../components/charts/StyledTable";
import {
  formatAsDollars,
  formatAsThousands,
} from "../../utils/formatters";
import { useIntl } from "react-intl";
import { useInputs } from "@bellawatt/use-inputs";
// import times from "lodash/times";
import sum from "lodash/sum";
import TableTitle from "../../components/TableTitle";
import { times } from "lodash";
import { useCalculations } from "../../utils/useCalculations";

export default function AnnualFuelCostTable({
  totalElectricCosts,
  totalFossilFuelCosts,
}) {
  const { vehicleSets, lifespanYears, startYear } = useInputs();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const labels = [
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.year" })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.fossilFleetCost" })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.electricFleetCost" })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.savings" })}
    </Typography>,
  ];

  const yearData = times(lifespanYears, (i) => startYear + i).map((year) => {
    return [
      <Typography fontSize="14px" fontWeight="200">
        {year}
      </Typography>,
      <Typography fontSize="14px" fontWeight="200">{formatAsDollars(totalFossilFuelCosts)}</Typography>,
      <Typography fontSize="14px" fontWeight="200">{formatAsDollars(totalElectricCosts)}</Typography>,
      <Typography fontSize="14px" fontWeight="200">{formatAsDollars(totalFossilFuelCosts - totalElectricCosts)}</Typography>,
    ];
  });

  const totalHours =
    sum(vehicleSets.map((set) => Calculations.annualMiles({ input: set }) * set.vehicleCount));

  const rows = [
    ...yearData,
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.totalNumberHours' })}
      </Typography>,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsThousands(totalHours)}
      </Typography>,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsThousands(totalHours)}
      </Typography>,
      null,
    ],
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.total' })}
      </Typography>,
      null,
      null,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(
          (totalFossilFuelCosts - totalElectricCosts) * lifespanYears
        )}
      </Typography>,
    ],
  ];

  return (
    <Box>
      <TableTitle title={formatMessage({ id: "panels.fuel.annualCosts" })} isLowCase />
      <StyledTable headings={labels} rows={rows} />
    </Box>
  );
}
