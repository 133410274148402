import React, { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import StyledLineGraph from "../../components/charts/StyledLineGraph";
import StyledBarGraph from "../../components/charts/StyledBarGraph";
import times from "lodash/times";
import { formatHours } from "../../utils/formatters";
import { useInputs } from "@bellawatt/use-inputs";
import DropdownInput from "../../components/DropdownInput";
import { useDisplay } from "../../components/DisplayProvider";
import TableTitle from "../../components/TableTitle";
import { useCalculations } from "../../utils/useCalculations";

const VehicleSelect = ({ vehicleSet: { name: evName } }) => {
  return (
    <Box>
      <Typography>{evName}</Typography>
    </Box>
  );
};

export default function ChargingOverTimeChart() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { vehicleSets } = useInputs();
  const { mode } = useDisplay();
  const [isBar, setIsBar] = useState(false);
  const Calculations = useCalculations();

  const [selectedVehicleSetId, setSelectedVehicleSetId] = useState(
    vehicleSets[0].id
  );

  const selectedVehicleSet =
    vehicleSets.find(({ id }) => id === selectedVehicleSetId) || vehicleSets[0];

  const { replacementVehicle, vehicleCount } = selectedVehicleSet;

  const chargeSchedule = Calculations.averageWorkdayBatterySchedule({
    input: selectedVehicleSet,
  });

  const labels = times(24);

  const chargeData = isBar
    ? {
        labels,
        datasets: [
          {
            label: `${vehicleCount}x ${replacementVehicle.name}`,
            data: chargeSchedule,
            backgroundColor: "#0072CE",
            borderColor: "#0072CE",
            barPercentage: 0.8,
          },
        ],
      }
    : {
        labels,
        datasets: [
          {
            label: `${vehicleCount}x ${replacementVehicle.name}`,
            fill: true,
            data: chargeSchedule,
            backgroundColor: "#0072CE",
            pointRadius: 0,
            borderWidth: 2,
            borderColor: "#0072CE",
            pointBackgroundColor: "#0072CE",
          },
        ],
      };

  const xTicks = {
    max: 100,
    min: 0,
    callback: function (value) {
      if (value % 2 === 0) {
        return formatHours(value);
      }
    },
    padding: 20,
    color: "#333333",
    font: {
      size: 12,
      weight: "700",
    },
  };

  const yTicks = {
    min: 0,
    stepSize: 25,
    callback: function (value, index, values) {
      value = value.toString();
      return `${value}%`;
    },
    color: theme.palette.grey[900],
    font: {
      size: 12,
    },
  };

  return (
    <Box width="100%" mt={mode === "print" ? "150px" : "0"}>
      <TableTitle
        title={formatMessage({ id: "panels.charging.overTime" })}
        titleStyle={{
          textTransform: "capitalize",
        }}
        subTitle={formatMessage({ id: "panels.charging.overTimeSubTitle" })}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: "column",
          [theme.breakpoints.up("sm")]: { flexDirection: "row" },
        }}
      >
        <DropdownInput
          id="vehicle-set-select"
          label={formatMessage({ id: "panels.charging.selectSet" })}
          labelBold
          labelStyle={{
            fontWeight: "400",
          }}
          value={selectedVehicleSet.id}
          options={vehicleSets.map((vehicleSet) => ({
            value: vehicleSet.id,
            children: <VehicleSelect vehicleSet={vehicleSet} />,
          }))}
          onChange={(e) => setSelectedVehicleSetId(e.target.value)}
          mb={4}
          sx={{ [theme.breakpoints.up("sm")]: { maxWidth: 300 } }}
        />
        <Box
          width="320px"
          height="35px"
          display="flex"
          style={{ borderRadius: "16px", border: "1px solid #0072CE" }}
        >
          <Button
            onClick={() => setIsBar(true)}
            style={{
              width: "50%",
              height: "100%",
              backgroundColor: isBar ? "#0072CE" : "transparent",
              borderRadius: "0",
              borderTopLeftRadius: "16px",
              borderBottomLeftRadius: "16px",
            }}
          >
            <Typography
              fontWeight="700"
              fontSize="14px"
              textTransform="uppercase"
              color={!isBar ? "#0072CE" : "white"}
            >
              {formatMessage({ id: "panels.charging.barChart" })}
            </Typography>
          </Button>
          <Button
            onClick={() => setIsBar(false)}
            style={{
              width: "50%",
              height: "100%",
              backgroundColor: !isBar ? "#0072CE" : "transparent",
              borderRadius: "0",
              borderTopRightRadius: "16px",
              borderBottomRightRadius: "16px",
            }}
          >
            <Typography
              fontWeight="700"
              fontSize="14px"
              textTransform="uppercase"
              color={isBar ? "#0072CE" : "white"}
            >
              {formatMessage({ id: "panels.charging.waterfall" })}
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box maxWidth="890px" height="400px">
        {isBar ? (
          <StyledBarGraph
            height="400px"
            data={chargeData}
            yTicks={yTicks}
            xTicks={xTicks}
            suggestedYMax={100}
          />
        ) : (
          <StyledLineGraph
            data={chargeData}
            showLegend
            yTicks={yTicks}
            xTicks={xTicks}
            suggestedYMax={100}
          />
        )}
      </Box>
      <Typography mt="24px" fontSize="16px">
        {formatMessage({ id: "panels.charging.note" })}
      </Typography>
    </Box>
  );
}
