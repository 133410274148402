import React from 'react';
import { Box } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsCents, formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import TableTitle from '../../components/TableTitle';

export default function EstimatedRevenuesChart({ revenuesByYear }) {
  const { formatMessage } = useIntl();
  const { carbonCreditsPrice } = useInputs();

  const { labels, values } = revenuesByYear.reduce(
    (acc, item) => {
      acc.values.push(item.value);
      acc.labels.push(item.year);
      return acc;
    },
    { labels: [], values: [] },
  );

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.overview.electricVeh' }),
        fill: false,
        data: values,
        backgroundColor: '#F3CA24',
        pointRadius: 0,
        borderWidth: 2,
        borderColor: '#F3CA24',
      },
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: '#333333',
    font: {
      size: 11,
      weight: '700',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: '#333333',
    padding: 20,
    font: {
      size: 11,
      weight: '400',
    },
  };

  return (
    <Box maxWidth="100%" height="450px" mt={4}>
      <TableTitle
        title={formatMessage({ id: 'panels.lcfs.estimatedRevenues' })}
        subTitle={formatMessage(
          { id: 'panels.lcfs.subtitle' },
          {
            price: formatAsCents(carbonCreditsPrice),
          },
        )}
      />
      <StyledBarGraph
        data={chargeData}
        yTicks={yTicks}
        xTicks={xTicks}
        showLegend={false}
        tooltipTitleFormatter={(data) => data[0].label}
        tooltipLabelFormatter={(data) => `${data.dataset.label}: ${formatAsDollars(data.raw)}`}
      />
    </Box>
  );
}
