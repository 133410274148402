import { LoadProfile } from '@bellawatt/electric-rate-engine';
import { sum } from 'lodash';
import times from 'lodash/times';
import { YEAR } from '../utils/assumptions';
import { isHourInUse } from '../utils/time';
import VehicleSet from './VehicleSet';
import {
  DIESEL_EMISSIONS_IN_LBS_PER_GAL,
  GASOLINE_EMISSIONS_IN_LBS_PER_GAL,
} from '../utils/assumptions';
import LCFS_VALUES, { dieselEER, energyDensity, gridIntensity } from '../data/LCFS';

const loadProfileOfZeros = times(8760, (i) => 0);

// calculations that require an array of vehicle sets, but don't necessarily apply to only one site
const VehicleSets = {
  //TODO: review
  optimizedChargers(vehicleSets) {
    const chargingSets = {};

    vehicleSets.forEach(({ chargingWindows, ...vehicle }) => {
      chargingWindows.forEach((chargingWindow) => {
        const vehicleChargingUsage = {
          ...vehicle,
          usage: times(24, (hour) =>
            isHourInUse(chargingWindow, hour) ? parseInt(vehicle.vehicleCount, 10) : 0,
          ),
          chargingWindow,
        };
        chargingSets[chargingWindow.chargerId] = {
          ...chargingWindow,
          ...chargingWindow.charger,
          vehicles: chargingSets[chargingWindow.chargerId]
            ? [...chargingSets[chargingWindow.chargerId].vehicles, vehicleChargingUsage]
            : [vehicleChargingUsage],
        };
      });
    });

    Object.keys(chargingSets).forEach((key) => {
      const summedHours = chargingSets[key].vehicles.reduce(
        (sum, { usage }) => sum.map((val, i) => val + usage[i]),
        times(24, () => 0),
      );

      chargingSets[key].minimumRequired = Math.ceil(
        Math.max(...summedHours) / chargingSets[key].ports,
      );
    });

    return Object.values(chargingSets);
  },

  //TODO: review
  allChargers(vehicleSets) {
    const chargerList = [];
    vehicleSets.forEach(({ chargingWindows, vehicleCount }) => {
      chargingWindows.forEach((chargingWindow) => {
        chargerList.push({ ...chargingWindow, vehicleCount });
      });
    });
    return chargerList;
  },

  annualHours(vehicleSets, fossilFuelType) {
    return sum(
      vehicleSets
        .filter(({ existingVehicle: { fuel } }) =>
          fossilFuelType ? fuel === fossilFuelType : true,
        )
        .map((set) => VehicleSet.annualHours(set)),
    );
  },

  annualIceGallons(vehicleSets, fossilFuelType) {
    return sum(
      vehicleSets
        .filter(({ existingVehicle: { fuel } }) =>
          fossilFuelType ? fuel === fossilFuelType : true,
        )
        .map((set) => VehicleSet.annualIceGallons(set)),
    );
  },

  totalKwh(vehicleSets) {
    const aggregateLoadProfile = vehicleSets.reduce((acc, set) => {
      return acc.aggregate(VehicleSet.annualLoadProfile(set));
    }, new LoadProfile(loadProfileOfZeros, { year: YEAR }));

    return aggregateLoadProfile.sum();
  },

  emissionsData(vehicleSets, { electricityEmissions, lifespanYears }) {
    const totalDieselHours = this.annualHours(vehicleSets, 'diesel');
    const totalGasolineHours = this.annualHours(vehicleSets, 'gas');

    const totalGallonsOfDiesel = this.annualIceGallons(vehicleSets, 'diesel');
    const totalGallonsOfGasoline = this.annualIceGallons(vehicleSets, 'gas');

    const totalDieselEmissions = totalGallonsOfDiesel * DIESEL_EMISSIONS_IN_LBS_PER_GAL;
    const totalGasolineEmissions = totalGallonsOfGasoline * GASOLINE_EMISSIONS_IN_LBS_PER_GAL;
    const totalEmissions = totalDieselEmissions + totalGasolineEmissions;

    const totalMwh = this.totalKwh(vehicleSets) / 1000;

    // g CO2/kWh * 2.20462 = lbs CO2/Mwh
    const electricityEmissionsInLbsPerMwh = electricityEmissions * 2.20462;
    const electricityEmissionsFromEVs = totalMwh * electricityEmissionsInLbsPerMwh;

    const annualEmissionsReduced = totalEmissions - electricityEmissionsFromEVs;
    const lifetimeEmissionsReduced = annualEmissionsReduced * lifespanYears;
    const annualTreesPlanted = annualEmissionsReduced / 48;
    const lifetimeTreesPlanted = annualTreesPlanted * lifespanYears;

    return {
      totalDieselHours,
      totalGasolineHours,
      totalGallonsOfDiesel,
      totalGallonsOfGasoline,
      totalDieselEmissions,
      totalGasolineEmissions,
      totalEmissions,
      totalMwh,
      electricityEmissionsInLbsPerMwh,
      electricityEmissionsFromEVs,
      annualEmissionsReduced,
      lifetimeEmissionsReduced,
      annualTreesPlanted,
      lifetimeTreesPlanted,
    };
  },

  vehiclePurchaseCosts(vehicleSets, isRetiringFleet) {
    return sum(
      vehicleSets.map(
        ({ existingVehicle, replacementVehicle, vehicleCount }) =>
          vehicleCount * (isRetiringFleet ? existingVehicle.msrp : replacementVehicle.msrp),
      ),
    );
  },

  //TODO: review
  chargerPurchaseCosts(vehicleSets, isRetiringFleet) {
    if (isRetiringFleet) {
      return 0;
    } else {
      const chargelist = this.allChargers(vehicleSets);
      const cost = sum(chargelist.map(({ vehicleCount, charger }) => vehicleCount * charger.price));

      return cost;
    }
  },

  lcfsCost(year, sets, carbonCreditsPrice) {
    const lcfsValue = LCFS_VALUES?.[year]?.diesel;

    if (!lcfsValue) {
      return 0;
    }

    const lcfsRev =
      ((lcfsValue * dieselEER - gridIntensity) / 1000000) * carbonCreditsPrice * energyDensity;

    return lcfsRev * this.totalKwh(sets);
  },

  totalCosts(
    vehicleSets,
    {
      isRetiringFleet,
      dieselPrice,
      gasolinePrice,
      rate,
      incentives,
      lifespanYears,
      btmConstructionCosts = 0,
      carbonCreditsPrice,
    },
  ) {
    const byYear = [];
    const byCategory = {
      fuel: 0,
      maintenance: 0,
    };

    const annualFuelCosts = sum(
      vehicleSets.map((vehicleSet) =>
        isRetiringFleet
          ? VehicleSet.annualFossilFuelCosts(vehicleSet, { gasolinePrice, dieselPrice })
          : VehicleSet.annualElectricityCosts(vehicleSet, rate),
      ),
    );

    for (let i = 0; i < lifespanYears + 1; i++) {
      if (i === 0) {
        // only include vehicle purchase, charger purchase, and incentive costs for first year
        // First year is upfront costs and fuel costs accumulate over the first year so don't apply yet
        const chargerCost = this.chargerPurchaseCosts(vehicleSets, isRetiringFleet);
        const purchaseCosts = this.vehiclePurchaseCosts(vehicleSets, isRetiringFleet);
        const installationCosts = isRetiringFleet ? 0 : btmConstructionCosts;
        const incentivesAmount = isRetiringFleet
          ? 0
          : 0 - sum(incentives.map(({ estimated_amount }) => estimated_amount));

        byYear.push(purchaseCosts + chargerCost + installationCosts + incentivesAmount);
        byCategory.purchase = purchaseCosts;
        byCategory.incentives = incentivesAmount;
        byCategory.chargers = chargerCost;
        byCategory.btmConstructionCosts = installationCosts;
      } else {
        // only include fuel costs for subsequent years

        const lcfs =
          carbonCreditsPrice > 0 ? this.lcfsCost(YEAR + i, vehicleSets, carbonCreditsPrice) : 0;
        const lcfsValue = isRetiringFleet ? 0 : lcfs;

        const annualMaintenanceCosts = sum(
          vehicleSets.map((set) =>
            isRetiringFleet
              ? VehicleSet.annualIceMaintenanceCost(set, i)
              : VehicleSet.annualEvMaintenanceCost(set, i),
          ),
        );

        if (!byCategory.lcfs) {
          byCategory.lcfs = 0;
        }

        byYear.push(byYear[i - 1] + annualFuelCosts + annualMaintenanceCosts + lcfsValue);

        if (i === 1) {
        }
        byCategory.fuel += annualFuelCosts;
        byCategory.lcfs += lcfsValue;
        byCategory.maintenance += annualMaintenanceCosts;
      }
    }

    return {
      byYear,
      byCategory,
    };
  },
};

export default VehicleSets;
