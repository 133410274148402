/* eslint-disable import/prefer-default-export */

import { formatChargerName } from "./formatters";

const typeConversions = {
  // Passenger vehicles:
  Sedan: "Passenger",
  Crossover: "Passenger",
  SUV: "Passenger",
  Hatchback: "Passenger",
  Coupe: "Passenger",
  Minivan: "Passenger",
  "Station Wagon": "Passenger",

  // Non-Passenger vehicles
  transit_bus: "Transit Bus",
  step_van: "Step Van",
  pickup_truck: "Pick-up Truck",
  school_bus: "School Bus",
  cutaway_chassis: "Cutaway Chassis",
  utility_truck: "Utility Truck",
  box_truck: "Box Truck",
  refuse_truck: "Refuse Truck",
  trailer_truck: "Trailer Truck",
  terminal_tractor: "Terminal Tractor",
  motor_coach: "Motor Coach",
  forklift: "Forklift",
  cargo_van: "Cargo Van",
  shuttle_bus: "Shuttle Bus",
  specialty: "Specialty",
  bev_drive_system: "BEV Drive System",
  tru: "TRU",
  tractor: "Tractor",
};

const passengerSubtypeConversions = {
  Sedan: "Sedan",
  Crossover: "SUV", // There is no crossover archetype, so including this conversion keeps all crossovers from the API within the app
  SUV: "SUV",
  Hatchback: "Hatchback",
  Coupe: "Coupe",
  Minivan: "Minivan",
  "Station Wagon": "Station Wagon",
};

const efficiencyUnitConversions = {
  "kwh per mile": 1,
  "kwh per 100 miles": 100,
  "kWh/hr": 1,
};

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {ElectricVehicle}
 */
export const transformApiElectricVehicle = (vehicle) => ({
  fromApi: true,
  id: vehicle.handle,
  category:
    vehicle.form_factor === "tru"
      ? "TRU"
      : vehicle.is_offroad
      ? "Off-Road"
      : "On-Road",
  name: `${vehicle.make} ${vehicle.model} ${vehicle.trim}`.trim(),
  type: typeConversions[vehicle.form_factor] || vehicle.form_factor,
  subtype:
    vehicle.type === "Passenger"
      ? passengerSubtypeConversions[vehicle.form_factor]
      : vehicle.subtype,
  formFactor: vehicle.form_factor,
  make: vehicle.make,
  year: vehicle.model_year,
  model: vehicle.model,
  duty: vehicle.duty ? vehicle.duty : vehicle.subtype,
  trim: vehicle.trim,
  imageUrl:
    vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full || null,
  rangeInMiles: vehicle.total_range,
  rangeInHours: vehicle.total_range,
  range: vehicle.total_range,
  fuel: vehicle.fuel,
  liftCapacity: vehicle.lift_capacity || 0,
  batteryCapacityInKwh: vehicle.battery_capacity,
  weightClass: vehicle.weight_class,
  msrp: vehicle.msrp,
  msrpIsEstimated: vehicle.is_estimated_price,
  efficiencyInHoursPerKwhHeavy: vehicle.electric_efficiency_heavy,
  efficiencyInHoursPerKwhMedium: vehicle.electric_efficiency_medium,
  efficiencyInHoursPerKwhLight: vehicle.electric_efficiency_light,
  efficiencyHeavyLoad:
    efficiencyUnitConversions[vehicle.electric_efficiency_unit] /
    vehicle.electric_efficiency_heavy,
  efficiencyMediumLoad:
    efficiencyUnitConversions[vehicle.electric_efficiency_unit] /
    vehicle.electric_efficiency_medium,
  efficiencyLightLoad:
    efficiencyUnitConversions[vehicle.electric_efficiency_unit] /
    vehicle.electric_efficiency_light,
  efficiencyInMilesPerKwh:
    efficiencyUnitConversions[vehicle.electric_efficiency_unit] /
    vehicle.electric_efficiency,
  maintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost,
  maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0,
  cargoVolume: vehicle.cargo_volume_max || vehicle.cargo_volume_min,
  seats: vehicle.seats_max || vehicle.seats_min,
  gvwr: vehicle.gvwr,
  horsepower: vehicle.horsepower,
  availability: vehicle.availability,
  fuelConsuption: {
    heavy: vehicle.electric_efficiency_heavy,
    meadium: vehicle.electric_efficiency_medium,
    light: vehicle.electric_efficiency_light,
    type: vehicle.electric_efficiency_unit,
  },
  runtime: vehicle.runtime,
  milesPerGallon:
    33.705 /
    (efficiencyUnitConversions[vehicle.electric_efficiency_unit] /
      vehicle.electric_efficiency),
});

/**
 *
 * @param {ApiVehicle} vehicle
 * @returns {FossilVehicle} vehicle
 */
export const transformApiFossilVehicle = (vehicle) => ({
  fromApi: true,
  id: vehicle.handle,
  category:
    vehicle.form_factor === "tru"
      ? "TRU"
      : vehicle.is_offroad
      ? "Off-Road"
      : "On-Road",
  name: `${vehicle.make} ${vehicle.model} ${vehicle.trim || ""}`.trim(),
  type: typeConversions[vehicle.form_factor] || vehicle.form_factor,
  subtype:
    vehicle.type === "Passenger"
      ? passengerSubtypeConversions[vehicle.form_factor]
      : vehicle.subtype,
  formFactor: vehicle.form_factor,
  make: vehicle.make,
  year: vehicle.model_year,
  model: vehicle.model,
  duty: vehicle.duty ? vehicle.duty : vehicle.subtype,
  trim: vehicle.trim,
  imageUrl:
    vehicle.images.find(({ url_thumbnail }) => url_thumbnail)?.url_full || null,
  rangeInMiles: vehicle.total_range,
  hoursPerGallonHeavy: vehicle.fossil_fuel_efficiency_heavy,
  hoursPerGallonMedium: vehicle.fossil_fuel_efficiency_medium,
  hoursPerGallonLight: vehicle.fossil_fuel_efficiency_light,
  efficiencyHeavyLoad: vehicle.fossil_fuel_efficiency_heavy,
  efficiencyMediumLoad: vehicle.fossil_fuel_efficiency_medium,
  efficiencyLightLoad: vehicle.fossil_fuel_efficiency_light,
  fuelCapacity: vehicle.fuel_capacity,
  horsepower: vehicle.horsepower,
  fuel: vehicle.fuel,
  msrp: vehicle.msrp,
  weightClass: vehicle.weight_class,
  cargoVolume: vehicle.cargo_volume_max || vehicle.cargo_volume_min,
  seats: vehicle.seating,
  maintenanceCostPerMile: vehicle.is_offroad ? 0 : vehicle.maintenance_cost,
  maintenanceCostPerHour: vehicle.is_offroad ? vehicle.maintenance_cost : 0,
  fuelConsuption: {
    heavy: vehicle.fossil_fuel_efficiency_heavy,
    meadium: vehicle.fossil_fuel_efficiency_medium,
    light: vehicle.fossil_fuel_efficiency_light,
    type: vehicle.fossil_efficiency_unit,
  },
  runtime: vehicle.runtime,
  milesPerGallon: vehicle.fossil_fuel_efficiency,
  seating: vehicle.seating,
  payload: vehicle.payload,
});

export const transformApiCharger = (charger) => ({
  id: charger.charger_id,
  name: formatChargerName(charger),
  chargerType: charger.current === "AC" ? "level_2" : "dc_fast_charging",
  cellularSupport: charger.cellular_support,
  bluetooth: charger.bluetooth,
  wifi: charger.wifi,
  ethernet: charger.ethernet,
  ports: charger.num_of_ports,
  cordLength: charger.cord_length,
  price: charger.price,
  priceIsEstimated: charger.is_estimated_price,
  ocppVersion: charger.ocpp_version,
  oscpVersion: charger.oscp_version,
  plugTypes: charger.plug_types,
  make: charger.make,
  model: charger.model,
  modelVariant: charger.model_variant,
  imageUrl: charger.img,
  portKw: charger.current === "AC" ? charger.kw_ac : charger.kw_dc,
  formFactor: charger.form_factor,
  outlets: charger.home_outlet,
  vehicleToGrid: charger.vgi_capable,
  warranty: charger.warranty,
  certifications: charger.certifications,
});

/**
 *
 * @param {GenericVehicle} genericVehicle
 * @returns {ElectricVehicle}
 */
export function transformGenericToElectricVehicle(genericVehicle) {
  return {
    fromApi: false,
    id: genericVehicle.id,
    category: genericVehicle.category,
    name: genericVehicle.name,
    type: genericVehicle.type,
    subtype: genericVehicle.subtype,
    formFactor: genericVehicle.formFactor,
    make: genericVehicle.make,
    year: genericVehicle.year,
    model: genericVehicle.model,
    duty: genericVehicle.duty ? genericVehicle.duty : genericVehicle.subtype,
    trim: genericVehicle.trim,
    imageUrl: genericVehicle.imageUrl,
    rangeInMiles: genericVehicle.rangeInMiles,
    rangeInHours: "",
    range: genericVehicle.rangeInMiles,
    fuel: genericVehicle.fuel === "BEV" ? "Electric" : genericVehicle.fuel,
    liftCapacity: genericVehicle.liftCapacity,
    batteryCapacityInKwh: genericVehicle.batteryCapacityInKwh,
    weightClass: genericVehicle.weightClass,
    msrpIsEstimated: true,
    efficiencyInHoursPerKwhHeavy: genericVehicle.efficiencyInHoursPerKwhHeavy,
    efficiencyInHoursPerKwhMedium: genericVehicle.efficiencyInHoursPerKwhMedium,
    efficiencyInHoursPerKwhLight: genericVehicle.efficiencyInHoursPerKwhLight,
    maintenanceCostPerMile: genericVehicle.maintenanceCostPerMile || 0,
    maintenanceCostPerHour: genericVehicle.maintenanceCostPerHour || 0,
    seats: genericVehicle.seats,
    msrp: genericVehicle.msrp,
    gvwr: genericVehicle.gvwr,
    cargoVolume: genericVehicle.cargoVolume,
    fuelConsuption: {
      heavy: genericVehicle?.fuelConsuption?.heavy,
      meadium: genericVehicle?.fuelConsuption?.medium,
      light: genericVehicle?.fuelConsuption?.light,
      type: genericVehicle?.fuelConsuption?.type,
    },
    efficiencyInMilesPerKwh: genericVehicle.efficiencyInMilesPerKwh || 0,
    drivetrain: genericVehicle.drivetrain,
    efficiencyMile: genericVehicle.efficiencyMile,
    seating: genericVehicle.seating,
    payload: genericVehicle.payload,
    interiorHeight: genericVehicle.interiorHeight,
    milesPerGallon: (33.705 / genericVehicle.efficiencyInMilesPerKwh),
  };
}

/**
 *
 * @param {GenericVehicle} genericVehicle
 * @returns {FossilVehicle}
 */
export function transformGenericToFossilVehicle(genericVehicle) {
  return {
    id: genericVehicle.id,
    name: genericVehicle.name,
    make: genericVehicle.make,
    model: genericVehicle.model,
    type: genericVehicle.type,
    gvwr: genericVehicle.gvwr,
    msrp: genericVehicle.msrp,
    milesPerGallon: genericVehicle.milesPerGallon || 0,
    cargoVolume: genericVehicle.cargoVolume,
    horsepower: genericVehicle.horsePower,
    duty: genericVehicle.duty ? genericVehicle.duty : genericVehicle.subtype,
    imageUrl: genericVehicle.imageUrl,
    rangeInMiles: genericVehicle.rangeInMiles,
    fuel: genericVehicle.fuel,
    weightClass: genericVehicle.weightClass,
    fromApi: false,
    category: genericVehicle.category,
    subtype: genericVehicle.subtype,
    formFactor: genericVehicle.formFactor,
    year: "",
    trim: "",
    hoursPerGallonHeavy: genericVehicle.hoursPerGallonHeavy,
    hoursPerGallonMedium: genericVehicle.hoursPerGallonMedium,
    hoursPerGallonLight: genericVehicle.hoursPerGallonLight,
    seats: genericVehicle.seats,
    maintenanceCostPerMile: genericVehicle.maintenanceCostPerMile || 0,
    maintenanceCostPerHour: genericVehicle.maintenanceCostPerMile || 0,
    fuelConsuption: {
      heavy: genericVehicle?.fuelConsuption?.heavy,
      meadium: genericVehicle?.fuelConsuption?.medium,
      light: genericVehicle?.fuelConsuption?.light,
      type: genericVehicle?.fuelConsuption?.type,
    },
    efficiencyMile: genericVehicle.efficiencyMile,
    seating: genericVehicle.seating,
    payload: genericVehicle.payload,
    interiorHeight: genericVehicle.interiorHeight,
    drivetrain: genericVehicle.drivetrain,
  };
}
