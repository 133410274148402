import ButtonInput from '../components/ButtonInput';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useInputs } from '@bellawatt/use-inputs';
import getDefaultsWithApi from '../use-inputs/getDefaults';
import { ResetIcon } from '../assets/icons/icons';

export default function ResetInputs() {
  const { formatMessage } = useIntl();
  const { setInput } = useInputs();

  const handleResetInputs = () => {
    // eslint-disable-next-line no-alert
    if (window.confirm(formatMessage({ id: 'inputs.confirmResetInputs' }))) {
      setInput({ isResetting: 'true' });
      getDefaultsWithApi().then((data) => setInput({ ...data, isResetting: false }));
    }
  };

  return (
    <Box mt={4} width="100%">
      <ButtonInput
        variant="outlined"
        small
        onClick={handleResetInputs}
        style={{
          borderRadius: '50px',
          width: '100%',
          borderColor: '#0072CE',
          padding: '4px',
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="8px"
          sx={(theme) => ({
            paddingTop: '10px',
            paddingBottom: '10px',
            color: "#0072CE",
            fill: "#0072CE",
            fontSize: '14px',
            fontWeight: '700',
            width: '100%',
          })}
        >
          <ResetIcon />
          {formatMessage({ id: 'inputs.reset' })}
        </Box>
      </ButtonInput>
    </Box>
  );
}
