import React from "react";
import { useIntl } from "react-intl";
import { formatTimeRange } from "../utils/formatters";
import Alert from "../components/Alert";
import { useCalculations } from "../utils/useCalculations";

export default function ChargerRecommendation({ vehicleSet }) {
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const {
    chargingWindows,
    milesPerWorkday,
    name,
    replacementVehicle: {
      efficiencyInMilesPerKwh
    },
  } = vehicleSet;
  const { start, finish } = chargingWindows[0];

  const resultPerDay = milesPerWorkday * efficiencyInMilesPerKwh;

  const fullChargeKwh = `${resultPerDay.toFixed(2)} kWh`;

  const timeRange = formatTimeRange(start, finish);
  const numHours = `${
    start < finish ? finish - start : 23 - start + finish
  } hours`;
  const minStateOfCharge = `${Calculations.minimumStateOfCharge({
    input: vehicleSet,
  })}%`;

  const formattedDrivingHours = formatMessage(
    { id: "chargingWindows.drivingHours" },
    {
      miles: milesPerWorkday,
    }
  );

  const formattedMessage = formatMessage(
    { id: "chargingWindows.recommendation" },
    {
      timeRange: <b>{timeRange}</b>,
      vehicleName: <b>{name}</b>,
      drivingHours: <b>{formattedDrivingHours}</b>,
      chargeAmount: <b>{fullChargeKwh}</b>,
      numHours: <b>{numHours}</b>,
      minStateOfCharge: <b>{minStateOfCharge}</b>,
    }
  );

  return <Alert>{formattedMessage}</Alert>;
}
