import times from 'lodash/times';
import moment from 'moment-timezone';

const translations = {
  en: {
    translation: {
      header: 'FLEETS',
      tabs: {
        overview: 'Overview',
        fuel: 'Fuel Costs',
        electricity: 'Electricity',
        incentives: 'Incentives',
        comparison: 'Vehicles',
        charging: 'Charging',
        operations: 'Operations',
        lcfs: 'LCFS',
        emissions: 'Emissions',
        print: 'Print',
        financials: 'Financials',
      },
      inputs: {
        vehicleSet: {
          title: 'Vehicle Sets',
          addNewButton: ' Add New Vehicle Set',
          editCard: {
            specs: {
              type: 'Type',
              hoursPerVehicle: 'Hours per vehicle',
              daysOperating: 'Days operating',
              dcfc: 'DCFC',
              multipleWindows: 'Multiple time slots',
              hoursPerVehicleValue: '{heavy} H / {medium} M / {light} L',
              dailyMileage: 'Daily Mileage',
            },
          },
          vehicleCatalog: {
            title: 'Select an EV that meets your needs',
            subtitle: 'Select an available electric vehicle that meets your needs for {setName}',
            sortBy: 'Sort By',
            showing: 'Showing {subset} of {total} vehicles',
            showMore: 'Show more',
            noMatches: 'No vehicles match the current filters',
            electricVehicle: 'Electric Vehicle',
            recommendedVehicle: 'Recommended Vehicle',
            selected: 'Current Vehicle',
            selectReplacement: 'Select As Replacement',
            type: 'Type',
            subtype: 'Subtype',
            milesPerDay: 'Miles per day',
            daysOperating: 'Days operating',
            replacementYear: 'Replacement Year',
            range: 'Range',
            capacity: {
              battery: 'Battery Capacity',
              cargo: 'Cargo Capactiy',
              passenger: 'Passenger Capacity',
            },
            headroom: 'Headroom',
            legroom: 'Legroom',
            availability: 'Availability',
            milesDay: 'Miles / Day',
            msrp: 'MSRP',
            cancel: 'Cancel',
            confirm: 'Confirm Selection',
            sortOptions: {
              rangeDesc: 'Range (decreasing)',
              rangeAsc: 'Range (increasing)',
            },
            make: 'Make',
            vehicleRange: 'Vehicle Range',
            filters: 'Filters',
            over: 'Over 500 mi',
            lessThan: 'Less than 250 mi',
            between: '251 mi - 500 mi',
          },
          editSetDialog: {
            alertWarning: 'Total hours in a day may not exceed 250',
            alertTxt1:
              'If vehicle usage exceeds {range} miles per day then they will need to be charged up more than once per day (e.g. in the middle of the day). Alternatively you may want to reduce the number of vehicles of this type on the previous screen so only vehicles used for under {range} per day are switched to {vehicle}.',
            title: 'Edit Vehicle Set',
            titleEquivalent: 'Edit Equivalent Electric Vehicle',
            titleCurrent: 'Edit Current/ Planned Vehicle',
            selection: 'Vehicle Selection',
            replacementVehicle: 'Replacement Electric Vehicle',
            usage: 'Vehicle Usage',
            behavior: 'Charging Behavior',
            cancel: 'Cancel',
            save: 'Update Vehicle Set',
            delete: 'Delete Vehicle Set',
            warningBold: 'Delete this vehicle set? ',
            warning: 'You will be unable to recover any data from it.',
            yes: 'Yes, Delete it',
            name: 'Name your vehicle set',
            vehicleCount: 'Number Of Vehicles',
            currentPlanned: 'Your Current/Planned Vehicle',
            avgMiles: 'Average Business Miles Per Vehicle (Per Day)',
            avgPersonal: 'Average Personal Miles Per Vehicle (Per Day)',
            includePersonal: 'Include Personal Miles',
            personalPaidFor: 'My company pays for fuel for personal miles',
            combined: 'Business and Personal Miles Combined',
            daysOperation: 'Days in Operation',
            monthsOperation: 'Months in Operation',
            runtime: 'Runtime',
            weekdays: 'Weekdays Only',
            allMonths: 'All Months',
            customMonths: 'Have custom months of operation?',
            customDays: 'Have custom days of operation?',
            specifyMonths: 'Specify Months',
            specifyDays: 'Specify Days',
            vehicleChangeWarning: 'This change will affect your recommended electric vehicle',
            chargerOverlap: 'Multiple chargers are in use at once for this vehicle set',
            lowBatteryWarning:
              'With this configuration, the minimum state of charge across the week will be {percent}. Are you sure the charging times, charging level(s), and daily miles are a conservative estimate?',
            depletedBatteryWarning:
              "With this configuration, your vehicle's battery will be depleted at some point during the day. Please add another charging window or adjust your existing one(s).",
            chargerSelectedMessage:
              'A specific charger was selected. To edit this selection, please go to the "Charging" tab.',
            msrp: 'MSRP',
            changeReplacementVehicleButton: 'Change replacement vehicle',
            type: 'Type',
            subtype: 'Subtype',
            range: 'Range',
            battery: 'Battery Capacity',
            kwh: 'kWh',
            cargo: 'Cargo Capacity',
            cubicFt: 'ft³',
            passenger: 'Passenger Capacity',
            availability: 'Availability',
            headroom: 'Headroom',
            legroom: 'Legroom',
            averageEnergyDay: 'Average miles per vehicle (per day)',
            hoursHeavyEnergyDay: 'Hours of heavy energy use (tilling) per day',
            hoursMediumEnergyDay: 'Hours of medium energy use (mowing,  heavy loader work) per day',
            hoursLightEnergyDay:
              'Hours of light energy use (towing small trailers, light loader work) per day',
              vehicleUsageTooltip: "If vehicle exceeds 250 miles per day then they will need to be charged up more than once per day (e.g. in the middle of the day). Alternatively, you may want to reduce the number of vehicles of this type on the previous screen so only vehicles used under 250 miles per day are switched to EVs.",
          },
        },
        assumptions: {
          title: 'Assumptions',
          vehicleLifespan: {
            label: 'Vehicle Lifetime (years)',
            tooltip: 'The vehicle lifetime is the expected length of time you plan to drive the vehicle. This value is used to calculate potential savings and potential operations costs.',
          },
          organizationType: {
            label: 'Organization Type',
            tooltip: 'The organization type to identify potential third party incentives.',
          },
          publicEntity: {
            label: 'Public Entity',
            tooltip: 'Used to indicate if your business is a public entity, the entity type is used to identify potential third party incentives.',
          },
          taxExempt: {
            label: 'Tax Exempt',
            tooltip: 'Used to indicate if your business is a tax exempt entity, the tax exempt field is used to identify potential third party incentives.',
          },
          multipleSitesPurchase: {
            header: 'Purchasing for multiple sites',
            subheader:
              '(National average fuel prices will be applied and local incentives will not be analyzed)',
          },
        },
        location: {
          title: 'Site Location',
          zipCode: {
            label: 'Zip Code',
            tooltip: 'Your location drives electricity and fuel costs. Electricity costs are sourced from {usEnergyLink}. Fuel costs are sourced from {aaaLink}.',
            usEnergyLink: 'US Energy Information Administration',
            aaaLink: 'AAA',
            formatError: 'Invalid Zip Code',
          },
          inDac: {
            label: 'In a DAC',
            tooltip: 'Disadvantaged Community (DAC) locations receive additional incentives',
          },
          buildingType: {
            label: 'Building Type',
            tooltip: 'Your building type drives demand charges',
          },
          chargerInstallationCost: {
            label: 'Charger Installation Costs',
            tooltip: 'The cost of installing the chargers including panels, trenching and cabling etc from the meter to the charger. Based on CALeVIP reported numbers, the weighted average cost to install each level 2 charger port is $6,266 and each DC Fast Charger port is $79,534. Source: California Energy Commission, July 2022',
            checkbox: 'Custom installation costs'
          },
        },
        fuel: {
          title: 'Fuel Costs',
          gasolinePrice: {
            label: 'Gasoline Price ($/Gal)',
            tooltip: 'Source: {link}',
          },
          dieselPrice: {
            label: 'Diesel Price ($/Gal)',
            tooltip: 'Diesel price is updated every 2 business days to the most recent available from AAA. Price is updated in the fleets tool live with the AAA update. SRC: {link}',
          },
          agriculturalDieselPrice: {
            label: 'Agricultural Diesel Price ($/Gal)',
            tooltip: 'Source: {link}',
          },
          electricityPrice: {
            label: 'Electricity Cost ($/kWh)',
            link: 'US Energy Information Administration',
            tooltip: 'Electricity cost is updated annually to the most recent available from US EIA. Cost is updated in the fleets tool live with the US EIA update. SRC: {link}',
          },
        },
        buildingTypeOptions: {
          warehouse: 'Warehouse',
        },
        booleanOptions: {
          yes: 'Yes',
          no: 'No',
        },
        chargerCatalog: {
          title: 'Select a charger that meets your needs',
          subtitle:
            'To charge a {vehicle} driving {miles} miles per day, chargers must provide a minimum of {kWhPerWorkday} per day',
          showMore: 'Show More',
          requirements: 'Charger Requirements',
          vehicle: 'Vehicle',
          vehicleCount: 'Number of Vehicles',
          capacity: 'Battery Capacity',
          milesPerDay: 'Miles Per Day',
          efficiency: 'Efficiency',
          window: 'Charging Window',
          duration: 'Charging Duration',
          output: 'Required Output',
          preferred: 'Preferred',
          showing: 'Showing {subset} of {total} chargers',
          selected: 'Selected Charger',
          selectReplacement: 'Select As Replacement',
          generic: 'Generic Chargers Only',
          allBranded: 'All Branded Chargers',
          type: 'Type',
          wallMounted: 'Wall Mounted',
          pedestal: 'Pedestal',
          overhead: 'Overhead',
          sortOptions: {
            msrpDesc: 'MSRP (decreasing)',
            msrpAsc: 'MSRP (increasing)',
          },
          brand: 'Charger Brand',
          minOutput: 'Minimum Output',
          price: 'Maximum Price',
          ports: 'Ports',
          cable: 'Cable Length',
          chargerType: 'Charger Type',
          miles: 'miles',
          efficiencyMiles: 'kWh / mi',
          milesAb: 'mi',
          hours: 'hrs',
          upTo: 'Up to',
          all: 'All',
        },
        inputsButton: 'Inputs & Assumptions',
        updateButton: 'Update',
        reset: 'Start over',
        confirmResetInputs: 'Are you sure you want to reset all of your inputs?',
      },
      fleetSummary: {
        title: 'Fleet Summary',
        lifetime: {
          label: 'Potential Lifetime Savings',
          tooltip:
            'Potential Lifetime savings (including OpEx, CapEx and potential Federal and Local Incentives line items) are displayed on the Financial Summary Tab and are based on a lifetime of {value} years which can be changed under assumptions on the left side of the page. View the Financials tab for an explanation and breakdown of how this lifetime savings figure was calculated.',
        },
        size: 'Size (vehicles)',
        fuelSavings: {
          label: 'Potential Avg. Annual Op/Ex Saving',
          tooltip:
            'Potential Avg. Annual Op/Ex Savings are a combination of Maintenance, Insurance and Fuel costs. View the Financials tab for an explanation and breakdown of how this lifetime savings figure was calculated.',
        },
        perMile: 'Per Mile',
        incentives: {
          label: 'Federal, State and Local Potential Incentives',
          tooltip:
            'View the Incentives tab for an explanation and breakdown of how this incentives figure was calculated.',
        },
        emissions: {
          label: 'Potential GHG Lifetime Tailpipe Emissions Saved',
          tooltip:
            'View the Fuel Costs tab for an explanation and breakdown of how this fuel savings figure was calculated.',
        },
        tons: '{value} tons',
      },
      onboarding: {
        stepXof: 'step {stepNumber} of 5',
        back: 'Back',
        gettingStarted: {
          title: 'Going electric starts with understanding your needs',
          leadCopy:
            'We need to understand your requirements so we can recommend the BrightDrop electric vehicles and identify potential third party incentives.',
          tellUsOrganization: 'Tell us about your organization',
          tellUsVehicle: 'Tell us about your vehicles',
          seeSavings: 'See how much you could save',
          createFirstVehicleSet: 'Create Your First Vehicle Set',
          doLater: "I'll Do This Later",
          doLaterToolTip: "Uses a template set of 20 generic ICE vehicles based in 90802",
        },
        generalInformation: {
          title: 'General Information',
          leadCopy: 'We use this information to calculate fuel costs and identify potential third party incentives.',
          organizationType: {
            label: 'Organization Type',
            tooltip: 'We ask for the organization type so we can identify applicable incentives',
          },
          zipCode: {
            label: 'Zip Code',
            tooltip: 'We ask for the zip code so we can identify applicable incentives',
          },
          seeDefinition: 'Select an option to see definition',
          vehiclesLocated: 'Used to check incentive applicability and fuel prices',
          purchasingMultipleSites: {
            header:
              'I am purchasing for multiple sites (National average fuel prices will be applied and local incentives will not be analyzed)',
            subHeader:
              '(National average fuel prices will be applied and local incentives will not be analyzed)',
            posHeader: 'Note: If you have multiple sites you may want to run this tool for each location to get accurate incentives and fuel costs.'
          },
          next: 'Next',
          organizationDescriptions: {
            car_sharing_companies:
              'Companies that allow people to rent cars for short periods of time, such as by the hour.',
            federal_government_entities:
              'The government of the United States or any bureau, department or other agency of the federal government.',
            local_government_entities:
              'A county, incorporated city, emergency services district, other special district, joint board, or other entity defined as a political subdivision that maintains the capability to provide mutual aid.',
            logistics_company: 'Companies that move goods.',
            medium_or_large_businesses:
              'Depending on the industry, medium and large businesses have at least 250 to 1,500 employees. Visit the Small Business Administration website for an industry specific definition. Few if any incentives distinguish between small, medium or large businesses. ',
            native_tribes: 'The recognized governing body of any Native American tribe.',
            nonprofits:
              'Legal entities that operate for a collective, public or social benefit as opposed to generating profits. Typically these companies are tax-exempt and hold 501(c)(3) or 501(c)(7) status.',
            private_school_districts: 'Privately funded schools and school districts.',
            public_school_districts: 'Government funded schools and school districts.',
            rental_companies:
              'Companies that rent vehicles for short periods of time ranging from a few hours to a few weeks.',
            small_businesses:
              'Depending on the industry, small businesses have a minimum of 1 and a maximum of 250 to 1,500 employees. Visit the Small Business Administration website for an industry specific definition. Few if any incentives distinguish between small, medium or large businesses. ',
            state_government_entities:
              'State or any department, agency, commission, or authority of the state.',
            transit_agencies: 'Public entity responsible for managing transit services.',
            universities:
              'An institute of higher education and research which grants academic degrees.',
            utility:
              'A provider of a service used by the public such as an electricity or gas supply.',
            other: 'Entitities not covered by other definitions.',
            farms: 'Individual agricultural user.',
          },
        },
        vehicleUsage: {
          title: 'Vehicle Usage',
          leadCopy:
            'This information drives range and charging requirements determining EV and charger recommendations. Please fill out based on the conventional gas/diesel vehicle you are replacing or planning to replace with your BrightDrop electric vehicle.',
          next: 'Next',
        },
        selectVehicle: {
          title: 'Select Vehicle',
          leadCopy: 'Tell us about your plans for purchasing new or replacement vehicles',
          next: 'Next',
          numberVehicles: 'Number Of Vehicles',
          numberVehiclesError: 'Must have at least 1 vehicle',
          vehicleType: 'Select type',
          undefinedType: 'Select type',
          vehicleSubtype: 'Select subtype',
          undefinedSubtype: 'Select subtype',
          name: 'Name your vehicle set',
          range: 'EPA Estimated Range',
          miles: 'miles',
          capacity: 'Capacity',
          milesKw: 'Efficiency',
          mpgDiesel: 'Mpg diesel for an equivalent fossil vehicle',
          weightClass: 'Weight Class',
          fuel: 'Fuel',
          diesel: 'Diesel',
          gas: 'Gas',
          all: 'All',
          current: 'Current/Planned Vehicle',
          equivalent: 'Equivalent Electric Vehicle',
          milesKWh: '{value} miles/ kWh',
          generic: 'Generic',
          model: 'Model',
          makeModel: 'Make/Model',
          currentPlanned: 'Current/ Planned Vehicle',
          runTime: 'Runtime',
          runTimeValue: '{value} hours',
          horsePower: 'Horsepower',
          weightClassIndicator: 'This is Weight class {weightClass}',
          fuelType: 'Fuel Type',
          labels: {
            gvwr: 'Vehicle GVWR',
            mpg: 'Vehicle MPG',
            mpge: 'Vehicle MPGe',
            msrp: 'Vehicle MSRP',
            cargo: 'Vehicle Cargo Capacity (ft³)',
            resaleYear: 'Fossil Fuel Vehicle resale value, 1 year',
            resaleLife: 'Fossil Fuel Vehicle resale value, Lifetime ({value} years)'
          },
          tooltip: {
            resale: "Default resale/residual values modeled on AFLEET 2020 data per vehicle type and year of operation. Overriding either the default first-year or lifetime residuals with your own expected values will generate a new residual value curve which fills in residuals for all years with a smooth exponential decay curve. We assume vehicles are in use for 10-years, but you can edit this later in Assumptions."
          },
          fuelConsuption: {
            title: 'Fuel Consumption',

            heavy: 'Heavy: {value} gallons/hr',
            medium: 'Medium: {value} gallons/hr',
            light: 'Light: {value} gallons/hr',

            heavyKwh: 'Heavy: {value} kWh/hr',
            mediumKwh: 'Medium: {value} kWh/hr',
            lightKwh: 'Light: {value} kWh/hr',

            heavyToolTip: 'Heavy energy use activities would include tilling.',
            mediumToolTip:
              'Medium energy use activities would include mowing, and heavy loader work',
            lightToolTip:
              'Light energy use activities would include towing small trailers, and light loader work.',
          },
        },
        chargingBehavior: {
          title: 'Charging Behavior',
          leadCopy:
            'Think ahead to when you expect to charge your new BrightDrop electric vehicle(s). Enter each daily window of time you expect to charge your vehicles and where, and we will recommend ideal charger type(s).',
          next: 'Done',
          selectChargersFor: 'Selecting Charger(s) for each:',
          vehicleDetails: 'driving an average of {miles} miles per day',
          includeChargerCosts: 'Include Charger Costs',
          includeChargerInstallationCosts: 'Include Charger Installation Costs'
        },
      },
      panels: {
        warning: 'Warning!',
        insufficientChargeWarning: 'The following vehicles deplete their batteries:',
        insufficientChargeCTA:
          'Please Edit Inputs to add more charging times, increase the charging levels, and/or decrease the daily miles.',
        overview: {
          payback: {
            noBreakeven: 'No Breakeven',
            noBreakevenDetails:
              'Due to factors such the cost of vehicles, chargers, charger installation and the operational lifespan the cost of electric vehicles remains higher than that of the cost of fossil fuel vehicles',
            years: 'After {value} years',
            year: 'After {value} year',
            summary:
              'the cumulative cost of electric vehicles is expected to become cheaper than the cost of an equivalent fossil fuel fleet.',
          },
          lifetime: {
            look: "You're looking at ",
            savings: '{value} in potential savings ',
            loss: 'the electrified vehicles costing {value} more',
            overTime: 'over the life of the vehicles.',
          },
          overTimeCosts: 'Cumulative costs over time',
          barChart: 'Bar Chart',
          waterfall: 'Waterfall',
          fuel: 'Fuel',
          chargers: 'Chargers',
          resale: 'Resale Value',
          incentives: 'Incentives',
          operations: 'Operations',
          vehicles: 'Vehicles',
          grants: 'Incentives',
          lcfs: 'LCFS',
          infrastructure: 'Infrastructure',
          total: 'Total',
          electric: 'Electric Vehicle(s)',
          fossil: 'Diesel Vehicle(s)',
          savings: 'EV Savings',
          costs: 'Total Costs',
          savingsChart: 'Savings from EV vs Fossil Fuel',
          evCheaper: 'Electric Vehicle',
          fossilCheaper: 'Fossil Fuel',
          evSavings: 'Ev Savings',
          fossilVeh: 'Fossil Fuel Vehicles',
          electricVeh: 'Electric Vehicles',
          maintenance: 'Maintenance',
          insurance: 'Insurance',
          purchase: 'Purchase',
          installation: 'Installation',
          na: 'N/A',
          fossilFuelAlternative: 'Fossil Fuel Alternative',
          electricFleet: 'Electric Fleet',
          selectVehicle: 'Select Vehicle',
          selectedVehicle: 'Selected Vehicle',
          replacementVehicle: 'Replacement Vehicle',
          standardVehicleSpecs: 'Standard Vehicle Specs',
          weekdays: 'Weekdays',
          table: {
            msrpValue: '$ {value}',
            msrp: 'MSRP',
            drivetrainValue: '{value}',
            cargoSpaceDetail: '{value} Cubic Feet',
            expectedWorkloadFooter: 'Standard Vehicle Specs',
            vehicleSpecsFooter: 'All vehicle specs and comparisons are based on publicly available 3rd party data provided by ZappyRide.',
            vehicleSpecs: 'Vehicle Specs',
            range: 'EPA Estimated Range',
            eRange: 'EPA Estimated Range on Full Charge',
            cargoSpace: 'Cargo Space',
            drivetrain: 'Drivetrain',
            mpg: 'Fossil Fuel MPG',
            eMpg: 'Fossil Fuel MPG Equivalent',
            cargo: 'Cargo Space',
            specsFooter: 'All vehicle specs and comparisons are based on publicly available 3rd party data provided by ZappyRide.',
            runtime: 'Runtime',
            battery: 'Battery Capacity',
            fuel: 'Fuel Capacity',
            efficiency: 'Fuel Efficiency',
            expectedWorkload: 'Expected Workload',
            chargingWindows: 'Charging Window(s)',
            workdays: 'Workdays',
            typicalWorkday: 'Typical Workday',
            heavy: 'Heavy: {value} {unit}',
            medium: 'Medium: {value} {unit}',
            light: 'Light: {value} {unit}',
            miles: '{value} Miles',
            heavyTooltip: 'Heavy energy use activities would include tilling.',
            mediumTooltip:
              'Medium energy use activities would include mowing, and heavy loader work.',
            lightTooltip:
              'Light energy use activities would include towing small trailers, and light loader work.',
            units: {
              kw: 'kW',
              kWh: 'kWh',
              hours: 'hours',
              kwHr: 'kWh/hr',
              gasHr: 'gals/hr',
              gallons: 'gallons',
              mpg: 'MPG ({fuel})',
              eMpg: 'MPGe'
            }
          },
          tooltips: {
            cost: 'Fuel costs over the lifetime of the project',
            incentives:
              'Incentives reducing the cost of vehicle or charger purchase or charger installation costs. Detailed incentive estimates are provided in the incentives tab above.',
            resale: 'Resale value of vehicles at the end of the last year of operation.',
            purchase: 'Purchase cost of vehicles',
            insurance:
              'Insurance cost based on the type of vehicle (e.g. sedan, cargo van, box truck), MSRP and state. Includes liability insurance and $500,000 comprehensive & collision insurance).',
            maintenance:
              'Maintenance cost is based on based on vehicle type (e.g. sedan, cargo van, box truck…) and fuel (electricity, gasoline or diesel). Maintenance costs increase over time, as vehicles get older.',
            lcfs: 'Low Carbon Fuel Standard revenues. Applies only to projects in California. Projects can receive carbon credits based on their kWh of electricity used for electric vehicles, grid intensity (CO₂ emissions per kWh) and the estimated LCFS credit price.',
            grants:
              'Incentives reducing the cost of vehicle or charger purchase or charger installation costs. Detailed incentive estimates are provided in the incentives tab above.',
            installation:
              'The cost of Behind the Meter installation as defined in site location assumptions in the left column.',
          },
        },
        fuel: {
          fordTransit: 'Ford Transit Connect',
          yearly: 'Annual Fuel Costs',
          eFleet: 'Electric Vehicle Fleet',
          eVehicles: 'Electric Vehicles',
          gFleet: 'Gasoline Fleet',
          fFleet: 'Fossil Fuel Fleet',
          fVehicles: 'Fossil Fuel Vehicle',
          yourSelect: 'Your selection',
          similar: 'Similar fossil fuel vehicle',
          chartSummary:
            'Based on your selections, fuel costs of your electric fleet are expected to be {value} less per year than its fossil fuel equivalent.',
          electricity: 'Electricity',
          gas: 'Gasoline',
          set: 'Vehicle Set',
          count: 'Count',
          efficiency: 'Efficiency {units}',
          hours: 'Hours',
          perDay: 'per day',
          perYear: 'per year',
          fuelCost: 'Fuel Cost {units}',
          cost: 'Estimated Cost',
          annualTotal: 'Annual Total',
          lifetime: 'Vehicle Lifetime Total',
          fossilFleet: 'Fossil Fuel Vehicle Fleet',
          electricFleet: 'Electric Vehicle Fleet',
          annualCosts: 'Annual Fuel Costs',
          gasPriceSource: 'Gasoline Price Source: ',
          electricPriceSource: 'Electricity Price Source: ',
          priceSourceNote:
            'Note: Annual and lifetime costs based on assuming a year has up to 52 weeks of driving, with each week following the same usage pattern entered. If vehicles operate part of the year, we scale down the weeks driven to match.',
          usEnergy: 'US Energy Information Administration',
          reg: 'Regular',
          commercial: 'Commercial Rate',
          year: 'Year',
          fossilFleetCost: 'Fossil Fuel Fleet Fuel Cost',
          electricFleetCost: 'Electric Fleet Fuel Cost',
          total: 'Total',
          savings: 'Savings',
          totalCosts: 'Total Costs',
          totalNumberHours: 'Total mileage',
          costPerMile: 'Fuel Cost per Mile',
          miles: 'Miles',
          table: {
            electricHours: '(kWh/hr)',
            electricDollars: '($/kWh)',
            gasUnits: '(Miles/Gallon)',
            gasDollars: '($/Gal)',
            toolTip: '{total} hours per year',
            heavyEfficiency: 'Heavy: {value}',
            mediumEfficiency: 'Medium: {value}',
            lightEfficiency: 'Light: {value}',
            mpg: '(MPGe)',
            heavyEfficiencyTooltip: 'Heavy energy use activities would include tilling.',
            mediumEfficiencyTooltip:
              'Medium energy use activities would include mowing, and heavy loader work.',
            lightEfficiencyTooltip:
              'Light energy use activities would include towing small trailers, and light loader work.',
          },
          toolTips: {
            fordTransitConnect: 'Mileage is based on your specified daily miles driven, days and months of operation for this vehicle set.',
          }
        },
        electricity: {
          infoMessage:
            'Your fleet is estimated draw a peak of {peak} and use {kWh} of electricity per month.',
          chargingLoads: 'Charging Equipment Loads',
          warningAlertBold:
            "Your site's load profile is estimated to exceed {peak}kWh, which exceeds 200kWh, a typical capacity constraint.",
          warningAlert:
            "Contact your utility's advisory services to discuss how this may impact your site and begin working towards any potential upgrades.",
          chargingLoadsSummary:
            'Your charging schedule is expected to increase the electricity load on your site.',
          loadProfile: 'Site Load Profile',
          chartTitle: 'Site load profile (kWh)',
          loadSummary:
            "The site's electricity load will be the aggregate of all vehicles and their charging patterns, as shown for each weekday:",
          loadSummaryDisclaimer: "Estimation assumes unmanaged charging. The site's electricity load will be the aggregate of all vehicles and their charging patterns, as shown for each weekday:",
          note: 'Note that peak usage is expected to occur on Tuesday, Wednesday, Friday, and Sunday.',
          breakdown: 'Electricity Rate Breakdown',
          breakdownSummary:
            'The total monthly cost would be {value}, which includes the cost to recharge to full and the subscription charges.',
          overage: 'Overage',
          sub: 'Subscription',
          energy: 'Energy',
          footerNote: 'Note that peak usage is expected to occur on Tuesday, Wednesday, Friday, and Sunday.'
        },
        comparison: {
          header: 'Vehicle Comparison',
          subheader:
            'Select vehicle set to see a side by side comparison of fossil fuel and electric equivalent vehicles.',
          dropdownLabel: 'Vehicle Set',
          gasoline: 'Gasoline',
          diesel: 'Diesel',
          batteryElectric: 'Battery Electric',
          electric: 'Electric',
          standardVehicleSpecs: 'Standard Vehicle Specs',
          fuel: {
            header: 'Potential Fuel Cost',
            annualFossilCost: 'Potential Annual Fossil Fuel Cost',
            annualElectricCost: 'Potential Annual Electric Cost',
            fossilPerHourCost: 'Fossil Fuel Cost Per Hour',
            electricPerHourCost: 'Electricity Cost Per Hour',
            hoursPerYear: 'Total Hours Per Year',
            fossilAssumptions: 'Fossil Fuel Cost Assumptions',
            electricAssumptions: 'Electricity Cost Assumptions',
            avgGasolineCost: 'Average cost of diesel for {state}',
            avgDieselCost: 'Average cost of diesel for {state} - {price} - Source: {source}',
            avgElectricityCost:
              'Average cost of electricity for {state} - {price} - Source: {source}',
            avgGasolineCostNational: 'Federal average cost of gasoline',
            avgDieselCostNational: 'Federal average cost of gasoline',
            avgElectricityCostNational: 'Federal average cost of electricity',
            vehicleSpecs: 'Vehicle Specs',
            runtime: 'Runtime',
            battery: 'Battery Capacity',
            efficiency: 'Fuel Efficiency',
            expectedWorkload: 'Expected Workload',
            chargingWindows: 'Charging Windows',
            workdays: 'Workdays',
            typicalWorkday: 'Typical Workday',
            heavy: 'Heavy: {value} {unit}',
            medium: 'Medium: {value} {unit}',
            light: 'Light: {value} {unit}',
            heavyTooltip: 'Heavy energy use activities would include tilling.',
            mediumTooltip:
              'Medium energy use activities would include mowing, and heavy loader work.',
            lightTooltip:
              'Light energy use activities would include towing small trailers, and light loader work.',
            units: {
              kw: 'kW',
              hours: 'hours',
              kwHr: 'kWh/hr',
              gasHr: 'gals/hr',
            },
            fuelEfficiency: '{value} MPG {fuel}',
            gasolineCostPerMile: 'Expected Gasoline Cost Per Mile',
            gasolineCostPerMileValue: '$ {value}',
            totalMilesPerYear: 'Total Miles Per Year'
          },
          electricEV: {
            mpge: 'MPGe',
            fuelEfficiency: '{value} MPGe',
            electricityCostPerMile: 'Expected Electricity Cost Per Mile',
            electricityCostPerMileValue: '$ {value}'
          },
          incentives: {
            header: 'Incentives',
            capitalIncentives: 'Capital Incentives',
            ongoingPerMile: 'Ongoing Incentives per mile',
          },
          specs: {
            header: 'Specs',
            cargo: 'Cargo Capacity',
            seating: 'Seating',
            interiorHeight: 'Interior Height',
            payload: 'Payload',
            headroom: 'Headroom',
            legroom: 'Legroom',
            drivetrain: 'Drivetrain'
          },
        },
        incentives: {
          title: 'Potential Eligible Incentives',
          subtitle: 'This project may potentially be eligible for the following incentives.',
          alert:
            'These are potentially eligible incentives based on the information you entered. Please be aware you must apply directly with the organization referenced and confirm eligibility and amount directly with that organization.    Funding amounts estimated here are estimates only and are not guaranteed by BrightDrop or its dealers.',
          noIncentives: "Sorry, we couldn't find any incentives for your location.",
          multipleSitesAlert:
            'Incentives are not analyzed when purchasing for multiple sites. To see incentives, uncheck "purchasing for multiple sites" in the Assumptions Inputs and enter a zipcode.',
          status: 'Status / {status}',
          eligibleStatus: 'Eligible',
          ineligibleStatus: 'Ineligible',
          hiatusStatus: 'Hiatus',
          closedStatus: 'Closed',
          waitlistStatus: 'Waitlist',
          ineligible: {
            header: 'Ineligible Incentives',
            subheader: 'Your project is ineligible for the incentives below.',
          },
          details: 'Details',
          fundingBreakdown: 'Funding Breakdown',
          eligibilityAnalysis: 'Project Eligibility Analysis',
          more: 'more',
          closingDate: 'Closing Date',
          typicalAmount: 'Typical Amount',
          organizations: 'Organizations',
          totalAvailable: 'Total Available',
          fundingToDate: 'Funding To Date',
          applicability: 'Applicability',
          summarizedBy: 'summarized by',
          applyNow: 'Apply Now',
          learnMore: 'Learn More',
          na: 'N/A',
          all: 'All',
          accessRequired: 'Access required to continue',
          contactBrightDrop: 'Contact BrightDrop sales to request access and see eligible incentives',
        },
        charging: {
          barChart: 'Bar chart',
          waterfall: 'Waterfall',
          header: 'Charging and Facility Needs',
          total: 'Total charger cost is ',
          tooltip: 'Total estimated charger cost is based on the cost of the charger multiplied by the number of chargers needed to support your fleet. Assumes 1 charger per vehicle is required.',
          tooltipInstallation: 'The cost of installing the chargers including panels, trenching and cabling etc... from the meter to the charger. Based on CALeVIP reported numbers, the weighted average cost to install each level 2 charger port is $6,266 and each DC Fast Charger port is $79,534. Source: California Energy Commission, July 2022',
          alert: 'Total estimated charger cost is',
          alertDisclaimer: '(use the installation cost from the financials table)',
          alertInstallation: 'Total estimated charger installation cost is',
          preferred: '- Preferred',
          chargerAssignment: 'Charger assignment',
          vehicles: '{value} Vehicles',
          typicalWorkday: 'Typical Working Day',
          infoCard: {
            change: 'Change',
            warning: "Doesn't meet kW output required",
            success: 'Meets kW output required',
            connectivity: 'Connectivity',
            estimated: 'Estimated Cost',
            type: 'Plug Type(s)',
            cable: 'Cable Length',
            cableFt: '{value} ft',
            ports: 'Ports',
            form: 'Form Factor',
            outlet: 'Outlet(s)',
            ocpp: 'OCPP',
            oscp: 'OSCP',
            vehicleGrid: 'Vehicle to Grid',
            warranty: 'Warranty',
            warrantyYrs: '{value} years',
            certs: 'Certifications',
            generic: 'Generic',
            dualPort: 'Dual Port',
            edit: 'Change',
          },
          chargerType: 'Charger Type',
          vehicle: 'Vehicle',
          time: 'Time',
          singlePort: 'Single Port',
          dualPort: 'Dual Port',
          minimumReq: 'Minimum req: {value}',
          overTime: 'Estimated State of Charge (SOC) Over Time',
          overTimeSubTitle: 'Charging to 80% or less for daily use helps promote the long-term health of the battery and allows you to recharge by taking advantage of available energy from regenerative braking. Regenerative braking is limited above 80% and unavailable at 100% charge. For longer trips, you can adjust your charge level to 100% to extend range as needed.',
          peak: 'Peak Mileage',
          average: 'Average Mileage',
          selectSet: 'Vehicle Set',
          ev: 'EV: {vehicleName}',
          replaces: 'Replaces: {vehicleName}',
          chargeState: 'Battery Charge State',
          note: 'Note: The graph assumes daily mileage is averaged across all hours of the day when the vehicle is not available to charge. Actual mileage and battery state will differ.',
          changeWindow:
            'Over the course of a day your vehicle is expected to reach a minimun state of charge 13% with 23 miles remaining. Consider changing your charging window, or selecting a more powerful charger',
          chargeWarning:
            'Over the course of a day your vehicle will hit a minimum charge of {charge}.',
        },
        lcfs: {
          infoMessage:
            'Switching to electricity will also allow you to generate Low Carbon Fuel Standard (LCFS) credits, which could generate {perYear}',
          infoMessageNonCali:
            'This project is not in California so does not qualify for Low carbon Fuel Standard (LCFS) credits',
          perYear: '{value} per year.',
          estimatedRevenues: 'Estimated LCFS Revenues',
          subtitle: 'Assumed LCFS Credit Price: {price}',
        },
        operations: {
          annualMaintenanceCosts: 'Annual Maintenance Costs',
          milesValue: '$/mi cost:',
          annualInsuranceCosts: 'Annual Insurance Costs',
          alertTab:
            'Estimated Maintenance costs include routine periodic maintenance. Excludes battery replacement.',
          electric: 'Electric Vehicles',
          fossil: 'Fossil Fuel Vehicles',
          graphTitle: 'Estimated Annual Maintenance Costs',
          graphTitle2: 'ESTIMATED ANNUAL INSURANCE COSTS',
          tableTitle1: 'Electric Vehicle Fleet',
          tableRows: {
            costPerHour: 'Maintenance Cost Per Hour',
            costPerMile: 'Estimated Average Maintenance Cost Per Mile per Vehicle',
            costPerYear: 'Estimated Average Annual Maintenance Cost, All Vehicles',
            totalCost: 'Total Maintenance Cost, All Vehicles, Lifetime',
          },
          tableTitle2: 'Fossil Fuel Vehicle Fleet',
          tableDesc: 'Cost based on {link}',
          tableDescLink: 'manufacturers’ suggested maintenance activities.',
          insuranceSummary:
            'The cost to insure a vehicle depends on the vehicle purchase price and the state.',
          accordeon: {
            title: 'Estimated Maintenance Cost Assumptions',
            subTitle: 'Annual per-mile maintenance costs are modeled as increasing linearly over time, derived from both the years of operation and annual mileage driven. Per-mile maintenance costs for specific years can be viewed by hovering over the graph above.',
            exampleTitle: 'Example Vehicle Set 1',
            bullet1: '{count}x Medium Duty Cargo Vans being driven {annualMiles} total miles per year',
            bullet2: '{evCost} per mile for electric passenger SUVs',
            bullet3: '{iceCost} per mile for fossil fuel passenger SUVs',
            source: 'SOURCE: Derived from {link} and manufacturers figures (where available).',
            link: 'AFLEET 2020',
            methodologyExample: 'METHODOLOGY EXAMPLE:',
            lightDieselTitle: 'Light-Duty Diesel Step Vans',
            lightDieselText: 'Cost $/mi-yr = Annual Miles / 16500 * C * year # * 0.0232 (slope) + 0.0158 (intercept) * (1.05)^(year # - 1)',
            lightElectricTitle: 'Light-Duty Electric Step Vans',
            lightElectricText: 'Cost $/mi-yr = Annual Miles / 16500 * C * year # * 0.0157 (slope) + 0.0107 (intercept) * (1.05)^(year # - 1)',
            slopeDesc: 'The slope and intercept vary for other vehicle types, duties, and fuel types. C is 1.5, 0.75, 0.6, or 0.25 if vehicle drives ~10k, ~15k, ~20k, or ~25k or more miles per year, respectively. ',
            perMileOne: 'per mile for year 1 of operation',
            perMileLife: 'per mile for year {lifetime} of operation',
            yearToYear: 'Year-to-year maintenance cost increase of {value}',
            totalMaintenance: 'Total maintenance cost of: {value}',
          },
          accordeon2: {
            title: 'Estimated Insurance Cost Assumptions',
            subTitle: 'Insurance Costs',
            insuranteEstimates: 'Insurance cost amounts are estimates. These estimates are based on {link} data.',
            link: 'AFLEET 2020',
            listTitle: 'Liability insurance is derived from a function of vehicle type and state of operation.',
            bullet1: 'For cars, pickups, cargo vans, step vans, utility trucks, specialty vehicles and cutaway chassis 50/100/50 liability insurance is assumed.',
            bullet2: 'For school buses, transit buses shuttle buses, and motor coaches $2M Combined Single Limit (CSL) insurance is assumed',
            bullet3: 'For box trucks, BEV drive systems, and refuse trucks $500,000 CSL insurance is assumed',
            bullet4: 'For trailer trucks $1M CSL is assumed',
            comprehensiveInsurante: 'Comprehensive and Collision insurance - is derived from the vehicle type, state of operation and MSRP. This assumes $500k comprehensive / collision coverage.',
          }
        },
        emissions: {
          alert: 'These emission figures refer to tailpipe emissions, and do not reflect the entire supply chain.',
          summary: {
            co2SavedPerYear: 'Of CO₂ Potentially Saved Per Year',
            co2SavedLifetime: 'Of CO₂ Potentially Saved Over Vehicle Lifetime',
            treesPlantedPerYear: 'Equivalent Number of Trees Planted Per Year',
            treesPlantedLifetime: 'Equivalent Number of Trees Planted Over Vehicle Lifetime',
            emissionFigures: 'These emission figures refer to tailpipe emissions, and do not reflect the entire supply chain.'
          },
          table: {
            item: 'Item',
            diesel: 'Diesel',
            gasoline: 'Gasoline',
            lpg: 'LPG',
            annualMiles: 'Annual Miles',
            fossilFuelVolume: 'Fossil Fuel Volume',
            fossilFuelEmissions: 'Fossil Fuel CO₂ Emissions',
            co2EmissionsByFuel: 'CO₂ Emissions by Fossil Fuel',
            treesPlanted: 'Trees Planted (Equivalency)',
            totalCo2Emissions: 'Total CO₂ Emissions from Fossil Fuel',
            electricityEquivalent: 'Electricity Equivalent',
            electricityEmissions: 'Electricity Emissions',
            co2EmissionsFromCharging: 'Total CO₂ Emissions from EV charging',
            co2EmissionsReduced: 'CO₂ Emissions Reduced',
            co2EmissionsGhg: 'CO₂ to GHG conversion',
            ghgEmissionsReduced: 'GHG Emissions Reduced',
            ghgEmissionsReducedSimplified: 'GHG Emissions Reduced (Simplified)',
            co2EmissionsFrovEv: 'Total CO₂ Emissions from EV charging',
            californiaEletricityEmissions: 'California Electricity CO₂ Emissions',
            units: {
              miles: '{value} miles',
              hours: '{value} hours',
              gallons: '{value} Gal',
              co2PerGallon: '{value} Lbs CO₂ / Gal',
              lbs: '{value} Lbs',
              tons: '{value} Tons',
              lbsCo2: '{value} Lbs CO₂',
              mwh: '{value} MWh',
              lbsPerMwh: '{value} Lbs CO₂/MWh',
              trees: '{value} Trees',
              reducedSimplifiedlbsCo2:'{value} Tons GHG',
              ghgEmissionsReducedLbsCo2: '{value} Lbs GHG',
              co2EmissionsReduced: '{value} Lbs CO₂',
              lbsMwh: '{value}  Lbs / MWh'
            },
            notes: {
              title: 'Notes',
              annualMiles: 'The total number of miles driven annually by your fleet.',
              fossilFuelVolume: `For each vehicle set, we applied each vehicle’s efficiency to the annual miles driven for that usage level`,
              fossilFuelVolume2:
                "Source: U.S. Energy Information Administration",
              fossilFuelEmissions: 'Source: U.S. Energy Information Administration',
              co2EmissionsByFuel: '"Fossil Fuel Volume" * "CO₂ Emissions In Lbs/Gal"',
              totalCo2Emissions:
                '"Diesel CO₂ Emissions" + "Gasoline CO₂ Emissions" + "LPG CO₂ emissions"',
              coEmissionsReduced:
                '"Total Emissions from Fossil Fuels" - "CO₂ Emissions for Equivalent EVs"',
              emissionFromEvCharging:
                '"Electricity Equivalent" * "Electricity Emissions"',
              conversionBasedOnGlobal:
                'Conversion based on global warming potential of the primary greenhouse gases',
              electricityEquivalent:
                'The amount of electricity required to replace the selected fleet of fossil fuel vehicles one-to-one with electric vehicles.',
              electricityEmissions:
                'The amount of CO₂ emitted per MWh of electricity generated, based on your zip code.',
              co2EmissionsFromCharging: '"Electricity Equivalent" * "Electricity Emissions"',
              sourceEnergyInformation: 'Source: U.S. Energy Information Administration',
              coEmissedReducedGhg: 'CO₂ Emissions Reduced converted to GHG',
              usTonEquivalent: 'A US ton is equivalent to 2,000 lbs',
              co2EmissionsFrovEv: 
              '"Electricity Equivalent" * "Electricity Emissions"',
              co2EmissionsReduced: '"Total CO₂ Emissions from Fossil Fuels" - "Total CO₂ Emissions from EV Charging"',
              co2EmissionsGhg: 'Conversion based on global warming potential of the primary greenhouse gases',
              ghgEmissionsReduced: 'CO₂ Emissions Reduced converted to GHG',
              ghgEmissionsReducedSimplified: 'A US ton is equivalent to 2,000 lbs',
              treesPlanted: 'Assumes a tree absorbs 48 pounds of CO₂ per year.',
              treesPlantedLink: 'Source: European Environment Agency'
            },
          },
        },
        financials: {
          summary: {
            afterTitle: 'After {years} year{plural}',
            afterDesc:
              'The cumulative cost of electric vehicles is expected to potentially become cheaper than the cost of an equivalent fossil fuel fleet.',
            savingsTitle: '{value} in potential savings',
            savingsPre: 'You’re looking at',
            savingsDesc: 'over the life of the vehicles.',
            noBreakevenTitle: 'No Breakeven',
            noBreakevenTxt: 'The cumulative cost of electric vehicles is not expected to potentially become cheaper than the cost of an equivalent fossil fuel fleet.',
            noSavings: 'There is no monetary saving resulting from switching to electric vehicles'
          },
          labels: {
            fuel: 'Fuel',
            maintenance: 'Maintenance',
            lcfs: 'LCFS',
            purchase: 'Vehicles',
            incentives: 'Incentives',
            chargers: 'Chargers',
            resale: 'Resale Value',
            installationCosts: 'Installation',
            insurance: 'Insurance',
          },
          CumulativeTitle: 'Cumulative costs over time',
          TotalCostTitle: 'Total Costs',
          eletrictTitle: 'Electric Vehicle Fleet',
          year: 'Year',
          electricVehicles: 'Electric vehicles',
          fossilFuelVehicles: 'Fossil Fuel Vehicles',
          barChart: 'Bar chart',
          waterfall: 'Waterfall',
          table: {
            na: 'N/A',
            eletricTitle: 'Electric Vehicles',
            fossilTitle: 'Diesel Vehicles',
            resale: 'Resale',
            evTitle: 'EV Savings',
            fuel: 'Fuel',
            chargers: 'Chargers',
            operations: 'Operations',
            vehicles: 'Vehicles',
            incentives: 'Incentives',
            lcfs: 'LCFS',
            total: 'Total',
            chargerPurchase: 'Charger Purchase',
            installations: 'Installation',
            maintenance: 'Maintenance',
            insurance: 'Insurance',
            purchase: 'Purchase',
            toolTips: {},
          },
        },
      },
      organizationTypes: {
        car_sharing_companies: 'Car Sharing Companies',
        federal_government_entities: 'Federal Government Entities',
        local_government_entities: 'Local Government Entities',
        logistics_company: 'Logistics Company',
        medium_or_large_businesses: 'Medium Or Large Businesses',
        native_tribes: 'Native Tribes',
        nonprofits: 'Nonprofits',
        private_school_districts: 'Private School Districts',
        public_school_districts: 'Public School Districts',
        rental_companies: 'Rental Companies',
        small_businesses: 'Small Businesses',
        state_government_entities: 'State Government Entities',
        transit_agencies: 'Transit Agencies',
        universities: 'Universities',
        utility: 'Utility',
        other: 'Other',
        farms: 'Farms',
      },
      chargerTypes: {
        level_2: 'Level 2',
        level_3: 'Level 3',
        level_1: 'Level 1',
        dc_fast_charging: 'DCFC',
        AC: 'AC',
        AC_11_52_1: 'AC',
        AC_9_6_2: 'AC',
        AC_9_6_1: 'AC',
        AC_7_7_1: 'AC',
        AC_7_2_1: 'AC',
        AC_9_6_1_wire: 'AC Wire',
        DC_75_1: 'DC',
        DC_75_2: 'DC',
        DC_62_5_1: 'DC',
        DC_60_3: 'DC',
        DC_60_2: 'DC',
        DC_60_1: 'DC',
        DC_50_2: 'DC',
        DC_50_1: 'DC',
        DC_40_1: 'DC',
        DC_120_2: 'DC',
        DC_25_1: 'DC',
        DC_24_3: 'DC',
        DC_24_1: 'DC',
        DC_120_1: 'DC',
        DC_100_2: 'DC',

        unknown: 'Unknown',
      },
      timePeriods: {
        days: {
          weekdays: {
            long: 'Weekdays',
            abbreviated: 'Weekdays',
          },
          weekends: {
            long: 'Weekends',
            abbreviated: 'Weekends',
          },
          0: {
            long: 'Sunday',
            abbreviated: 'Sun',
          },
          1: {
            long: 'Monday',
            abbreviated: 'Mon',
          },
          2: {
            long: 'Tuesday',
            abbreviated: 'Tue',
          },
          3: {
            long: 'Wednesday',
            abbreviated: 'Wed',
          },
          4: {
            long: 'Thursday',
            abbreviated: 'Thu',
          },
          5: {
            long: 'Friday',
            abbreviated: 'Fri',
          },
          6: {
            long: 'Saturday',
            abbreviated: 'Sat',
          },
        },
        months: {
          0: {
            long: 'January',
            abbreviated: 'Jan',
          },
          1: {
            long: 'February',
            abbreviated: 'Feb',
          },
          2: {
            long: 'March',
            abbreviated: 'Mar',
          },
          3: {
            long: 'April',
            abbreviated: 'Apr',
          },
          4: {
            long: 'May',
            abbreviated: 'May',
          },
          5: {
            long: 'June',
            abbreviated: 'Jun',
          },
          6: {
            long: 'July',
            abbreviated: 'Jul',
          },
          7: {
            long: 'August',
            abbreviated: 'Aug',
          },
          8: {
            long: 'September',
            abbreviated: 'Sep',
          },
          9: {
            long: 'October',
            abbreviated: 'Oct',
          },
          10: {
            long: 'November',
            abbreviated: 'Nov',
          },
          11: {
            long: 'December',
            abbreviated: 'Dec',
          },
        },
        years: '{years} years',
        workdays: {
          weekdays: 'Weekdays',
          weekends: 'Week',
        },
        labels: {
          month: 'Month',
          day: 'Day',
          hour: 'Hour',
        },
      },
      chargingWindows: {
        chargingTimes: {
          ...times(24, (i) => moment(i, 'H').format('h a')),
        },
        access: {
          private: 'Private [Fleet owned]',
          open: 'Public [Fleet Owned]',
          tooltip: 'We ask you about charger access as there are some incentives that require chargers are accessible to the public, and other incentives award higher rebates to publicly accessible chargers.Private [Fleet owned]- a charger at your depot or on your private premises that is not accessible to the public to charge their vehicles. Public [Fleet owned] - a charger you own that you let the public use to charge their vehicles (can make you eligible for more incentives and/or incentives may be higher amounts)'
        },
        times: {
          start: 'Start Time',
          end: 'End Time',
          type: 'Charger Type',
          add: 'Add Charging Window',
          output: 'Type/Output',
          access: 'Charger access'
        },
        hoursPerDay: '{value} hours per day',
        drivingHours:
          '{miles} miles per day',
        recommendation:
          'A charger has been recommended assuming a {timeRange} charging window for your {vehicleName} vehicles operating {drivingHours} that require charging {chargeAmount} in {numHours}. With this configuration, the minimum state of charge will be {minStateOfCharge}.',
      },
      findYourRep: 'Contact a BrightDrop Sales Consultant',
      externalSiteDialog: {
        title: 'Leaving',
        message:
          "Are you sure you want to navigate away from BrightDrop's website? BrightDrop holds no liability for content outside its website.",
        cancel: 'Cancel',
        continue: 'Continue',
      },
      footer: {
        poweredBy: 'powered by',
        copyrightAndTrademark: {
          text: 'Copyright & Trademarks',
          url: 'https://www.gm.com/copyright-trademark',
        },
        userGuidelines: {
          text: 'User Guidelines',
          url: 'https://www.gobrightdrop.com/user-guidelines.pdf',
        },
        privacyPolicy: {
          text: 'Privacy Policy',
          url: 'https://www.gm.com/privacy-statement',
        },

        disclaimer:
          'Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up to date costs, hardware specifications, and incentives information.',
        copyright: '© {year} All rights reserved.',
      },

      perMileFor: 'per mile for',
      fossilFuel: 'Fossil Fuel',
      electric: 'Electric',
    },
  },
};

export default translations;
