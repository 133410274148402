import { Dialog, DialogContent, DialogTitle, DialogActions, useMediaQuery } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ActionButtons } from './ActionButtons';
import CatalogHeader from './CatalogHeader';
import VehicleCatalog from './VehicleCatalog';

export default function VehicleCatalogDialog({ isOpen, onClose, onChangeVehicle, vehicleSet }) {
  const [selectedVehicle, setSelectedVehicle] = useState(vehicleSet.replacementVehicle);

  useEffect(() => {
    setSelectedVehicle(vehicleSet.replacementVehicle);
  }, [vehicleSet.replacementVehicle]);

  const handleSelect = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={isLargeScreen ? 'xl' : 'sm'}
      onBackdropClick={onClose}
    >
      <DialogTitle>
        <CatalogHeader onClose={onClose} vehicleSet={vehicleSet} />
      </DialogTitle>
      <DialogContent px="48px">
        <VehicleCatalog
          vehicleSet={vehicleSet}
          initialType={vehicleSet.existingVehicle.type}
          selectedVehicle={selectedVehicle}
          onSelect={handleSelect}
        />
      </DialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <ActionButtons onClose={onClose} onSave={() => onChangeVehicle(selectedVehicle)} />
      </DialogActions>
    </Dialog>
  );
}
