import React from 'react';
import { Box, ButtonBase, Collapse, Tab, Tabs, useMediaQuery } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import useTabs from '../tabs/useTabs';
import { CloseIcon, LogoIcon } from '../assets/icons/icons';
import { useMobileMenu } from './MobileMenuProvider';
import GaTracker from '../utils/GaTracker';

export default function Header() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { selectedTab, setInput } = useInputs();
  const tabs = useTabs();
  const { isOpen, setIsOpen } = useMobileMenu();

  const handleClickLogo = () => {
    GaTracker.trackEvent({
      category: "Lead Generation",
      action: "Logo Header",
      label: "Click Logo"
    });
  }

  return (
    <Box>
      <Box
        sx={{
          height: '72px',
          px: '16px',
          backgroundColor: 'white',
          width: '100%',
          position: 'relative',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            height: '72px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            m: '0 auto',
            px: isMobile ? '5px' : '24px',
            width: '100%',
            maxWidth: (theme) => theme.settings.maxWidth.header,
          }}
        >
          <Box display="flex" alignItems="center" gap="16px">
            <a href="https://www.gobrightdrop.com/" target="_blank" rel="noreferrer" onClick={handleClickLogo}>
              <LogoIcon />
            </a>
          </Box>
          <Box>{/* MENU? */}</Box>
        </Box>
      </Box>
      {isMobile && (
        <Collapse
          in={isOpen}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 100,
            padding: '24px',
            backgroundColor: (theme) => theme.palette.common.white,
          }}
        >
          <Box maxWidth="340px" m="0 auto">
            <Tabs
              value={selectedTab}
              onChange={(_, newTab) => {
                setInput({ selectedTab: newTab });
                setIsOpen(false);
              }}
              orientation="vertical"
              sx={{
                minHeight: 'auto',
                '&.MuiTabs-indicator': {
                  backgroundColor: (theme) => `linear-gradient(#0072CE, #38E8FF) !important`,
                  zIndex: 1,
                },
                '&.MuiTabs-flexContainer': {
                  justifyContent: 'space-between',
                },
              }}
              TabIndicatorProps={{
                sx: {
                  left: 0,
                  right: 0,
                  width: 'unset',
                  backgroundColor: 'unset',
                  borderBottom: (theme) => `3px solid ${theme.palette.primary.main}`,
                },
              }}
            >
              {tabs.map(({ label }, index) => {
                return (
                  <Tab
                    sx={(theme) => ({
                      fontSize: '16px',
                      textTransform: 'unset',
                      borderBottom: `1px solid ${theme.palette.common.border}`,
                      py: '20px',
                      '&.Mui-selected': {
                        fontWeight: 700,
                        color: 'inherit',
                        zIndex: 2,
                      },
                    })}
                    label={label}
                    value={index}
                    key={index}
                    disableRipple
                  />
                );
              })}
            </Tabs>
            <Box display="flex" justifyContent="center" alignItems="center" mt="48px">
              <ButtonBase
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '48px',
                  width: '48px',
                  borderRadius: '50%',
                  border: (theme) => `1px solid ${theme.palette.common.border}`,
                }}
                onClick={() => setIsOpen(false)}
              >
                <CloseIcon />
              </ButtonBase>
            </Box>
          </Box>
        </Collapse>
      )}
    </Box>
  );
}
