import React from "react";
import { Box, useTheme } from "@mui/material";
import StyledBarGraph from "../../components/charts/StyledBarGraph";
import { useIntl } from "react-intl";
import { times } from "lodash";
import { useInputs } from "@bellawatt/use-inputs";
import { formatHours } from "../../utils/formatters";
import { useCalculations } from "../../utils/useCalculations";

export default function SiteLoadChart() {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { vehicleSets } = useInputs();
  const Calculations = useCalculations();

  const labels = times(24);

  const load = vehicleSets.map((set) => {
    return Calculations.annualLoadProfile({
      input: set,
      includePersonal: set.personalMilesPaidFor,
    });
  });

  const getBackgroundColor = (index) => {
    // eslint-disable-next-line default-case
  switch (index % 5) {
    case 0:
      return "#0072CE";
    case 1:
      return "#05C3DD";
    case 2:
      return "#6CC24A";
    case 3:
      return "#F0B323";
    case 4:    
      return "#E35205";
    default:
      return "#0072CE";
    }
  };

  const datasets = vehicleSets.map((set, index) => {
    const data = {
      label: set.replacementVehicle.name,
      fill: false,
      data: labels.map((hour) =>
        load[index].filterBy({ hourStarts: [hour] }).max()
      ),
      backgroundColor: getBackgroundColor(index),
      barThickness: 31,
    };
    return data;
  });

  const chargeData = {
    labels,
    datasets,
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[800],
    callback: function (value) {
      if (value % 2 === 0) {
        return formatHours(value);
      }
    },
    font: {
      size: 11,
      weight: "bold",
    },
  };

  const yTicks = {
    min: 0,
    stepSize: 100,
    color: theme.palette.grey[800],
    padding: 20,
    font: {
      size: 11,
    },
  };

  return (
    <Box height="400px" maxWidth="890px">
      <StyledBarGraph
        data={chargeData}
        yTicks={yTicks}
        xTicks={xTicks}
        yTitle={formatMessage({ id: "panels.electricity.chartTitle" })}
        showVerticalGrid
        stacked
        tooltipTitleFormatter={(data) => formatHours(Number(data[0].label))}
        tooltipLabelFormatter={(data) =>
          `${data.dataset.label}: ${data.raw.toFixed(2)} kWh`
        }
      />
    </Box>
  );
}
