import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Box} from "@mui/material";
import { useIntl } from "react-intl";
import Link from "../../components/Link";
import { formatAsThousands } from "../../utils/formatters";
import { useInputs } from "@bellawatt/use-inputs";
import { useCalculations } from "../../utils/useCalculations";

const FossilFuelValue = 22.45;

const StyledCell = styled(TableCell)(({ theme }) => ({
  border: "none",
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(2)} 0`,
  "th&td": {
    fontWeight: 300,
    fontSize: "14px",
    color: "#3D3935",
  },
}));

const StyledRow = styled(TableRow)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.border}`,
  "&:last-child": {
    borderBottom: `1px solid ${theme.palette.common.border}`,
  },
}));

export default function EmissionsTable() {
  const Calculations = useCalculations();
  const { formatMessage } = useIntl();

  const { vehicleSets, electricityEmissions, lifespanYears } = useInputs();
  const emissionsData = Calculations.emissionsData({
    input: vehicleSets,
    electricityEmissions,
    lifespanYears,
  });

  const emissionsDataPerSet = vehicleSets.map((set) => {
    const {
      totalDieselMiles,
      totalDieselEmissions,
      fossilFuelVolume,
      totalMwh,
    } = Calculations.emissionsData({
      input: [set],
      electricityEmissions,
      lifespanYears,
    });

    return {
      totalDieselMiles: totalDieselMiles * set.vehicleCount,
      fossilFuelVolume: fossilFuelVolume * set.vehicleCount,
      c02emissionsByFossil: totalDieselEmissions * set.vehicleCount,
      totalMwh: totalMwh,
    };
  });

  const {
    totalGasolineEmissions,
    electricityEmissionsInLbsPerMwh,
    electricityEmissionsFromEVs,
    totalGasolineMiles,
  } = emissionsData;

  return (
       <Box sx={{
              '@media (max-width: 768px)': {
                     overflow: 'scroll',
              },
       }}>
       <Table>
       <TableHead>
              <StyledRow>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.item" })}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.diesel" })}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.gasoline" })}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.notes.title" })}
              </StyledCell>
              </StyledRow>
       </TableHead>

       <TableBody>
              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: "panels.emissions.table.annualMiles" })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              {formatMessage(
                     { id: "panels.emissions.table.units.miles" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.totalDieselMiles,
                     0
                     )
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage(
                     { id: "panels.emissions.table.units.miles" },
                     { value: formatAsThousands(totalGasolineMiles) }
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.notes.annualMiles" })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({
                     id: "panels.emissions.table.fossilFuelVolume",
              })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              {formatMessage(
                     { id: "panels.emissions.table.units.gallons" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.fossilFuelVolume,
                     0
                     )
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell></StyledCell>

              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.fossilFuelVolume",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({
                     id: "panels.emissions.table.fossilFuelEmissions",
              })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              {formatMessage(
                     { id: "panels.emissions.table.units.co2PerGallon" },
                     {
                     value: FossilFuelValue,
                     }
              )}
              </StyledCell>
              <StyledCell>
              {" "}
              {formatMessage(
                     { id: "panels.emissions.table.units.co2PerGallon" },
                     { value: "17.86" }
              )}
              </StyledCell>

              <StyledCell>
              <Link
                     textTransform="none"
                     width="auto"
                     variant="tooltip"
                     to="https://www.eia.gov/electricity/data/emissions/xls/emissions_region2020.xlsx"
                     external
              >
                     {formatMessage({
                     id: "panels.emissions.table.notes.fossilFuelEmissions",
                     })}
              </Link>
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: "panels.emissions.table.co2EmissionsByFuel" })}
              </StyledCell>
              <StyledCell sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.lbsCo2" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.c02emissionsByFossil,
                     0
                     )
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.lbsCo2" },
                     { value: formatAsThousands(totalGasolineEmissions) }
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.co2EmissionsByFuel",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: "panels.emissions.table.totalCo2Emissions" })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.lbsCo2" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.c02emissionsByFossil,
                     0
                     ) + totalGasolineEmissions
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.totalCo2Emissions",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({
                     id: "panels.emissions.table.electricityEquivalent",
              })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.mwh" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.totalMwh,
                     0
                     )
                     ),
                     }
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.electricityEquivalent",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({
                     id: "panels.emissions.table.electricityEmissions",
              })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.lbsPerMwh" },
                     { value: electricityEmissionsInLbsPerMwh.toFixed(2) }
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.electricityEmissions",
              })}
              <Link
                     textTransform="none"
                     width="auto"
                     variant="tooltip"
                     to="https://www.eia.gov/electricity/data/emissions/xls/emissions_region2020.xlsx"
                     external
              >
                     {" "}
                     {formatMessage({
                     id: "panels.emissions.table.notes.fossilFuelEmissions",
                     })}
              </Link>
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: "panels.emissions.table.co2EmissionsFrovEv" })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.lbsCo2" },
                     { value: formatAsThousands(electricityEmissionsFromEVs) }
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.co2EmissionsFrovEv",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({
                     id: "panels.emissions.table.co2EmissionsReduced",
              })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.co2EmissionsReduced" },
                     {
                     value: formatAsThousands(
                     emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.c02emissionsByFossil,
                     0
                     ) +
                     totalGasolineEmissions -
                     electricityEmissionsFromEVs
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({
                     id: "panels.emissions.table.notes.co2EmissionsReduced",
              })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: "panels.emissions.table.treesPlanted" })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: "center" }}>
              {formatMessage(
                     { id: "panels.emissions.table.units.trees" },
                     {
                     value: formatAsThousands(
                     (emissionsDataPerSet.reduce(
                     (acc, setResult) => acc + setResult.c02emissionsByFossil,
                     0
                     ) +
                     totalGasolineEmissions -
                     electricityEmissionsFromEVs) /
                     48
                     ),
                     }
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: "panels.emissions.table.notes.treesPlanted" })}
              <Link
                     textTransform="none"
                     width="auto"
                     variant="tooltip"
                     to="https://www.eea.europa.eu/articles/forests-health-and-climate-change/key-facts/trees-help-tackle-climate-change"
                     external
              >
                     {" "}
                     {formatMessage({
                     id: "panels.emissions.table.notes.treesPlantedLink",
                     })}
              </Link>
              </StyledCell>
              </StyledRow>
       </TableBody>

       {/* <TableBody>
              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.fossilFuelVolume' })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              31,307 Gal
              </StyledCell>
              <StyledCell>
              0 Gal
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.annualHours' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.fossilFuelEmissions' })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              22.45 ;bs CO2 /Gal
              </StyledCell>
              <StyledCell>
              0 Gal
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.fossilFuelVolume' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.co2EmissionsByFuel' })}
              </StyledCell>
              <StyledCell component="th" scope="row">
              700,654 Lbs
              </StyledCell>
              <StyledCell>
              0 Lbs
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.co2EmissionsByFuel' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.totalCo2Emissions' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.lbsCo2' },
                     { value: formatAsThousands(totalDieselEmissions) },
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.totalCo2Emissions' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.electricityEquivalent' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.mwh' },
                     { value: formatAsThousands(totalDieselEmissions) },
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.electricityEquivalent' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.electricityEmissions' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.lbsMwh' },
                     { value: formatAsThousands(totalDieselEmissions) },
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.electricityEmissions' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.co2EmissionsFrovEv' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.lbsCo2' },
                     { value: formatAsThousands(totalDieselEmissions) },
              )}
              </StyledCell>

              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.co2EmissionsFrovEv' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.co2EmissionsReduced' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.co2EmissionsReduced' },
                     { value: totalMwh.toFixed(2) },
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.co2EmissionsReduced' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.co2EmissionsGhg' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {electricityEmissionsInLbsPerMwh.toFixed(2)}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.co2EmissionsGhg' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.ghgEmissionsReduced' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.ghgEmissionsReducedLbsCo2' },
                     { value: formatAsThousands(electricityEmissionsFromEVs) },
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.ghgEmissionsReduced' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.ghgEmissionsReducedSimplified' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.reducedSimplifiedlbsCo2' },
                     {
                     value: formatAsThousands(annualEmissionsReduced),
                     },
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.ghgEmissionsReducedSimplified' })}
              </StyledCell>
              </StyledRow>

              <StyledRow>
              <StyledCell component="th" scope="row">
              {formatMessage({ id: 'panels.emissions.table.treesPlanted' })}
              </StyledCell>
              <StyledCell colspan="2" sx={{ textAlign: 'center' }}>
              {formatMessage(
                     { id: 'panels.emissions.table.units.trees' },
                     {
                     value: formatAsThousands(annualTreesPlanted),
                     },
              )}
              </StyledCell>
              <StyledCell>
              {formatMessage({ id: 'panels.emissions.table.notes.treesPlanted' })}
              </StyledCell>
              </StyledRow>
       </TableBody> */}
       </Table>
    </Box>
  );
}
