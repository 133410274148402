import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { ComplexVehicleCard } from './VehicleCard';

export default function VehicleList({
  vehicles,
  onSelect,
  selectedVehicle,
  vehicleCount,
  milesPerWorkday,
}) {
  const { formatMessage } = useIntl();

  return (
    <Box
      minHeight="680px"
      sx={(theme) => ({
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: { justifyContent: 'center' },
      })}
    >
      {vehicles.length === 0 && (
        <Typography fontWeight="700" fontSize="14px">
          {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.noMatches' })}
        </Typography>
      )}
      {vehicles.length > 0 && (
        <Box
          display="grid"
          gap="16px 20px"
          gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
        >
          {vehicles.map((vehicle) => {
            return (
              <ComplexVehicleCard
                key={vehicle.id}
                replacementVehicle={selectedVehicle}
                vehicleCount={vehicleCount}
                milesPerWorkday={milesPerWorkday}
                replacementOption={vehicle}
                selectedVehicle={selectedVehicle}
                onSelect={onSelect ? () => onSelect(vehicle) : undefined}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
}
