import React from "react";
import { Grid, Box } from "@mui/material";
import { useIntl } from "react-intl";
import TextFieldInput from "../../components/TextFieldInput";
import OperationsDaysMonths from "./OperationsDaysMonths";
import Alert from "../../components/Alert";

export default function VehicleUsage({
  vehicleSet,
  onChangeVehicleSet,
  
}) {
  const { formatMessage } = useIntl();

  const { milesPerWorkday } = vehicleSet;

  // let multipleVehicles = vehicleCount > 1;
  // let vehicleName = `${replacementVehicle.name}${multipleVehicles && "s"} `;

  return (
    <Box display="flex" flexDirection={"column"}>
      <Box width="400px" sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
       }}>
        <TextFieldInput
          width={"100%"}
          mask="999"
          value={milesPerWorkday}
          label={formatMessage({
            id: "inputs.vehicleSet.editSetDialog.averageEnergyDay",
          })}
          onBlur={(v) =>
            onChangeVehicleSet({
              averageMilesWorkday: parseInt(v),
              milesPerWorkday: parseInt(v),
            })
          }
          type="number"
          required
        />
      </Box>



      <Box
        width="100%"
        height="1px"
        sx={{ background: "#bbbb", margin: "16px 0" }}
      />

      <Grid item xs={12} style={{marginBottom:"50px"}}>
        <OperationsDaysMonths
          vehicleSet={vehicleSet}
          onChangeVehicleSet={onChangeVehicleSet}
        />
      </Grid>

      <Alert>{formatMessage({id: "inputs.vehicleSet.editSetDialog.vehicleUsageTooltip"})}</Alert>
    </Box>
  );
}
