import { Typography, Box, InputAdornment } from "@mui/material";
import { useIntl } from "react-intl";
import TextFieldInput from "../../../components/TextFieldInput";

const DefaultInputs = ({
  requiredArr = [],
  disabledArr = [],
  removedArr = [],
  tab,
  vehicleSet,
  onChangeVehicleSet,
  isModal = false,
}) => {
  const { formatMessage } = useIntl();

  const changeProps = (values) => {
    const newValues = {};

    if (isModal) {
      if (tab !== "Fossil Fuel Make/Model") {
        newValues.existingVehicle = {
          ...vehicleSet.existingVehicle,
          ...values,
        };
      } else {
        newValues.replacementVehicle = {
          ...vehicleSet.replacementVehicle,
          ...values,
        };
      }
    } else {
      if (tab === "Fossil Fuel Make/Model") {
        newValues.existingVehicle = {
          ...vehicleSet.existingVehicle,
          ...values,
        };
      } else {
        newValues.replacementVehicle = {
          ...vehicleSet.replacementVehicle,
          ...values,
        };
      }
    }

    onChangeVehicleSet(newValues);
  };

  const handleWeightClass = (value) => {
    if (value < 6000) return 1;
    if (value > 6000 && value < 10001) return 2;
    if (value > 10000 && value < 14001) return 3;
    if (value > 14000 && value < 16001) return 4;
    if (value > 16000 && value < 19501) return 5;
    if (value > 19500 && value < 26001) return 6;
    if (value > 26000 && value < 33001) return 7;
    if (value > 33000) return 8;
  };

  const changeVehicle = (value, key) => {
    let aditionalProp = {};

    if (key === "cargoVolume") {
      value = `${value} ft³`;
    }

    if (key === "gvwr") {
      aditionalProp.weightClass = handleWeightClass(value);
    }

    changeProps({
      [key]: value,
      ...aditionalProp,
    });
  };

  const selectedVehicle = !isModal
    ? tab === "Fossil Fuel Make/Model"
      ? vehicleSet.existingVehicle
      : vehicleSet.replacementVehicle
    : tab !== "Fossil Fuel Make/Model"
    ? vehicleSet.existingVehicle
    : vehicleSet.replacementVehicle;

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        sx={{
          "@media (max-width: 768px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          width="46%"
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          {removedArr.includes("gvwr") ? null : (
            <TextFieldInput
              value={selectedVehicle.gvwr}
              label={formatMessage({
                id: "onboarding.selectVehicle.labels.gvwr",
              })}
              type="number"
              onBlur={(e) => changeVehicle(e, "gvwr")}
              required={requiredArr.includes("gvwr")}
              disabled={disabledArr.includes("gvwr")}
            />
          )}
          <Typography fontWeight="300" fontSize="11px">
            {formatMessage(
              { id: "onboarding.selectVehicle.weightClassIndicator" },
              { weightClass: selectedVehicle.weightClass }
            )}
          </Typography>
        </Box>
        <Box
          width="46%"
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          {tab === "Fossil Fuel Make/Model" ? (
            removedArr.includes("mpg") ? null : (
              <TextFieldInput
                value={Number(selectedVehicle.milesPerGallon).toFixed(0)}
                label={formatMessage({
                  id: "onboarding.selectVehicle.labels.mpg",
                })}
                onBlur={(e) => {
                  if (!isModal) {
                    if (tab === "Fossil Fuel Make/Model") {
                      changeVehicle(e, "milesPerGallon");
                    } else {
                      changeProps({
                        milesPerGallon: e,
                        efficiencyInMilesPerKwh: 33.705 / e,
                      });
                    }
                  } else {
                    if (tab !== "Fossil Fuel Make/Model") {
                      changeVehicle(e, "milesPerGallon");
                    } else {
                      changeProps({
                        milesPerGallon: e,
                        efficiencyInMilesPerKwh: 33.705 / e,
                      });
                    }
                  }
                }}
                required={requiredArr.includes("mpg")}
                disabled={disabledArr.includes("mpg")}
                type="number"
              />
            )
          ) : null}
        </Box>
      </Box>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        sx={{
          "@media (max-width: 768px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <Box
          width="46%"
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          {removedArr.includes("msrp") ? null : (
            <TextFieldInput
              isThousand
              value={selectedVehicle.msrp}
              label={formatMessage({
                id: "onboarding.selectVehicle.labels.msrp",
              })}
              onBlur={(e) => {
                changeVehicle(e, "msrp");
              }}
              required={requiredArr.includes("msrp")}
              disabled={disabledArr.includes("msrp")}
              type="number"
              inputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          )}
        </Box>
        <Box
          width="46%"
          sx={{
            "@media (max-width: 768px)": {
              width: "100%",
            },
          }}
        >
          {removedArr.includes("cargo") ? null : (
            <TextFieldInput
              value={selectedVehicle.cargoVolume.replace("ft³", "")}
              onBlur={(e) => changeVehicle(e, "cargoVolume")}
              label={formatMessage({
                id: "onboarding.selectVehicle.labels.cargo",
              })}
              required={requiredArr.includes("cargo")}
              disabled={disabledArr.includes("cargo")}
            />
          )}
        </Box>
      </Box>
    </>
  );
};

export default DefaultInputs;
