import React from 'react';
import { Box } from '@mui/material';
import ChargerCard from './ChargerCard';

export default function ChargerList({ chargers, vehicleData }) {
  return (
    <Box
      display="flex"
      maxWidth="900px"
      flexWrap="wrap"
      gap={3}
      sx={(theme) => ({
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: { justifyContent: 'start', pl: '22px' },
      })}
    >
      {chargers.map(({ charger, start, finish, id, isGeneric }) => (
        <ChargerCard
          key={`${charger.id}-${id}`}
          charger={charger}
          vehicleData={vehicleData}
          isGeneric={isGeneric}
          start={start}
          finish={finish}
          windowId={id}
        />
      ))}
    </Box>
  );
}
