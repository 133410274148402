import { React } from "react";
import { Box, Divider } from "@mui/material";
import { useInputs } from "@bellawatt/use-inputs";
import { cloneDeep } from "lodash";
import GettingStartedStep from "./steps/GettingStartedStep";
import GeneralInformationStep from "./steps/GeneralInformationStep";
import VehicleUsageStep from "./steps/VehicleUsageStep";
import SelectVehicleStep from "./steps/selectVehicleStep/SelectVehicleStep";
import ChargingBehaviorStep from "./steps/ChargingBehaviorStep";
import OnboardingButtonRow from "./OnboardingButtonRow";
import { addComputedData } from "../utils/computedData";
import alarmMultipleChargersInUseAtOnce from "../calculations/alarmMultipleChargersInUseAtOnce";
import { useCalculations } from "../utils/useCalculations";
import validZipcode from "../data/sampleData/validZipcode";

export default function OnboardingContent() {
  const {
    onboardingStep,
    vehicleSets,
    apiFossilVehicles,
    apiElectricVehicles,
    apiChargers,
    setInput,
    zipCode,
    isMultipleSites,
  } = useInputs();
  const Calculations = useCalculations();

  const vehicleSet = vehicleSets[0];

  // const { averageMilesWorkday } = vehicleSet;

  // const hoursExceeded = averageMilesWorkday > 250;

  const handleSave = () => {
    setInput({ isOnboarded: true });
  };

  const handleChangeVehicleSet = (newData) => {
    const newSets = cloneDeep(vehicleSets);
    newSets[0] = {
      ...newSets[0],
      ...newData,
    };

    setInput({ vehicleSets: newSets, computeData: false });
  };

  const handleChangeMake = (newData) => {
    let replacementVehicleId = vehicleSet.replacementVehicleId;
    let existingVehicleId = vehicleSet.existingVehicleId;

    if (newData["existingVehicleId"] !== undefined) {
      existingVehicleId = newData.existingVehicleId;
    }

    if (newData["replacementVehicleId"] !== undefined) {
      replacementVehicleId = newData.replacementVehicleId;
    }

    // when working vehicle set state is changed, pass it through the use-inputs computed flow
    // to make sure all pieces of state are available
    const newVehicleSet = addComputedData({
      vehicleSets: [
        {
          ...vehicleSet,
          ...newData,
          replacementVehicleId,
          existingVehicleId,
        },
      ],
      apiFossilVehicles,
      apiElectricVehicles,
      apiChargers,
      computeData: true,
    }).vehicleSets[0];

    const newSets = cloneDeep(vehicleSets);
    newSets[0] = newVehicleSet;

    setInput({ vehicleSets: newSets, computeData: false });
  };

  const isValidZipcode = validZipcode.includes(zipCode)

  const steps = [
    {
      name: "gettingStarted",
      content: <GettingStartedStep />,
    },
    {
      name: "generalInformation",
      content: <GeneralInformationStep />,
      isDisabled: () => (!/[0-9]{5}/.test(zipCode) && !isMultipleSites) || !isValidZipcode,
    },
    {
      name: "vehicleUsage",
      content: (
        <VehicleUsageStep
          vehicleSet={vehicleSet}
          onChangeVehicleSet={handleChangeVehicleSet}
          // hoursExceeded={hoursExceeded}
        />
      ),
      // isDisabled: () => hoursExceeded,
    },
    {
      name: "selectVehicle",
      content: (
        <SelectVehicleStep
          vehicleSet={vehicleSet}
          onChangeMake={handleChangeMake}
          onChangeVehicleSet={handleChangeVehicleSet}
        />
      ),
      isDisabled: () =>
        !vehicleSet.vehicleCount || vehicleSet.vehicleCount <= 0,
    },
    {
      name: "chargingBehavior",
      content: (
        <ChargingBehaviorStep
          vehicleSet={vehicleSet}
          onChangeVehicleSet={handleChangeVehicleSet}
        />
      ),
      isDisabled: () =>
        Calculations.minimumStateOfCharge({ input: vehicleSet }) <= 0 ||
        alarmMultipleChargersInUseAtOnce(vehicleSet.chargingWindows),
    },
  ];

  const stepIndex = steps.findIndex((step) => step.name === onboardingStep);
  return (
    <>
      <Box>{steps[stepIndex]?.content}</Box>
      <Divider sx={{ mt: "48px", mb: "24px" }} />
      <OnboardingButtonRow
        onSave={handleSave}
        forwardDisabled={steps[stepIndex]?.isDisabled?.()}
      />
    </>
  );
}
