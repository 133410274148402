import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography, useTheme } from '@mui/material';
import { PencilIcon } from '../assets/icons/icons';
import { formatHours } from '../utils/formatters';

const formatOperatingDays = ([first, ...rest]) => {
  const daysOfWeek = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  // workdays are stored with 0 === Sunday, but we want to display Monday first
  const reorderedOperatingDays = first === 0 ? [...rest, first] : [first, ...rest];
  return reorderedOperatingDays.map((day) => daysOfWeek[day]).join(',');
};

const formatChargingWindows = (chargingWindows, defaultMsg) => {
  return chargingWindows.length > 1
    ? defaultMsg
    : `${formatHours(chargingWindows[0].start)} - ${formatHours(chargingWindows[0].finish)}`;
};

const VehicleSpec = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
      <Typography
        fontSize="0.75rem"
        textAlign="left"
        width="60%"
        lineHeight={2}
        color={theme.palette.grey[900]}
        fontWeight="400"
      >
        {label}
      </Typography>
      <Typography
        textAlign="left"
        width="40%"
        fontSize="0.75rem"
        color={theme.palette.grey[800]}
        textTransform="capitalize"
      >
        {value}
      </Typography>
    </Box>
  );
};

export default function EditVehicleSetCard({ vehicleSet, onClick }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const {
    name,
    vehicleCount,
    workdays,
    chargingWindows,
    milesPerWorkday,
    personalMilesPaidFor,
    personalMilesPerWorkday,
    existingVehicle: { name: vehicleName, subtype, imageUrl, type },
  } = vehicleSet;

  let dailyValue = personalMilesPaidFor ? (milesPerWorkday + personalMilesPerWorkday) :  milesPerWorkday

  return (
    <Box border={`1px solid ${theme.palette.grey[300]}`} borderRadius="16px" mb={2} py={1.5} px={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
        pb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <img src={imageUrl} alt="vehicle set" width="80px" />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box justifyContent="flex-end" mb={1}>
            <Typography color={theme.palette.grey[900]} mb={0.5} textAlign="right" fontWeight="700">
              {name}
            </Typography>
            <Box display="flex">
              <Typography fontSize="0.75rem" textAlign="right">
                {vehicleCount}x {vehicleName}
              </Typography>
            </Box>
          </Box>
          <Box
            flexShrink={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            border={`1px solid #0072CE`}
            borderRadius="6px"
            width="40px"
            height="40px"
            onClick={onClick}
            sx={{
              cursor: 'pointer',
              '&:hover': { border: `1px solid ${theme.palette.common.teal[800]}` },
            }}
          >
            <PencilIcon />
          </Box>
        </Box>
      </Box>
      <Box py={1}>
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.type' })}
          value={`${subtype ? subtype : type}`}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.dailyMileage' })}
          value={dailyValue}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.daysOperating' })}
          value={formatOperatingDays(workdays)}
        />
        <VehicleSpec
          label={formatMessage({ id: 'inputs.vehicleSet.editCard.specs.dcfc' })}
          value={formatChargingWindows(
            chargingWindows,
            formatMessage({ id: 'inputs.vehicleSet.editCard.specs.multipleWindows' }),
          )}
        />
      </Box>
    </Box>
  );
}
