import { Box, Typography } from "@mui/material";
import StyledTable from "../../components/charts/StyledTable";
import {
  formatAsCents,
  formatAsDollars,
  formatAsThousands
} from "../../utils/formatters";
import { useIntl } from "react-intl";
import { useInputs } from "@bellawatt/use-inputs";
import ToolTip from "../../components/ToolTip";
import TableTitle from "../../components/TableTitle";
import { useCalculations } from "../../utils/useCalculations";
import GlobalData from "../../calculations/GlobalData";

export default function FossilFleetTable({ fossilFuelCosts }) {
  const { vehicleSets, dieselPrice, gasolinePrice } =
    useInputs();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();
  const { data } = GlobalData()

  const labels = [
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: 'panels.fuel.set' })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: 'panels.fuel.count' })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {    formatMessage(
        { id: 'panels.fuel.efficiency' },
        { units: formatMessage({ id: 'panels.fuel.table.gasUnits' }) },
      )}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {`${formatMessage({ id: 'panels.fuel.miles' })} (${formatMessage({
        id: 'panels.fuel.perYear',
      })})`}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage(
        { id: "panels.fuel.fuelCost" },
        { units: formatMessage({ id: "panels.fuel.table.gasDollars" }) }
      )}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.cost" })}
    </Typography>,
  ];

    let TotalFinalValue = []

    const vehicleSetData = vehicleSets.map((set) => {
      const fuelCost = set.existingVehicle.fuel === 'Diesel' ? dieselPrice : gasolinePrice;
      
      const efficiencies = set.existingVehicle.milesPerGallon

      const hoursPerYearArr = Calculations.annualMiles({ input: set, fossilFuelType: set.existingVehicle.fuel }) * set.vehicleCount
      

      const fuelCostArr = fuelCost

      const finalValue =
        (hoursPerYearArr / efficiencies) * fuelCostArr.toFixed(2);
  
      TotalFinalValue.push(finalValue)

      return [
        <Typography fontSize="14px" fontWeight="200">
          {set.existingVehicle.name}
        </Typography>,
        <Typography fontSize="14px" fontWeight="200">
          {set.vehicleCount}
        </Typography>,
        <Typography fontSize="14px" fontWeight="200">
          {efficiencies}
        </Typography>,
        <Box>
          <ToolTip content={formatMessage({ id: 'panels.fuel.toolTips.fordTransitConnect' })}>
            <Typography fontSize="14px" fontWeight="200">
              {formatAsThousands(hoursPerYearArr)}
            </Typography>
          </ToolTip>
        </Box>,
        <Typography fontSize="14px" fontWeight="200">
          {formatAsCents(fuelCostArr)}
        </Typography>,
        <Typography fontSize="14px" fontWeight="200">
          {formatAsDollars(finalValue)}
        </Typography>,
      ]
    })

  const rows = [
    ...vehicleSetData,
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.annualTotal' })}
      </Typography>,
      null,
      null,
      null,
      null,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(TotalFinalValue.reduce((total, valor) => total + valor, 0))}
      </Typography>,
    ],
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.lifetime' })}
      </Typography>,
      null,
      null,
      null,
      null,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(data.fossilFleetCost.fuel)}
      </Typography>,
    ],
  ];

  return (
    <Box>
      <TableTitle title={formatMessage({ id: "panels.fuel.fossilFleet" })} isLowCase />
      <StyledTable headings={labels} rows={rows} />
    </Box>
  );
}
