import { Button } from '@mui/material';

export default function CheckboxButtonInput({ checked, children, onClick, ...rest }) {
  const styles = {
    base: {
      backgroundColor: 'transparent',
      color: '#3D3935',
      border: `1px solid #3D3935`,
      borderRadius: '50px',
      padding: '12px 26px',
      fontSize: '14px',
      fontWeight: '300',
      width: 'auto',
      height: 'auto',
    },
    active: {
      border: `none`,
      backgroundColor: '#3D3935',
      color: '#fff',
    },
  };

  return (
    <Button
      aria-checked={checked}
      aria-label={children}
      type="button"
      role="checkbox"
      onClick={onClick}
      style={checked ? { ...styles.base, ...styles.active } : { ...styles.base }}
      {...rest}
    >
      {children}
    </Button>
  );
}
