import { Box, Typography } from "@mui/material";
import StyledTable from "../../components/charts/StyledTable";
import {
  formatAsCents,
  formatAsDollars, formatAsThousands
} from "../../utils/formatters";
import { useIntl } from "react-intl";
import { useInputs } from "@bellawatt/use-inputs";
import sum from "lodash/sum";
// import ToolTip from "../../components/ToolTip";
import TableTitle from "../../components/TableTitle";
import getDefaultRate from "../../calculations/rates/defaultRate";
import ToolTip from "../../components/ToolTip";
import { useCalculations } from "../../utils/useCalculations";

export default function ElectricFleetTable({ electricCosts, fossilFuelCosts }) {
  const { vehicleSets, electricityPrice, lifespanYears } = useInputs();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const rate = getDefaultRate(electricityPrice);

  let TotalFinalValue = []

  const labels = [
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: 'panels.fuel.set' })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: 'panels.fuel.count' })}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {`${formatMessage({ id: 'panels.fuel.miles' })} (${formatMessage({
        id: 'panels.fuel.perYear',
      })})`}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage(
        { id: "panels.fuel.fuelCost" },
        { units: formatMessage({ id: "panels.fuel.table.electricDollars" }) }
      )}
    </Typography>,
    <Typography fontWeight="300" fontSize="14px">
      {formatMessage({ id: "panels.fuel.cost" })}
    </Typography>,
  ];

  const vehicleSetData = vehicleSets.map((set) => {

    const hoursPerYearArr = formatAsThousands(
      Calculations.annualMiles({ input: set }) * set.vehicleCount
    )

    const fuelCostArr = formatAsCents(electricityPrice)

    const finalValue = Calculations.annualElectricityCosts({
      input: set,
      rate
    })
    
    TotalFinalValue.push(finalValue)

    return [
      <Typography fontSize="14px" fontWeight="200">
        {set.replacementVehicle.name}
      </Typography>,
      <Typography fontSize="14px" fontWeight="200">
        {set.vehicleCount}
      </Typography>,
      <Box>
        <ToolTip content={formatMessage({ id: 'panels.fuel.toolTips.fordTransitConnect' })}>
          <Typography fontSize="14px" fontWeight="200">
            {hoursPerYearArr}
          </Typography>
        </ToolTip>
      </Box>,
      <Typography fontSize="14px" fontWeight="200">
        {fuelCostArr}
      </Typography>,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(finalValue)}
      </Typography>,
    ]
  })

  const rows = [
    ...vehicleSetData,
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.annualTotal' })}
      </Typography>,
      null,
      null,
      null,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(TotalFinalValue.reduce((total, valor) => total + valor, 0))}
      </Typography>,
    ],
    [
      <Typography fontSize="14px" fontWeight="200">
        {formatMessage({ id: 'panels.fuel.lifetime' })}
      </Typography>,
      null,
      null,
      null,
      <Typography fontSize="14px" fontWeight="200">
        {formatAsDollars(sum(Object.values(electricCosts)) * lifespanYears)}
      </Typography>,
    ],
  ];

  return (
    <Box>
      <TableTitle title={formatMessage({ id: "panels.fuel.electricFleet" })} isLowCase/>
      <StyledTable headings={labels} rows={rows} />
    </Box>
  );
}
