import { useState, useEffect } from 'react';
import getDefaultRate from './rates/defaultRate';
import { useInputs } from "@bellawatt/use-inputs";
import { getMaintenanceValues, getVehicleDuty, getVehicleFuel, getVehicleType } from '../utils/calculationsUtils';
import { useCalculations } from '../utils/useCalculations';
import sum from "lodash/sum";

const GlobalData = () => {
  const initialState = {
    totalFleetSavings: {},
    electricFleetCost: {},
    fossilFleetCost: {},
    totalMaintenanceCost: {},
    maintenanceCostByYear: {},
    insuranceCostByYear: {},
    fleetResaleValue: {}
  }

  const {
    vehicleSets,
    electricityPrice,
    lifespanYears,
    dieselPrice,
    gasolinePrice,
    apiEvaluatedIncentives,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
    chargerInstallationCost,
    setInput,
    state,
    carbonCreditsPrice,
    includeChargerInstallationCost,
    includeChargerCost,
    resaleOneYearEv,
    resaleOneYearFossil,
    resaleLifetimeEv,
    resaleLifetimeFossil
  } = useInputs();

  const [data, setData] = useState(initialState);
  const [resaleEv, setResaleEv] = useState(0);
  const [resaleFossil, setResaleFossil] = useState(0);
  const [customInstallationCosts, setCustomInstallationCosts] = useState(chargerInstallationCost);

  const Calculations = useCalculations();

  const setValue = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value
    }));
  };

  //total/all vehicles
  const totalVehicleCount = vehicleSets.reduce((total, set) => total + set.vehicleCount, 0);

  const totalVehicles = totalVehicleCount;

  //Handle resale
  useEffect(() => {
    let fossilResaleValues = Calculations?.calcResaleValue({input:vehicleSets[0].existingVehicle, yearsOfOperation: lifespanYears})
    let eVResaleValues = Calculations.calcResaleValue({input:vehicleSets[0].replacementVehicle, yearsOfOperation: lifespanYears})
  
    if((resaleOneYearEv && resaleLifetimeEv) || (resaleOneYearFossil && resaleLifetimeFossil)) {
      const resaleData =  {
        resaleFirstYearEv: resaleOneYearEv, 
        resaleFirstYearFossil: resaleOneYearFossil, 
        resaleLifeTimeEv: resaleLifetimeEv, 
        resaleLifeTimeFossil: resaleLifetimeFossil 
      }
      Calculations?.setData({resaleInputs: resaleData })

      fossilResaleValues = Calculations.calcResaleValue({
        input:vehicleSets[0].existingVehicle, 
        yearsOfOperation: lifespanYears, 
        resaleInputs: resaleData
      });
  
      eVResaleValues = Calculations.calcResaleValue({
        input:vehicleSets[0].replacementVehicle, 
        yearsOfOperation: lifespanYears, 
        resaleInputs: resaleData
      })
    }
  
    fossilResaleValues *= -totalVehicles;
    eVResaleValues *= -totalVehicles;
  
    setResaleEv(eVResaleValues)
    setResaleFossil(fossilResaleValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resaleOneYearEv, resaleLifetimeEv, resaleOneYearFossil, resaleLifetimeFossil, totalVehicles, vehicleSets, lifespanYears]);


  //handle Maintenance
  let maitenanceFossil = []
  let maitenanceEv = []

  const totalMaintenanceCost = (isIce) => {
    const costArray = new Array(lifespanYears).fill(0).map((_, index) => 
      vehicleSets.reduce((accumulator, set) => {
          const vehicleType = isIce ? getVehicleType(set.existingVehicle.type) : getVehicleType(set.replacementVehicle.type);
          const vehicleDuty = isIce ? getVehicleDuty(set.existingVehicle.subtype) : getVehicleDuty(set.replacementVehicle.subtype);
          const vehicleFuel = isIce ? getVehicleFuel(set.existingVehicle.fuel) : getVehicleFuel(set.replacementVehicle.fuel);

          const [baselineMiles, slope, intercept] = getMaintenanceValues(vehicleType, vehicleDuty, vehicleFuel);
          
          const maintenanceValues = {
            baselineMiles: baselineMiles,
            slope: slope,
            intercept: intercept,
          };

          Calculations?.setData({maintenanceValues: maintenanceValues});

          const maintenanceCost = Calculations.annualMaintenanceCost({ 
            input: set, 
            isRetiringFleet: isIce, 
            ageInYears: index+1, 
            includePersonal: set.personalMilesPaidFor,
            maintenanceValues: maintenanceValues
          });
          
          if(isIce) {
            maitenanceFossil.push(maintenanceCost * set.vehicleCount)
          } else {
            maitenanceEv.push(maintenanceCost * set.vehicleCount) 
          }

        return accumulator + maintenanceCost * set.vehicleCount;
      }, 0)
    );
    return costArray.reduce((p, a) => p + a, 0);
  };

  const totalFleetMaintenance = {
    electric: totalMaintenanceCost(false),
    fossil: totalMaintenanceCost(true),
  };

  const maitenanceByYear = {
    electric: maitenanceEv,
    fossil: maitenanceFossil
  }

  //handle insurance
  const evInsurenceCost = vehicleSets.reduce((totalCost, set) => {
    for (let i = 1; i <= lifespanYears; i++) {
      totalCost += Calculations.annualInsuranceCosts({
        input: set.replacementVehicle,
        count: set.vehicleCount,
        state: state,
        yearsOfOperation: i
      });
    }
    
    return totalCost;
  }, 0);

  const fossilInsurenceCost = vehicleSets.reduce((totalCost, set) => {
    for (let i = 1; i <= lifespanYears; i++) {
      totalCost += Calculations.annualInsuranceCosts({
        input: set.existingVehicle,
        count: set.vehicleCount,
        state: state,
        yearsOfOperation: i
      });
    }
    return totalCost;
  }, 0);

  const insuranceSavings = (fossilInsurenceCost - evInsurenceCost)

  const evInsurenceByYear = new Array(lifespanYears).fill(0).map( (_,index) => 
    vehicleSets.reduce(
      (_, set) => Calculations.annualInsuranceCosts({ input: set.replacementVehicle, count:set.vehicleCount, state: state, yearsOfOperation: index+1}),
      0
  )
);

  const fossilInsurenceByYear = new Array(lifespanYears).fill(0).map((_,index) => 
    vehicleSets.reduce(
      (_, set) => Calculations.annualInsuranceCosts({ input: set.existingVehicle, count:set.vehicleCount, state: state, yearsOfOperation: index+1}),
      0
  )
);

  const insuranceByYear = {
    electric: evInsurenceByYear,
    fossil: fossilInsurenceByYear
  }

  //Handle financial Fleets cost
  const rate = getDefaultRate(electricityPrice);
 
  let customVehicleSets = JSON.parse(JSON.stringify(vehicleSets));

  if (!includeChargerCost) {
    customVehicleSets.forEach(vehicleSet => {
      vehicleSet.chargingWindows.forEach(chargingWindow => {
        if (chargingWindow.charger) {
          chargingWindow.charger.price = 0;
        }
      });
    });
  }

  const tmpVehicleSets=customVehicleSets.map((vehicleSet) => ({
    ...vehicleSet,
    chargingWindows: vehicleSet.chargingWindows.map((cw) => ({
      ...cw,
      charger: {
        ...cw.charger,
        chargerType: cw.charger.chargerType === "level_2" ? "ac" : "dc",
      }
      
    })),
  }));

  //chargers


  useEffect(() => {
    const cost = !includeChargerInstallationCost
    ? 0
    : (Calculations.getInstallationCosts({  
          input: tmpVehicleSets,
        level2ChargersInstallationCost,
        dcfcChargersInstallationCost,
      }));

    setCustomInstallationCosts(cost)
    if (chargerInstallationCost !== customInstallationCosts) {
      setInput({ chargerInstallationCost: customInstallationCosts });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomInstallationCosts(chargerInstallationCost)
  
  }, [chargerInstallationCost])

  function calculateCosts(chargingWindows) {
    let totalNumberOfChargers = 0;
    let totalChargerPurchaseCost = 0;

    for(let window of chargingWindows) {
        let charger = window.charger;
        if(!charger) {
            console.warn("Invalid charger data detected in charging window:", window);
            continue;
        }
        
        // Calculate number of chargers
        let numberOfChargers = Math.ceil(totalVehicles / charger.ports);
        totalNumberOfChargers += numberOfChargers;

        // Calculate purchase cost
        let chargerPurchaseCost = charger.price * numberOfChargers;
        totalChargerPurchaseCost += chargerPurchaseCost;
    }

    return {
        totalNumberOfChargers,
        totalChargerPurchaseCost,
    };
  }

  const allChargers = Calculations.getAllChargers({ input: customVehicleSets });

  const newFuelDieselValue = sum(
    vehicleSets.map((set) => {
      const milePerYear = Calculations.annualMiles({ input: set, fossilFuelType: set.existingVehicle.fuel }) * set.vehicleCount;
      const mpg = set.existingVehicle.milesPerGallon;
      const fuelCost = set.existingVehicle.fuel === 'Diesel' ? dieselPrice : gasolinePrice;
      return (milePerYear / mpg) * fuelCost * lifespanYears;
    })
  );

  const totalCostParams = {
    dieselPrice,
    gasolinePrice,
    rate,
    incentives: apiEvaluatedIncentives,
    lifespanYears,
    carbonCreditsPrice,
    chargerInstallationCost: customInstallationCosts,
  };

  const totalRetiringFleetCosts = Calculations.totalCosts({
    input: customVehicleSets,
    ...totalCostParams,
    isRetiringFleet: true,
    state,
  });
  
  const totalElectrifiedFleetCosts = Calculations.totalCosts({
    input: customVehicleSets,
    ...totalCostParams,
    isRetiringFleet: false,
    state,
  });

  const electricAggregated = totalElectrifiedFleetCosts.byCategory;
  const fossilAggregated = totalRetiringFleetCosts.byCategory;

  const evSavings = Object.keys(electricAggregated).reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = (fossilAggregated[key] || 0) - electricAggregated[key];
    }

    return acc;
  }, {});

  const totalEvCost = electricAggregated.fuel + calculateCosts(allChargers).totalChargerPurchaseCost + customInstallationCosts + totalFleetMaintenance.electric + electricAggregated.purchase + resaleEv + electricAggregated.incentives + evInsurenceCost

  const totalFossilCost = newFuelDieselValue + totalFleetMaintenance.fossil + fossilAggregated.purchase + resaleFossil + fossilInsurenceCost

  const totalCost = {
    fuel: newFuelDieselValue - electricAggregated.fuel,
    chargers: -calculateCosts(allChargers).totalChargerPurchaseCost,
    installationCosts: evSavings.installationCosts,
    maintenance: totalFleetMaintenance.fossil - totalFleetMaintenance.electric,
    insurance: insuranceSavings,
    purchase: evSavings.purchase,
    resale: (resaleFossil - resaleEv ),
    incentives: evSavings.incentives,
    total: totalFossilCost - totalEvCost
  };

  const electricCost = {
    fuel: electricAggregated.fuel,
    chargers: calculateCosts(allChargers).totalChargerPurchaseCost,
    installationCosts: customInstallationCosts,
    maintenance: totalFleetMaintenance.electric,
    insurance: evInsurenceCost,
    purchase: electricAggregated.purchase,
    resale: resaleEv,
    incentives: electricAggregated.incentives,
    total: totalEvCost
  }

  const fossilCost = {
    fuel: newFuelDieselValue,
    chargers: 0,
    installationCosts: 0,
    maintenance: totalFleetMaintenance.fossil,
    insurance: fossilInsurenceCost,
    purchase: fossilAggregated.purchase,
    resale: resaleFossil,
    incentives: 0,
    total: totalFossilCost
  }


  useEffect(() => {
    setData((prevData) => ({
        ...prevData,
        totalFleetSavings: totalCost,
        electricFleetCost: electricCost,
        fossilFleetCost: fossilCost,
        totalMaintenanceCost: totalFleetMaintenance,
        maintenanceCostByYear: maitenanceByYear,
        insuranceCostByYear: insuranceByYear,
        fleetResaleValue: {fossil: resaleFossil, electric: resaleEv}
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[electricAggregated.incentives, vehicleSets, setInput, resaleEv, resaleFossil])

  return {
    data,
    setValue,
  };
};

export default GlobalData;
