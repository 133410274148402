import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

export function YearlyFuelCostChart({ electricCosts, fossilFuelCosts, savings }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const labels = [
    [
      formatMessage({ id: 'panels.fuel.eFleet' }),
      `(${formatMessage({ id: 'panels.fuel.yourSelect' })})`,
    ],
    [
      formatMessage({ id: 'panels.fuel.fFleet' }),
      `(${formatMessage({ id: 'panels.fuel.similar' })})`,
    ],
  ];

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.fuel.eVehicles' }),
        data: [electricCosts],
        backgroundColor: '#0072CE',
        barPercentage: 0.5,
      },
      {
        label: formatMessage({ id: 'panels.fuel.fVehicles' }),
        data: [0, fossilFuelCosts],
        backgroundColor: '#05C3DD',
        barPercentage: 0.5,
      },
    ],
  };

  const yTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[900],
    font: {
      size: 14,
    },
  };

  const layout = {
    padding: {
      left: 0,
    },
  };

  const xTicks = {
    min: 0,
    callback: (value) => '',
    stepSize: 25000,
  };
  const dataLabels = {
    display: true,
    anchor: 'end',
    align: 'end',
    textAlign: 'left',
    color: theme.palette.grey[900],
    formatter: (value, context) => {
      return value === 0 ? null : formatAsDollars(value);
    },
  };
  return (
    <Box maxWidth="890px">
      <Typography variant="primary" fontSize="22px" fontWeight='200'>
        {formatMessage({ id: 'panels.fuel.yearly' })}
      </Typography>
      <Typography marginTop="16px" fontSize="16px" fontWeight='300'>
        {formatMessage(
          { id: 'panels.fuel.chartSummary' },
          { value: <b>{formatAsDollars(savings)}</b> },
        )}
      </Typography>
      <Box height="300px">
        <StyledBarGraph
          data={chargeData}
          yTicks={yTicks}
          xTicks={xTicks}
          layout={layout}
          indexAxis={'y'}
          showLegend
          showVerticalGrid
          legendPosition="bottom"
          legendAlign="center"
          stacked={true}
          dataLabels={dataLabels}
          tooltipEnabled={false}
          suggestedXMax={Math.max(fossilFuelCosts, electricCosts) * 1.25}
          gridColor={theme.palette.grey[200]}
        />
      </Box>
    </Box>
  );
}
