import React from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';
import { CloseIcon } from '../../assets/icons/icons';

export default function CatalogHeader({ onClose, vehicleName, milesPerDay, kWhPerWorkday }) {
  const { formatMessage } = useIntl();
  return (
    <Box display="flex" justifyContent="space-between" width="100%" mb={5}>
      <Box>
        <Typography
          mb={3}
          sx={(theme) => ({ fontSize: '24px', [theme.breakpoints.up('sm')]: { fontSize: '34px' } })}
        >
          {formatMessage({ id: 'inputs.chargerCatalog.title' })}
        </Typography>
        <Typography
          sx={(theme) => ({ fontSize: '14px', [theme.breakpoints.up('sm')]: { fontSize: '16px' } })}
        >
          {formatMessage(
            { id: 'inputs.chargerCatalog.subtitle' },
            {
              vehicle: <b>{vehicleName}</b>,
              miles: (
                <b>
                  {milesPerDay} {formatMessage({ id: 'inputs.chargerCatalog.miles' })}
                </b>
              ),
              kWhPerWorkday: <b>{Math.round(kWhPerWorkday)}kW</b>,
            },
          )}
        </Typography>
      </Box>
      <Box height="30px">
        <ButtonInput variant="link" onClick={onClose}>
          <CloseIcon />
        </ButtonInput>
      </Box>
    </Box>
  );
}
