const LCFS = {
  2021: {
    diesel: 91.66,
    gasoline: 90.74,
  },
  2022: {
    diesel: 90.41,
    gasoline: 89.5,
  },
  2023: {
    diesel: 89.15,
    gasoline: 88.25,
  },
  2024: {
    diesel: 87.89,
    gasoline: 87.01,
  },
  2025: {
    diesel: 86.64,
    gasoline: 85.77,
  },
  2026: {
    diesel: 85.38,
    gasoline: 84.52,
  },
  2027: {
    diesel: 84.13,
    gasoline: 83.28,
  },
  2028: {
    diesel: 82.87,
    gasoline: 82.04,
  },
  2029: {
    diesel: 81.62,
    gasoline: 80.8,
  },
  2030: {
    diesel: 80.36,
    gasoline: 79.55,
  },
};

export const dieselEER = 5.0;
export const gasolineEER = 3.4;
export const energyDensity = 3.6;
export const gridIntensity = 82.92;

export default LCFS;
