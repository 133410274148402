import { Box, Typography } from '@mui/material';

export default function TableTitle({ title, subTitle, mb, isLowCase, titleStyle }) {
  return (
    <Box>
      <Box mb={mb || 4}>
        <Typography
          mb="12px"
          fontSize="22px"
          fontWeight="400"
          color="#3D3935"
          variant="primary"
          textTransform={!isLowCase && "uppercase"}
          {...titleStyle}
        >
          {title}
        </Typography>
        {subTitle && (
          <Typography mt={2} fontSize="16px" fontWeight="400" color="#404040">
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
