import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';
import { formatAsDollars } from '../../utils/formatters';

const formatOperatingDays = ([first, ...rest]) => {
  const daysOfWeek = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
  // workdays are stored with 0 === Sunday, but we want to display Monday first
  const reorderedOperatingDays = first === 0 ? [...rest, first] : [first, ...rest];
  return reorderedOperatingDays.map((day) => daysOfWeek[day]).join(',');
};

const Spec = ({ spec }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" justifyContent="space-between" py="3px">
      <Typography fontSize="14px" fontWeight="700" color={theme.palette.grey[900]}>
        {formatMessage({ id: spec.name })}
      </Typography>
      <Typography fontSize="14px" color={theme.palette.grey[600]}>
        {spec.value}
      </Typography>
    </Box>
  );
};

const Title = ({ count, name }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p="10px"
      m="6px"
      backgroundColor={theme.palette.grey[100]}
    >
      <Typography fontSize="14px" color={theme.palette.grey[900]}>
        {count}x
      </Typography>
      <Typography fontSize="14px" color={theme.palette.grey[900]}>
        {name}
      </Typography>
    </Box>
  );
};

export function SimpleVehicleCard({ vehicleSet }) {
  const theme = useTheme();
  const { replacementVehicle, vehicleCount, milesPerWorkday, workdays } = vehicleSet;
  const { type, subtype, name } = replacementVehicle;

  const specList = [
    {
      name: 'inputs.vehicleSet.vehicleCatalog.type',
      value: type,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.subtype',
      value: subtype,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.milesPerDay',
      value: milesPerWorkday,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.daysOperating',
      value: formatOperatingDays(workdays),
    },
  ];

  return (
    <Box p={2} border={`1px solid ${theme.palette.grey[300]}`}>
      <Box
        display="flex"
        justifyContent="space-between"
        fontWeight={700}
        gap={3}
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
        pb={2}
        mb={2}
      >
        <Box>{vehicleCount}x</Box>
        <Box>{name}</Box>
      </Box>
      <Box>
        {specList.map((spec) => (
          <Spec spec={spec} key={spec.name} />
        ))}
      </Box>
    </Box>
  );
}

export function ComplexVehicleCard({
  replacementOption,
  selectedVehicle,
  onSelect,
  replacementVehicle,
  vehicleCount,
  milesPerWorkday,
}) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const {
    make,
    model,
    type,
    subtype,
    imageUrl,
    name: replacementName,
    range,
    batteryCapacityInKwh,
    msrp,
  } = replacementOption;

  const specList = [
    {
      name: 'inputs.vehicleSet.vehicleCatalog.type',
      value: type,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.subtype',
      value: subtype,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.range',
      value: range,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.capacity.battery',
      value: batteryCapacityInKwh,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.capacity.cargo',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.capacity.passenger',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.headroom',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.legroom',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.availability',
      value: 0,
    },
    {
      name: 'inputs.vehicleSet.vehicleCatalog.milesDay',
      value: milesPerWorkday,
    },
  ];

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Box border={`1px solid ${theme.palette.grey[300]}`} borderRadius="2px">
      {vehicleCount && replacementVehicle?.name && (
        <Title count={vehicleCount} name={replacementVehicle.name} />
      )}
      <Box px={2} pb={2} mt={1}>
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize="16px" fontWeight={700}>
            {make}
          </Typography>
        </Box>
        <Typography>{model}</Typography>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={imageUrl} alt={replacementName} width="auto" height="124px" />
        </Box>
        {isLargeScreen && (
          <Box borderBottom={`1px solid ${theme.palette.grey[300]}`} pb="10px">
            {specList.map((spec) => (
              <Spec key={spec.name} spec={spec} />
            ))}
          </Box>
        )}

        <Box display="flex" justifyContent="space-between" my={2} alignItems="center">
          <Typography fontSize="16px" fontWeight="700" color={theme.palette.grey[900]}>
            {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.msrp' })}
          </Typography>
          <Typography fontSize="16px" color={theme.palette.grey[600]}>
            {formatAsDollars(msrp)}
          </Typography>
        </Box>
        {onSelect && (
          <ButtonInput
            fullWidth
            small
            variant={selectedVehicle?.id === replacementOption.id ? 'contained' : 'outlined'}
            onClick={onSelect}
            textTransform="none"
          >
            {formatMessage({
              id: `inputs.vehicleSet.vehicleCatalog.${
                selectedVehicle?.id === replacementOption.id ? 'selected' : 'selectReplacement'
              }`,
            })}
          </ButtonInput>
        )}
      </Box>
    </Box>
  );
}
