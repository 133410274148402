import React from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';
import { CloseIcon } from '../../assets/icons/icons';

export default function CatalogHeader({ onClose, vehicleSet }) {
  const { formatMessage } = useIntl();
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box textAlign="center" flex="1 1">
        <Typography
          mb={3}
          variant="primary"
          sx={(theme) => ({
            fontSize: '24px',
            [theme.breakpoints.up('sm')]: { fontSize: '32px' },
          })}
        >
          {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.title' })}
        </Typography>
        <Typography
          sx={(theme) => ({ fontSize: '14px', [theme.breakpoints.up('sm')]: { fontSize: '16px' } })}
        >
          {formatMessage(
            { id: 'inputs.vehicleSet.vehicleCatalog.subtitle' },
            { setName: <b>{vehicleSet.name}</b> },
          )}
        </Typography>
      </Box>
      <Box height="30px">
        <ButtonInput variant="link" onClick={onClose}>
          <CloseIcon />
        </ButtonInput>
      </Box>
    </Box>
  );
}
