import React from 'react';
import { Box, Typography } from '@mui/material';
import { DarkGasCanIcon, BlueLighningIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';

export default function VehicleOverview({ name, imgUrl, isElectric, fuel }) {
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={3}
      minHeight={260}
      justifyContent="space-between"
    >
      <img src={imgUrl} alt={name} height={100} />
      <Typography
        fontSize="1.5rem"
        textAlign="center"
        mb={1}
        textTransform="uppercase"
      >
        {name}
      </Typography>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" mr={1}>
          {isElectric ? (
            <BlueLighningIcon />
          ) : (
            <DarkGasCanIcon height="24" width="24" color="#000" />
          )}
        </Box>
        <Typography color={(theme) => '#404040'} marginLeft={1}>
          {formatMessage({
            id: isElectric
              ? 'panels.comparison.electric'
              : fuel.toLowerCase() === 'diesel'
              ? 'panels.comparison.diesel'
              : 'panels.comparison.gasoline',
          })}
        </Typography>
      </Box>
    </Box>
  );
}
