import { useIntl } from 'react-intl';
import WarningMessage from './WarningMessage';

/**
 * @typedef BlockerProps
 * @property {Array<Site>} sites
 * */

/**
 *
 * @param {BlockerProps} params
 * @returns
 */
export const InsufficientChargeBlocker = ({ vehicleSets }) => {
  const { formatMessage } = useIntl();

  const vehicleNames = vehicleSets.map((set) => {
    return set.replacementVehicle
      ? `${set.replacementVehicle.type} ${set.replacementVehicle.subtype}`
      : `${set.existingVehicle.type} ${set.existingVehicle.subtype}`;
  });

  return (
    <WarningMessage
      variant="warning"
      padding={3}
      message={
        <>
          <div>
            <b>{formatMessage({ id: 'panels.warning' })}</b>{' '}
            {formatMessage({ id: 'panels.insufficientChargeWarning' })}{' '}
          </div>
          <ul>
            {vehicleNames.map((vehicle, index) => (
              <li style={{ fontWeight: '600' }} key={index}>
                {vehicle}
              </li>
            ))}
          </ul>
          <div>{formatMessage({ id: 'panels.insufficientChargeCTA' })}</div>
        </>
      }
    />
  );
};
