import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material';

export default function StyledTable({ headings, rows }) {
  const theme = useTheme();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow sx={{ height: '48px' }}>
            {headings.map((heading, index) => (
              <TableCell
                key={index}
                sx={{
                  padding: '10px',
                  borderTop: `1px solid ${theme.palette.common.border}`,
                  fontWeight: '700',
                }}
              >
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, iRow) => (
            <TableRow key={iRow} sx={{ height: '48px' }}>
              {row.map((col, iCol) => (
                <TableCell
                  key={`${iRow}-${iCol}`}
                  align="left"
                  sx={{
                    padding: '10px',
                    borderTop: `1px solid ${theme.palette.common.border}`,
                    fontWeight: iRow === rows.length - 1 ? 700 : 400,
                    verticalAlign: 'top',
                  }}
                >
                  {col || null}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
