import { useState } from 'react';
import ExternalSiteDialog from '../dialogs/externalSiteDialog/ExternalSiteDialog';
import { Link as RouterLink } from 'react-router-dom';
import ButtonInput from './ButtonInput';

export default function Link({
  to,
  external = false,
  children,
  variant = 'link',
  width,
  textTransform = 'uppercase',
  color
}) {
  const [openDialog, setOpenDialog] = useState(false);

  if (external) {
    return (
      <>
        <a
          href={to}
          style={{ textDecoration: 'none', width, color }}
          onClick={(event) => {
            event.preventDefault();
            setOpenDialog(true);
          }}
        >
          {variant && variant !== 'tooltip' ? (
            <ButtonInput
              variant={variant}
              style={{
                padding: '9px 0px',
                fontWeight: '300',
                fontSize: '14px',
                lineHeight: '16px',
                textTransform: { textTransform },
                width: width ||'100%',
              }}
            >
              {children}
            </ButtonInput>
          ) : (
            children
          )}
        </a>
        <ExternalSiteDialog isOpen={openDialog} to={to} onCancel={() => setOpenDialog(false)}>
          {children}
        </ExternalSiteDialog>
      </>
    );
  }

  return <RouterLink to={to}>{children}</RouterLink>;
}
