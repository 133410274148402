import { Box } from '@mui/material';
import VehicleCatalog from '../dialogs/vehicleCatalog/VehicleCatalog';
import Card from '../components/Card';

export default function VehiclesPage() {
  return (
    <Box
      p={2}
      sx={{
        maxWidth: (theme) => theme.settings.maxWidth.body,
        margin: '0 auto',
      }}
    >
      <Card>
        <VehicleCatalog />
      </Card>
    </Box>
  );
}
