import React from 'react';
import { Box } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { steps } from '../data/onboardingSteps';
import ProgressBar from './ProgressBar';
import OnboardingHeader from './OnboardingHeader';
import OnboardingContent from './OnboardingContent';
import Card from '../components/Card';

export default function OnboardingLayout() {
  const { onboardingStep } = useInputs();
  const stepIndex = steps.findIndex((step) => step === onboardingStep);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="24px"
      p="24px"
      m="0 auto"
      sx={{
        maxWidth: (theme) => theme.settings.maxWidth.body,
      }}
    >
      <Card justifyContent={'center'} display="flex">
        <ProgressBar stepIndex={stepIndex} />
      </Card>
      <Card>
        <Box maxWidth="910px" margin="0 auto" pt="64px">
          <OnboardingHeader />
          <OnboardingContent />
        </Box>
      </Card>
    </Box>
  );
}
