import React, { useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { CarFrontIcon, BoltIcon } from "../../assets/icons/icons";
import { useInputs } from "@bellawatt/use-inputs";
import FinancialsTable from "./FinancialsTable";
import { useIntl } from "react-intl";
import TotalCostChart from "./TotalCostChart";
import StyledLineGraph from "../../components/charts/StyledLineGraph";
import { formatAsDollars } from "../../utils/formatters";
import TableTitle from "../../components/TableTitle";
import getDefaultRate from "../../calculations/rates/defaultRate";
import ToolTip from "../../components/ToolTip";
import { useCalculations } from "../../utils/useCalculations";
import { getMaintenanceValues, getVehicleDuty, getVehicleFuel, getVehicleType } from "../../utils/calculationsUtils";
import GlobalData from "../../calculations/GlobalData";
import { useEffect } from "react";

function SummaryBox({ icon, title, text, preTitle, tooltip }) {
  return (
    <Box
      p={"16px"}
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      bgcolor="#C3D7EE"
      borderRadius="16px"
      height="100%"
    >
      <Box mt={"8px"}>{icon}</Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Typography
          color={"#363D44"}
          align="left"
          fontSize="14px"
          fontWeight="400"
        >
          {preTitle}
        </Typography>
        <Box display="flex" flexDirection="row">
          <Typography
            variant="primary"
            fontSize="14px"
            fontWeight="700"
            color={"#2D4774"}
            mb={preTitle ? '0' : "8px"}
          >
            {title}
          </Typography>
          {tooltip && (
            <Box>
              <ToolTip content={tooltip}></ToolTip>
            </Box>
          )}
        </Box>

        <Typography
          color={"#363D44"}
          align="left"
          fontSize="14px"
          fontWeight="400"
          textAlign={"center"}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

export default function Financials() {
  const {
    vehicleSets,
    electricityPrice,
    lifespanYears,
    dieselPrice,
    gasolinePrice,
    apiEvaluatedIncentives,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
    chargerInstallationCost,
    setInput,
    state,
    carbonCreditsPrice,
    includeChargerInstallationCost,
    includeChargerCost
  } = useInputs();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const Calculations = useCalculations();
  const [totalValues, setTotalValues] = useState([])
  const { data } = GlobalData()
  const [customInstallationCosts, setCustomInstallationCosts] = useState(chargerInstallationCost);
  const rate = getDefaultRate(electricityPrice);
 
  let customVehicleSets = JSON.parse(JSON.stringify(vehicleSets));

  if (!includeChargerCost) {
    customVehicleSets.forEach(vehicleSet => {
      vehicleSet.chargingWindows.forEach(chargingWindow => {
        if (chargingWindow.charger) {
          chargingWindow.charger.price = 0;
        }
      });
    });
  }
 
  const tmpVehicleSets=customVehicleSets.map((vehicleSet) => ({
    ...vehicleSet,
    chargingWindows: vehicleSet.chargingWindows.map((cw) => ({
      ...cw,
      charger: {
        ...cw.charger,
        chargerType: cw.charger.chargerType === "level_2" ? "ac" : "dc",
      }
      
    })),
  }));

  useEffect(() => {
    const cost = !includeChargerInstallationCost
    ? 0
    : (Calculations.getInstallationCosts({  
          input: tmpVehicleSets,
        level2ChargersInstallationCost,
        dcfcChargersInstallationCost,
      }));

    setCustomInstallationCosts(cost)
    if (chargerInstallationCost !== customInstallationCosts) {
      setInput({ chargerInstallationCost: customInstallationCosts });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setCustomInstallationCosts(chargerInstallationCost)
  
  }, [chargerInstallationCost])

  const totalVehicleCount = vehicleSets.reduce((total, set) => total + set.vehicleCount, 0);

  const totalCostParams = {
    dieselPrice,
    gasolinePrice,
    rate,
    incentives: apiEvaluatedIncentives,
    lifespanYears,
    carbonCreditsPrice,
    chargerInstallationCost: customInstallationCosts,
  };

  const totalRetiringFleetCosts = Calculations.totalCosts({
    input: customVehicleSets,
    ...totalCostParams,
    isRetiringFleet: true,
    state,
  });
  
  const totalElectrifiedFleetCosts = Calculations.totalCosts({
    input: customVehicleSets,
    ...totalCostParams,
    isRetiringFleet: false,
    state,
  });

  const handleGasData = () => {
    const values = []; 

    const fuelValue = Calculations.annualFossilFuelCosts({
      input: customVehicleSets,
      dieselPrice,
      gasolinePrice,
    }) * totalVehicleCount;
    const maintenanceValue = data?.maintenanceCostByYear?.fossil ?? [];
    const insuranceValue = data?.insuranceCostByYear?.fossil ?? []; 
    const resale = data?.fleetResaleValue?.fossil
   
    let currentValue = fuelValue + maintenanceValue[0] + insuranceValue[0] + totalRetiringFleetCosts.byCategory.purchase;
    values.push(currentValue);

    for (let i = 1; i < maintenanceValue.length; i++) {
      currentValue = values[i - 1] + fuelValue + maintenanceValue[i] + insuranceValue[i];
      values.push(currentValue);
    }

    if(values.length > lifespanYears) {
      values.splice(-(values.length - lifespanYears))
    }

    const lastIndex = values.length - 1;
    values[lastIndex] += resale;
    
    return values;
  }

  const handleEvData = () => {
    const values = []; 

    const fuelValue = Calculations.annualElectricityCosts({
      input: customVehicleSets,
      rate,
    });
    const maintenanceValue = data?.maintenanceCostByYear?.electric ?? [];
    const insuranceValue = data?.insuranceCostByYear?.electric ?? []; 
    const resale = data?.fleetResaleValue?.electric
    const evByCategory = totalElectrifiedFleetCosts.byCategory

    let currentValue = fuelValue + maintenanceValue[0] + insuranceValue[0] + evByCategory.purchase + evByCategory.incentives + evByCategory.installationCosts + evByCategory.chargers;
    values.push(currentValue);

    for (let i = 1; i < maintenanceValue.length; i++) {
      currentValue = values[i - 1] + fuelValue + maintenanceValue[i] + insuranceValue[i];
      values.push(currentValue);
    }

    if(values.length > lifespanYears) {
      values.splice(-(values.length - lifespanYears))
    }
    const lastIndex = values.length - 1;
    values[lastIndex] += resale;

    return values;
  }
 
  const gasData = handleGasData();
  const electricData = handleEvData();

  const breakevenYear = () => {
    let i = 0;
    const len = gasData.length;
    while (i < len) {
      if (gasData[i] >= electricData[i]) {
        return i + 1;
      }
      ++i;
    }

    return 0;
  };

  const labels = gasData.map(
    (_, idx) => `${formatMessage({ id: "panels.financials.year" })} ${idx + 1}`
  );

  const xTicks = {
    max: 100,
    min: 0,
    callback: (_, idx) =>
      `${formatMessage({ id: "panels.financials.year" })} ${idx + 1}`,
    padding: 20,
    color: "#333333",
    font: {
      size: 12,
      weight: "700",
    },
  };

  const yTicks = {
    min: 0,
    callback: function (value) {
      value = value.toString();
      return formatAsDollars(value);
    },
    color: theme.palette.grey[900],
    font: {
      size: 12,
    },
  };

  const graphData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: "panels.financials.electricVehicles" }),
        fill: false,
        data: electricData,
        pointRadius: 0,
        borderWidth: 4,
        borderColor: "#0072CE",
        backgroundColor: "#0072CE",
      },
      {
        label: formatMessage({ id: "panels.financials.fossilFuelVehicles" }),
        fill: false,
        data: gasData,
        pointRadius: 0,
        borderWidth: 4,
        borderColor: "#05C3DD",
        backgroundColor: "#05C3DD",
      },
    ],
  };

  const totalSavings = data && data.totalFleetSavings.total;
  const noBreakEven = totalSavings <= 0

  const totalMaintenanceCost = (isIce) => {
    const costArray = new Array(lifespanYears).fill(0).map((_, index) => 
      vehicleSets.reduce((accumulator, set) => {
          const vehicleType = isIce ? getVehicleType(set.existingVehicle.type) : getVehicleType(set.replacementVehicle.type);
          const vehicleDuty = isIce ? getVehicleDuty(set.existingVehicle.subtype) : getVehicleDuty(set.replacementVehicle.subtype);
          const vehicleFuel = isIce ? getVehicleFuel(set.existingVehicle.fuel) : getVehicleFuel(set.replacementVehicle.fuel);

          const [baselineMiles, slope, intercept] = getMaintenanceValues(vehicleType, vehicleDuty, vehicleFuel);
          
          const maintenanceValues = {
            baselineMiles: baselineMiles,
            slope: slope,
            intercept: intercept,
          };

          Calculations?.setData({maintenanceValues: maintenanceValues});

          const maintenanceCost = Calculations.annualMaintenanceCost({ 
            input: set, 
            isRetiringFleet: isIce, 
            ageInYears: index+1, 
            includePersonal: set.personalMilesPaidFor,
            maintenanceValues: maintenanceValues
          });

          return accumulator + maintenanceCost;
      }, 
      0)
    );
    return costArray.reduce((p,a) => p+ a, 0);
  }

  const totalFleetMaintenance = {
    electric: totalMaintenanceCost(false) * totalVehicleCount,
    fossil: totalMaintenanceCost(true) * totalVehicleCount
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          display="grid"
          wrap="wrap"
          gridTemplateColumns="repeat(auto-fill, minmax(48%, 1fr))"
          gap="20px"
          justifyContent="space-between"
          mb="48px"
        >
          <Box minWidth="256px" height="100%">
            {noBreakEven ?
              <SummaryBox
                  title={formatMessage({ id: "panels.financials.summary.noBreakevenTitle" },)}  
                  text={formatMessage({
                    id: "panels.financials.summary.noBreakevenTxt",
                  })}
                  icon={<BoltIcon />}
                />  
                              
              :<SummaryBox
                title={formatMessage(
                  { id: "panels.financials.summary.afterTitle" },
                  {
                    years: breakevenYear(),
                    plural: breakevenYear() > 1 ? "s" : "",
                  }
                )}  
                text={formatMessage({
                  id: "panels.financials.summary.afterDesc",
                })}
                icon={<BoltIcon />}
              />
            }

          </Box>
          <Box minWidth="256px" height="100%">
            {!noBreakEven ? 
              <SummaryBox
                title={formatMessage(
                  { id: "panels.financials.summary.savingsTitle" },
                  { value: totalSavings && formatAsDollars((totalSavings).toFixed(2)) }
                )}
                text={formatMessage({
                  id: "panels.financials.summary.savingsDesc",
                })}
                preTitle={formatMessage({
                  id: "panels.financials.summary.savingsPre",
                })}
                icon={<CarFrontIcon />}
              /> :
              <SummaryBox
                text={formatMessage({
                  id: "panels.financials.summary.noSavings",
                })}
                icon={<CarFrontIcon />}
              />
            }
            
          </Box>
        </Grid>

        <Box>
          <TableTitle
            isLowCase
            title={formatMessage({ id: "panels.financials.CumulativeTitle" })}
            mb="0px"
          />
          <StyledLineGraph
            suggestedYMax={100}
            data={graphData}
            yTicks={yTicks}
            xTicks={xTicks}
          />
        </Box>

        <TotalCostChart
          totalRetiringFleetCosts={totalRetiringFleetCosts}
          totalElectrifiedFleetCosts={totalElectrifiedFleetCosts}
          totalValues={totalValues}
        />
        <Box mt="24px">
          <TableTitle
            isLowCase
            title={formatMessage({ id: "panels.financials.eletrictTitle" })}
            mb="16px"
          />
          <FinancialsTable
            totalRetiringFleetCosts={totalRetiringFleetCosts}
            totalElectrifiedFleetCosts={totalElectrifiedFleetCosts}
            state={state}
            totalFleetMaintenance={totalFleetMaintenance}
            totalVehicleCount={totalVehicleCount}
            setTotalValues={setTotalValues}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
