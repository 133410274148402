import { Box, useTheme, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import DropdownInput from '../../components/DropdownInput';

const rangeConfig = {
  all: {
    rangeMin: 0,
    rangeMax: 0,
  },
  lessThan250: {
    rangeMin: 0,
    rangeMax: 249,
  },
  from250to500: {
    rangeMin: 250,
    rangeMax: 499,
  },
  over500: {
    rangeMin: 500,
    rangeMax: 0,
  },
};

export default function Filters({ onChange, filters, typeOptions, subtypeOptions, makeOptions }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const rangeOptions = [
    {
      value: 'all',
      children: formatMessage({ id: 'inputs.chargerCatalog.all' }),
    },
    {
      value: 'lessThan250',
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.lessThan' }),
    },
    {
      value: 'from250to500',
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.between' }),
    },
    {
      value: 'over500',
      children: formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.over' }),
    },
  ];

  return (
    <Box mb={4}>
      <Typography fontSize="16px" py="20px">
        {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.filters' })}
      </Typography>
      <DropdownInput
        id="vehicle-types-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.type' })}
        options={typeOptions}
        value={filters.type}
        onChange={(e) => {
          onChange('type', e.target.value);
        }}
        mb={3}
      />
      <DropdownInput
        id="vehicle-subtype-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.subtype' })}
        options={subtypeOptions}
        value={filters.subtype}
        onChange={(e) => {
          onChange('subtype', e.target.value);
        }}
        mb={3}
      />
      <Box borderBottom={`1px solid ${theme.palette.grey[300]}`} mb={3}>
        <DropdownInput
          id="vehicle-make-filter-input"
          label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.make' })}
          options={makeOptions}
          value={filters.make}
          onChange={(e) => {
            onChange('make', e.target.value);
          }}
          mb={3}
        />
      </Box>
      <DropdownInput
        id="vehicle-range-filter-input"
        label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.vehicleRange' })}
        options={rangeOptions}
        value={Object.keys(rangeConfig).find(
          (key) =>
            rangeConfig[key].rangeMin === filters.rangeMin &&
            rangeConfig[key].rangeMax === filters.rangeMax,
        )}
        onChange={(e) => {
          onChange('range', rangeConfig[e.target.value]);
        }}
      />
    </Box>
  );
}
