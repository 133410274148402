import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import {
  GasCanIcon,
  CarIcon,
  StarIcon,
  SavingsIcon,
  EmissionsIcon,
} from "../assets/icons/icons";
import { useIntl } from "react-intl";
import { useInputs } from "@bellawatt/use-inputs";
import {
  formatAsCents,
  formatAsDollars,
  formatAsThousands,
} from "../utils/formatters";
import sum from "lodash/sum";
import ToolTip from "./ToolTip";
import { useCalculations } from "../utils/useCalculations";
import GlobalData from "../calculations/GlobalData";

const SummaryText = ({ title, value, tooltip }) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box
        fontSize="11px"
        lineHeight="1.25"
        display="flex"
        flexDirection="row"
        alignItems="flex-start"
      >
        <Box mt={0.6}>{title}</Box>
        {tooltip && <ToolTip content={tooltip}></ToolTip>}
      </Box>
      <Typography fontSize="20px" variant="primary">
        {value}
      </Typography>
    </Box>
  );
};

const SummaryBox = ({ icon, children }) => {
  return (
    <Box display="flex" justifyContent="start">
      <Box alignItems="center" display="flex" pl={2} mr={3}>
        <Box
          mr={3}
          flexShrink={0}
          width="36px"
          height="36px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="4px"
        >
          {icon}
        </Box>
        <Box pr={0}>{children}</Box>
      </Box>
    </Box>
  );
};

const Divider = () => (
  <Box
    width="2px"
    backgroundColor={(theme) => theme.palette.common.border}
    flexShrink={0}
  />
);

export default function FleetSummary() {
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();
  const {
    vehicleSets,
    electricityEmissions,
    lifespanYears,
    apiEvaluatedIncentives,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
    chargerInstallationCost,
    setInput,
    includeChargerInstallationCost,
    includeChargerCost,
  } = useInputs();

  let customVehicleSets = JSON.parse(JSON.stringify(vehicleSets));
  const { data } = GlobalData()

  if (!includeChargerCost) {
    customVehicleSets.forEach(vehicleSet => {
      vehicleSet.chargingWindows.forEach(chargingWindow => {
        if (chargingWindow.charger) {
          chargingWindow.charger.price = 0;
        }
      });
    });
  }

  const tmpVehicleSets=customVehicleSets.map((vehicleSet) => ({
    ...vehicleSet,
    chargingWindows: vehicleSet.chargingWindows.map((cw) => ({
      ...cw,
      charger: {
        ...cw.charger,
        chargerType: cw.charger.chargerType === "level_2" ? "ac" : "dc",
      }
      
    })),
  }));

  let customInstallationCosts = !includeChargerInstallationCost
    ? 0
    : (Calculations.getInstallationCosts({
        input: tmpVehicleSets,
        level2ChargersInstallationCost,
        dcfcChargersInstallationCost,
      }));

  useEffect(() => {
    if (chargerInstallationCost !== customInstallationCosts) {
      setInput({ chargerInstallationCost: customInstallationCosts });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const totalVehicles = sum(
    customVehicleSets.map(({ vehicleCount }) => vehicleCount)
  );

  const totalMilesPerSet = customVehicleSets.map((set) => {
    return Calculations.annualMiles({ input: [set] }) * set.vehicleCount;
  });

  const incentivesAmount = sum(
    apiEvaluatedIncentives.map(({ estimated_amount }) => estimated_amount)
  );

  const emissionsData = Calculations.emissionsData({
    input: customVehicleSets,
    electricityEmissions,
    lifespanYears,
  });

  const emissionsDataPerSet = vehicleSets.map((set) => {
    const {
      totalDieselMiles,
      totalDieselEmissions,
      fossilFuelVolume,
      totalMwh,
    } = Calculations.emissionsData({
      input: [set],
      electricityEmissions,
      lifespanYears,
    });

    return {
      totalDieselMiles: totalDieselMiles * set.vehicleCount,
      fossilFuelVolume: fossilFuelVolume * set.vehicleCount,
      c02emissionsByFossil: totalDieselEmissions * set.vehicleCount,
      totalMwh: totalMwh,
    };
  });

  const tonsSaved =
    (emissionsDataPerSet.reduce(
      (acc, setResult) => acc + setResult.c02emissionsByFossil,
      0
    ) +
      emissionsData.totalGasolineEmissions -
      emissionsData.electricityEmissionsFromEVs) /
    2204.6;

  const totalAnnualSavings = (data.totalFleetSavings.fuel + data.totalFleetSavings.insurance + data.totalFleetSavings.maintenance) / lifespanYears;

  const perHourSavings =
    totalAnnualSavings /
    totalMilesPerSet.reduce((acc, value) => acc + value, 0);

  return (
    <Box justifyContent="center">
      <Box width="100%">
        <Box width="100%" mb="20px" fontSize="16px" fontWeight="700">
          {formatMessage({ id: "fleetSummary.title" })}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <SummaryBox icon={<SavingsIcon />}>
            <SummaryText
              title={formatMessage({ id: "fleetSummary.lifetime.label" })}
              value={formatAsDollars(data.totalFleetSavings.total)}
              tooltip={formatMessage(
                { id: "fleetSummary.lifetime.tooltip" },
                { value: lifespanYears }
              )}
            />
          </SummaryBox>

          <Divider />

          <SummaryBox icon={<CarIcon />}>
            <SummaryText
              title={formatMessage({ id: "fleetSummary.size" })}
              value={totalVehicles}
            />
          </SummaryBox>

          <Divider />

          <SummaryBox icon={<GasCanIcon />}>
            <Box display="flex" justifyContent="space-between">
              <Box pr={3}>
                <SummaryText
                  title={formatMessage({
                    id: "fleetSummary.fuelSavings.label",
                  })}
                  value={formatAsDollars(totalAnnualSavings)}
                  tooltip={formatMessage({
                    id: "fleetSummary.fuelSavings.tooltip",
                  })}
                />
              </Box>
              <SummaryText
                title={formatMessage({ id: "fleetSummary.perMile" })}
                value={formatAsCents(perHourSavings)}
              />
            </Box>
          </SummaryBox>

          <Divider />

          <SummaryBox icon={<StarIcon />}>
            <Box display="flex">
              <Box maxWidth="170px">
                <SummaryText
                  title={formatMessage({ id: "fleetSummary.incentives.label" })}
                  value={formatAsDollars(incentivesAmount)}
                  tooltip={formatMessage({
                    id: "fleetSummary.incentives.tooltip",
                  })}
                />
              </Box>
            </Box>
          </SummaryBox>

          <Divider />

          <SummaryBox icon={<EmissionsIcon />}>
            <SummaryText
              title={formatMessage({ id: "fleetSummary.emissions.label" })}
              value={formatMessage(
                { id: "fleetSummary.tons" },
                { value: formatAsThousands(tonsSaved * lifespanYears) }
              )}
              tooltip={formatMessage({ id: "fleetSummary.emissions.tooltip" })}
            />
          </SummaryBox>
        </Box>
      </Box>
    </Box>
  );
}
