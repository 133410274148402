import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme, Button, ClickAwayListener, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../utils/formatters';
import { parse, format } from 'date-fns';
import organizationTypes from '../../data/organizationTypes';
import { useDisplay } from '../../components/DisplayProvider';
import Link from '../../components/Link';
import ByZappy from '../../components/ByZappy';
import EligibilityRationale from './EligibilityRationale';

const URL_REGEX =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
const isUrl = (str) => URL_REGEX.test(str);

const IncentiveSpec = ({ title, value }) => (
  <Box mb={4}>
    <Typography fontSize="0.85rem" my={1} variant="primary" display="block">
      {title}
    </Typography>
    <Typography fontSize="0.85rem" my={1}>
      {value}
    </Typography>
  </Box>
);

export default function IncentiveCard({ incentive, isEligible }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();

  const {
    grantor_name,
    eligibility,
    current_program_status,
    estimated_amount,
    incentive_name,
    incentive_description,
    expiration_date,
    typical_amount,
    eligible_organizations,
    total_funding,
    current_funding,
    incentive_focus,
    incentive_url,
    incentive_application_url,
  } = incentive;

  const [descriptionTooltipOpen, setDescriptionTooltipOpen] = useState(false);

  const isLongDescription = incentive_description.length > 250;

  const formattedExpirationDate = format(
    parse(expiration_date, 'yyyy-MM-dd', new Date()),
    'LLL d, yyyy',
  );

  const formattedOrganizations =
    eligible_organizations.length === organizationTypes.length
      ? formatMessage({ id: 'panels.incentives.all' })
      : eligible_organizations
          .map((o) => {
            const translationKey = organizationTypes.find(({ id }) => id === o)?.translationKey;
            return translationKey ? formatMessage({ id: translationKey }) : null;
          })
          .filter((o) => o)
          .join(', ');

  const formattedApplicability = incentive_focus
    .map((a) => a.charAt(0).toUpperCase() + a.slice(1))
    .join(', ');

  const getEligibilityColor = (eligibility) => {
    switch (eligibility) {
      case 'eligible':
        return '#6CC24A';
      case 'ineligible':
        return '#E35205';
      default:
        return theme.palette.grey[900];
    }
  };

  const getEligibilityText = (eligibility, programStatus) => {
    if (eligibility === 'eligible') return 'eligible';

    switch (programStatus) {
      case 'hiatus':
        return 'hiatus';

      case 'waitlist':
        return 'waitlist';

      case 'closed':
        return 'closed';

      default:
        return 'ineligible';
    }
  };

  return (
    <Box display="flex">
      <Box
        flexShrink={0}
        width="16px"
        backgroundColor={isEligible ? '#0072CE' : '#333333'}
        border={`1px solid ${isEligible ? '#0072CE' : '#333333'}`}
        sx={{
          borderRadius: '16px 0 0 16px',
        }}
      />
      <Box
        px={3}
        pt="10px"
        pb="16px"
        border="1px solid #BBBBBB"
        backgroundColor={isEligible ? 'transparent' : '#EEEEEE'}
        flexGrow={1}
        borderLeft="transparent"
        borderRadius="0 5px 5px 0"
        sx={{
              '@media (max-width: 768px)': {
                     width: '95%',
              },
       }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={3}
          py={2}
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
        >
          <Typography fontSize="18px" fontWeight="700">
            {grantor_name}
          </Typography>
          <Typography fontSize="18px" fontWeight="700">
            {formatMessage(
              { id: 'panels.incentives.status' },
              {
                status: (
                  <Typography
                    component="span"
                    fontSize="18px"
                    fontWeight="700"
                    color={getEligibilityColor(eligibility)}
                  >
                    {formatMessage({
                      id: `panels.incentives.${getEligibilityText(
                        eligibility,
                        current_program_status,
                      )}Status`,
                    })}
                  </Typography>
                ),
              },
            )}
          </Typography>
        </Box>

        <Grid container columnSpacing={4}>
          <Grid item xs={mode === 'print' ? 6 : 12} md={6}>
            <Typography
              fontSize="22px"
              fontWeight='700'
              mb={2}
              variant="primary"
              color={isEligible ? '#0072CE' : '#333333'}
            >
              {formatAsDollars(estimated_amount)}
            </Typography>

            <Typography fontSize="0.85rem" my={1} variant="primary" display="block">
              {incentive_name}
            </Typography>

            <Typography fontSize="0.875rem" mb={1}>
              {isLongDescription
                ? incentive_description.slice(0, 160) + '...'
                : incentive_description}

              {isLongDescription && (
                <ClickAwayListener onClickAway={() => setDescriptionTooltipOpen(false)}>
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setDescriptionTooltipOpen(true)}
                    open={descriptionTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={incentive_description}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: 'black',
                          backgroundColor: 'white',
                          border: `1px solid ${theme.palette.grey[300]}`,
                          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
                          borderRadius: '2px',
                          fontSize: '14px',
                          fontWeight: 300,
                          px: 2,
                          py: 1,
                          maxWidth: 400,
                        },
                      },
                    }}
                  >
                    <Button
                      onClick={() => setDescriptionTooltipOpen(true)}
                      sx={{
                        textTransform: 'none',
                        p: 0,
                        verticalAlign: 'text-top',
                        lineHeight: '1rem',
                        fontSize: '0.875 rem',
                        color: '#0072CE',
                        fontWeight: '200',
                      }}
                    >
                      {formatMessage({ id: 'panels.incentives.more' })}
                    </Button>
                  </Tooltip>
                </ClickAwayListener>
              )}
            </Typography>

            <Typography fontSize="0.85rem" my={1} variant="primary" display="block">
              {formatMessage({ id: 'panels.incentives.eligibilityAnalysis' })}
            </Typography>

            <EligibilityRationale incentive={incentive} />
          </Grid>

          <Grid item xs={mode === 'print' ? 3 : 12} md={3}>
            <Typography fontSize="1rem" mb={2} fontWeight="700">
              {formatMessage({ id: 'panels.incentives.details' })}
            </Typography>
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.closingDate' })}
              value={formattedExpirationDate}
            />
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.typicalAmount' })}
              value={typical_amount}
            />
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.organizations' })}
              value={formattedOrganizations}
            />
          </Grid>

          <Grid item xs={mode === 'print' ? 3 : 6} md={3} mb={5}>
            <Typography fontSize="1rem" mb={2} fontWeight="700">
              {formatMessage({ id: 'panels.incentives.fundingBreakdown' })}
            </Typography>
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.totalAvailable' })}
              value={current_funding ? formatAsDollars(current_funding) : '-'}
            />
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.fundingToDate' })}
              value={total_funding ? formatAsDollars(total_funding) : '-'}
            />
            <IncentiveSpec
              title={formatMessage({ id: 'panels.incentives.applicability' })}
              value={formattedApplicability}
            />
          </Grid>

          <Grid item xs={12}>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              gap="8px"
              mb={2}
              pt={3}
              borderTop={`1px solid ${theme.palette.grey[300]}`}
              sx={{
                     '@media (max-width: 768px)': {
                            flexDirection: 'column',
                     },
              }}
            >
              <Box
                display="flex"
                width="300px"
                alignItems="center"
                justifyContent="space-between"
                flexDirection="row"
                gap="8px"
                sx={{
                     '@media (max-width: 768px)': {
                            flexDirection: 'column',
                     },
              }}
              >
                <Link to={incentive_url} external variant="outlinedNoTransform" width="150px">
                  {formatMessage({ id: 'panels.incentives.learnMore' })}
                </Link>
                {isUrl(incentive_application_url) && eligibility === 'eligible' && (
                  <Link to={incentive_application_url} external variant="filled" width="150px">
                    {formatMessage({ id: 'panels.incentives.applyNow' })}
                  </Link>
                )}
              </Box>

              <Box justifyContent="flex-end" display="flex">
                <ByZappy>{formatMessage({ id: 'panels.incentives.summarizedBy' })}</ByZappy>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
