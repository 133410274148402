/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import times from 'lodash/times';

moment.tz.setDefault('America/New_York');

const hourlyDates = {};

const generateHoursOfYear = (year) => {
  const profileTime = moment(year, 'Y');

  return times(profileTime.isLeapYear() ? 8784 : 8760, (hourOfYear) => {
    const val = {
      month: profileTime.month(),
      dayOfWeek: profileTime.day(),
      hourStart: profileTime.hour(),
      date: profileTime.format('YYYY-MM-DD'),
      hourOfYear,
    };

    profileTime.add(1, 'hour');
    return val;
  });
};

export const getHoursOfYear = (year) => {
  if (!hourlyDates[year]) {
    hourlyDates[year] = generateHoursOfYear(year);
  }

  return hourlyDates[year];
};

export const isHourInUse = ({ start, finish }, hour) => {
  if (start > finish) {
    return hour >= start || hour < finish;
  }
  return hour >= start && hour < finish;
};
