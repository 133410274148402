import { Box, FormControl, Input, Select, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { ChevronIcon, LockerIcon } from '../assets/icons/icons';
import ToolTip from './ToolTip';

export default function DropdownInput({
  id,
  error,
  required,
  disabled,
  placeholder,
  label,
  labelBold,
  tooltip,
  value,
  onChange,
  options,
  inputStyle,
  labelStyle,
  ...rest
}) {
  return (
    <Box {...rest}>
      <FormControl fullWidth>
        <Box
          component="label"
          mb={1}
          sx={{
            width: '100%',
            color: (theme) => (error ? theme.palette.common.red[400] : theme.palette.grey[900]),
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            textAlign: 'left',
          }}
        >
          <Box
            sx={{
              fontSize: '14px',
              fontWeight: '300',
              display: 'flex',
              gap: '4px',
              height: '18px',
              color: (theme) => (error ? theme.palette.common.red[400] : '#1A1A1A'),
              ...labelStyle,
            }}
          >
            {labelBold ? (
              <Typography color="#404040" fontWeight="700" fontSize="14" {...labelStyle} >
                {label}
              </Typography>
            ) : (
              label
            )}
            {required && <Typography color='#E35205' fontSize='14px'>*</Typography>}
          </Box>
          <Box display="flex" alignItems="center">
            <Select
              value={value}
              disabled={disabled}
              onChange={onChange}
              fullWidth
              variant="outlined"
              IconComponent={(props) => {
                let isOpen = props?.className?.includes('iconOpen');

                return <ChevronIcon rotate={!isOpen} transition className={props?.className} style={{marginRight: '12px'}}/>;
              }}
              input={
                <Input
                  disableUnderline
                  onChange={(ev) => onChange(ev.currentTarget.value)}
                  className="input"
                  sx={(theme) => ({
                    border: '1px solid #BBBBBB',
                    borderRadius: '5px',
                    width: '100%',
                    height: '60px', 
                    padding: '16px 12px',
                    '&.Mui-error': {
                      borderColor: theme.palette.common.red[400],
                    },
                    '&.Mui-disabled': {
                      backgroundColor: theme.palette.grey[100],
                    },
                    ...inputStyle,
                  })}
                />
              }
            >
              {options.map(({ value: val, children }) => (
                <MenuItem key={val} value={val}>
                  {children}
                </MenuItem>
              ))}
            </Select>
            {tooltip && <ToolTip content={tooltip}></ToolTip>}
            {disabled && <Box ml='8px'><LockerIcon /></Box>}
          </Box>
        </Box>
      </FormControl>
    </Box>
  );
}
