import React, { useState } from "react";
import { useIntl } from "react-intl";
import CollapsibleInput from "./CollapsibleInput";
import DropdownInput from "../components/DropdownInput";
import booleanOptions from "../data/booleanOptions";
import { useInputs } from "@bellawatt/use-inputs";
import TextFieldInput from "../components/TextFieldInput";
import { Box } from "@mui/system";
import { Checkbox, Typography } from "@mui/material";

export default function SiteInputs() {
  const { formatMessage } = useIntl();
  const [touched, setTouched] = useState({});

  const touchField = (fieldName) =>
    setTouched({ ...touched, [fieldName]: true });

  const { isMultipleSites, zipCode, isInADac, setInput} =
    useInputs();

  return (
    <CollapsibleInput
      title={formatMessage({ id: "inputs.location.title" })}
      customTitleSx={{ textTransform: "none", fontWeight: "400" }}
    >
      {!isMultipleSites && (
        <Box mb={2}>
          <TextFieldInput
            mask="99999"
            value={zipCode}
            label={formatMessage({ id: "inputs.location.zipCode.label" })}
            tooltip={formatMessage(
              { id: 'inputs.location.zipCode.tooltip' },
              {
                     usEnergyLink: (
                            <a href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a" target="_blank" rel="noreferrer">
                                   {formatMessage({ id: 'inputs.location.zipCode.usEnergyLink' })}
                            </a>
                     ),
                     aaaLink: (
                            <a href="https://gasprices.aaa.com/" target="_blank" rel="noreferrer">
                                   {formatMessage({ id: 'inputs.location.zipCode.aaaLink' })}
                            </a>
                     ),
              },
            )}
            onBlur={(v) => {
              touchField("zipCode");
              setInput({ zipCode: v });
            }}
            inputProps={{ maxLength: 5 }}
            maxLength={5}
            type="number"
            error={
              touched.zipCode &&
              !/[0-9]{5}/.test(zipCode) &&
              formatMessage({ id: "inputs.location.zipCode.formatError" })
            }
          />
        </Box>
      )}
      <Box display="flex" mb={2}>
        <Checkbox
          checked={isMultipleSites}
          onChange={(e) => setInput({ isMultipleSites: e.target.checked })}
          inputProps={{ "aria-label": "Purchasing for multiple sites" }}
          disableRipple
          sx={{ marginRight: ".1rem", alignSelf: "flex-start" }}
        />
        <Box>
          <Typography fontSize="0.875rem" mb={0.5} mt={1.4}>
            {formatMessage({
              id: "inputs.assumptions.multipleSitesPurchase.header",
            })}
          </Typography>
          <Typography fontSize="0.75rem">
            {formatMessage({
              id: "inputs.assumptions.multipleSitesPurchase.subheader",
            })}
          </Typography>
        </Box>
      </Box>
      <DropdownInput
        id="in-a-dac-input"
        label={formatMessage({ id: "inputs.location.inDac.label" })}
        options={booleanOptions.map(({ id, translationKey }) => ({
          value: id,
          children: formatMessage({ id: translationKey }),
        }))}
        value={isInADac ? "yes" : "no"}
        onChange={(e) => setInput({ isInADac: e.target.value === "yes" })}
        tooltip={formatMessage({ id: "inputs.location.inDac.tooltip" })}
        mb={1.5}
      />
    </CollapsibleInput>
  );
}
