import { Box } from '@mui/material';
import { styled } from '@mui/system';

const Card = styled(Box)(({ props }) => ({
  backgroundColor: 'white',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 5px rgba(0, 0, 0, 0.1)',
  borderRadius: '6px',
  padding: '24px',
}));

export default Card;
