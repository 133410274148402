import React from 'react';
import { BurgerMenuIcon, PrintIcon } from '../assets/icons/icons';
import {
  Tabs,
  Tab,
  Grid,
  Box,
  useTheme,
  Link,
  useMediaQuery,
  Typography,
  ButtonBase,
} from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import { useMobileMenu } from '../components/MobileMenuProvider';
import Loading from '../components/Loading';

export default function TabContent({ tabList }) {
  const { selectedTab, apisLoading, setInput } = useInputs();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const { isOpen, setIsOpen } = useMobileMenu();

  const anyApisLoading = Object.keys(apisLoading).some((key) => apisLoading[key]);

  return (
    <Grid container justifyContent="center" alignContent="stretch" width="100%">
      {anyApisLoading && (
        <Loading />
      )}
      {isLargeScreen && (
        <Grid item width="100%">
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={selectedTab}
            onChange={(_, newTab) => setInput({ selectedTab: newTab })}
            TabIndicatorProps={{ children: <span /> }}
            sx={{
              backgroundColor: 'transparent',
              minHeight: 'auto',
              '& .MuiTabs-indicator': {
                zIndex: 1,
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                background: 'transparent',
                "& > span": {
                  maxWidth: 75,
                  width: "100%",
                  background: () => `linear-gradient(to right, #0072CE, #38E8FF) !important`,
                }
              },
              '& .MuiTabs-flexContainer': {
                justifyContent: 'space-between',
              },
            }}
          >
            {tabList.map(({ label }, index) => {
              return (
                <Tab
                  sx={{
                    color: theme.palette.grey[900],
                    fontSize: '14px',
                    fontWeight: 400,
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    flexGrow: 1,
                    padding: '20px 60px',
                    textTransform: 'none',
                    '&.Mui-selected': {
                      color: theme.palette.grey[900],
                      fontWeight: 700,
                      zIndex: 2,
                      borderBottom: 'none !important'
                    },
                    '&.MuiTab-root': {
                      borderBottom: 'none !important'
                    },

                  }}
                  label={label}
                  value={index}
                  key={index}
                  disableRipple
                />
              );
            })}
          </Tabs>
        </Grid>
      )}
      {isLargeScreen && (
        <Grid item xs={12} height="90px">
          <Box justifyContent="end" display="flex" height="100%" alignItems="center">
            <Link sx={{ cursor: 'pointer' }} href="/print" underline="none">
              <Box
                display="flex"
                p={1}
                alignItems="center"
                color={theme.palette.primary.main}
                border={`1px solid #0072CE`}
                borderRadius="6px"
                sx={{ '&:hover': { border: `1px solid borderColor: '#0072CE'` } }}
              >
                <PrintIcon />
              </Box>
            </Link>
          </Box>
        </Grid>
      )}

      {!isLargeScreen && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          pb={2}
          mb={4}
          borderBottom={`1px solid ${theme.palette.grey[300]}`}
        >
          <Typography fontSize={16} fontWeight={700}>
            {tabList[selectedTab].label}
          </Typography>
          <ButtonBase onClick={() => setIsOpen(!isOpen)}>
            <BurgerMenuIcon />
          </ButtonBase>
        </Box>
      )}
      <Grid item xs={12}>
        {tabList[selectedTab].content}
      </Grid>
    </Grid>
  );
}
