import { LoadProfile, RateCalculator } from '@bellawatt/electric-rate-engine';
import BatterySchedule from './BatterySchedule';
import { YEAR } from '../utils/assumptions';
import CHARGERS from '../data/CHARGERS';
import comprehensiveRates from '../data/insurance/comprehensive.json';
import liabilityRates from '../data/insurance/liability.json';

/**
 *
 * @param {FossilVehicle|ElectricVehicle} vehicle
 * @returns {number}
 */
export function calcResaleValue(vehicle, yearsOfOperation) {
  if (vehicle.duty === 'light_duty')
    return vehicle.msrp * 0.9429 * Math.exp(-0.084 * yearsOfOperation);
  if (vehicle.duty === 'heavy_duty')
    return (
      vehicle.msrp *
      (-0.0003 * Math.pow(yearsOfOperation, 3) +
        0.0136 * Math.pow(yearsOfOperation, 2) -
        0.2056 * yearsOfOperation +
        1.2336)
    );

  return vehicle.msrp * 0.8826 * Math.exp(-0.1 * yearsOfOperation);
}

const VehicleSet = {
  annualHours(vehicleSet) {
    const {
      workdays,
      workmonths,
      hoursPerWorkdayHigh,
      hoursPerWorkdayMedium,
      hoursPerWorkdayLow,
      vehicleCount,
    } = vehicleSet;

    const hoursPerDay = hoursPerWorkdayHigh + hoursPerWorkdayMedium + hoursPerWorkdayLow;

    const annualHours = hoursPerDay * 365.25 * (workdays.length / 7) * (workmonths.length / 12);

    return vehicleCount * annualHours;
  },

  calculateAnnualHours(vehicleSet, hoursPerDay) {
    const { workdays, workmonths, vehicleCount } = vehicleSet;

    const annualHours = hoursPerDay * 365.25 * (workdays.length / 7) * (workmonths.length / 12);

    return vehicleCount * annualHours;
  },

  annualEvKwh(vehicleSet) {
    const {
      workdays,
      workmonths,
      hoursPerWorkdayHigh,
      hoursPerWorkdayMedium,
      hoursPerWorkdayLow,
      vehicleCount,
      replaceMentVehicle: {
        efficiencyInHoursPerKwhHeavy,
        efficiencyInHoursPerKwhMedium,
        efficiencyInHoursPerKwhLight,
      },
    } = vehicleSet;

    const resultPerDay =
      hoursPerWorkdayHigh * efficiencyInHoursPerKwhHeavy +
      hoursPerWorkdayMedium * efficiencyInHoursPerKwhMedium +
      hoursPerWorkdayLow * efficiencyInHoursPerKwhLight;

    const annualKwh = resultPerDay * 365.25 * (workdays.length / 7) * (workmonths.length / 12);

    return vehicleCount * annualKwh;
  },

  annualIceGallons(vehicleSet) {
    const {
      workdays,
      workmonths,
      hoursPerWorkdayHigh,
      hoursPerWorkdayMedium,
      hoursPerWorkdayLow,
      vehicleCount,
      existingVehicle: { efficiencyHeavyLoad, efficiencyMediumLoad, efficiencyLightLoad },
    } = vehicleSet;

    const resultPerDay =
      hoursPerWorkdayHigh * efficiencyHeavyLoad +
      hoursPerWorkdayMedium * efficiencyMediumLoad +
      hoursPerWorkdayLow * efficiencyLightLoad;

    const gallonsPerYear = resultPerDay * 365.25 * (workdays.length / 7) * (workmonths.length / 12);

    return vehicleCount * gallonsPerYear;
  },

  annualFossilFuelCosts(vehicleSet, { dieselPrice, gasolinePrice }) {
    const { existingVehicle } = vehicleSet;

    const priceDictionary = {
      diesel: dieselPrice,
      gasoline: gasolinePrice,
      gas: gasolinePrice,
    };

    const price = priceDictionary[existingVehicle.fuel];

    return price * this.annualIceGallons(vehicleSet);
  },

  annualBatterySchedule: BatterySchedule.annualBatterySchedule,

  averageWorkdayBatterySchedule: BatterySchedule.averageWorkdayBatterySchedule,

  annualLoadProfile(vehicleSet, includePersonal = true) {
    const rawData = this.annualBatterySchedule(vehicleSet, includePersonal).map(
      ({ chargedKwh }) => chargedKwh,
    );

    return new LoadProfile(rawData, { year: YEAR });
  },

  annualElectricityCosts(vehicleSet, rate) {
    const loadProfile = this.annualLoadProfile(vehicleSet, vehicleSet.personalMilesPaidFor);

    const rateCalculator = new RateCalculator({
      ...rate,
      loadProfile,
    });

    return rateCalculator.annualCost();
  },

  annualInsuranceCosts(vehicle, count, state) {
    if (!state) return 0;
    const perVehicleLiabilityRate =
      liabilityRates[state.toLowerCase()][vehicle.formFactor.toLowerCase()];
    const perVehicleComprehensiveRate =
      comprehensiveRates[state.toLowerCase()][vehicle.formFactor.toLowerCase()] *
      Math.round(vehicle.msrp / 1000);
    return (perVehicleLiabilityRate + perVehicleComprehensiveRate) * count;
  },

  annualEvInsuranceCosts(vehicleSet, state) {
    return this.annualInsuranceCosts(vehicleSet.replacementVehicle, vehicleSet.vehicleCount, state);
  },

  annualFossilInsuranceCosts(vehicleSet, state) {
    return this.annualInsuranceCosts(vehicleSet.existingVehicle, vehicleSet.vehicleCount, state);
  },

  minimumStateOfCharge(vehicleSet) {
    const batterySchedule = this.annualBatterySchedule(vehicleSet);

    const minCharge = Math.min(...batterySchedule.map((x) => x.startKwh));
    const maxCharge = Math.max(...batterySchedule.map((x) => x.startKwh));

    return Math.round((minCharge / maxCharge) * 100);
  },

  recommendedCharger(vehicleSet, { start, finish }) {
    const {
      replacementVehicle: {
        efficiencyInHoursPerKwhHeavy,
        efficiencyInHoursPerKwhMedium,
        efficiencyInHoursPerKwhLight,
      },
      hoursPerWorkdayHigh,
      hoursPerWorkdayMedium,
      hoursPerWorkdayLow,
    } = vehicleSet;

    const resultPerDay =
      hoursPerWorkdayHigh * efficiencyInHoursPerKwhHeavy +
      hoursPerWorkdayMedium * efficiencyInHoursPerKwhMedium +
      hoursPerWorkdayLow * efficiencyInHoursPerKwhLight;

    // kWh required to fully charge on a workday
    const fullChargeKwh = resultPerDay;
    const chargingHours = start < finish ? finish - start : 23 - start + finish;
    const minKw = fullChargeKwh / chargingHours;

    // make sure an acceptable charger exists
    const chargerKws = CHARGERS.map(({ portKw }) => portKw);
    const maxChargerKw = Math.max(...chargerKws);
    if (minKw > maxChargerKw) return null;

    // find charger with lowest kW above the minimum kW, if it exists
    const recommendedKw = Math.min(...chargerKws.filter((portKw) => portKw >= minKw));

    return CHARGERS.find(({ portKw }) => portKw >= minKw && portKw === recommendedKw);
  },

  evYearAdjusted(ageInYears) {
    return (
      0.0004 * ageInYears ** 4 -
      0.0128 * ageInYears ** 3 +
      0.126 * ageInYears ** 2 -
      0.1762 * ageInYears +
      1.0563
    );
  },

  iceYearAdjusted(ageInYears) {
    return (
      0.0002 * ageInYears ** 4 -
      0.0065 * ageInYears ** 3 +
      0.0547 * ageInYears ** 2 +
      0.1258 * ageInYears +
      0.7821
    );
  },

  //TODO: review
  annualEvMaintenanceCost(vehicleSet, ageInYears) {
    const { replacementVehicle } = vehicleSet;
    return (
      this.annualHours(vehicleSet) *
      replacementVehicle.maintenanceCostPerHour *
      (ageInYears > 1 ? this.evYearAdjusted(ageInYears) : 1)
    );
  },

  //TODO: review
  annualIceMaintenanceCost(vehicleSet, ageInYears) {
    const { existingVehicle } = vehicleSet;

    return (
      this.annualHours(vehicleSet) *
      existingVehicle.maintenanceCostPerHour *
      (ageInYears > 1 ? this.iceYearAdjusted(ageInYears) : 1)
    );
  },
};

export default VehicleSet;
