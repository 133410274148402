import { useInputs } from '@bellawatt/use-inputs';
import { InputAdornment } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import TextFieldInput from '../components/TextFieldInput';
import CollapsibleInput from './CollapsibleInput';
import Link from '../components/Link';

const StyledLink = ({ href, children }) => {
  return (
    <Link to={href} external variant="tooltip" target="_blank" textTransform="none" color='#fff'>
      {children}
    </Link>
  );
};

export default function FuelInputs() {
  const { formatMessage } = useIntl();

  const { dieselPrice, electricityPrice, setInput } = useInputs();

  return (
    <CollapsibleInput title={formatMessage({ id: 'inputs.fuel.title' })} hasBottomBorder customTitleSx={{textTransform: 'none', fontWeight: '400'}}>
      <TextFieldInput
        value={dieselPrice.toFixed(4)}
        label={formatMessage({ id: 'inputs.fuel.dieselPrice.label' })}
        tooltip={formatMessage(
          { id: 'inputs.fuel.dieselPrice.tooltip' },
          { link: <StyledLink href="https://gasprices.aaa.com/">AAA</StyledLink> },
        )}
        onBlur={(v) => setInput({ dieselPrice: parseFloat(v), userUpdatedDiesel: true })}
        inputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
        mb={4}
      />

      <TextFieldInput
        value={electricityPrice.toFixed(4)}
        label={formatMessage({ id: 'inputs.fuel.electricityPrice.label' })}
        tooltip={formatMessage(
          { id: 'inputs.fuel.electricityPrice.tooltip' },
          {
            link: (
              <StyledLink href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a">
                {formatMessage({ id: 'inputs.fuel.electricityPrice.link' })}
              </StyledLink>
            ),
          },
        )}
        onBlur={(v) => setInput({ electricityPrice: parseFloat(v), userUpdatedElectricity: true })}
        inputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        type="number"
        mb={4}
      />
    </CollapsibleInput>
  );
}
