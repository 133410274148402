import { Box, Typography, useMediaQuery } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
// import VehicleSet from '../../calculations/VehicleSet';
import AnnualMaintenanceCostsChart from './AnnualMaintenanceCostsChart';
import AnnualMaintenanceInsuranceChart from './AnnualMaintenanceInsuranceChart';
import Alert from '../../components/Alert';
import { BoltIcon } from '../../assets/icons/icons';
import { useIntl } from 'react-intl';
import OperationsTable from './OperationsTable';
import { formatAsDollars, formatAsCents, formatAsThousands } from '../../utils/formatters';
import CollapsibleInput from '../../inputs/CollapsibleInput';
import Link from '../../components/Link';
import { useCalculations } from '../../utils/useCalculations';
import { getMaintenanceValues, getVehicleDuty, getVehicleFuel, getVehicleType } from '../../utils/calculationsUtils';
import GlobalData from '../../calculations/GlobalData';

export default function Operations() {
  const { vehicleSets, lifespanYears } = useInputs();
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const Calculations = useCalculations();
  const { data } = GlobalData();

  const fossilMaintenanceCosts = {
    values: new Array(lifespanYears).fill(0),
    miles: new Array(lifespanYears).fill(0)
  };
  
  vehicleSets.forEach(set => {
    const vehicleType = getVehicleType(set.existingVehicle.type);
    const vehicleDuty = getVehicleDuty(set.existingVehicle.subtype);
    const vehicleFuel = getVehicleFuel(set.existingVehicle.fuel);
  
    const [baselineMiles, slope, intercept] = getMaintenanceValues(vehicleType, vehicleDuty, vehicleFuel);
    
    const maintenanceValues = {
      baselineMiles: baselineMiles,
      slope: slope,
      intercept: intercept,
    };
  
    Calculations?.setData({maintenanceValues: maintenanceValues});
  
    for (let index = 0; index < lifespanYears; index++) {
      const maintenanceCost = Calculations.annualMaintenanceCost({ 
        input: set, 
        isRetiringFleet: true, 
        ageInYears: index+1, 
        includePersonal: set.personalMilesPaidFor,
        maintenanceValues: maintenanceValues
      });
      const maintenanceMiles = Calculations.annualMaintenanceCost({ 
        input: set, 
        isRetiringFleet: true, 
        ageInYears: index+1, 
        includePersonal: set.personalMilesPaidFor,
        maintenanceValues: maintenanceValues,
        getMiles: true
      });
  
      fossilMaintenanceCosts.values[index] += maintenanceCost * set.vehicleCount;
      fossilMaintenanceCosts.miles[index] += maintenanceMiles * set.vehicleCount;
    }
  });

  const evMaintenanceCosts = {
    values: new Array(lifespanYears).fill(0),
    miles: new Array(lifespanYears).fill(0)
  };
  
  vehicleSets.forEach(set => {
    const vehicleType = getVehicleType(set.replacementVehicle.type);
    const vehicleDuty = getVehicleDuty(set.replacementVehicle.subtype);
    const vehicleFuel = getVehicleFuel(set.replacementVehicle.fuel);
  
    const [baselineMiles, slope, intercept] = getMaintenanceValues(vehicleType, vehicleDuty, vehicleFuel);
    
    const maintenanceValues = {
      baselineMiles: baselineMiles,
      slope: slope,
      intercept: intercept,
    };
  
    Calculations?.setData({maintenanceValues: maintenanceValues});
  
    for (let index = 0; index < lifespanYears; index++) {
      const maintenanceCost = Calculations.annualMaintenanceCost({ 
        input: set, 
        isRetiringFleet: false, 
        ageInYears: index+1, 
        includePersonal: set.personalMilesPaidFor,
        maintenanceValues: maintenanceValues
      });
      const maintenanceMiles = Calculations.annualMaintenanceCost({ 
        input: set, 
        isRetiringFleet: false, 
        ageInYears: index+1, 
        includePersonal: set.personalMilesPaidFor,
        maintenanceValues: maintenanceValues,
        getMiles: true
      });
  
      evMaintenanceCosts.values[index] += maintenanceCost * set.vehicleCount;
      evMaintenanceCosts.miles[index] += maintenanceMiles * set.vehicleCount;
    }
  });

  const totalMaintenanceCost = (isIce) => {
    const costArray = new Array(lifespanYears).fill(0).map((_, index) => 
      vehicleSets.reduce((accumulator, set) => {
          const vehicleType = isIce ? getVehicleType(set.existingVehicle.type) : getVehicleType(set.replacementVehicle.type);
          const vehicleDuty = isIce ? getVehicleDuty(set.existingVehicle.subtype) : getVehicleDuty(set.replacementVehicle.subtype);
          const vehicleFuel = isIce ? getVehicleFuel(set.existingVehicle.fuel) : getVehicleFuel(set.replacementVehicle.fuel);

          const [baselineMiles, slope, intercept] = getMaintenanceValues(vehicleType, vehicleDuty, vehicleFuel);
          
          const maintenanceValues = {
            baselineMiles: baselineMiles,
            slope: slope,
            intercept: intercept,
          };

          Calculations?.setData({maintenanceValues: maintenanceValues});

          const maintenanceCost = Calculations.annualMaintenanceCost({ 
            input: set, 
            isRetiringFleet: isIce, 
            ageInYears: index+1, 
            includePersonal: set.personalMilesPaidFor,
            maintenanceValues: maintenanceValues
          });

          return accumulator + maintenanceCost;
      }, 
      0)
    );
    return costArray.reduce((p,a) => p+ a, 0);
  }


  // AVERAGE
  const totalVehicleCount = vehicleSets.reduce((total, set) => total + set.vehicleCount, 0);
  const vehicleSetCount = vehicleSets.length;
  const totasMaintenanceEv =  (totalMaintenanceCost(false) * totalVehicleCount) / vehicleSetCount 
  const totasMaintenanceFossil = (totalMaintenanceCost(true) * totalVehicleCount) / vehicleSetCount

  const totalMilesDriven = vehicleSets.reduce((accumulator, set) => {
    const milePerYear = Calculations.annualMiles({ input: set, fossilFuelType: set.existingVehicle.fuel }) * set.vehicleCount;
    return accumulator + milePerYear;
  }, 0);

  const averageElectricMaintenanceCost = totasMaintenanceEv / lifespanYears;
  const averageElectricMiles = averageElectricMaintenanceCost / totalMilesDriven;

  const averageFossilMaintenanceCost = totasMaintenanceFossil / lifespanYears;
  const averageFossilMiles = averageFossilMaintenanceCost / totalMilesDriven;
  
  const firstTable = [
    {
      title: formatMessage({ id: "panels.operations.tableRows.costPerMile" }),
      value: formatAsCents(averageElectricMiles),
    },
    {
      title: formatMessage({ id: "panels.operations.tableRows.costPerYear" }),
      value: formatAsDollars(averageElectricMaintenanceCost),
    },
    {
      title: formatMessage({ id: "panels.operations.tableRows.totalCost" }),
      value: formatAsDollars(totasMaintenanceEv),
    },
  ];
  
  const secondTable = [
    {
      title: formatMessage({ id: "panels.operations.tableRows.costPerMile" }),
      value: formatAsCents(averageFossilMiles),
    },
    {
      title: formatMessage({ id: "panels.operations.tableRows.costPerYear" }),
      value: formatAsDollars(averageFossilMaintenanceCost),
    },
    {
      title: formatMessage({ id: "panels.operations.tableRows.totalCost" }),
      value: formatAsDollars(totasMaintenanceFossil),
    },
  ];

  return (
    <Box mb={6}>

      <Box mb={8}>
        <Alert variant="regular" customIcon={<BoltIcon />}>
          {formatMessage({ id: 'panels.operations.alertTab' })}
        </Alert>
      </Box>
      <Box
        display="flex"
        mb="24px"
        style={{
          width: "100%",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <OperationsTable
          title={formatMessage({
            id: "panels.operations.tableTitle1",
          }).toUpperCase()}
          array={firstTable}
        />
        <OperationsTable
          title={formatMessage({
            id: "panels.operations.tableTitle2",
          }).toUpperCase()}
          array={secondTable}
        />
      </Box>


      <Box mb={8}>
        <Typography fontSize="22px" fontWeight="200">
          {formatMessage({ id: 'panels.operations.graphTitle' }).toUpperCase()}
        </Typography>
        <AnnualMaintenanceCostsChart
          fossilFleetCosts={fossilMaintenanceCosts.values}
          evFleetCosts={evMaintenanceCosts.values}
        />
      </Box>

      <Box mb={8}>
        <CollapsibleInput 
          title={formatMessage({ id: 'panels.operations.accordeon.title' })}
          hasAllBorder
          customContainerSx={{ m: 1 }}
          customTitleSx={{
            fontSize: "22px",
            textTransform: "none",
            fontWeight: "200",
          }}
        >
            <Box px={4}>
              <Typography fontWeight={300} mb='8px'>{formatMessage({ id: 'panels.operations.accordeon.subTitle' })}</Typography>
              <Box>
                <Typography fontWeight={300}> {formatMessage({id: 'panels.operations.accordeon.exampleTitle'})} </Typography>
                <ul>
                  <li><Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon.bullet1' }, {count: 3, annualMiles: formatAsThousands(76268)})}</Typography></li>
                    <li><Typography fontWeight={300}>{formatAsCents(0.05)} {formatMessage({ id: 'panels.operations.accordeon.perMileOne' })}</Typography></li>
                    <li><Typography fontWeight={300}>{formatAsCents(0.30)} {formatMessage({ id: 'panels.operations.accordeon.perMileLife' }, {lifetime: 10})} </Typography></li>
                    <li><Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon.yearToYear' }, {value: 0.0278})} </Typography></li>
                    <li><Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon.totalMaintenance'}, {value: '$xx,xxx'})} </Typography></li>
                </ul>
              </Box>
              <Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon.source' }, {link: <a href="https://afleet.es.anl.gov/afleet/" target='_blank' rel="noreferrer">{formatMessage({ id: 'panels.operations.accordeon.link' })}</a>})}</Typography>

              <Typography fontWeight={300} m='12px 0' >{formatMessage({ id: 'panels.operations.accordeon.methodologyExample' })}</Typography>
              <Typography fontWeight={300} m='12px 0' >{formatMessage({ id: 'panels.operations.accordeon.lightDieselTitle' })}</Typography>
              <ul style={{margin: 0}}>
                <li>
                  <Typography fontWeight={300} m='12px 0' >{formatMessage({ id: 'panels.operations.accordeon.lightDieselText' })}</Typography>
                </li>
              </ul>

              <Typography fontWeight={300} m='12px 0' >{formatMessage({ id: 'panels.operations.accordeon.lightElectricTitle' })}</Typography>
              <ul style={{margin: 0}}>
                <li>
                  <Typography fontWeight={300} m='12px 0' >{formatMessage({ id: 'panels.operations.accordeon.lightElectricText' })}</Typography>
                </li>
              </ul>

              <Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon.slopeDesc' })} </Typography>
            </Box>
        </CollapsibleInput>
      </Box>


      <Box mb={8}>
        <Typography fontSize="22px" fontWeight="200">
          {formatMessage({ id: 'panels.operations.graphTitle2' }).toUpperCase()}
        </Typography>
        <AnnualMaintenanceInsuranceChart
          fossilFleetCosts={data.insuranceCostByYear.fossil}
          evFleetCosts={data.insuranceCostByYear.electric}
        />
      </Box>

 
      <Box mb={8}>
        <CollapsibleInput 
          title={formatMessage({ id: 'panels.operations.accordeon2.title' })}
          hasAllBorder
          customContainerSx={{ m: 1 }}
          customTitleSx={{
            fontSize: "22px",
            textTransform: "none",
            fontWeight: "200",
          }}
        >
            <Box px={4}>
              <Typography fontWeight={300}>{formatMessage({ id: 'panels.operations.accordeon2.subTitle' })}</Typography>
              <Typography fontWeight={300} mb='12px'>
                {formatMessage({ id: 'panels.operations.accordeon2.insuranteEstimates' }, {link: <Link to="https://greet.es.anl.gov/files/afleet-tool-2020" external variant='tooltip'>{formatMessage({ id: 'panels.operations.accordeon2.link' })}</Link>})}
              </Typography>
              <Box>
                <Typography fontWeight={300} mb='-12px'>{formatMessage({ id: 'panels.operations.accordeon2.listTitle' })}</Typography>
                <ol>
                  <li><Typography fontWeight={300}>{formatMessage({ id: 'panels.operations.accordeon2.bullet1' })}</Typography></li>
                  <li><Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon2.bullet2' })}</Typography></li>
                  <li><Typography fontWeight={300}> {formatMessage({ id: 'panels.operations.accordeon2.bullet3' })}</Typography></li>
                  <li><Typography fontWeight={300}>{formatMessage({ id: 'panels.operations.accordeon2.bullet4' })}</Typography></li>
                </ol>
              </Box>
              <Typography fontWeight={300}>{formatMessage({ id: 'panels.operations.accordeon2.comprehensiveInsurante' })}</Typography>
            </Box>
        </CollapsibleInput>
      </Box>
    </Box>
  );
}
