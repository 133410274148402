// MAPPING
const maintenanceData = {
  'step_van': {
    'Light': {
      'Gasoline': [16500, 0.01521, 0.0105],
      'Diesel': [16500, 0.02321, 0.01575],
      'Electric': [16500, 0.01571, 0.01065]
    },
    'Medium': {
      'Gasoline': [16500, 0.01707, 0.0105],
      'Diesel': [16500, 0.02604, 0.01575],
      'Electric': [16500, 0.01762, 0.01065]
    }
  },
  'cargo_van': {
    'Light': {
      'Diesel': [27000, 0.04217, 0.0225],
      'Electric': [27000, 0.01749, 0]
    },
    'Medium': {
      'Diesel': [27000, 0.04721, 0.0225],
      'Electric': [27000, 0.01957, 0.009]
    }
  },
  'Delivery Step Van': {
    'Light': {
      'Gasoline': [16500, 0.01521, 0.0105],
      'Diesel': [16500, 0.02321, 0.01575],
      'Electric': [16500, 0.01571, 0.01065]
    },
    'Medium': {
      'Gasoline': [16500, 0.01707, 0.0105],
      'Diesel': [16500, 0.02604, 0.01575],
      'Electric': [16500, 0.01762, 0.01065]
    }
  },
  'Utility Cargo Van': {
    'Light': {
      'Diesel': [27000, 0.04217, 0.0225],
      'Electric': [27000, 0.01749, 0]
    },
    'Medium': {
      'Diesel': [27000, 0.04721, 0.0225],
      'Electric': [27000, 0.01957, 0.009]
    }
  }
};

export function getMaintenanceValues(
  vehicleType,
  vehicleDuty,
  fuelType
){
  if (!maintenanceData[vehicleType] || !maintenanceData[vehicleType][vehicleDuty]) {
    return undefined;
  }
  
  return maintenanceData[vehicleType][vehicleDuty][fuelType];
}

// USE
// const [baselineMiles, slope, intercept] = getMaintenanceValues('step_van', 'Light', 'Gasoline') || [0, 0, 0];

// Convert `vehicle.type` to the corresponding value
export function getVehicleType(type) {
  const vehicleTypeUpperCase = type.toUpperCase();
  if (vehicleTypeUpperCase.includes("CARGO VAN")) {
      return "cargo_van";
  } else if (vehicleTypeUpperCase.includes("STEP VAN")) {
      return "step_van";
  } else {
      return null;
  }
}

// Convert `vehicle.duty` to the corresponding value
export function getVehicleDuty(duty){
  const vehicleDutyUpperCase = duty.toUpperCase();
  if (vehicleDutyUpperCase.includes("LIGHT")) {
      return "Light";
  } else if (vehicleDutyUpperCase.includes("MEDIUM")) {
      return "Medium";
  } else {
      return null;
  }
}

export function getVehicleFuel(fuel) {
  const vehicleDutyUpperCase = fuel.toUpperCase();
  if (vehicleDutyUpperCase.includes("DIESEL")) {
      return "Diesel";
  } else if (vehicleDutyUpperCase.includes("BEV")) {
      return "Electric";
  } else if (vehicleDutyUpperCase.includes("ELECTRIC")) {
    return "Electric";
  } else if (vehicleDutyUpperCase.includes("GASOLINE")) {
    return "Gasoline";
  } else {
      return null;
  }
}
