import { getHoursOfYear, isHourInUse } from '../utils/time';
import { YEAR } from '../utils/assumptions';
import times from 'lodash/times';
import mean from 'lodash/mean';

const calcKwhPerDay = (vehicleSet, includePersonal) => {
  const {
    replacementVehicle: {
      batteryCapacityInKwh,
      rangeInMiles,
      rangeInHours,
      category,
      idlingFactor = 0, // if this is constant, it should be added to vehicles via the computed property or on fetch or whatever
      efficiencyInHoursPerKwhHeavy,
      efficiencyInHoursPerKwhMedium,
      efficiencyInHoursPerKwhLight,
    },
    milesPerWorkday,
    personalMilesPerWorkday,
    hoursPerWorkdayHigh,
    hoursPerWorkdayMedium,
    hoursPerWorkdayLow,
    vehicleCount,
  } = vehicleSet;

  const resultPerDay =
    hoursPerWorkdayHigh * efficiencyInHoursPerKwhHeavy +
    hoursPerWorkdayMedium * efficiencyInHoursPerKwhMedium +
    hoursPerWorkdayLow * efficiencyInHoursPerKwhLight;

  const kwhPerMile = batteryCapacityInKwh / rangeInMiles;
  const kwhPerHour = batteryCapacityInKwh / (rangeInHours || 1);
  const idling = 1 - idlingFactor;
  const kwhPerDay =
    category === 'On-Road'
      ? (milesPerWorkday + (includePersonal ? personalMilesPerWorkday : 0)) * kwhPerMile
      : resultPerDay * kwhPerHour;

  return vehicleCount * kwhPerDay * idling;
};

const calcPlugSchedule = ({ chargingWindows }) =>
  [...Array(24).keys()].map((hour) => {
    const firstInUseChargingWindow = chargingWindows.filter((cw) => isHourInUse(cw, hour))[0];

    return {
      chargingApproach: firstInUseChargingWindow?.chargingApproach || null,
      portKw: firstInUseChargingWindow?.charger?.portKw || null,
    };
  });

const calcChargingHour = ({
  vehicleSet,
  startKwh,
  hourStart,
  plugSchedule,
  evenPowerDrawInKwhPerHour,
}) => {
  const {
    replacementVehicle: { batteryCapacityInKwh },
    vehicleCount,
  } = vehicleSet;
  const { chargingApproach, portKw } = plugSchedule[hourStart];

  const totalPortKw = portKw * vehicleCount;

  const availablePowerInKw =
    chargingApproach === 'Max' ? totalPortKw : Math.min(totalPortKw, evenPowerDrawInKwhPerHour); // even if approach is "even", the available power shouldn't surpass the charger's kW availability

  const unchargedBatteryCapacity = vehicleCount * batteryCapacityInKwh - startKwh;
  const actualPowerDrawInKwh = chargingApproach
    ? Math.min(availablePowerInKw, unchargedBatteryCapacity)
    : 0;

  return {
    startKwh,
    finishKwh: startKwh + actualPowerDrawInKwh,
    dischargedKwh: 0,
    chargedKwh: actualPowerDrawInKwh,
  };
};

const BatterySchedule = {
  annualBatterySchedule(vehicleSet, includePersonal = true) {
    const {
      replacementVehicle: { batteryCapacityInKwh, idlingFactor = 1 },
      vehicleCount,
    } = vehicleSet;

    const hoursOfYear = getHoursOfYear(YEAR);

    const plugSchedule = calcPlugSchedule(vehicleSet);
    const kWhPerDay = calcKwhPerDay(vehicleSet, includePersonal);

    const hoursOfCharging = plugSchedule.filter((plug) => plug.portKw).length;
    const hoursAwayFromChargers = 24 - hoursOfCharging;

    const dischargedKwhPerHour = (kWhPerDay * idlingFactor) / hoursAwayFromChargers;

    const evenPowerDrawInKwhPerHour = kWhPerDay / hoursOfCharging;

    const batterySchedule = [];

    hoursOfYear.forEach((date, currIdx) => {
      const { finishKwh } = batterySchedule[currIdx - 1] || {
        finishKwh: batteryCapacityInKwh * vehicleCount,
      };
      const { hourStart } = date;

      const isWorkday =
        vehicleSet.workdays.includes(date.dayOfWeek) && vehicleSet.workmonths.includes(date.month);
      const isDriving = isWorkday && plugSchedule[date.hourStart].portKw === null;

      let hourEntry;
      if (isDriving) {
        hourEntry = {
          startKwh: finishKwh,
          finishKwh: finishKwh - dischargedKwhPerHour,
          dischargedKwh: dischargedKwhPerHour,
          chargedKwh: 0,
        };
      } else {
        // charging
        hourEntry = calcChargingHour({
          vehicleSet,
          evenPowerDrawInKwhPerHour,
          startKwh: finishKwh,
          hourStart,
          plugSchedule,
        });
      }
      batterySchedule[currIdx] = { ...hourEntry, date };
    });

    return batterySchedule;
  },

  averageWorkdayBatterySchedule(vehicleSet) {
    const {
      workdays,
      workmonths,
      vehicleCount,
      replacementVehicle: { batteryCapacityInKwh },
    } = vehicleSet;
    const totalBatteryCapacity = batteryCapacityInKwh * vehicleCount;

    const schedule = this.annualBatterySchedule(vehicleSet);

    // only use battery schedule for valid workdays
    const workdaysSchedule = schedule.filter(
      ({ date: { month, dayOfWeek } }) =>
        workmonths.includes(month) && workdays.includes(dayOfWeek),
    );

    return times(24, (i) => {
      // filter by hour of the day and take the average
      const hourlyStartKwhs = workdaysSchedule
        .filter(({ date: { hourStart } }) => hourStart === i)
        .map(({ startKwh }) => startKwh);

      return Math.round(Math.max(mean(hourlyStartKwhs) / totalBatteryCapacity, 0) * 100);
    });
  },
};

export default BatterySchedule;
