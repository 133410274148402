import React, { useState } from "react";
import { Box, useTheme } from "@mui/material";
import { ChevronIcon } from "../assets/icons/icons";
import { useDisplay } from "../components/DisplayProvider";

export default function CollapsibleInput({
  title,
  defaultOpen = false,
  hasBottomBorder,
  hasAllBorder,
  children,
  customContainerSx,
  customTitleSx,
}) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { mode } = useDisplay();

  return (
    <Box
      border={hasAllBorder && `1px solid ${theme.palette.grey[300]}`}
      width="100%"
      borderRadius="16px"
    >
      <Box
        onClick={() => setIsOpen((prev) => !prev)}
        sx={{ cursor: "pointer", ...customContainerSx }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={2}
        px={2}
        borderTop={hasAllBorder ?? `1px solid ${theme.palette.grey[300]}`}
        borderBottom={
          !hasAllBorder &&
          (isOpen || hasBottomBorder) &&
          `1px solid ${theme.palette.grey[300]}`
        }
        color={theme.palette.grey[900]}
      >
        <Box
          width="100%"
          fontWeight="700"
          fontSize="14px"
          textTransform="uppercase"
          sx={
            customTitleSx
          }
        >
          {title}
        </Box>
        <ChevronIcon rotate={!isOpen} transition />
      </Box>
      {(isOpen || mode === "print") && (
        <Box mt={2} mb={3}>
          {children}
        </Box>
      )}
    </Box>
  );
}
