import React from 'react';
import VehicleSetInputs from './VehicleSetInputs';
import AssumptionInputs from './AssumptionInputs';
import LocationInputs from './LocationInputs';
import FuelInputs from './FuelInputs';
import ResetInputs from './ResetInputs';

export default function LeftInputs() {
  return (
    <>
      <VehicleSetInputs />
      <AssumptionInputs />
      <LocationInputs />
      <FuelInputs />
      <ResetInputs />
    </>
  );
}
