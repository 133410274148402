import { Box, useTheme, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import DropdownInput from '../../components/DropdownInput';

export default function ContentHeader({ subset, total, sortOrder, sortOptions, changeSort }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end" mb={1} width="100%">
      <Box></Box>
      <Box
        display="flex"
        alignItems="center"
        sx={(theme) => ({
          flexDirection: 'column-reverse',
          [theme.breakpoints.up('sm')]: { flexDirection: 'row' },
        })}
      >
        <Typography fontSize="12px" mt="20px" color={theme.palette.grey[900]} mr={1}>
          {formatMessage(
            { id: 'inputs.vehicleSet.vehicleCatalog.showing' },
            { subset: subset, total: total },
          )}
        </Typography>
        <DropdownInput
          id="vehicle-sort-input"
          label={formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.sortBy' })}
          options={sortOptions}
          value={sortOrder}
          onChange={(e) => changeSort(e.target.value)}
        />
      </Box>
    </Box>
  );
}
