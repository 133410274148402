import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import Howmuch from '../../assets/howMuch.svg';
import TellUs from '../../assets/tellUs.svg';
import Vehicle from '../../assets/vehicleBrightDrop.svg';


const IconWithText = ({ icon, text }) => {
  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" width="230px">
      <Grid item sx={{ height: '150px' }}>
        <img src={icon} style={{ height: '150px', width: '150px' }} alt={text} />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            fontWeight: '400',
            textAlign: 'center',
            fontSize: '16px',
            color: '#404040',
            marginTop: '16px',
          }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default function GettingStartedStep() {
  const { formatMessage } = useIntl();

  return (
    <Box display="flex" justifyContent="center" gap="24px"
    sx={{
       '@media (max-width: 768px)': {
         flexDirection: 'column',
         alignItems: 'center',
       },
     }}
    >
      <IconWithText
        icon={TellUs}
        text={formatMessage({ id: 'onboarding.gettingStarted.tellUsOrganization' })}
      />
      <IconWithText
        icon={Vehicle}
        text={formatMessage({ id: 'onboarding.gettingStarted.tellUsVehicle' })}
      />
      <IconWithText
        icon={Howmuch}
        text={formatMessage({ id: 'onboarding.gettingStarted.seeSavings' })}
      />
    </Box>
  );
}
