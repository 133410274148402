// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    handle: 'solectrac_e25g',
    make: 'Solectrac',
    model: '',
    trim: 'e25G',
    fuel: 'BEV',
    type: 'tractor',
    msrp: 85000,
    doors: null,
    horsepower: 25,
    acceleration: null,
    availability: 'released',
    subtype: 'Light Duty',
    duty: 'Y',
    gvwr: null,
    payload: null,
    images: [
      {
        alt: 'Solectrac E25G Icon',
        position: 0,
        url_full: 'solectrac_e25gg.png',
        url_thumbnail: 'solectrac_e25gg.png',
      },
    ],
    electric_vehicle_id: 830,
    available_for_purchase_as_new: true,
    epa_id: 44158,
    fuel_id: 30167,
    model_year: 0,
    form_factor: 'step_van',
    custom_price: null,
    battery_capacity: 173,
    ac_charging_power: 3.3,
    dc_charging_power: null,
    seats_min: 1,
    seats_max: 1,
    cargo_volume_min: null,
    cargo_volume_max: null,
    official_website_url: '',
    eligible_for_ca_rebate: 'No',
    eligible_for_ca_hov: true,
    san_joaquin_valley_ev_rebate: 2000,
    electric_range: 999,
    total_range: 999,
    electric_efficiency: 48,
    is_commercial_only: false,
    is_base_trim: 'Y',
    is_archtype: false,
    is_offroad: false,
    electric_efficiency_unit: 'kWh/hr',
    weight_class: null,
    curb_weight: null,
    lift_capacity: null,
    is_estimated_price: false,
    fuel_types: ['electricity'],
    plug_types: ['J1772'],
    battery_type: 'lithium ion',
    is_overhead_charging_capable: false,
    overhead_charging_power: null,
    is_induction_charging_capable: false,
    induction_charging_power: null,
    maintenance_cost: 0.13,
    maintenance_cost_unit: 'mile',
    is_estimated_maintenance: true,
    is_estimated_battery_capacity: false,
    is_estimated_gvwr: false,
    is_estimated_payload: false,
    is_estimated_plug_types: false,
    display_name: 'Solectrac e25G',
    drivetrain: '4AWD',
    fossil_fuel_efficiency: 27,
    additional_fields: [],
    video_reviews: [],
    equivalent_gas_vehicle: {
      make: 'N/A',
      model: 'N/A',
      msrp: 0,
      picture: 0,
      epa_id: 0,
      model_year: 0,
      fossil_fuel_efficiency: 0,
    },
    electric_efficiency_heavy: 6.79,
    electric_efficiency_medium: 3.75,
    electric_efficiency_light: 2.19,
    runtime: '3.2 - 10',
  },
];
