import { Box, Typography } from '@mui/material';

const OperationsTable = ({ title, array }) => {
  return (
    <Box>
      <Typography fontSize="22px" fontWeight="200" mb="24px">
        {title}
      </Typography>
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        {array.map((item, index) => {
          let isLast = index + 1 === array.length;
          return (
            <Box
              key={`operations-row-${index}`}
              style={{
                height: '40px',
                width: '380px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: !isLast && '1px solid #BBBBBB',
              }}
              sx={{
                     '@media (max-width: 768px)': {
                            flexDirection: 'column',
                            alignItems: 'flex-start !important',
                     },
              }}
            >
              <Typography fontSize="13px" fontWeight="300" color="#3D3935">
                {item.title}
              </Typography>
              <Typography fontSize="13px" fontWeight="300" color="#3D3935">
                {item.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OperationsTable;
