import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { formatAsDollars } from '../../utils/formatters';

export default function ChargerDetails({ charger, isGeneric }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const {
    ports,
    plugTypes,
    price,
    cordLength,
    formFactor,
    outlets,
    ocppVersion,
    oscpVersion,
    vehicleToGrid,
    warranty,
    certifications,
  } = charger;

  const genericInfo = [
    {
      spec: 'panels.charging.infoCard.estimated',
      value: formatAsDollars(price),
    },
    {
      spec: 'panels.charging.infoCard.ports',
      value: ports || '--',
    },
  ];

  const brandedInfo = [
    {
      spec: 'panels.charging.infoCard.estimated',
      value: formatAsDollars(price),
    },
    {
      spec: 'panels.charging.infoCard.type',
      value: plugTypes || '--',
    },
    {
      spec: 'panels.charging.infoCard.cable',
      value: formatMessage({ id: 'panels.charging.infoCard.cableFt' }, { value: cordLength }),
    },
    {
      spec: 'panels.charging.infoCard.ports',
      value: ports || '--',
    },
    {
      spec: 'panels.charging.infoCard.form',
      value: formFactor || '--',
    },
    {
      spec: 'panels.charging.infoCard.outlet',
      value: outlets || '--',
    },
    {
      spec: 'panels.charging.infoCard.ocpp',
      value: ocppVersion || '--',
    },
    {
      spec: 'panels.charging.infoCard.oscp',
      value: oscpVersion || '--',
    },
    {
      spec: 'panels.charging.infoCard.vehicleGrid',
      value: formatMessage({ id: `inputs.booleanOptions.${vehicleToGrid ? 'yes' : 'no'}` }),
    },
    {
      spec: 'panels.charging.infoCard.warranty',
      value: warranty
        ? formatMessage({ id: 'panels.charging.infoCard.warrantyYrs' }, { value: warranty })
        : '--',
    },
    {
      spec: 'panels.charging.infoCard.certs',
      value: certifications || '--',
    },
  ];

  const info = isGeneric ? genericInfo : brandedInfo;

  return (
    <Box>
      {info.map(({ spec, value }, index) => (
        <Box
          key={spec}
          display="flex"
          justifyContent="flex-start"
          py="6px"
          style={{ borderBottom: index + 1 === info.length ? '' : '1px solid #BBBBBB' }}
        >
          <Box width="110px" mr="4px">
            <Typography fontSize="13px" color={theme.palette.grey[900]} fontWeight="600">
              {formatMessage({ id: spec })}
            </Typography>
          </Box>
          <Typography
            fontSize="14px"
            fontWeight="400"
            color={theme.palette.grey[900]}
            textAlign="right"
            maxWidth="140px"
          >
            {value}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
