import { Box } from '@mui/material';
import logo from '../assets/images/icons/zappyride.png';

export default function ByZappy({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        fontSize: '12px',
        fontStyle: 'italic',
        whiteSpace: 'nowrap',
      }}
    >
      {children}
      <img src={logo} alt="ZappyRide" height="16" style={{ marginBottom: '-2px' }} />
    </Box>
  );
}
