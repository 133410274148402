import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import IncentiveCard from './IncentiveCard';
import { CancelIcon } from '../../assets/icons/icons';
import { useInputs } from '@bellawatt/use-inputs';
import { useDisplay } from '../../components/DisplayProvider';
import TableTitle from '../../components/TableTitle';
import Alert from '../../components/Alert';
//import sampleApiEvaluatedIncentives from '../../data/sampleData/sampleApiEvaluatedIncentives'

export default function Incentives() {
  const { formatMessage } = useIntl();
  const { mode } = useDisplay();

  const { apiEvaluatedIncentives } = useInputs();
  const orderedIncentives = apiEvaluatedIncentives.sort(
    (a, b) => a.estimated_amount - b.estimated_amount,
  );
  const eligibleIncentives = orderedIncentives.filter(
    ({ eligibility }) => eligibility === 'eligible',
  );
  const ineligibleIncentives = orderedIncentives.filter(
    ({ eligibility }) => eligibility !== 'eligible',
  );
  return (
    <Grid container>
      <Grid item xs={12} mt={mode === 'print' ? '150px' : '0'}>
        <Box mt={0}>
          <TableTitle
            titleStyle={{
              textTransform: 'none',
            }}
            title={formatMessage({ id: 'panels.incentives.title' })}
            subTitle={formatMessage({ id: 'panels.incentives.subtitle' })}
          />
        </Box>
        <Box mb="24px">
          <Alert>{formatMessage({ id: 'panels.incentives.alert' })}</Alert>
        </Box>
        {eligibleIncentives.map((incentive, idx) => (
          <Box key={idx} mb={2}>
            <IncentiveCard incentive={incentive} isEligible />
          </Box>
        ))}

        {!!ineligibleIncentives.length && (
          <Box
            mt={4}
            mb={2}
            py={1.5}
            px={3}
            backgroundColor="#EEEEEE"
            color="#323232"
            display="flex"
            alignItems="center"
            borderRadius="16px"
          >
            <CancelIcon />
            <Box ml={4}>
              <Typography fontWeight="700">
                {formatMessage({ id: 'panels.incentives.ineligible.header' })}
              </Typography>
              <Typography fontSize="0.875rem">
                {formatMessage({ id: 'panels.incentives.ineligible.subheader' })}
              </Typography>
            </Box>
          </Box>
        )}

        {ineligibleIncentives.map((incentive, idx) => (
          <Box key={idx} mb={2}>
            <IncentiveCard incentive={incentive} isEligible={false} />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
