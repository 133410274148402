import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDisplay } from './DisplayProvider';
import OverviewVehicles from '../tabs/overview/OverviewVehicles';
import Fuel from '../tabs/fuel/Fuel';
import Incentives from '../tabs/incentives/Incentives';
import Comparison from '../tabs/comparison/Comparison';
import Charging from '../tabs/charging/Charging';
import Emissions from '../tabs/emissions/Emissions';
import Electricity from '../tabs/electricity/Electricity';
import Operations from '../tabs/operations/Operations';
import LCFS from '../tabs/lcfs/LCFS';
import { LogoIcon } from '../assets/icons/icons';

const sections = [
  <OverviewVehicles />,
  <Fuel />,
  <Electricity />,
  <Incentives />,
  <Comparison />,
  <Charging />,
  <Operations />,
  <LCFS />,
  <Emissions />,
];

export default function ContentPrint() {
  const { formatMessage } = useIntl();
  const { mode, setMode } = useDisplay();

  useEffect(() => {
    setMode('print');

    return () => {
      setMode('screen');
    };
    // We only want mount and unmount here otherwise infinite loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let timeout;

    if (mode === 'print') {
      //   Delay gives time for charts to render
      timeout = setTimeout(() => window.print(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [mode]);

  return (
    <Box backgroundColor="white">
      <Box
        sx={{
          backgroundColor: 'white',
          minHeight: '64px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <LogoIcon />
      </Box>
      {sections.map((section, index) => (
        <Box sx={{ breakAfter: 'page', mb: '40px' }} key={index}>
          {section}
        </Box>
      ))}
      <Box textAlign="center">
        {formatMessage({ id: 'footer.copyright' }, { year: new Date().getFullYear() })}
      </Box>
    </Box>
  );
}
