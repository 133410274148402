import { Box, Typography } from "@mui/material";
import WarningMessage from "../../components/WarningMessage";
import { useIntl } from "react-intl";
import { formatAsThousands } from "../../utils/formatters";
import TableTitle from "../../components/TableTitle";
import SiteLoadChart from "./SiteLoadChart";
import Alert from "../../components/Alert";
import { useInputs } from "@bellawatt/use-inputs";
import { sum } from "lodash";
import { useCalculations } from "../../utils/useCalculations";

export default function Electricity() {
  const { formatMessage } = useIntl();
  const { vehicleSets } = useInputs();
  const Calculations = useCalculations();

  const monthlyAverage = sum(
    vehicleSets.map((vehicleSet) => {
      let a = Calculations.annualLoadProfile({ input: vehicleSet });
      return (sum(a.sumByMonth()) / vehicleSet.workmonths.length);
    })
  ).toFixed(2)


  const peakAverage = sum(
    vehicleSets.map((vehicleSet) => {
      let a = Calculations.annualLoadProfile({ input: vehicleSet });
      return (sum(a.maxByMonth()) / vehicleSet.workmonths.length);
    })
  ).toFixed(1)


  const warning = 200;

  let isWarning = peakAverage > warning;

  return (
    <Box>
      {isWarning && (
        <Box mb="24px">
          <Alert variant="warning">
            <strong>
              {formatMessage(
                { id: "panels.electricity.warningAlertBold" },
                { peak: peakAverage }
              )}
            </strong>&nbsp;
            {formatMessage({ id: "panels.electricity.warningAlert" })}
          </Alert>
        </Box>
      )}
      <WarningMessage
        variant="electricInfo"
        fullWidth
        message={formatMessage(
          { id: "panels.electricity.infoMessage" },
          {
            peak: <b>{`${peakAverage} kW`}</b>,
            kWh: <b>{`${formatAsThousands(monthlyAverage)} kWh`}</b>,
          }
        )}
      />
      <Box mt={6}>
        <TableTitle
          title={formatMessage({ id: "panels.electricity.chargingLoads" })}
          subTitle={formatMessage({
            id: "panels.electricity.chargingLoadsSummary",
          })}
          isLowCase
        />
      </Box>
      <Box mt={6}>
        <TableTitle
          title={formatMessage({ id: "panels.electricity.loadProfile" })}
          isLowCase
        />
        <Typography color="#3D3935" fontSize="16px" fontWeight="300" lineHeight="20px">
        {formatMessage({ id: "panels.electricity.loadSummaryDisclaimer" })}
        </Typography>
        <SiteLoadChart />
      </Box>
      <p>
        {formatMessage({ id: "panels.electricity.footerNote" })}
      </p>
    </Box>
  );
}
