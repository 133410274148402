
export const chargingTimes = [...Array(24).keys()].map((hourIdx) => ({
  id: hourIdx,
  translationKey: `chargingWindows.chargingTimes.${hourIdx}`,
}));


export const chargingAccess = [
  {
    id: true,
    translationKey: 'chargingWindows.access.open'
  },
  {
    id: false,
    translationKey: 'chargingWindows.access.private'
  }
]