import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useInputs } from '@bellawatt/use-inputs';
import ChargingSets from './ChargingSets';
import ChargingOverTimeChart from './ChargingOverTimeChart';
import VehicleSetList from './VehicleSetList';
import { useIntl } from 'react-intl';
import Alert from '../../components/Alert';
import { DolarIcon } from '../../assets/icons/icons';
import { formatAsDollars } from '../../utils/formatters';
import { useCalculations } from '../../utils/useCalculations';
import ToolTip from "../../components/ToolTip"

export default function Charging() {
  const { vehicleSets, level2ChargersInstallationCost, dcfcChargersInstallationCost, includeChargerInstallationCost, includeChargerCost } = useInputs();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const estimatedChargerCost = Calculations.chargerPurchaseCosts({
    input: vehicleSets
  })

  const tmpVehicleSets=vehicleSets.map((vehicleSet) => ({
    ...vehicleSet,
    chargingWindows: vehicleSet.chargingWindows.map((cw) => ({
      ...cw,
      charger: {
        ...cw.charger,
        chargerType: cw.charger.chargerType === "level_2" ? "ac" : "dc",
      }
      
    })),
  }));
  const defaultInstallationCost = Calculations.getInstallationCosts({
    input: tmpVehicleSets,
    level2ChargersInstallationCost,
    dcfcChargersInstallationCost,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={4}>
          <Typography fontSize="20px" fontWeight="700" variant="primary">
            {formatMessage({ id: "panels.charging.header" })}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {(includeChargerCost || includeChargerInstallationCost) && (
          <Box mb={4}>
            <Alert variant="regular" customIcon={<DolarIcon />}>
              {includeChargerCost && (
                <Box display="flex" alignItems={"center"}>
                  {formatMessage({ id: "panels.charging.alert" })}
                  <Typography
                    fontWeight="700"
                    color="#2D4774"
                    ml="5px"
                    display="flex"
                    verticalAlign="center"
                  >
                    {formatAsDollars(estimatedChargerCost)}&nbsp;
                  </Typography>
                  <ToolTip
                    content={formatMessage({ id: "panels.charging.tooltip" })}
                  />
                </Box>
              )}
              {includeChargerInstallationCost && (
                <Box display="flex" alignItems={"center"}>
                  {formatMessage({ id: "panels.charging.alertInstallation" })}
                  <Typography
                    fontWeight="700"
                    color="#2D4774"
                    ml="5px"
                    display="flex"
                    verticalAlign="center"
                  >
                    {formatAsDollars(defaultInstallationCost)}&nbsp;
                  </Typography>
                  <ToolTip
                    content={formatMessage({ id: "panels.charging.tooltipInstallation" })}
                  />
                </Box>
              )}

            </Alert>
          </Box>
        )}
      </Grid>
      <Grid item xs={12} mt={2}>
        <VehicleSetList />
      </Grid>
      <Grid item xs={12} mt="65px">
        <Box mb={4}>
          <Typography
            fontSize="22px"
            fontWeight="400"
            variant="primary"
            textTransform="capitalize"
          >
            {formatMessage({ id: "panels.charging.chargerAssignment" })}
          </Typography>
        </Box>
        <ChargingSets vehicleSets={vehicleSets} />
      </Grid>
      <Grid item xs={12} mt="65px">
        <ChargingOverTimeChart />
      </Grid>
    </Grid>
  );
}
