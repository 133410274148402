import React from 'react';
import { Box, useTheme } from '@mui/material';
import StyledBarGraph from '../../components/charts/StyledBarGraph';
import { formatAsDollars } from '../../utils/formatters';
import { useIntl } from 'react-intl';

const waterfallTooltip = {
  displayColors: false,
  callbacks: {
    label: (ctx) => {
      const { dataIndex, chart } = ctx;
      const [firstDataset, secondDataset] = chart.data.datasets;
      const firstDatasetValue = firstDataset.data[dataIndex];
      const secondDatasetValue = secondDataset.data[dataIndex];

      if (!firstDatasetValue && !secondDatasetValue) return null;
    
      const firstLabel = `${firstDataset.label}: ${formatAsDollars(firstDatasetValue)}`;
      
      const secondLabel = `${secondDataset.label}: ${formatAsDollars(secondDatasetValue)}`;

      return [firstLabel, secondLabel];
    },
    footer: (tooltipItems) => {
      const tooltip = tooltipItems[0];
      const [firstValue, secondValue] = tooltip.raw;
      const result = Math.abs(secondValue - firstValue);

      return `Difference: ${formatAsDollars(result)}`;
    },
  },
}

export default function SavingsChart({ customLabel, modifiedTotalCostElectric, modifiedTotalCostGas, waterfall }) {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const labels = [...customLabel] 

  const evIsCheaper = [
    modifiedTotalCostElectric.fuel,
    modifiedTotalCostElectric.chargers,
    modifiedTotalCostElectric.installationCosts,
    modifiedTotalCostElectric.maintenance,
    modifiedTotalCostElectric.insurance,
    modifiedTotalCostElectric.purchase,
    modifiedTotalCostElectric.resale,
    modifiedTotalCostElectric.incentives
  ]

  const fossilIsCheaper = [
    modifiedTotalCostGas.fuel,
    modifiedTotalCostGas.chargers,
    modifiedTotalCostGas.installationCosts,
    modifiedTotalCostGas.maintenance,
    modifiedTotalCostGas.insurance,
    modifiedTotalCostGas.purchase,
    modifiedTotalCostGas.resale,
    modifiedTotalCostGas.incentives
  ]

  const chargeData = {
    labels,
    datasets: [
      {
        label: formatMessage({ id: 'panels.overview.fossilCheaper' }),
        data: fossilIsCheaper,
        backgroundColor: '#05C3DD',
        barPercentage: 0.6,
      },
      {
        label: formatMessage({ id: 'panels.overview.evCheaper' }),
        data: evIsCheaper,
        backgroundColor: '#0072CE',
        barPercentage: 0.6,
      }
    ],
  };

  const xTicks = {
    min: 0,
    padding: 20,
    color: theme.palette.grey[800],
    font: {
      size: 11,
      weight: 'bold',
    },
  };

  const yTicks = {
    min: 0,
    callback: (value) => `${formatAsDollars(value)}`,
    color: theme.palette.grey[800],
    padding: 20,
    font: {
      size: 11,
    },
  };

  const LabelsColorComponent = ({color}) =>{
    return (<div style={{ background: color, width: '15px', borderRadius: '15px', height:15}}> </div>)
  }

  const LabelsComponent = () =>{
    return( 
    <div style={{  display: 'flex', justifyContent: 'flex-end', marginTop: 30, columnGap: 10, alignItems: 'center'}}>
     { chargeData.datasets.map(dataset => {
      return (
      <div key={`${dataset.label}-label`} style={{  display: 'flex', columnGap: 10}}>
        <LabelsColorComponent  key={dataset.label} color={dataset.backgroundColor}/>
        {dataset.label}
      </div>
      )
     })}
    <LabelsColorComponent  color={waterfall.summaryBackgroundColor}/>
    {waterfall.summaryLabel}
  </div> )
  }

  return (
    <Box height="400px" maxWidth="890px">
      <LabelsComponent/>
      <StyledBarGraph 
       waterfall={waterfall}
        data={chargeData}
        graphTitle={formatMessage({ id: 'panels.overview.costs' })}
        yTicks={yTicks}
        xTicks={xTicks}
        showLegend={false}
        stacked
        tooltip={waterfallTooltip}
      />
    </Box>
  );
}
