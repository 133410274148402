import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useInputs } from "@bellawatt/use-inputs";
import CollapsibleInput from "../../inputs/CollapsibleInput";
import { useTheme } from "@emotion/react";
import Table from "./Table";
import { useIntl } from "react-intl";
import {
  formatAsDollars,
  formatGenericChargerName,
  formatHours,
} from "../../utils/formatters";
import { isEqual } from "lodash";
import { DAYS_ABBREVIATED } from "../../data/timePeriods";
import { BlueLighningIcon, DarkGasCanIcon } from "../../assets/icons/icons";

const VehicleBoxes = ({ type, srcImage, children, headerColor }) => {
  const theme = useTheme();

  return (
    <Grid borderRadius="16px" border={`1px solid ${theme.palette.grey[300]}`}>
      <Grid
        item
        textAlign="center"
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
        padding="10px"
      >
        {type}
      </Grid>
      <Grid
        container
        direction="row"
        padding="10px 15px"
        flex={1}
        columnSpacing={2}
        alignItems="center"
      >
        <Grid item height="100px" display="flex" alignItems="center">
          <img src={srcImage} alt={type} width="100px" height="auto" />
        </Grid>
        <Grid item flex={1} maxWidth="66%">
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

const OverviewVehicleColumn = ({ renderHeader, specs }) => {
  const { formatMessage } = useIntl();

  const tableItems = [
    {
      title: formatMessage({ id: "panels.overview.table.vehicleSpecs" }),
      items: [
        {
          label: specs.battery
            ? formatMessage({ id: "panels.overview.table.eRange" })
            : formatMessage({ id: "panels.overview.table.range" }),
          value: specs.range,
        },

        {
          label: formatMessage({ id: "panels.overview.table.msrp" }),
          value: specs.msrp,
        },
        {
          label: formatMessage({ id: "panels.overview.table.cargo" }),
          value: specs.cargo,
        },
        {
          label: formatMessage({ id: "panels.overview.table.drivetrain" }),
          value: specs.drivetrain,
        },
      ],
    },
    {
      title: formatMessage({ id: "panels.overview.table.expectedWorkload" }),
      items: [
        {
          label: formatMessage({ id: "panels.overview.table.workdays" }),
          value: specs.workdays,
        },
        {
          label: formatMessage({ id: "panels.overview.table.typicalWorkday" }),
          value: specs.typicalWorkdays,
        },
      ],
    },
  ];

  if (specs.chargingWindows) {
    tableItems[1].items.push({
      label: formatMessage({ id: "panels.overview.table.chargingWindows" }),
      value: specs.chargingWindows.map((chargingWindow) => {
        const { chargerType, portKw } = chargingWindow?.charger;

        const displayType = formatGenericChargerName({
          type: formatMessage({ id: `chargerTypes.${chargerType}` }),
          portKw,
        });

        return (
          <Box paddingBottom="10px" key="charging-window">
            {formatChargingWindows(chargingWindow)}
            <Typography fontSize="12px">{displayType}</Typography>
          </Box>
        );
      }),
    });
  }

  return (
    <Grid item flex={1} width="50%" sx={{
              '@media (max-width: 768px)': {
                     width: '100%',
              },
       }}>
      <Grid container direction="column" rowSpacing={4}>
        <Grid item width="100%">
          {renderHeader()}
        </Grid>
        <Grid item>
          <Grid container direction="column" rowSpacing={4}>
            {tableItems.map((item) => {
              return (
                <Grid item>
                  <Table title={item.title} items={item.items} />
                  {item.footer && (
                    <Typography
                      fontSize="14px"
                      fontStyle="italic"
                      padding="10px"
                    >
                      {item.footer}
                    </Typography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid item paddingTop={4}>
        <Typography fontSize="14px" fontStyle="italic" padding="10px">
          {formatMessage({ id: "panels.overview.standardVehicleSpecs" })}
        </Typography>
      </Grid>
    </Grid>
  );
};

const formatChargingWindows = (chargingWindows) => {
  return `${formatHours(chargingWindows.start)} - ${formatHours(
    chargingWindows.finish
  )}`;
};

export default function Overview() {
  const { vehicleSets } = useInputs();
  const { formatMessage } = useIntl();

  return (
    <Grid container width="100%" rowSpacing={1}>
      {vehicleSets.map((set) => {
        const filteredWorkdays = DAYS_ABBREVIATED.sort(
          (a, b) => a.position - b.position
        ).filter(
          ({ itemIndex, translationKey }) =>
            set.workdays.includes(itemIndex) && translationKey
        );
        const days = filteredWorkdays.map(({ translationKey }) =>
          formatMessage({ id: translationKey })
        );
        return (
          <CollapsibleInput
            title={set.name}
            hasAllBorder
            defaultOpen
            customContainerSx={{ m: 1 }}
            customTitleSx={{
              fontSize: "22px",
              textTransform: "none",
              fontWeight: "400",
            }}
          >
            <Grid
              container
              columnSpacing={4}
              paddingX={4}
              justifyContent="space-around"
              flex="1"
              sx={{
                     '@media (max-width: 768px)': {
                            flexDirection: 'column',
                     },
              }}
            >
              <OverviewVehicleColumn
                specs={{
                  range: formatMessage(
                    {
                      id: "panels.overview.table.miles",
                    },
                    { value: set.existingVehicle.rangeInMiles }
                  ),
                  mpg: `${set.existingVehicle.milesPerGallon} ${formatMessage(
                    {
                      id: "panels.overview.table.units.mpg",
                    },
                    { fuel: set.existingVehicle.fuel }
                  )}`,
                  msrp: formatAsDollars(set.existingVehicle.msrp),
                  cargo: set.existingVehicle.cargoVolume,
                  drivetrain: set.existingVehicle.drivetrain,
                  workdays: isEqual(set.workdays, [1, 2, 3, 4, 5])
                    ? formatMessage({ id: "panels.overview.weekdays" })
                    : days.join(", "),
                  typicalWorkdays: formatMessage(
                    {
                      id: "panels.overview.table.miles",
                    },
                    { value: set.milesPerWorkday }
                  ),
                }}
                renderHeader={() => {
                  return (
                    <VehicleBoxes
                      type={
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={3}
                          justifyContent="center"
                        >
                          <DarkGasCanIcon />
                          <Typography fontWeight="600">
                            {formatMessage({
                              id: "panels.overview.fossilFuelAlternative",
                            })}
                          </Typography>
                        </Box>
                      }
                      srcImage={set.existingVehicle.imageUrl}
                      headerColor="#EEEEEE"
                    >
                      <>
                        <Typography fontSize="14px" fontWeight="600">
                          {formatMessage({
                            id: "panels.overview.selectedVehicle",
                          })}
                        </Typography>
                        <Typography fontSize="17px" fontWeight="500" mt={1}>
                          {`${set.vehicleCount}x ${set.existingVehicle.name}`}
                        </Typography>
                      </>
                    </VehicleBoxes>
                  );
                }}
              />

              <OverviewVehicleColumn
                specs={{
                  range: formatMessage(
                    {
                      id: "panels.overview.table.miles",
                    },
                    { value: set.replacementVehicle.rangeInMiles }
                  ),
                  mpg: `${Number(set.replacementVehicle.milesPerGallon).toFixed(
                    0
                  )} ${formatMessage({
                    id: "panels.overview.table.units.eMpg",
                  })}`,
                  msrp: formatAsDollars(set.replacementVehicle.msrp),
                  cargo: set.replacementVehicle.cargoVolume,
                  drivetrain: set.replacementVehicle.drivetrain,
                  workdays: isEqual(set.workdays, [1, 2, 3, 4, 5])
                    ? formatMessage({ id: "panels.overview.weekdays" })
                    : days.join(", "),
                  typicalWorkdays: formatMessage(
                    {
                      id: "panels.overview.table.miles",
                    },
                    { value: set.milesPerWorkday }
                  ),
                  chargingWindows: set.chargingWindows,
                  battery: true,
                }}
                renderHeader={() => {
                  return (
                    <VehicleBoxes
                      type={
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={3}
                          justifyContent="center"
                        >
                          <BlueLighningIcon />
                          <Typography fontWeight="600">
                            {formatMessage({
                              id: "panels.overview.electricFleet",
                            })}
                          </Typography>
                        </Box>
                      }
                      srcImage={set.replacementVehicle.imageUrl}
                      headerColor="#E5EBF5"
                    >
                      <>
                        <Typography fontSize="14px" fontWeight="600">
                          {formatMessage({
                            id: "panels.overview.replacementVehicle",
                          })}
                        </Typography>
                        <Typography fontSize="17px" fontWeight="500" mt={1}>
                          {`${set.vehicleCount}x ${set.replacementVehicle.name}`}
                        </Typography>
                      </>
                    </VehicleBoxes>
                  );
                }}
              />
            </Grid>
          </CollapsibleInput>
        );
      })}
      <Typography fontSize="14px" fontStyle="italic" padding="10px">
        {formatMessage({ id: "panels.overview.table.specsFooter" })}
      </Typography>
    </Grid>
  );
}
