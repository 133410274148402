import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  DialogActions,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import { useInputs } from "@bellawatt/use-inputs";
import CollapsibleInput from "../../inputs/CollapsibleInput";
import { BackArrowIcon } from "../../assets/icons/icons";
import ButtonInput from "../../components/ButtonInput";
import ModalButtonRow from "./ModalButtonRow";
import VehicleUsage from "./VehicleUsage";
import ChargingWindows from "./ChargingWindows";
import "./EditVehicleSetDialog.css";
import SelectVehicleStep from "../../onboarding/steps/selectVehicleStep/SelectVehicleStep";

export default function EditVehicleSetDialog({
  isOpen,
  onClose,
  onDelete,
  canRemove,
  onSave,
  vehicleSet,
  onChangeVehicleSet,
  onChangeMake
}) {
  const { formatMessage } = useIntl();
  const {
    isSetVehicleModal,
  } = useInputs();

  // const { averageMilesWorkday } = vehicleSet;

  // const hoursExceeded = averageMilesWorkday > 250;

  // const disable = hoursExceeded;

  return (
    <Dialog
      open={isSetVehicleModal}
      onBackdropClick={onClose}
      maxWidth={"100%"}
      fullWidth
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <DialogTitle>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "flex-start",
              alignItens: "center",
              width: "100%",
              [theme.breakpoints.up("sm")]: { width: "902px" },
            })}
          >
            <Box sx={{ width: "35%", marginTop: "10px" }}>
              <ButtonInput variant="link" onClick={onClose}>
                <BackArrowIcon width="32px" />
              </ButtonInput>
            </Box>
            <Box sx={{ width: "auto" }}>
              <Typography fontSize="48px" fontWeight="200">
                {formatMessage({ id: "inputs.vehicleSet.editSetDialog.title" })}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ width: "902px", justifyContent: "center", padding: 0 }}
          className="hiddenScroll"
        >
          <Box mb="4px">
            <CollapsibleInput
              title={formatMessage({
                id: "inputs.vehicleSet.editSetDialog.selection",
              })}
              hasAllBorder
              defaultOpen
            >
              <Box padding="32px">
                <SelectVehicleStep
                  vehicleSet={vehicleSet}
                  onChangeMake={onChangeMake}
                  onChangeVehicleSet={onChangeVehicleSet}
                />
              </Box>
            </CollapsibleInput>
          </Box>

          <Box mb="4px">
            <CollapsibleInput
              title={formatMessage({
                id: "inputs.vehicleSet.editSetDialog.usage",
              })}
              hasAllBorder
            >
              <Box padding="32px">
                <VehicleUsage
                  vehicleSet={vehicleSet}
                  onChangeVehicleSet={onChangeVehicleSet}
                  // hoursExceeded={hoursExceeded}
                />
              </Box>
            </CollapsibleInput>
          </Box>

          <CollapsibleInput
            title={formatMessage({
              id: "inputs.vehicleSet.editSetDialog.behavior",
            })}
            hasAllBorder
          >
            <Box>
              <ChargingWindows
                small
                vehicleSet={vehicleSet}
                onChangeVehicleSet={onChangeVehicleSet}
              />
            </Box>
          </CollapsibleInput>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ModalButtonRow
            onClose={onClose}
            onDelete={onDelete}
            showDelete={canRemove}
            onSaveAndClose={onSave}
            // disable={disable}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
}
