import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';
import { TrashIconVariant } from '../../assets/icons/icons';
import WarningButtonRow from './WarningButtonRow';

export default function ModalButtonRow({ onClose, onDelete, onSaveAndClose, showDelete, disable }) {
  const { formatMessage } = useIntl();
  const [showWarning, setShowWarning] = useState(false);

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%" sx={{width: '47%'}}>
      {showWarning ? (
        <WarningButtonRow onCancel={() => setShowWarning(false)} onDelete={onDelete} />
      ) : (
        <>
          {showDelete && (
            <ButtonInput onClick={() => setShowWarning(true)} variant="link" alert>
              <Box display="flex" alignItems="center">
                <TrashIconVariant customColor={'#E35205'} />
                <Typography pl={1} fontSize="16px" color='#E35205'>
                  {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.delete' }).toUpperCase()}
                </Typography>
              </Box>
            </ButtonInput>
          )}

          <Box display="flex">
            <ButtonInput variant="outlined" onClick={onClose} style={{marginRight: '24px'}}>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.cancel' })}
            </ButtonInput>

            <ButtonInput variant="rounded" onClick={() => onSaveAndClose()} disabled={disable}>
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.save' })}
            </ButtonInput>
          </Box>
        </>
      )}
    </Box>
  );
}
