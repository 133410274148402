import React from 'react';
import { Box } from '@mui/system';
import { Typography, useMediaQuery } from '@mui/material';
import { AlertIcon, AlertErrorIcon } from '../assets/icons/icons';

const Alert = ({ variant, margin, children, customIcon }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const styles = () => {
    switch (variant) {
      case 'warning':
        return [
          { sx: { background: '#FFEFEF' }, icon: customIcon ? customIcon : <AlertErrorIcon /> },
        ];

      case 'regular':
        return [{ sx: { background: '#C3D7EE' }, icon: customIcon ? customIcon : <AlertIcon /> }];

      default:
        return [{ sx: { background: '#C3D7EE' }, icon: customIcon ? customIcon : <AlertIcon /> }];
    }
  };

  const stylesSx = styles()[0].sx;

  return (
    <Box
      display="flex"
      sx={{
        width: '100%',
        borderRadius: '16px',
        padding: isMobile ? '16px' : '16px 24px',
        margin: margin,
        alignItems: 'center',
        flexDirection: 'row',
        ...stylesSx,
      }}
    >
      <Box
        width="auto"
        height="auto"
        display="flex"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          alignSelf: 'flex-start',
          margin: isMobile && '0 0 16px 16px',
          '@media (max-width: 768px)': {
              alignSelf: 'center',
          },
        }}
      >
        {styles()[0].icon}
      </Box>
      <Box ml="24px">
        <Typography color="#3D3935" fontSize="16px" fontWeight="300" lineHeight="20px">
          {children}
        </Typography>
      </Box>
    </Box>
  );
};

export default Alert;
