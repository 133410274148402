import React from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import ContactIcon from '../assets/contactBg.png'

import { useIntl } from 'react-intl';
import GaTracker from '../utils/GaTracker';

export default function FindYourRep() {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const handleClick = () => {
    GaTracker.trackEvent({
      category: "Lead Generation",
      action: "Contact sales consultant",
      label: "Click Contact sales consultant"
    });
  }

  return (
    <Link
      href="https://www.gobrightdrop.com/request-info"
      target="_blank"
      rel="noreferrer"
      color={theme.palette.grey[900]}
      onClick={handleClick}
    >
      <Box
        position="fixed"
        bottom="15px"
        right="5%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        zIndex={3}
        sx={(theme) => ({ [theme.breakpoints.up('sm')]: { bottom: '80px' } })}
      >
        <Box
          boxShadow="0px 0px 16px rgba(0, 0, 0, 0.13)"
          borderRadius="24px"
          backgroundColor="white"
          width="150px"
          height="40px"
          pt="2px"
          mb="12px"
          textAlign="center"
          padding='3px 2px'
        >
          <Typography fontSize="12px" fontFamily="Overpass" fontWeight="bold">
            {formatMessage({ id: 'findYourRep' })}
          </Typography>
        </Box>
        <img src={ContactIcon} alt='Contact us' />
      </Box>
    </Link>
  );
}
