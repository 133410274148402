import { Box, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import ButtonInput from '../../components/ButtonInput';

export function ActionButtons({ onClose, onSave, disableSave }) {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      py={3}
      borderTop={`1px solid ${theme.palette.grey[400]}`}
      sx={(theme) => ({ px: 3, [theme.breakpoints.up('sm')]: { px: '70px' } })}
    >
      <ButtonInput variant="outlined" onClick={onClose}>
        {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.cancel' })}
      </ButtonInput>
      <ButtonInput variant="contained" onClick={onSave} disabled={disableSave}>
        {formatMessage({ id: 'inputs.vehicleSet.vehicleCatalog.confirm' })}
      </ButtonInput>
    </Box>
  );
}
