export const formatAsThousands = (val, round = true) => {
  let ret = val;
  if (round) {
    ret = Math.round(val);
  }

  return ret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatAsDollars = (val) => {
  if (isNaN(val)) {
    return val;
  }

  if (val < 0) {
    return `-$${formatAsThousands(-val)}`;
  }
  return `$${formatAsThousands(val)}`;
};

export const formatAsCents = (val) => `$${val.toFixed(2)}`;

export const formatHours = (hours24) => {
  const hours = ((hours24 + 11) % 12) + 1;
  const amPm = hours24 > 11 ? "pm" : "am";
  return hours + amPm;
};

export const titleCase = (string) => {
  if (string === null || string === "") return "";
  const str = string.toString();

  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
};

export const formatVehicleName = ({ make, model, type, subtype }) => {
  const name = make ? `${make} ${model}` : `${type} (${subtype})`;
  return titleCase(name).replaceAll("_", " ");
};

export const formatVehicleSetTitle = ({ vehicleCount, vehicle }) =>
  `${vehicleCount}x ${formatVehicleName(vehicle)}`;

export const formatChargerName = ({ make, model, model_variant }) =>
  `${make} ${model}${model_variant ? ` (${model_variant})` : ""}`;

export const formatGenericChargerName = ({ type, portKw, ports }) =>
  `${type} - ${portKw}kW ${
    ports === 2 ? "Dual Port" : ports > 2 ? ports + " Ports" : ""
  }`;

export const formatTimeRange = (start, finish) =>
  `${formatHours(start)} - ${formatHours(finish)}`;
