import { Box, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import { BlockIcon } from '../../assets/icons/icons';

export default function OutputMessage() {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  return (
    <Box
      display="flex"
      justifyContent="start"
      alignItems="center"
      p="4px"
      mx="6px"
      mb={2}
      backgroundColor={theme.palette.common.red[100]}
    >
      <Box>
        <BlockIcon />
      </Box>
      <Typography pl="7px" fontSize="11px" fontWeight="700" color={theme.palette.common.red[400]}>
        {formatMessage({
          id: 'panels.charging.infoCard.warning',
        })}
      </Typography>
    </Box>
  );
}
