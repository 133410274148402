import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, FormControlLabel, Checkbox, useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import CheckboxButtonRow from '../../inputs/CheckboxButtonRow';
import { DAYS_ABBREVIATED, MONTHS_ABBREVIATED } from '../../data/timePeriods';
import { isEqual } from 'lodash';
import ButtonInput from '../../components/ButtonInput';

function SectionLabel({ children }) {
  return (
    <Typography fontSize="14px" fontWeight="300" mb="8px" color={'#1A1A1A'}>
      {children}
    </Typography>
  );
}

export default function OperationsDaysMonths({ vehicleSet, onChangeVehicleSet }) {
  const { formatMessage } = useIntl();
  const { workdays, workmonths } = vehicleSet;
  const [weekdaysOnly, setWeekdaysOnly] = useState(isEqual(workdays, [1, 2, 3, 4, 5]));
  const [allMonths, setAllMonths] = useState(workmonths.length === 12);
  const [showMonths, setShowMonths] = useState(false);
  const [showDays, setShowDays] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    setWeekdaysOnly(isEqual(workdays, [1, 2, 3, 4, 5]));
  }, [workdays]);

  useEffect(() => {
    setAllMonths(workmonths.length === 12);
  }, [workmonths]);

  const handleWeekdaysCheckbox = () => {
    if (!weekdaysOnly) {
      setWeekdaysOnly(true);
      onChangeVehicleSet({ workdays: [1, 2, 3, 4, 5] });
    } else {
      setWeekdaysOnly(false);
    }
  };

  const handleMonthsCheckbox = () => {
    if (!allMonths) {
      setAllMonths(true);
      onChangeVehicleSet({ workmonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] });
    } else {
      setAllMonths(false);
    }
  };

  return (
    <Grid container rowSpacing={6}>
      <Grid item xs={12}>
        <SectionLabel>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.daysOperation' })}
        </SectionLabel>
        <FormControlLabel
          sx={{ marginBottom: '24px' }}
          control={
            <Checkbox
              style={{
                color: weekdaysOnly ? '#0072CE' : '#333333',
              }}
              checked={weekdaysOnly}
              onClick={() => handleWeekdaysCheckbox()}
            />
          }
          label={
            <Typography fontSize="14px" fontWeight="300" color="#1a1a1a">
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.weekdays' })}
            </Typography>
          }
        />
        <SectionLabel>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.customDays' })}
        </SectionLabel>
        {!weekdaysOnly || showDays ? (
          <CheckboxButtonRow
            width="100%"
            data={DAYS_ABBREVIATED}
            selectionArray={workdays}
            onChange={(value) => onChangeVehicleSet({ workdays: value })}
          />
        ) : (
          <Box>
            <ButtonInput
              fullWidth={isMobile}
              variant="outlined"
              small
              onClick={() => setShowDays(true)}
            >
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.specifyDays' })}
            </ButtonInput>
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <SectionLabel>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.monthsOperation' })}
        </SectionLabel>
        <FormControlLabel
          sx={{ marginBottom: '24px' }}
          control={
            <Checkbox
              style={{
                color: allMonths ? '#0072CE' : '#333333',
              }}
              checked={allMonths}
              onClick={() => handleMonthsCheckbox()}
            />
          }
          label={
            <Typography fontSize="14px" fontWeight="300" color="#1a1a1a">
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.allMonths' })}
            </Typography>
          }
        />
        <SectionLabel>
          {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.customMonths' })}
        </SectionLabel>
        {!allMonths || showMonths ? (
          <CheckboxButtonRow
            data={MONTHS_ABBREVIATED}
            wrapInHalf
            selectionArray={workmonths}
            onChange={(value) => onChangeVehicleSet({ workmonths: value })}
          />
        ) : (
          <Box>
            <ButtonInput
              fullWidth={isMobile}
              variant="outlined"
              small
              onClick={() => setShowMonths(true)}
            >
              {formatMessage({ id: 'inputs.vehicleSet.editSetDialog.specifyMonths' })}
            </ButtonInput>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}
