import CHARGERS from '../data/CHARGERS';
import { ELECTRIC_VEHICLES, ICE_VEHICLES, ARCHETYPES_VEHICLE } from '../data/VEHICLES';
import {
  transformGenericToElectricVehicle,
  transformGenericToFossilVehicle,
} from '../utils/conversions';

const computed = {
  vehicleSets: ({ vehicleSets, apiFossilVehicles, apiElectricVehicles, apiChargers, computeData }) => {
    if(!computeData) return vehicleSets

    return vehicleSets.map((vehicleSet) => {
      let existingVehicle =
        // commented temporary since API vehicles is having wrong data and we hardcoded vehicles here 
        // apiFossilVehicles.find((v) => v.id === vehicleSet.existingVehicleId) ||
        transformGenericToFossilVehicle(
          ICE_VEHICLES.find((v) => v.id === vehicleSet.existingVehicleId) || {},
        );

      const archetypeVehicle =
        transformGenericToFossilVehicle(
          ARCHETYPES_VEHICLE.find((v) => v.id === vehicleSet.existingVehicleId) || {},
        );

      const replacementVehicle =
        // commented temporary since API vehicles is having wrong data and we hardcoded vehicles here 
        // apiElectricVehicles.find((v) => v.id === vehicleSet.replacementVehicleId) ||
        transformGenericToElectricVehicle(
          ELECTRIC_VEHICLES.find((v) => v.id === vehicleSet.replacementVehicleId) || {},
        );

      const chargingWindows = vehicleSet.chargingWindows.map((chargingWindow) => ({
        ...chargingWindow,
        charger: chargingWindow.isGeneric
          ? CHARGERS.find(({ id }) => id === chargingWindow.chargerId)
          : apiChargers.find(({ id }) => id === chargingWindow.chargerId),
      }));
      
      if(['on_road_step_van_light_duty', 'on_road_step_van_medium_duty', 'on_road_cargo_van_medium_duty', 'on_road_cargo_van_light_duty'].indexOf(vehicleSet.existingVehicleId) >= 0) {
        existingVehicle = archetypeVehicle
      }

      return {
        ...vehicleSet,
        chargingWindows,
        existingVehicle,
        replacementVehicle,
      };
    })
  }
};

export default computed;
