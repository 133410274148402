import React, { useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Connectivity from './Connectivity';
import ChargerDetails from './ChargerDetails';
import ChargerCatalog from '../../dialogs/chargerCatalog/ChargerCatalog';
import { formatGenericChargerName } from '../../utils/formatters';
import ButtonInput from '../../components/ButtonInput';

const NameRows = ({ brand, isPreferred, displayType }) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography mt={1} pb="4px" fontSize="16px" fontWeight="400">
          {brand}
        </Typography>
      </Box>
      <Typography fontSize="14px" fontWeight="400">
        {displayType}
      </Typography>
    </>
  );
};

export default function ChargerCard({ vehicleData, charger, isGeneric, start, finish, windowId }) {
  const { formatMessage } = useIntl();
  const { imageUrl, ports, chargerType, portKw, name } = charger;
  const [openCatalog, setOpenCatalog] = useState(false);

  const image =
    imageUrl ||
    'https://assets.zappyride.com/img/commercial_chargers/archetypes/SINGLE PORT WALL CHARGER 7.7KW.png';

  const brand = isGeneric ? formatMessage({ id: 'panels.charging.infoCard.generic' }) : name;

  const displayType = formatGenericChargerName({
    type: formatMessage({ id: `chargerTypes.${chargerType}` }),
    portKw,
  });

  // TODO: real data
  const isPreferred = false;

  const isDualPort = ports === 2;

  return (
    <Box
      key={windowId}
      display="flex"
      flexDirection="column"
      borderRadius="16px"
      width="265px"
      border={`1px solid #BBBBBB`}
      px={2}
      py={2}
    >
      <Box height="100px">
        <NameRows isPreferred={isPreferred} brand={brand} displayType={displayType} />
        {isDualPort && (
          <Box
            style={{
              padding: '4px',
              borderRadius: '5px',
              background: '#333333',
              margin: '16px 0',
              width: '65px',
            }}
          >
            <Typography fontSize="11px" fontWeight="700" color={'#ffff'} p="0" m="0">
              {formatMessage({ id: 'panels.charging.infoCard.dualPort' })}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        style={{ borderBottom: '1px solid #BBBBBB', paddingBottom: '16px' }}
      >
        <img src={image} alt={name || chargerType} style={{ width: 'auto', height: 160 }} />
      </Box>

      {!isGeneric && <Connectivity charger={charger} />}

      <Box flexGrow={1}>
        <ChargerDetails charger={charger} isGeneric={isGeneric} />
      </Box>
      
      <Box width="100%" display="flex" justifyContent="center" mt={2}>
        <ButtonInput onClick={() => setOpenCatalog(true)} variant="outlined" fullWidth style={{paddingTop: '5px', paddingBottom: '5px'}}>
          {formatMessage({ id: 'panels.charging.infoCard.change' })}
        </ButtonInput>
      </Box>

      <ChargerCatalog
        isOpen={openCatalog}
        onClose={() => setOpenCatalog(false)}
        charger={charger}
        vehicleData={vehicleData}
        start={start}
        finish={finish}
        windowId={windowId}
      />
    </Box>
  );
}
