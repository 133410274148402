import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { AlertIcon, CloundIcon, LeafIcon } from "../../assets/icons/icons";
import { useInputs } from "@bellawatt/use-inputs";
import EmissionsTable from "./EmissionsTable";
import { useIntl } from "react-intl";
import { formatAsThousands } from "../../utils/formatters";
import { useCalculations } from "../../utils/useCalculations";
import Alert from "../../components/Alert";

function SummaryBox({ icon, title, text }) {
  return (
    <Box
      p={"16px"}
      display="flex"
      alignItems="center"
      height="100%"
      width={"185px"}
      bgcolor="#C3D7EE"
      borderRadius="16px"
    >
      <Box mt={"8px"}>{icon}</Box>
      <Box display="flex" flexDirection="column" ml="16px" height="100%">
        <Typography
          variant="primary"
          fontSize="14px"
          fontWeight="300"
          color={"#3D3935"}
          mb="8px"
        >
          {title}
        </Typography>
        <Typography
          color={"#3D3935"}
          align="left"
          fontSize="14px"
          fontWeight="200"
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

export default function Emissions() {
  const { vehicleSets, electricityEmissions, lifespanYears } = useInputs();
  const { formatMessage } = useIntl();
  const Calculations = useCalculations();

  const emissionsData = Calculations.emissionsData({
    input: vehicleSets,
    electricityEmissions,
    lifespanYears,
  });

  const emissionsDataPerSet = vehicleSets.map((set) => {
    const {
      totalDieselMiles,
      totalDieselEmissions,
      fossilFuelVolume,
      totalMwh,
    } = Calculations.emissionsData({
      input: [set],
      electricityEmissions,
      lifespanYears,
    });

    return {
      totalDieselMiles: totalDieselMiles * set.vehicleCount,
      fossilFuelVolume: fossilFuelVolume * set.vehicleCount,
      c02emissionsByFossil: totalDieselEmissions * set.vehicleCount,
      totalMwh: totalMwh,
    };
  });

  const tonsSaved =
    (emissionsDataPerSet.reduce(
      (acc, setResult) => acc + setResult.c02emissionsByFossil,
      0
    ) +
      emissionsData.totalGasolineEmissions -
      emissionsData.electricityEmissionsFromEVs) /
    2204.6;
  const treesPlanted =
    (emissionsDataPerSet.reduce(
      (acc, setResult) => acc + setResult.c02emissionsByFossil,
      0
    ) +
      emissionsData.totalGasolineEmissions -
      emissionsData.electricityEmissionsFromEVs) /
    48;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={4}>
          <Alert variant="regular" customIcon={<AlertIcon />}>
            {formatMessage({ id: "panels.emissions.alert" })}
          </Alert>
        </Box>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={3}
          justifyContent="space-between"
          mb={8}
        >
          <Grid item xs={12} md={6} lg={3}>
            <SummaryBox
              title={formatMessage(
                { id: "panels.emissions.table.units.tons" },
                { value: tonsSaved.toFixed(0) }
              )}
              text={formatMessage({
                id: "panels.emissions.summary.co2SavedPerYear",
              })}
              icon={<CloundIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SummaryBox
              title={formatMessage(
                { id: "panels.emissions.table.units.tons" },
                { value: formatAsThousands(tonsSaved * lifespanYears) }
              )}
              text={formatMessage({
                id: "panels.emissions.summary.co2SavedLifetime",
              })}
              icon={<CloundIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SummaryBox
              title={formatAsThousands(treesPlanted)}
              text={formatMessage({
                id: "panels.emissions.summary.treesPlantedPerYear",
              })}
              icon={<LeafIcon />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <SummaryBox
              title={formatAsThousands(treesPlanted * lifespanYears)}
              text={formatMessage({
                id: "panels.emissions.summary.treesPlantedLifetime",
              })}
              icon={<LeafIcon />}
            />
          </Grid>
        </Grid>

        <EmissionsTable
          emissionsData={emissionsData}
          vehicleSets={vehicleSets}
        />

        <Box m="24px 0">
          <Typography
            variant="primary"
            fontSize="14px"
            fontWeight="300"
            color={"#3D3935"}
          >
            <i>
              {formatMessage({
                id: "panels.emissions.summary.emissionFigures",
              })}
            </i>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
